import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringDate24To12RoundFigureTime'
})
export class StringDate24To12RoundFigureTimePipe implements PipeTransform {

  transform(stringList: string): unknown {
    var finalString = null;
    var zeroString = "0";
    if(stringList===null || stringList===undefined){
      return finalString;
    }
    var hour = stringList.slice(0,2);
    var min = stringList.slice(3,5);

    if(30 > parseInt(min)) {
      min = '00';
    }

    if(12 < parseInt(hour)){
      var hourInt = parseInt(hour);
      hourInt = hourInt - 12;

      if(hourInt > 9) {
        finalString = (hourInt.toString()).concat(":",min," PM");
      } else {
        finalString = zeroString.concat(hourInt.toString(),":",min," PM")
      }

    } else if (12 == parseInt(hour)) {
      finalString = hour.concat(":",min," PM");
    }else {
      finalString = hour.concat(":",min," AM");
    }
    return finalString;
  }

}
