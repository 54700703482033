<!-- page content -->

<div class="right_col" role="main">
  <div class="form-group">
    <div class="clearfix"></div>


  </div>
  <div class="row">
    <div class="x_content">
      <div class="x_title">
        <h2>My Documents - {{username}}</h2>
        <div class="clearfix"></div>
      </div>
      <div class="x_panel">

        <!--

        <form id="fcForm" (ngSubmit)="f.form.valid" class="form-horizontal form-label-left" #f="ngForm" novalidate>



          <div class="">
            <div class="x_content">


              <div class="col-md-4 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label> Document Group </label>
                  <div>
                    <select id="documentGrp" class="form-control" name="dGroup" [(ngModel)]="myDocuments.documentGroup"
                      #documentGroup="ngModel" required>
                     

                      <option [value]="g" *ngFor="let g of docGrpArray">{{g}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-md-4 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label> Document Type </label>
                  <div>
                    <select id="documentTyp" class="form-control" name="dType" [(ngModel)]="myDocuments.documentType"
                      #documentType="ngModel" required>

                      <option [value]="t" *ngFor="let t of docTypArray">{{t}}</option>


                    </select>
                  </div>
                </div>
              </div>


              <div class="clearfix"></div>

              <div class="col-md-4 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label> File Name</label>
                  <div>
                    <input type="file" class="form-control" name="myFilename" [(ngModel)]="myDocuments.fileName"
                      #fileName="ngModel" name="FileName" required="required" autofocus="true" id="myFilename" />
                    <p *ngIf="submitted && !fileName.valid " class="help-block" style="color: red;">
                      Please Enter File Name</p>
                  </div>
                </div>
              </div>

              <div class="col-md-4 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label>Expiry Date</label>
                  <div>
                    <input type="date" class="form-control col-md-7 col-xs-12" name="expiryDate"
                      [(ngModel)]="myDocuments.expiryDate" #expiryDate="ngModel" />
                    <p *ngIf="submitted && !expiryDate.valid " class="help-block" style="color: red;">
                      Please Enter Expiry Date</p>
                  </div>
                </div>
              </div>


            </div>
          </div>






          <div class="actionBar">




            <div class="clearfix"> </div>
            <div class="pro-fab">
              <button class="btn btn-primary" type="button" (click)="save(f.form.valid)">Save</button>
              <button class="btn btn-danger-medium" type="button" (click)="reFresh()">Clear</button>

            </div>
          </div>


        </form>

      -->
        <!-- Listing the Documents-->

        

        <form id="docList" name="docList" type="multipart/form-data">

          <div class="Clearfix"></div>
          <div class="scrollsearchresults">
            <div class="col-xs-12 col-md-12">
              <div class="mobile">
                <div class="col-xs-12 col-md-6">
                </div>
                <table class="table table-hover cf">
                  <thead class="tablerow">
                    <tr class="rowcolors">
                      <th class="scrolltheadFixed">DOC Group</th>
                      <th class="scrolltheadFixed">Doc Type</th>
                      <th class="scrolltheadFixed">File Name</th>                      
                      <th class="scrolltheadFixed"> Upoaded Date </th>
                      <th class="scrolltheadFixed">Expiry Date</th>
                      <th class="scrolltheadFixed">Comments</th>
                      <th class="scrolltheadFixed">Status</th>
                      <th class="scrolltheadFixed"></th>
                      <th class="scrolltheadFixed"></th>
                      <th class="scrolltheadFixed"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td data-th="DOC Group">
                       <!-- <select id="documentGrp" class="form-control" name="dGroup"
                          [(ngModel)]="myDocuments.documentGroup" #documentGroup="ngModel" required>

                          <option>Address Proof</option>
                          <option> Id Proof</option>

                        </select>-->
                      </td>
                      <td data-th="Doc Type">
                        <!--<select id="documentTyp" class="form-control" name="dType"
                          [(ngModel)]="myDocuments.documentType" #documentType="ngModel" required>

                          <option>Aadhar Card</option>
                          <option> Voters Id</option>
                          <option> Passport</option>

                        </select>-->
                      </td>
                      <td data-th="FileName">
                        <input type="file" class="form-control col-md-7 col-xs-12" [(ngModel)]="myDoc.fileName"
                          name="dFieName" autofocus #fileName="ngModel" name="FileName" placeholder="FileName"
                          required="required" autofocus="true" id="docFieName" />
                      </td>

                 

                      <td data-th="Upoaded Date">
                       <!-- <input name="uDate" type="date" (input)="addExpense(j)" [(ngModel)]="myDocuments.date"
                          class="form-control" />-->
                      </td>
                      <td data-th="Expiry Date">
                       <!-- <input name="eDate" type="date" (input)="addExpense(j)" [(ngModel)]="myDocuments.date"
                          class="form-control" />-->
                      </td>

                      <td data-th="Comments">
                        <input type="text" class="form-control col-md-7 col-xs-12" [(ngModel)]="myDocuments.comments"
                          name="myComments" autofocus #comments="ngModel"  placeholder=""
                          required="required" autofocus="true" id="mycomments" />
                      </td>

                      <td data-th="Status">
                        <input type="text" class="form-control col-md-7 col-xs-12" [(ngModel)]="myDocuments.status"
                          name="myStatus" autofocus #fileName="ngModel"  placeholder=""
                          required="required" autofocus="true" id="mystatus" />
                      </td>

                      <!--<td>
                        <button class="btn btn-xs btn-success-small" data-method="upload" title="Upload"
                          (click)="documentUpload(myDocuments.id)">
                          <span class="docs-tooltip" data-toggle="tooltip">
                            <span class="fa fa-edit"></span>
                          </span>
                        </button>
                      </td>-->
                      <td>
                        <button class="btn btn-xs btn-success-small" data-method="download" title="download"
                         >
                          <span class="docs-tooltip" data-toggle="tooltip">
                            <span class="fa fa-edit"></span>
                          </span>
                        </button>
                      </td>
                      <td>
                        <button type="button" class="btn btn-xs btn-danger-small" data-method="delete" title="Delete"
                        >
                          <span class="docs-tooltip" data-toggle="tooltip">
                            <span class="fa fa-trash"></span>
                          </span>
                        </button>
                      </td>


                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </form>
      





      </div>
    </div>
  </div>
</div>