import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'allocationAmountPipe'
})
export class AllocationAmountPipePipe implements PipeTransform {
  Difference_In_Days: any;

  transform(number: number){ 
    return number.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

}
