export class LeaveMaster {

    basedOn: any;
    carryForward: Boolean;
    compensatoryOFf: Boolean;
    creditRule: any;
    colorCode: any;
    enacashSeperation: Boolean;
    encashable: Boolean;
    frequency: any;
    interveneHoliday: Boolean;
    leaveCredited: any;
    leaveName: any;
    leaveOption: any;
    leaveType: any;
    noOfDaysWorked: any;
    statutoryCode: any;
    totalPercenatage: any;
    type: any;
    validDays: any;
    dayAfterJoin: any;
    flatRate: any;
    maxLeaveCarryForward: any;
    accrual: any;

}