<!-- page content -->
<div class="full-page login-page"  style="background-color: rgba(51, 102, 255, 0.3) !important;">
	<div class="content" role="main" style=" height: 100%; display:flex ; justify-content: center;align-items: center;">
		<div class="container">
			<div class="row" style=" justify-content: center;">
				<!-- <div class="col-md-3"></div> -->
				<div class="col-md-7 col-sm-12 col-md-offset-6 col-sm-offset-3">

					<div class="card card-login" style="padding: 20px;border: 1px solid #ddd;">

						<div class="card-content">
							<div class=" mx-auto my-3">
								<img src="../../../assets/img/scale up PNG (2).png" alt="scaleupimage" style="height: 58px; display: block; margin: auto;">
							</div>
							<h4 class="card-title text-center" style="margin-bottom: 30px; font-size: 16px;">Please Enter the Company
								Name </h4>
							<form id="demo-form2" data-parsley-validate class="form-label-left" #f="ngForm">

								<p *ngIf="alertMsg" class="alert alert-danger">{{alertMsg}}</p>

								<div class="form-group label-floating" [ngClass]="{ 'has-error': submitted }">
									<label class="form-label" for="first-name">Company  Name <span
											class="required"></span>
									</label>
									<input type="text" id=compName class="form-control" name="companyName"
										[(ngModel)]="companyName" required="required" />
									<!--
										<div *ngIf="submitted && !companyName.valid" class="help-block">Please
										enter valid Company Name.</div>-->

									<div *ngIf="isDiscontinue" class="help-block">You are no longer a user.</div>


								</div>
								<div class="row" *ngIf="isSearching">
									<div class="col-12 text-center">

										<h4>Searching ... </h4>

									</div>
								</div>

								<!--

								   <div class="text-center">
										<button [disabled]="loading" type="submit" class="btn btn-success">Sign In</button>
										<div>
											<a href="#" data-target="#pwdModal" data-toggle="modal" (click)="clearsubalertMsg()">Forgot Password?</a>
										</div>
										<img *ngIf="loading" style="width:15px;height:15px;"
										src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
									</div>
								-->

								<div class="row">

									<ng-container *ngIf="apiResponse['Response'] == 'False'; else elseTemplate">

										<div class="col-12 text-center">
											<div class="alert alert-danger" role="alert">
												{{apiResponse['Error']}}

											</div>
										</div>

									</ng-container>

									<ng-template #elseTemplate>



									</ng-template>

									<div class="col-xs-12 mobile">
										<div *ngIf="customerList && customerList.length>0 ">
											<div class="scrollsearchresults">

												<ul class="p-l-5">
													<li class="d-flex flex-column p-t-5 p-b-5"
														*ngFor="let j=index; let customInfo of customerList">



														<div class="d-flex flex-column">
															<span style="font-size:15.0pt">{{customInfo.customername}}
															</span>
															<a class="mt-2" style="font-size:13.0pt"
																href="{{customInfo.domain}}">{{customInfo.domain}}</a>
														</div>




													</li>

												</ul>

											</div>

										</div>
									</div>

								</div>
								<!--
								<div class="d-flex justify-content-center">
									<a href="login" (click)="wantToLogIn()">Login </a>
								</div>
							-->


							</form>


						</div>
					</div>
				</div>

			</div>
		</div>
	</div>


</div>



<!-- /page content -->