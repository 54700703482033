import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { ContractclientDocument } from '../models/ContractClientDocument';
import { ContractclientService } from '../services/contractclient.service';
declare var $: any;

@Component({
  selector: 'app-contract-client-project-freeze',
  templateUrl: './contract-client-project-freeze.component.html',
  styleUrls: ['./contract-client-project-freeze.component.css']
})
export class ContractClientProjectFreezeComponent implements OnInit {
  contractClientData: ContractclientDocument[];
  client_id: ContractclientDocument[];
  contractClientProInfoData: any[] = [];
  contractClientProFreezeInfoData: any[] = [];
  contractClientProName: string;
  contractClientProFreezeData: ContractclientDocument;

  sendFreezeFile: ContractclientDocument;
  freezeTillDate: any;

  contractstream$!: Subscription;
  cllientBusName: string;

  projectName: string;
  startDateForProject: string;
  endDateForProject: string;

  projectFreezeDate: string;

  // Validation

  submitted: boolean = false;
  showprojectList: boolean = false;
  showfreezeList: boolean = false;

  constructor(private _snackBar: MatSnackBar,
    private contractclientService: ContractclientService) { }

  ngOnInit(): void {
    this.contractClientProFreezeData = new ContractclientDocument();
    this.getClientData()
  }

  getClientData() {
    this.contractstream$ = this.contractclientService.getContractClient().subscribe(
      data => {
        // console.log(data)
        this.contractClientData = data.client;
        // console.log("List of all contract client in client projects >>>> ", this.contractClientData);
      },
      error => {
        console.log(error);
      }
    );
  }

  getclientLocDetail(v: any, name: string) {
    // console.log("Anny >> >> >>", v, name)
    this.cllientBusName = v;
  }

  getProjectInfo() {
    this.client_id = [];

    this.client_id = this.contractClientData.filter((dName) => {
      return dName.business_name.toLowerCase() === this.cllientBusName.toLowerCase();
    });

    this.contractstream$ = this.contractclientService.getContractClientProjectforFreeze(this.client_id[0].id).subscribe(
      data => {
        // console.log("Respons from contract client Project List >>> ", data)
        this.contractClientProInfoData = [];
        this.contractClientProInfoData = data.contractprojectdeta;
        // console.log("Project list >>", this.contractClientProInfoData)

        this.showprojectList = true;
        if(this.contractClientProInfoData.length==0) {
          document.getElementById('formLable').innerText = 'All projects are freezed!';
        }

        this.freezeTillDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
        this.contractClientProFreezeData.freeze_date = this.freezeTillDate;

        this.getFreezeProject();
      },
      error => {
        console.log(error);
      }
    );
  }

  getFreezeProject() {
    this.contractstream$ = this.contractclientService.getContractClientProjectfreezeInfo(this.client_id[0].id).subscribe(
      data => {
        // console.log(data)
        this.contractClientProFreezeInfoData = [];
        this.contractClientProFreezeInfoData = data.contractprojectFreezedata;
      },
      error => {
        // console.log(error);
      }
    );
  }

  setFromAndToDate(e: any) {
    this.sendFreezeFile = e;
    this.startDateForProject = e.start_date;
    this.endDateForProject = e.end_date;
    this.projectName = e.projectName
    this.freezeTillDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    this.contractClientProFreezeData.freeze_date = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
  }

  setFreezeDate(e: any){
    this.freezeTillDate = this.contractClientProFreezeData.freeze_date;
    // console.log("Freeze date >> >> ",this.freezeTillDate)
  }

  addProjectFreezeData(valid) {
    this.submitted = true;

    if (!valid) {
      // console.log("Not valid")
      return
    }

    if (this.contractClientProFreezeInfoData.length!=0 && this.contractClientProFreezeInfoData.find(e => e.freeze_date === this.freezeTillDate)){
      // this.projectFreezeDate = `Project is freezed till ${this.freezeTillDate}`;
        $('#showAlertCantFreezeX').modal('toggle');
      return
    }

    this.sendFreezeFile.freeze_date = this.freezeTillDate;
    this.sendFreezeFile.contract_project_id = this.sendFreezeFile.id;
    // console.log("File >> ",this.sendFreezeFile)
    // console.log("Freeze date >> ",this.freezeTillDate)

    this.contractstream$ = this.contractclientService.saveContractProjectFreez(this.sendFreezeFile).subscribe(
      data => {
        // console.log(data)
        this._snackBar.open('Successfully freezed', 'Close', {
          duration: 2000,
        });

        this.getProjectInfo();
      },
      error => {
        // console.log(error);
        this.projectFreezeDate = error.error?.customException.message;
        $('#showAlertCantFreeze').modal('toggle');
        // this._snackBar.open(`Error: ${error.error?.customException.message}`, 'Close', {
        //   duration: 2000,
        // });
      }
    );
  }

  showFreezeTable(){
    if($('#showTable').hasClass('py-3')){
      $('#showTable').removeClass('py-3');
      $('#showFreezeProjectDataList').removeClass('fa-th-list')
      $('#showFreezeProjectDataList').addClass('fa-compress')
    } else {
      $('#showTable').addClass('py-3');
      $('#showFreezeProjectDataList').addClass('fa-th-list')
      $('#showFreezeProjectDataList').removeClass('fa-compress')
    }
    
    if(this.showfreezeList) {
      this.showfreezeList = false;
    } else {
      this.showfreezeList = true;
    }
  }

  ngOnDestroy(): void {
    this.contractstream$.unsubscribe();
  }
}
