<div class="app-content main-content">
    <div class="side-app main-container">

        <div class="alertcomp">

        </div>


        <div class="card-body px-0">
            <div class="page-leftheader my-3">
                <div class="page-title">Branch Master</div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form id="fcForm" class="form-horizontal form-label-left row" #branchform="ngForm">
                            <div class="col-md-7 col-sm-7 col-xs-7">
                                <div class="form-group label-floating">
                                    <label class="form-label">Add New Branch</label>
                                    <!-- <div>
                                        <div>
                                            <input type="text" class="form-control col-md-12 col-xs-12form-control col-md-12 col-xs-12"
                                                autocomplete="off" placeholder="Enter branch Name" name="branchName"
                                                [(ngModel)]="branchName" autofocus="true">
                                        </div>
                                    </div> -->
                                    <div>
                                        <input type="text" name="branch_name" class="form-control col-md-12 col-xs-12" autocomplete="off"
                                          placeholder="Enter branch Name" autofocus="true" [(ngModel)]="branchName" required
                                          #branch_name="ngModel" [ngClass]="(!branch_name.valid && branch_name.touched) ? 'is-invalid' : ''" />
                                      </div>
                                      <span class="help-text text-danger" *ngIf="(!branch_name.valid && branch.touched)">Enter branch Name</span>
                                </div>
                            </div>

                            <div class="col-md-5 col-sm-5 col-xs-5 justify-content-between align-items-start">
                                <div class="form-group label-floating">
                                    <label class="form-label">&nbsp;</label>
                                    <button *ngIf="addOrUpdate; else updateButton" type="button" id="navigatetarget"
                                        class="btn btn-primary w-40" style="margin-right: 3vw;" (click)="addBranch()">
                                        Add
                                    </button>
                                    <ng-template #updateButton>
                                        <button type="button" id="navigatetarget" class="btn btn-primary w-40"
                                            style="margin-right: 3vw;" (click)="updateBranch()">
                                            Update
                                        </button>
                                    </ng-template>
                                    <button type="button" class="btn btn-danger w-40" (click)="clear()">
                                        Clear
                                    </button>
                                </div>
                            </div>


                        </form>
                    </div>
                </div>
            </div>
        </div>


        <div class="card col-md-12 col-sm-12 col-xs-12">
            <div class="card-header border-bottom-0">
                <h3 class="card-title">List Of Branch</h3>
            </div>
            <div class="card-body card1S p-0">
                <div class="table-responsive">
                    <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                        id="hr-table">
                        <thead class="tablerow">
                            <tr class="rowcolors stickyatTop">
                                <th class="border-bottom-0 w-5 fs-6 ">Sl.No</th>
                                <th class="border-bottom-0 w-5 fs-6 ">Branch Name</th>
                                <th class="border-bottom-0 w-5 fs-6 ">Action</th>
                            </tr>
                        </thead>
                        <tbody style="max-height: 50vh; overflow: scroll;">
                            <tr *ngFor="let br of branchList; let i = index">
                                <td>{{i+1}}</td>
                                <td>{{br.name}}</td>
                                <td>
                                    <button class="btn btn-primary btn-icon btn-sm me-2" data-method="edit" title="Edit"
                                        (click)="editBranch(br.branchId,br.name)">
                                        <i class="fa fa-edit" data-bs-toggle="tooltip" data-original-title="edit"></i>
                                    </button>
                                    <button class="btn btn-danger btn-icon btn-sm me-2" data-method="delete"
                                        title="Delete" data-bs-target="#showAlertWarning"
                                        (click)="deleteBranch(br.branchId,br.name)">
                                        <i class="fa fa-trash" data-bs-toggle="tooltip" data-original-title="edit"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>

        </div>


    </div>
</div>