<div class="app-content main-content">
    <div class="side-app main-container">

        <div class="alertcomp">

        </div>


        <div class="card-header">
            <h1>Leave Management</h1>
        </div>

        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form id="fcForm" class="form-horizontal form-label-left row">
                            <div class="col-md-7 col-sm-7 col-xs-7">
                                <div class="form-group label-floating">
                                    <label class="form-label">Group By</label>
                                    <div>
                                        <div>
                                            <select id="groupByfilter" class="form-control" name="groupFilter"
                                                [(ngModel)]="groupByType" (ngModelChange)="changeGroupByType($event)">
                                                <option value="All" [selected]="true">All</option>
                                                <option value="{{sm.shiftname}}" *ngFor="let sm of shiftList">
                                                    {{sm.shiftname}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5 col-sm-5 col-xs-5 justify-content-between align-items-start">
                                <div class="form-group label-floating">
                                    <label class="form-label">&nbsp;</label>
                                    <button type="button" id="navigatetarget" class="btn btn-primary w-40"
                                        style="margin-right: 3vw;" (click)="onClickSearch()">
                                        Search
                                    </button>
                                    <button type="button" class="btn btn-danger w-40" (click)="checkBoxClear()">
                                        Clear
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>


        <!-- *ngIf="EmployeeListByGroup.length" -->
        <div class="card col-md-12 col-sm-12 col-xs-12">
            <div class="card-header border-bottom-0">
                <h3 class="card-title">List Of Employee</h3>
            </div>
            <div class="card-body card1S p-0">
                <div class="table-responsive">
                    <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                        id="hr-table">
                        <thead class="tablerow">
                            <tr class="rowcolors stickyatTop">
                                <th class="border-bottom-0 w-2 fs-6 "><input type="checkbox" style="cursor: pointer;"
                                        (change)="checkAllSelected($event)" class="checkboxMain"/></th>
                                <th class="border-bottom-0 w-5 fs-6 ">ID</th>
                                <th class="border-bottom-0 w-5 fs-6 ">Employee Name</th>
                                <th class="border-bottom-0 w-5 fs-6 ">Department </th>
                                <th class="border-bottom-0 w-5 fs-6 ">Shift</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let employee of EmployeeListByGroup; let i = index">
                                <td>
                                    <input type="checkbox" style="cursor: pointer;" class="checkboxclass"
                                        (change)="checkSelected( i , $event ,  EmployeeListByGroup )" />
                                </td>
                                <td>{{employee.uniqueemployeeid }}</td>
                                <td>{{employee.name}}</td>
                                <td>{{employee.departmentname}}</td>
                                <td>{{employee.shiftName}}</td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>

            <div class="card-footer d-flex justify-content-end">
                <button *ngIf="empSelectedListBulk.length === 0" type="button" class="btn btn-primary"
                    data-bs-toggle="modal" data-bs-target="#ModalOne" disabled>Apply To</button>
                <button *ngIf="empSelectedListBulk.length > 0" type="button" class="btn btn-primary"
                    data-bs-toggle="modal" data-bs-target="#ModalOne">Apply To</button>
            </div>

        </div>


        <div class="modal fade" id="ModalOne" tabindex="-1" aria-labelledby="ModalOneLabel" aria-hidden="true"
            style="display: none;">
            <div class="modal-dialog modal-lg modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="thirdModalLabel" style="font-size: 20px;">Assign LeaveType and
                            NoOfDays</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid ">
                            <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                id="hr-table">
                                <thead class="tablerow">
                                    <tr class="stickyatTop rowcolors">
                                        <th>Leave Type</th>
                                        <th>Days</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr *ngFor="let leavelist of LeaveTypeList; let i = index">
                                        <td data-th="Leave Type">{{ leavelist.leaveName }}</td>
                                        <td>
                                            <input type="text" class="form-control col-md-12 col-xs-12" value="0"
                                                (change)="onInputChange($event, i)">
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            (click)="update_handler()">Update</button>
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>




    </div>
</div>