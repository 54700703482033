import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { EmployeeMaster } from 'src/app/models/employeeMasterts';
import { EmployeeMasterservice } from 'src/app/services/employeemaster.service';
import { DocumentInfoService } from 'src/app/services/documentinfo.service';

@Component({
  selector: 'app-attendance-matrix',
  templateUrl: './attendance-matrix.component.html',
  styleUrls: ['./attendance-matrix.component.css']
})
export class AttendanceMatrixComponent implements OnInit {

  reportType: string = "Attendance Matrix Report";
  currentDate: string;
  @Input() attendanceMatrixReport: EmployeeMaster[] = [];
  @Input() fromDate: string;
  @Input() toDate: string;
  @Input() monthName: any;
  @Input() daysArray: any;
  @Input() dayOfWeekArray: any;
  @Input() LeaveList: any;
  @Input() LeavenamecolorCode: String;
  @Input() keyinempid: any;
  @Input() activeExitFilters: string;
  @Input() branch: string;
  @Input() listingFilters: string;
  // @Input() clearChild: boolean;
  @Input() clearChild: number;
  @Output() limitEvent = new EventEmitter();

  pageline: number;

  constructor(private employeeMasterservice: EmployeeMasterservice, private documentInfoService: DocumentInfoService) { }

  ngOnInit(): void {
    this.getLeaveListwithColorCode();
    this.pageline = 0;
  }

  getDynamicStyles(attendance: any) {
    let backgroundColor: string = '';

    if (attendance.annualHolidayColorCode) {
      backgroundColor = attendance.annualHolidayColorCode;
    } else if (attendance.weeklyHolidayColorCode) {
      backgroundColor = attendance.weeklyHolidayColorCode;
    } else if (attendance.leaveTypeColorCode) {
      backgroundColor = attendance.leaveTypeColorCode;
    }
    else {
      backgroundColor = 'white'; // You can change this to any default color  // Default background color if none of the conditions are met
    }

    return {
      'color': attendance.totalSwipeHour === '00:00:00' ? 'red' : 'black',
      'background-color': backgroundColor,
    };
  }

  getLeaveListwithColorCode() {
    this.employeeMasterservice.getLeaveList().subscribe(
      data => {
        this.LeaveList = data;
        console.log("List of LeavenamecolorCode available:", data)
      }, error => {
        console.error(error);
      }
    );

  }

  offsetChange(){
    this.pageline =  this.clearChild 
    console.log("passed / exisiting ", this.clearChild);
    console.log("increment value by ", this.pageline);
    this.pageline = this.pageline + 15;
    this.limitEvent.emit(this.pageline);
    // this.clearChild = this.pageline;
  }


  attendanceReportCSVDownload() {
    // console.log(`Unique Emp Id: ${this.uniqueEmployeeId}, Year: ${year}, Month: ${month}`)
    console.log("consolidate report value from  parent ")
    // console.log("1: ", this.fromDate, this.toDate, this.shift_setting, this.branch, this.selectedempid ? this.selectedempid : sessionStorage.getItem('id'))

    // console.log("2: ", this.fromDate, this.toDate, this.shift_setting, this.branch, this.keyinempid)

    this.documentInfoService.downloadAttendanceReportCSVFile(this.reportType, this.keyinempid ? this.keyinempid : null, this.fromDate, this.toDate, this.currentDate, this.activeExitFilters, this.branch , this.listingFilters).subscribe(data => {

      // console.log(data)
      var file = new Blob([data], { type: 'application/vnd.ms-excel' });
      var fileURL = URL.createObjectURL(file);
      var anchor = document.createElement("a");
      anchor.download = "AttendanceMatrixReport.xls";
      anchor.href = fileURL;
      anchor.click();
      //window.open(fileURL);
    },
      error => {
        console.log("Error Occured ", error);
        // console.log("Error Code ",error.status);
      })
  }














}
