import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
 
import { UserDetails } from '../models/UserDetails';
import { AlertService } from '../services';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-userdetails',
  templateUrl: './userdetails.component.html',
  styleUrls: ['./userdetails.component.css']
})
export class UserdetailsComponent implements OnInit {

  
  list:UserDetails[];
  constructor(private router: Router,private commonservice: CommonService, private alertmessage: AlertService) { }

  ngOnInit(): void {
   this.getemployelist();
  }
  
   
  getemployelist() {
    
    this.commonservice.getuserdetails().subscribe(
      data=>{
        console.log(data);
       
          this.list= data.userdet;
          console.log("list"+ this.list);
        
      },
      error=>{
        console.log(error);
      }
    );
  }
}
