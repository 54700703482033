export class ContractclientDocument {

    id: number;

    client_id: number;

    clientid: number;

    business_name: string;

    billing_type: string;

    billing_name: string;

    project_owner_id: string;

    name: string;
    
    project_owners_names: string;

    clientlocid: number;
    
    contract_location_id: number;

    contract_project_location_ID: number;
    
    location_name: string;
    
    contact_person: string;

    phone: string;

    gstin: string;

    address1: string;

    address2: string;

    city: string;

    state: string;

    pincode: string;

    location_type: string;

    contract_project_id: number;

    contract_projectID: number;
    
    projectname: string;

    freeze_date: string;

    start_date: string;

    end_date: string;

    start_date_x: Date;

    end_date_x: Date;

    remarks: string;

    project_location_id: number;

    project_id: number;

    billing_rate: string;

    allocationfor: string;

    counts: string;

    uom: string;

    designationid: number;

    designation: number;

    designation_id: number;

    designationID: number;

    rpt_manager: string;

    rpt_mang_emp_id: number;

    allocation_percentage: string;

    uniqueemployeeid: number;

    emp_id: number;

    empid: number;

    employeeID: string;

    attendence: number;

    netAttendence: number;

    companyRevenue: number;

    expenss: number;

    amount: number;
}
