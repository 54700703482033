export class PeopleInfo{
name: string;
password: string;
role: string;
visible:Boolean;
empId: string;
email: String;
mobileNumber: any;
discontinue: Boolean;
//id:number;
oldpassword : String;
id:number;
roleCode:string;
mob: any;
branchId : any;
secondaryRole: any[];

    // locationName:any;
    // locId:any;
    // displayName:any;
    // email:any;
    // mobileNumber:any;
    // priorityNo:any;
}
