import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
//import { VisitLogSearch, DispatchSummary, TmarkVisitLogHeader } from './../models/index';
import { ConstantService } from './constant.service';
declare var $: any;

@Injectable()
export class HelperService {

	// visitLogSearch: VisitLogSearch;
	// visitLogReports: TmarkVisitLogHeader[];
	// report: TmarkVisitLogHeader;

	startFinacialYear: any;
	endFinancialYear: any;


	// dispatchSummary: DispatchSummary;

	constructor(private datePipe: DatePipe, private constantService: ConstantService) { }


	// setDispatchInfo(selectedDispatch: any) {
	// 	this.dispatchSummary = selectedDispatch;
	// 	console.log(selectedDispatch);
	// }

	// getDispatchInfo(dispatchRoute: any) {
	// 	if (this.dispatchSummary.dispatchRouteId == dispatchRoute) {
	// 		console.log(this.dispatchSummary);
	// 		return this.dispatchSummary;
	// 	}
	// }

	// setVisitLogSeachDetails(searchDetails: any) {
	// 	this.visitLogSearch = searchDetails;
	// }

	// getVisitLogSearchDetails() {
	// 	return this.visitLogSearch;
	// }

	calcCurrentFinanceYr() {
		let finacialYear;
		var today = new Date();
		let sDate;
		let eDate;
		if ((today.getMonth() + 1) <= 3) {
			finacialYear = today.getFullYear()

		} else {
			finacialYear = (today.getFullYear() + 1)
		}
		sDate = new Date((finacialYear - 1), 3, 1);
		eDate = new Date(finacialYear, 2, 31);
		this.startFinacialYear = sDate.getTime();
		this.endFinancialYear = eDate.getTime();

	}

	getFinancialDate(dateIn: any) {
		let year = dateIn.getFullYear();
		let mth = dateIn.getMonth() + 1;
		let dt = dateIn.getDate();
		let month;
		let date;
		let finalDate;

		dt < 10 ? date = '0' + dt : date = dt;

		mth < 10 ? month = '0' + mth : month = mth;
		finalDate = year + '-' + month + '-' + date;
		return finalDate;
	}


	//prolake 2019-10-08 - start 

	public transformDate(contents: any[]) {

	//	this.visitLogReports = new Array<TmarkVisitLogHeader>();
		contents.forEach(content => {

			//this.report = new TmarkVisitLogHeader();
			if (content.visitDate) {
				let dateString = this.datePipe.transform(content.visitDate, 'yyyy-MM-dd');
				content.visitDate = new Date(dateString);
			} else {
				content.visitDate = this.constantService.NOT_VISITED;
			}
			
			if(content.assignFromDate){
				let assignFromDateString = this.datePipe.transform(content.assignFromDate, 'yyyy-MM-dd');
				content.assignFromDate = new Date(assignFromDateString);
			}


			//this.visitLogReports.push( this.report );

		});
		return contents;
	}

	//prolake 2019-10-08 - end 
}
