export class OrgFilesInfo{
    id : number;
    fileType : String;
    filePath : String;
    fileName : String;
    ulopadedBy : String;
    updatedBy : String;
    overAllStatus : String;  
    comments : String;
    createdDate : Date;
    uploadedOn : Date;
    updatedDate : Date;
}