import { Component } from '@angular/core';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MyDocuments } from '../models/mydocuents';
import { DocumentInfoService } from '../services/documentinfo.service';
import { AlertService } from '../services';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-bulkuploaddocuments',
  templateUrl: './bulkuploaddocuments.component.html',
  //styleUrls: ['./bulkuplaoddocuments.component.css']
  
  styleUrls: ['./bulkuploaddocuments.component.css']
})
export class BulkUploadDocComponent {
  public files: any[] = [];
  filesArray: any[] = [];
  fileslength : boolean;
  fileToUpload: File | null = null;
  selectedFiles: FileList;
  submitted: boolean;

  myDocuments: MyDocuments;
  uploadedDocDetails = [];

  excelDoc: any = {};
  strMessage = "";
  formula:string = "Multiple File Upload";


  multipleFilePath = [];
  listmultipleFilePath = [];
  shortLink: string = "";
  loading: boolean = false; // Flag variable
  constructor( private alertService: AlertService, private _snackBar: MatSnackBar, public dialog: MatDialog, private sanitizer: DomSanitizer, public datepipe: DatePipe, private docmentInfoService: DocumentInfoService) { }

  ngOnInit() {
    this.myDocuments = new MyDocuments();
    this.excelDoc.file = "";
    this.submitted = false
    this.strMessage = "";
    this.fileslength = true;
  }


  onFileChange(pFileList: File[]) {
    this.filesArray = Object.keys(pFileList).map(key => pFileList[key]);
    for(var i=0;i<this.filesArray.length; i++){
      this.files.push(this.filesArray[i]);
    }
    console.log("The Fils Size", this.files.length)
    this.fileslength = true;
    this._snackBar.open("Successfully upload!", 'Close', {
      duration: 2000,
    });
 

  }

  deleteFile(f) {
    this.files = this.files.filter(function (w) { return w.name != f.name });
    this._snackBar.open("Successfully delete!", 'Close', {
      duration: 2000,
    });
  }


  deleteFromArray(index) {
    console.log(this.files);
    this.files.splice(index, 1);
  }



  uploadImage(): void {
    
    if (this.files.length == 0) {
      this.fileslength = false;
      return;

    }else{
      this.fileslength = true;
    }

    //get image upload file obj;
    console.log("The Files Length is ", this.files.length);
  

    this.multipleFilePath = [];


    this.docmentInfoService.uploadMultipleDocument(this.files).subscribe(
      data => {
        console.log("The Return response is", data);
        this.multipleFilePath = data.mutipleFilePaths;
        this.listMultipleFile(this.multipleFilePath);
        this.alertmessages("Multiple Documents Successfully Uploaded For Temporary Folder" , "success");
        this.files = [];
        this.ngOnInit();
      },
      error => {
        console.log("The Error is ", error);
      });
  }

  listMultipleFile(multipleDocFiles: any) {
    this.listmultipleFilePath = [];
    if (this.multipleFilePath.length != 0) {
      for (var i = 0; i < this.multipleFilePath.length; i++) {
        this.listmultipleFilePath.push(this.multipleFilePath[i]);
        console.log("The multipleFile Path is", this.listmultipleFilePath[i]);

      }
    }
  }

  handleFileInput(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileToUpload = file;
    }
  }

  // OnClick of button Upload
  uploadFile() {
    this.submitted = true;
    if (this.fileToUpload?.size == 0) {
      return;
    }
    this.loading = !this.loading;
    this.uploadedDocDetails = [];
    console.log("The Name of Uploaded Files", this.fileToUpload.name);
    
    this.docmentInfoService.uploadExcelDocument(this.fileToUpload).subscribe(
      data => {
        console.log("The Return response is", data);
        this.uploadedDocDetails = data.empDocuments;
        console.log("The Employee Documents are", this.myDocuments);
        this.showResponse(this.uploadedDocDetails);
        this.excelDoc.file = ""; 
        this.submitted = false;
        this.fileToUpload = null;
        this.ngOnInit();
        //this.alertmessages(this.myDocuments , "success");
      },
      error => {
        console.log("The Error is ", error);
      });
    
  }

  showResponse(uploadedDocDetails: any){
    //var strMessage = "";
    for(var i=0;i<uploadedDocDetails.length;i++){
      console.log("The Employee Documents are", uploadedDocDetails[i].comments);
      this.strMessage = this.strMessage + "<br>" + uploadedDocDetails[i].comments;
     // this.strMessage = this.strMessage + "\n" + uploadedDocDetails[i].comments;
   
    }
    //this.alertmessages("Successfully Uploaded" , "success");
    //this.alertmessages(this.strMessage, "success");
    const modal = document.getElementById('showAlert');
    const overlay = document.getElementById('overlay');
    overlay.style.display = 'block';
    modal.style.display = 'block';

  }

  alertmessages(message:string , sign:string):void{
    let alertDiv = document.createElement('div');         
     alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function(){
      alertcomp.removeChild(alertDiv);
    }, 10000);
  }


  closeModal() {
    const modal = document.getElementById('showAlert');
    const overlay = document.getElementById('overlay');
    overlay.style.display = 'none';
    modal.style.display = 'none';
  }




  downloadCSV() {

    var options = {
      title: 'Document Details',
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      headers: ['employeeId', 'branchId', 'docGroup', 'docType', 'name', 'filePath','expiaryDate','comments']
    };
    let currentDateTime =this.datepipe.transform((new Date), 'MM/dd/yyyy h:mm:ss');
    this.formula = this.formula + "_" + currentDateTime;
    new Angular2Csv(this.uploadedDocDetails, this.formula, options);
  }
  
}
