export class mandatory {



    id: number;
    name: string;


    /// employeemaster mandatory field 

    EmployeeName: boolean;
    DOB: boolean;
    Gender: boolean;
    MobileNumber: boolean;
    Email: boolean;
    EmployeeID: boolean;
    Designation: boolean;
    ApproveAuthority: boolean;
    Department: boolean;
    UnitBranch: boolean;
    DOJ: boolean;


}