import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeShiftService } from '../services/employee-shift.service';

interface Shiftschedule{
  fromdate:string;
  todate:string;
}

@Component({
  selector: 'app-shiftallocated',
  templateUrl: './shiftallocated.component.html',
  styleUrls: ['./shiftallocated.component.css']
})
export class ShiftallocatedComponent implements OnInit {
  todate:string;
  fromdate:string;
  shiftallocated:any[] = [];
  limitPara: number;
  offset:number ;

  constructor(private shiftservice:EmployeeShiftService , private router:Router) { 
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; 
    this.fromdate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.todate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
  }

  ngOnInit(): void {
    this.limitPara = 10;
    this.offset = 0
  }

  search(e:Shiftschedule){

    console.log(e);
    

    if( !e.fromdate || e.fromdate.length === 0){
      this.alertmessages("From date Mandatory" , "danger")
      return;
    }
    if(!e.todate || e.todate.length === 0  ){
      this.alertmessages("To date Mandatory" , "danger")
      return;
    }

    if(!this.validateaDate(e.fromdate)){
      this.alertmessages("Date a not valid" , "danger")
      return;
    }else if(!this.validateaDate(e.todate)){
      this.alertmessages("Date is not valid" ,"danger")
      return;
    }


    let isValid:boolean = this.checkfromisGreaterThantodate(e.fromdate , e.todate);
    if(isValid){
      this.alertmessages("From date greater than To date" , "danger")
      return;
    }
    this.getempallocatedshifts(e.fromdate , e.todate)
    
  }
  checkfromisGreaterThantodate(from , to):boolean{
    let fromdate:Date = new Date(from);
    let todate:Date = new Date(to);

    if(fromdate.valueOf() > todate.valueOf()){
      return true 
    }
    return false;  
  }

  getempallocatedshifts(fromdate:string , todate:string){
    this.shiftservice.get_emp_allocatedShifts(fromdate , todate , this.limitPara , this.offset)
    .subscribe({
      next:(res)=>{
        if (this.offset == 0) {
          
          this.shiftallocated = res.empshiftinexplodedFormat

        } else {
          this.shiftallocated = this.shiftallocated.concat(res.empshiftinexplodedFormat);
        }
        
      },error:(err)=>{
        this.alertmessages("Error Occured ,Please try again ","danger");
      }
    })
  }

  validateaDate(d):boolean{
    let date:Date = new Date(d);
    let lyear = 1900;
    let currentyear = date.getFullYear();
    if(currentyear > lyear){
      return true;
    }
    return false;

  }

  onScroll() {
    this.offset = this.offset + 10;
    this.getempallocatedshifts(this.fromdate, this.todate);
  }

  limitpara() {
    this.limitPara = 0;
  }

  @HostListener('scroll', ['$event'])
  scrolled() {
    this.onScroll();
    const ele  = document.getElementById('scroll')
    console.log(ele.scrollTop)
  }


  alertmessages(message:string , sign:string):void{
		let alertDiv = document.createElement('div');         
		 alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
		<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
		let alertcomp = document.querySelector('.alertcomp');
		alertcomp.append(alertDiv);
		document.body.scrollTop = document.documentElement.scrollTop = 0;
    
		setTimeout(()=>{
		  alertcomp.removeChild(alertDiv);
		}, 3000);
	}
}
