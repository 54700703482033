import { Injectable } from "@angular/core";
import { CommonService } from "./common.service";
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { CustomerPeopleRole } from "../models/CustomerPeopleRole";
import { Configkey } from "../models/Configkey";
import { configSetting } from "../models/configSetting";

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  protected basePath = '/api';
  public defaultHeaders: Headers = new Headers();
  public url;

  constructor(private httpclient: HttpClient, private commonService: CommonService) { }

  public saveCustomer(customersInfo: any, showWarning: any): Observable<any> {
    this.url = '/v1/customer/save';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('showWarning', showWarning);
    return this.commonService.postWithHttpInfo(customersInfo, queryParameters, path);


  }


  public deleteCustomerPeopleRole(customerId: any, personId: any): Observable<any> {
    this.url = '/v1/customer/customerPeopleRole';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('customerId', customerId);
    queryParameters = queryParameters.set('personId', personId);
    return this.commonService.deleteQueryParamWithHttpInfo(queryParameters, path);
  }

  public getPeople(role: any): Observable<any> {
    this.url = '/v1/peopleByRole';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('role', role);


    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }



  public saveModules(customerModule: any, customerId: any): Observable<any> {
    this.url = '/v1/customer/modules';
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('customerId', customerId);
    const path = this.basePath + this.url;

    return this.commonService.postWithHttpInfo(customerModule, queryParameters, path);


  }



  public saveCustomerPeopleRole(customerPeopleRole: any, customerId: any): Observable<any> {
    this.url = '/v1/customer/customerPeopleRole';
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('customerId', customerId);
    const path = this.basePath + this.url;

    return this.commonService.postWithHttpInfo(customerPeopleRole, queryParameters, path);


  }


  public getCustomerModulesHistory(customerId: any): Observable<any> {
    this.url = '/v1/customer/modules/history';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('customerId', customerId);

    //return this.httpclient.post(path, queryParameters);/
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }




  public getCustomerPeopleRole(customerId: any) {
    this.url = '/v1/customer/customerPeopleRole';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('customerId', customerId);

    //return this.httpclient.post(path, queryParameters);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }


  public getCustomers(): Observable<any> {
    this.url = '/v1/customersearch/customerList';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }
  public searchCustomer(customername: any, custtomernum: any, limitPara: any, offsetPara: any, selected: any, allowedPermissionList: any, peopleid: any): Observable<any> {
    this.url = '/v1/customerSearch/customerList1';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('customername', customername);
    queryParameters = queryParameters.set('custtomernum', custtomernum);
    //queryParameters = queryParameters.set('customeralias', customeralias);
    queryParameters = queryParameters.set('limitPara', limitPara);
    queryParameters = queryParameters.set('offsetPara', offsetPara);
    queryParameters = queryParameters.set('selected', selected);
    queryParameters = queryParameters.set('allowedPermissionList', allowedPermissionList);
    queryParameters = queryParameters.set('peopleid', peopleid);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public customerData(customerId: any): Observable<any> {
    this.url = '/v1/customers/customersAllData';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('customerId', customerId);

    //return this.httpclient.post(path, queryParameters);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }


  public getCustomerModules(customerId: any): Observable<any> {
    this.url = '/v1/customer/modules';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('customerId', customerId);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }


  public getModules(): Observable<any> {
    this.url = '/v1/modules';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }


  public getDSCExpiryDetails(expiresWithInDays: any): Observable<any> {
    this.url = '/v1/customers/DSCexpiry';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('expiresWithInDays', expiresWithInDays);

    //return this.httpclient.post(path, queryParameters);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public deleteCustomer(customerId: any): Observable<any> {
    this.url = '/v1/customerSearch/deleteCUstomer';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('customerId', customerId);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public deleteCustomerModule(customerId: any, moduleId: any): Observable<any> {
    this.url = '/v1/customer/modules';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('customerId', customerId);
    queryParameters = queryParameters.set('moduleId', moduleId);
    return this.commonService.deleteQueryParamWithHttpInfo(queryParameters, path);
  }


  public updateCustomerModules(customerModule: any): Observable<any> {
    this.url = '/v1/customer/modules';
    const path = this.basePath + this.url;
    const queryParameters = new HttpParams();
    return this.commonService.putWithHttpInfo(customerModule, queryParameters, path);
  }

  public updateCustomerPeopleRole(customerPeopleRole: CustomerPeopleRole) {
    this.url = '/v1/customer/customerPeopleRole';
    const path = this.basePath + this.url;
    const queryParameters = new HttpParams();
    return this.commonService.putWithHttpInfo(customerPeopleRole, queryParameters, path);
  }


  public unauthorizedUser(searchCompany: any): Observable<any> {
    this.url = '/v1/customerSearch/unauthorizedUser';
    console.log("The Company Name for Search is", searchCompany.companyName);
    const path = this.basePath + this.url;
    //let queryParameters = new HttpParams();
    //queryParameters = queryParameters.set('customerName', searchCompany );

    let queryParams = new HttpParams();
    //queryParams = queryParams.set('customerName', searchCompany);
    queryParams = queryParams.set('customerName', searchCompany.companyName);
    //let limitPara = 0;
    //queryParameters = queryParameters.set('limitPara', limitPara);
    //queryParameters = queryParameters.set('offsetPara', offsetPara);
    //return this.getByQueryParamWithHttpInfo(queryParams,path);

    return this.commonService.getByQueryParamWithHttpInfo(queryParams, path);


  }

  public getConfigDataList(keyList: any[]): Observable<any> {
    const path = this.basePath + '/v1/configDataList'
    let queryParameters = new HttpParams();
    return this.httpclient.post(path,keyList);
  }


  public setdefault_emailid(config: Configkey): Observable<any> {
    const path = this.basePath + '/v1/config'
    let queryParameters = new HttpParams();
    // queryParameters.set('defaultemail' , emailid);
    return this.commonService.postWithHttpInfo(config, queryParameters, path)
  }
  public getdefault_emailid(): Observable<any> {
    const path = this.basePath + '/v1/config'
    let queryParameters = new HttpParams();
    // queryParameters.set('defaultemail' , emailid);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }
}
