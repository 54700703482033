export class EmployeeDocStatusUpdates {
    id:number;
    uniqueEmployeeId : number;
    employeename : String;
    docGroup : String;
    docType : String;
    fileName : String;
    overallStatus : String;
    comments : String;
    expiryDate : Date;
    uploadedOn : Date;
    approversUniqueEmployeeId : number;
    approvedBy : String;
    filePath : String;
}