export class RuleDetails {

    salaryHeadId: '';
    salaryHeadName: '';
    salaryDetailId: '';
    salaryDetailName: '';
    ruleId: '';
    ruleName: '';
    percentTotalIndic: '';
    grossSelectIndic: '';
    otAmountIncluded: '';
    bonusAmount: '';
    userId: '';
    daArears: false;
    ruleComp: ruleComp[] = [];
    ruleDetails: ruleDetailCredit[] = [];
}


export class ruleComp {
    selSalCompId: '';
    ruleId: '';
    selSalaryHeadId: '';
    selSalaryHeadName: '';
    selSalaryDetailId: '';
    selSalaryDetailName: '';
    userId: ''; // can be used has flag , has in backend we get based on logged user , not by passing from client side so for edit / update flag can be used here 
}


export class ruleDetailCredit {
    ruleDetailId: '';
    ruleId: '';
    salaryLowerLimit: '';
    salaryUpperLimit: '';
    percentAmount: '';
    calcLowerLimit: '';
    calcUpperLimit: '';
    indicator: '';
    userId: '';
}
