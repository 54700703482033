import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CommonService } from './common.service';
import { Fileextend } from '../models/Fileextend';
import { ContractclientDocument } from '../models/ContractClientDocument';

@Injectable({
  providedIn: 'root'
})
export class ContractclientService {

  protected basePath = '/api';
  public defaultHeaders: Headers = new Headers();
  public url;

  constructor(private httpclient: HttpClient, private commonService: CommonService) { }

  public getContractClient(): Observable<any> {
    this.url = '/v1/clientdetails';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    // console.log("Let's see the query parameter :",queryParameters)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getContractClientBillingTypes(): Observable<any> {
    this.url = '/v1/contract_client_billing_types';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    // console.log("Let's see the query parameter :",queryParameters)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getEmployeeList(): Observable<any> {
    this.url = '/v1/getEmployeeIdAndNames';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    // console.log("Let's see the query parameter :",queryParameters)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public saveContractClient(clientInfo: any): Observable<any> {
    this.url = '/v1/saveclient';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    // console.log("Let's see the query parameter :",queryParameters)
    return this.commonService.postWithHttpInfo(clientInfo, queryParameters, path);
  }

  public saveContractClientProOwner(clientProOwnerInfo: any): Observable<any> {
    this.url = '/v1/saveContractProjectOwner';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    // console.log("Let's see the query parameter :",queryParameters)
    return this.commonService.postWithHttpInfo(clientProOwnerInfo, queryParameters, path);
  }

  public getContractClientProOwner(clId: any): Observable<any> {
    this.url = '/v1/ProjectOwnerDetails';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('contract_projectID', clId)
    // console.log("Let's see the query parameter :",queryParameters)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public deleteContractClientProOwner(clientid: any): Observable<any> {
    this.url = '/v1/deleteContractProjectOwner';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('id', clientid)
    // console.log("Let's see the query parameter :",queryParameters)
    return this.httpclient.delete(path, {
      params: queryParameters
    })
  }

  public updateContractClient(clientInfo: any): Observable<any> {
    this.url = '/v1/clientupdate';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    // console.log("Let's see the query parameter :",queryParameters)
    return this.commonService.postWithHttpInfo(clientInfo, queryParameters, path);
  }

  public deleteContractClient(clientid: any): Observable<any> {
    this.url = '/v1/deleteClient';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('id', clientid)
    // console.log("Let's see the query parameter :",queryParameters)
    return this.httpclient.delete(path, {
      params: queryParameters
    })
  }

  public getContractClientLocation(clId: any): Observable<any> {
    this.url = '/v1/locationdetails';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('CLIENT_ID', clId)
    // console.log("Let's see the query parameter :",queryParameters)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public saveContractClientLocation(clientInfo: any): Observable<any> {
    this.url = '/v1/savelocation';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    // console.log("Let's see the query parameter :",queryParameters)
    return this.commonService.postWithHttpInfo(clientInfo, queryParameters, path);
  }

  public updateContractClientLocation(clientInfo: any): Observable<any> {
    this.url = '/v1/loactionupdate';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    // console.log("Let's see the query parameter :",queryParameters)
    return this.commonService.postWithHttpInfo(clientInfo, queryParameters, path);
  }

  public deleteContractClientLocation(clientLocid: any): Observable<any> {
    this.url = '/v1/deleteloaction';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('id', clientLocid)
    // console.log("Let's see the query parameter :",queryParameters)
    return this.httpclient.delete(path, {
      params: queryParameters
    })
  }

  public getContractClientProjectInfo(clId: any): Observable<any> {
    this.url = '/v1/contractProjectDetails';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('clientid', clId)
    // console.log("Let's see the query parameter :",queryParameters)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public saveContractClientProjectInfo(clientInfo: any): Observable<any> {
    this.url = '/v1/savecontractproject';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    // console.log("Let's see the query parameter :",queryParameters)
    return this.commonService.postWithHttpInfo(clientInfo, queryParameters, path);
  }

  public updateContractClientProjectInfo(clientInfo: any): Observable<any> {
    this.url = '/v1/updatecontractproject';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    // console.log("Let's see the query parameter :",queryParameters)
    return this.commonService.postWithHttpInfo(clientInfo, queryParameters, path);
  }

  public deleteContractClientProjectInfo(clientLocid: any): Observable<any> {
    this.url = '/v1/deletecontractproject';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('id', clientLocid)
    // console.log("Let's see the query parameter :",queryParameters)
    return this.httpclient.delete(path, {
      params: queryParameters
    })
  }

  // public saveContractClientProjectDocs(docToUpload: Fileextend[], project_id: any): Observable<any> {
  //   this.url = '/v1/saveprojectdoc';
  //   const path = this.basePath + this.url;

  //   // var formData: any = new FormData();
  //   // if (docToUpload) {
  //   //   let f: Fileextend | null = null;
  //   //   for (let i = 0; i < docToUpload.length; i++) {
  //   //     f = docToUpload[i];
  //   //     formData.append('file', f);
  //   //   }
  //   // }

  //   var formData: any = new FormData();
  //   let commentsMap = new Map<number, string>();
  //   if (docToUpload) {
  //     // let f: File | null = null;
  //     // for (let i = 0; i < docToUpload.length; i++) {
  //     //     f = docToUpload[i];
  //     //     formData.append('file', f);
  //     // }
  //     console.log(docToUpload)


  //     for (let i = 0; i < docToUpload.length; i++) {
  //       formData.append(`file`, docToUpload[i]);

  //       commentsMap.set(i, docToUpload[i]?.comment === undefined ? null : docToUpload[i]?.comment);

  //       formData.append(`comment[${i}]`, docToUpload[i]?.comment === undefined ? null :docToUpload[i]?.comment);
  //     }
  //     // formData.append('commentMap', JSON.stringify(commentsMap));
  //   }


  //   let value1: string | null = commentsMap.get(1); 
  //   console.log(value1)
  //   // const fileExtendObj = new Fileextend(myRenamedFile);

  //   //   this.files.push(fileExtendObj);

  //   //const formData: FormData = new FormData();

  //   //formData.append('file', docToUpload);

  //   let headers = new HttpHeaders();
  //   headers.append('Content-Type', 'multipart/form-data');
  //   //headers.append( 'Accept', 'multipart/form-data' );

  //   // const params = new HttpParams();

  //   const httpOptions = { headers: headers };

  //   return this.httpclient.post(path, formData, httpOptions);
  // }

  public saveContractClientProjectDocs(docToUpload: Fileextend, contract_project_id: any, client_id: any, comments: any, existingcomment: any[]): Observable<any> {
    this.url = '/v1/saveprojectdoc';
    console.log(existingcomment)
    const path = this.basePath + this.url;
    const formData: FormData = new FormData();
    if (docToUpload) {
      formData.append('file', docToUpload);
    }
    formData.append('existingcomments', JSON.stringify(existingcomment));
    let queryParameters = new HttpParams();
    if (comments) {
      queryParameters = queryParameters.set('comments', comments)
    }
    if (contract_project_id) {
      queryParameters = queryParameters.set('contract_project_id', contract_project_id);
      queryParameters = queryParameters.set('client_id', client_id);

    }
    return this.commonService.postWithHttpInfo(formData, queryParameters, path);
  }

  public getContractClientProjectDocs(contract_project_id: number): Observable<any> {
    this.url = '/v1/project_doc_details';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('contract_project_id', contract_project_id)
    // console.log("Let's see the query parameter :",queryParameters)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public deleteContractClientProjectDoc(docID: number): Observable<any> {
    this.url = '/v1/deletedoc';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('id', docID)
    return this.httpclient.delete(path, {
      params: queryParameters
    })
  }

  public saveContractClientProjectLoc(locToUpload: any[]): Observable<any> {
    this.url = '/v1/save_project_location';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(locToUpload, queryParameters, path);
  }

  public getContractClientProjectLoc(contract_project_id: number): Observable<any> {
    this.url = '/v1/projectlocationdetails';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('contract_project_id', contract_project_id)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public deleteContractClientProjectLoc(locID: number, project_id: number): Observable<any> {
    this.url = '/v1/delete_project_location';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('id', locID)
    queryParameters = queryParameters.set('project_id', project_id)
    return this.httpclient.delete(path, {
      params: queryParameters
    })
  }

  public getDesignationList(): Observable<any> {
    this.url = '/v1/getdesignation1';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public saveContractClientProDesEstData(cliProDesEstData: ContractclientDocument): Observable<any> {
    this.url = '/v1/save_desig_est';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(cliProDesEstData, queryParameters, path);
  }

  public getContractClientProDesEstData(cliProDesEstID: number): Observable<any> {
    this.url = '/v1/design_est_details';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('project_id', cliProDesEstID)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public updateContractClientProDesEstData(cliProDesEstData: ContractclientDocument): Observable<any> {
    this.url = '/v1/update_desig_est';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    // console.log("Let's see the query parameter :",queryParameters)
    return this.commonService.postWithHttpInfo(cliProDesEstData, queryParameters, path);
  }

  public deleteContractClientProDesEstData(desEstID: number, desLocID: number, desProId: number): Observable<any> {
    this.url = '/v1/delete_desig_est';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('id', desEstID)
    queryParameters = queryParameters.set('location_id', desLocID)
    queryParameters = queryParameters.set('project_id', desProId)
    return this.httpclient.delete(path, {
      params: queryParameters
    })
  }

  // public getEmpList(): Observable<any>{
  //   // Pending
  //   this.url = '/v1/getdesignation1';
  //   const path = this.basePath + this.url;
  //   let queryParameters = new HttpParams();
  //   return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  // }

  public getEmpListByDes(desID: number): Observable<any> {
    // console.log("Yes we got the ID Booii", desID)
    this.url = '/v1/employeeBydesignation';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('designationID', desID)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getReportingManager(desID: number): Observable<any> {
    // console.log("Yes we got the ID Booii", desID)
    this.url = '/v1/getreportingmanager';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('designationID', desID)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public saveContractClientProDesActData(cliProDesActData: ContractclientDocument): Observable<any> {
    console.log("Console data", cliProDesActData)
    this.url = '/v1/save_desig_actual';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(cliProDesActData, queryParameters, path);
  }

  public getContractClientProDesActData(proId: number): Observable<any> {
    // console.log("Yes we got the ID Booii", proId)
    this.url = '/v1/design_actual_details';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('project_id', proId)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public deleteContractClientProDesActData(id: number): Observable<any> {
    this.url = '/v1/delete_desig_actual';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('id', id)
    return this.httpclient.delete(path, {
      params: queryParameters
    })
  }

  public updateContractClientProDesActData(cliProDesActData: ContractclientDocument): Observable<any> {
    this.url = '/v1/updateProjectDesignActual';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    // console.log("Let's see the query parameter :",queryParameters)
    return this.commonService.postWithHttpInfo(cliProDesActData, queryParameters, path);
  }

  public getContractClientProjectforFreeze(clId: any): Observable<any> {
    this.url = '/v1/contractProjectNonFreezDetails';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('clientid', clId)
    // console.log("Let's see the query parameter :",queryParameters)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public saveContractProjectFreez(cliProData: ContractclientDocument): Observable<any> {
    this.url = '/v1/savepContractProjectFreeze';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(cliProData, queryParameters, path);
  }

  public getContractClientProjectfreezeInfo(clId: any): Observable<any> {
    this.url = '/v1/contractProjectFreezDetails';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('clientid', clId)
    // console.log("Let's see the query parameter :",queryParameters)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getContractProjectAllocationListAct(proId: any, proStartDate: any, proEndDate: any): Observable<any> {
    this.url = '/v1/contractProjectAllocationlistAct';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('project_id', proId)
    queryParameters = queryParameters.set('report_start_date', proStartDate)
    queryParameters = queryParameters.set('report_end_date', proEndDate)
    // console.log("Let's see the query parameter :",queryParameters)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getContractProjectAllocationListEst(proId: any, proStartDate: any, proEndDate: any): Observable<any> {
    this.url = '/v1/contractProjectAllocationlistEst';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('project_id', proId)
    queryParameters = queryParameters.set('report_start_date', proStartDate)
    queryParameters = queryParameters.set('report_end_date', proEndDate)
    // console.log("Let's see the query parameter :",queryParameters)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public downloadProjectReportCSVFile(proId: any, allocationfor: string, allocationloc: any, proStartDate: any, proEndDate: any): Observable<any> {
    console.log("proId : ", proId, " allocationfor : ", allocationfor, " allocationloc : ", allocationloc, " proStartDate : ", proStartDate, " proEndDate : ", proEndDate)
    this.url = '/v1/downloadContractClientAllocation';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('project_id', proId)
    queryParameters = queryParameters.set('allocation_for', allocationfor)
    queryParameters = queryParameters.set('allocation_loc', allocationloc)
    queryParameters = queryParameters.set('report_start_date', proStartDate)
    queryParameters = queryParameters.set('report_end_date', proEndDate)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path, { responseType: 'arraybuffer' });
  }
}
