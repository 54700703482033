export class Level {

	// expense 

	orderlevels: any;
	designation: any;
	role: any;
	department: any;
	user_id: any;
	timestamp: any;
	approvalLimit: number;
	policyapprovallimit: number;
	leaveAttendanceapprovallimit: number;
	leaveAttendanceapproveLevel: number;

	selectedLevel1: any;
	selectedDepartment1: any;
	designation1: any;
	role1: any;
	department1: any;

	selectedLevel2: any;
	selectedDepartment2: any;
	designation2: any;
	role2: any;
	department2: any;

	selectedLevel3: any;
	selectedDepartment3: any;
	designation3: any;
	role3: any;
	department3: any;

	// policy 

	policyorderlevels: any;
	policydesignation: any;
	policyrole: any;
	policydepartment: any;
	policyuser_id: any;
	policytimestamp: any;

	policyselectedLevel1: any;
	policyselectedDepartment1: any;
	policydesignation1: any;
	policyrole1: any;
	policydepartment1: any;

	policyselectedLevel2: any;
	policyselectedDepartment2: any;
	policydesignation2: any;
	policyrole2: any;
	policydepartment2: any;

	policyselectedLevel3: any;
	policyselectedDepartment3: any;
	policydesignation3: any;
	policyrole3: any;
	policydepartment3: any;

	// Leave and Attendance 

	leaveAttendanceorderlevels: any;
	leaveAttendancedesignation: any;
	leaveAttendancerole: any;
	leaveAttendancedepartment: any;
	leaveAttendanceuser_id: any;
	leaveAttendancetimestamp: any;

	leaveAttendanceselectedLevel1: any;
	leaveAttendanceselectedDepartment1: any;
	leaveAttendancedesignation1: any;
	leaveAttendancerole1: any;
	leaveAttendancedepartment1: any;

	leaveAttendanceselectedLevel2: any;
	leaveAttendanceselectedDepartment2: any;
	leaveAttendancedesignation2: any;
	leaveAttendancerole2: any;
	leaveAttendancedepartment2: any;

	leaveAttendanceselectedLevel3: any;
	leaveAttendanceselectedDepartment3: any;
	leaveAttendancedesignation3: any;
	leaveAttendancerole3: any;
	leaveAttendancedepartment3: any;

	// LeaveAttendance
	// leaveAttendance

}