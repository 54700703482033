import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router'


import 'rxjs';
import { ConstantService, SessionService } from '../services';
declare var $: any;
@Component({
    templateUrl: './sidebar.component.html',
    selector: 'sidebar'
})

export class SidebarComponent implements OnInit {

    planEntryCustomerName: string;
    showMenu: any;
    allowedPermissionList: any[] = [];
    rolePermission: any[] = [];
    loggedUsername: string;
    isloggedIn: boolean = false;
    MeId: any;
    constructor(
        private route: ActivatedRoute,
        private router: Router, private constantService: ConstantService, private sessionService: SessionService, private render: Renderer2) {
        if (window.innerWidth > 992) {
            const ele = document.getElementById('overlayMainComponent')

            const body = document.getElementById('bodytag');

            this.render.listen(ele, 'mouseover', () => {
                ele.style.display = "none";
                if (body.classList.contains('sidenav-toggled1')) {
                    body.classList.remove('sidenav-toggled1')
                    body.classList.add('sidenav-toggled')
                    // const imagele = document.getElementById('companyname');
                    // imagele.classList.add('d-none');
                    // const logo_element =document.getElementById('Scalelogo')
                    // logo_element.classList.remove('d-none');


                    // closing slide sub menu in sidebar
                    // if(document.querySelector('.is-expanded')){
                    //     const slide_sub_menu = document.querySelector('.is-expanded')
                    //     if(slide_sub_menu.querySelector(`.open`)){
                    //         slide_sub_menu.querySelector(`.open`).setAttribute("id","slide_none")
                    //         const slide_sub_menu_lists =  document.getElementById(`slide_none`);
                    //         slide_sub_menu.classList.remove('is-expanded')
                    //         slide_sub_menu_lists.classList.remove('open');
                    //         slide_sub_menu_lists.style.display = "none";
                    //         slide_sub_menu_lists.removeAttribute('id')
                    //     }

                    // }

                    // slide_sub_menu.removeAttribute("is-expanded")
                    // slide_sub_menu_lists.removeAttribute('open')
                }


            })

        }
    }

    ngOnInit() {

        this.rolePermission = this.constantService.getRolePermissions();
        //sujata change
        //   this.router.events.subscribe( (value) => { 
        //     if (value instanceof NavigationEnd) {
        //         console.log(value);

        // this.getLinks(value.url);
        //     }
        // } );
        this.allowedPermissionList = this.sessionService.getRolePermissionList();
        this.loggedUsername = sessionStorage.getItem('username');
        this.router.events.subscribe((value) => {
            if (value instanceof NavigationEnd) {
                if (value.url == '/login' || value.url == '/login/' || value.url == '/' || value.url == '/signup' || value.url == '/companyselection') {
                    this.isloggedIn = false;
                }
                else {
                    this.isloggedIn = true;

                    //today added
                    this.loggedUsername = sessionStorage.getItem('username');
                    console.log(sessionStorage.getItem('username'));

                    //new code for showing or hiding menu
                    if (sessionStorage.getItem('role') == "Employee")
                        //this.MeId = 1;
                        this.MeId = 0;
                    else
                        this.MeId = 0;
                }
            }
        });
    }

    toggleSideMenu() {
        var element1 = document.getElementById("sideNavBar");
        var element2 = document.getElementById("overlayer");

        if (element1.style.width === "0px" || element1.style.width === "") {
            element1.style.width = "250px";
            element2.style.display = "block"
        } else {
            element1.style.width = "0";
            element2.style.display = "none"
        }
    }

    changeView() {
        var element = document.getElementById("proApp");
        if (element.className === 'nav-md') {
            element.className = 'nav-sm';
        } else {
            element.className = 'nav-md';
        }
    }

    getUserRole() {
        return this.sessionService.getLoggedUserRole();
    }
    getusername() {
        return this.sessionService.getloggedusername();
    }

    logout() {
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('companyId');

        this.toggleSideMenu();

        this.router.navigate(['/login']);
    }

    menuClick(e) {
        // console.log(e);
        // console.log(e?.path)
        // console.log(e?.currentTarget?.childNodes[0]?.getAttribute("id"))

        const allelement = document.querySelectorAll(`.side-menu__item`)
        allelement.forEach(item => {
            if (item.classList.contains('active'))
                item.classList.remove('active');
        })
        const activeelement = document.getElementById(`${e?.currentTarget?.childNodes[0]?.getAttribute("id")}`)
        console.log(activeelement)
        if (activeelement) {
            activeelement.classList.add('active')
        }
        const body = document.getElementById('bodytag');
        // if(body?.classList?.contains('sidenav-toggled')){

        //     console.log("working inside side function")
        //     return
        // }
        // clearing the clicking functions already present on the element
        $("[data-bs-toggle='slide']").off('click');
        $("[data-bs-toggle='sub-slide']").off('click');
        $("[data-bs-toggle='sub-slide2']").off('click');
        // initiating the click function
        $("[data-bs-toggle='slide']").on('click', function (e) {
            const sidebar = document.getElementById('appSidebar3');
            sidebar.style.overflow = 'auto';
            var $this = $(this);
            var checkElement = $this.next();
            var animationSpeed = 300,
                slideMenuSelector = '.slide-menu';
            if (checkElement.is(slideMenuSelector) && checkElement.is(':visible')) {
                checkElement.slideUp(animationSpeed, function () {
                    checkElement.removeClass('open');

                    console.log(ul, "String in else if con : >>>>> 13")

                });
                checkElement.parent("li").removeClass("is-expanded");
            }
            else if ((checkElement.is(slideMenuSelector)) && (!checkElement.is(':visible'))) {
                var parent = $this.parents('ul').first();
                var ul = parent.find('ul:visible').slideUp(animationSpeed);
                ul.removeClass('open');
                var parent_li = $this.parent("li");

                parent.find('li.is-expanded').removeClass('is-expanded');
                checkElement.parent("li").removeClass("is-expanded");
                console.log(ul, "String in else if con : >>>>> 14")

                checkElement.slideDown(animationSpeed, function () {
                    checkElement.addClass('open');
                    parent.find('li.is-expanded').addClass('is-expanded');
                    parent_li.addClass('is-expanded');
                });
            }
            if (checkElement.is(slideMenuSelector)) {

                e.preventDefault();
            }
        });
        // Activate sidebar slide toggle
        $("[data-bs-toggle='sub-slide']").on('click', function (e) {
            // console.log(ul, "String in else if con : >>>>> 12")
            var $this = $(this);
            var checkElement = $this.next();
            var animationSpeed = 300,
                slideMenuSelector = '.sub-slide-menu';
            if (checkElement.is(slideMenuSelector) && checkElement.is(':visible')) {
                checkElement.slideUp(animationSpeed, function () {
                    checkElement.removeClass('open');

                    // console.log(ul, "String in else if con : >>>>> 16")
                });
                checkElement.parent("li").removeClass("is-expanded");
            }

            else if ((checkElement.is(slideMenuSelector)) && (!checkElement.is(':visible'))) {
                var parent = $this.parents('ul').first();
                var ul = parent.find('ul:visible').slideUp(animationSpeed);
                ul.removeClass('open');

                // console.log(ul, "String in else if con : >>>>>")

                var parent_li = $this.parent("li");
                checkElement.slideDown(animationSpeed, function () {
                    // console.log("its inside")
                    checkElement.addClass('open');
                    parent.find('li.is-expanded').removeClass('is-expanded');
                    parent_li.addClass('is-expanded');
                });
            }
            if (checkElement.is(slideMenuSelector)) {
                e.preventDefault();
            }
        });

        // Activate sidebar slide toggle
        $("[data-bs-toggle='sub-slide2']").on('click', function (e) {
            var $this = $(this);
            var checkElement = $this.next();
            var animationSpeed = 300,
                slideMenuSelector = '.sub-slide-menu2';
            if (checkElement.is(slideMenuSelector) && checkElement.is(':visible')) {
                checkElement.slideUp(animationSpeed, function () {
                    checkElement.removeClass('open');
                });
                checkElement.parent("li").removeClass("is-expanded");
            } else if ((checkElement.is(slideMenuSelector)) && (!checkElement.is(':visible'))) {
                var parent = $this.parents('ul').first();
                var ul = parent.find('ul:visible').slideUp(animationSpeed);
                ul.removeClass('open');
                var parent_li = $this.parent("li");
                checkElement.slideDown(animationSpeed, function () {
                    checkElement.addClass('open');
                    parent.find('li.is-expanded').removeClass('is-expanded');
                    parent_li.addClass('is-expanded');
                });
            }
            if (checkElement.is(slideMenuSelector)) {
                e.preventDefault();
            }
        });

        // To close the sub menu dropdown by clicking on inner content
        $('.hor-content').on('click', function () {
            $('.side-menu li').each(function () {
                $('.side-menu ul.open').slideUp(300)
                $(this).parent().removeClass("is-expanded");
                $(this).parent().parent().removeClass("open");
                $(this).parent().parent().prev().removeClass("is-expanded");
                $(this).parent().parent().parent().removeClass("is-expanded");
                $(this).parent().parent().parent().parent().removeClass("open");
                $(this).parent().parent().parent().parent().parent().removeClass("is-expanded");
            })
        })
    }

    mouseOver() {
        if (window.innerWidth > 992) {
            const body = document.getElementById('bodytag');
            // const imagele = document.getElementById('companyname');
            if (body.classList.contains('sidenav-toggled')) {
                body.classList.add('sidenav-toggled1')
                body.classList.remove('sidenav-toggled')
                const overlayMainComponent = document.getElementById('overlayMainComponent');
                overlayMainComponent.style.display = "block";
                // imagele.classList.remove('d-none');
                // const logo_element =document.getElementById('Scalelogo')
                // logo_element.classList.add('d-none');

            }
        }
    }
    mouseOut() {
        if (window.innerWidth > 992) {
            const body = document.getElementById('bodytag');
            if (body.classList.contains('sidenav-toggled1 ')) {
                body.classList.remove('sidenav-toggled1')
                body.classList.add('sidenav-toggled')
            }
        }
    }

    togglesidebarinmobileview() {
        let body = document.querySelector('body');
        if (window.innerWidth <= 992) {
            body.classList.remove('sidenav-toggled');
        }
        if (window.innerWidth > 992) {
            const ele = document.getElementById('sidemenu');
            if (document.querySelector('.is-expanded')) {
                const slide_sub_menu = document.querySelector('.is-expanded')
                if (slide_sub_menu.querySelector(`.open`)) {
                    slide_sub_menu.querySelector(`.open`).setAttribute("id", "slide_none")
                    const slide_sub_menu_lists = document.getElementById(`slide_none`);
                    // console.log(slide_sub_menu , slide_sub_menu_lists);
                    slide_sub_menu.classList.remove('is-expanded')
                    slide_sub_menu_lists.classList.remove('open');
                    slide_sub_menu_lists.style.display = "none";
                    slide_sub_menu_lists.removeAttribute('id')
                }

            }
        }
    }


    // getLinks(url) {
    //     if(url == '/login' || url == '/login/' || url == '/') {

    //         return;
    //     } else if(url == '/employeeswipe'){

    //         var role = sessionStorage.getItem('role');

    //         if(role=='Employee'){
    //             this.showMenu=0;
    //         }
    //         else
    //         {
    //             this.showMenu=1;
    //         }

    //         return;
    //     }



    // }


    notoggle() {
        console.log("no toggle ");
    }


}
