import { Component, HostListener, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { PeopleInfo } from '../models/peopleInfo';
import { Roles } from '../models/Roles';
import { AlertService, ConstantService, SessionService } from '../services';
//import { employee } from '../models/employee';
//import { branches } from '../models/branches';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

declare var $: any;
@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.css']
})


export class PeopleComponent implements OnInit {
  peopleList: PeopleInfo[];
  tablepeopleList: PeopleInfo[];
  peopleInfo: PeopleInfo;
  submitted: boolean;
  confirmPassword: String;
  passwordVerified: boolean;
  showPasswordErr: boolean;
  roles: Roles[];
  hidePassword: boolean;
  //employeeIdList : String[];
  //branch : branches[];
  permission: boolean;
  //branchname:string;
  userName: string;
  addUserPermission: boolean;
  limitPara: any;
  offsetPara: any;
  selectedqualifier: any;
  previousselectedqualifier: any;
  //employeeId:string;
  deleteUserName: string;

  hide_email: boolean;

  secondaryDepartmentObj: any;
  requestDepartmentObj: any[];
  model: any = {};
  secondaryRoleList: any = [];

  constructor(private commonService: CommonService, private alertService: AlertService, private sessionService: SessionService,
    private _snackBar: MatSnackBar, private constantService: ConstantService, private route: ActivatedRoute) { }

  ngOnInit() {


    this.peopleInfo = new PeopleInfo();
    this.peopleList = new Array<PeopleInfo>();
    //this.employeeIdList = new Array<String>();
    //this.branch = new Array<branches>();
    //this.branchname = '';
    //this.getBranchIds();
    //this.getPeoples();
    this.getRoles();
    this.addUserPermission = false;
    //this.getEmployeeIds();
    this.submitted = false;
    this.hidePassword = false;
    this.getUserRole();
    this.userName = "";
    this.limitPara = 0;
    this.offsetPara = 7;
    this.selectedqualifier = "Only Active"
    this.previousselectedqualifier = "Only Active"
    this.getAllPeoples();
    this.checkisRetrieved();
    this.hide_email;

  }

  checkisRetrieved() {
    const queryParams = this.route.snapshot.queryParams;

    if (queryParams.empIdcorrect != null) {
      const qualifier3 = queryParams.empIdcorrect;

      //   this.commonService.getEmployeeIds().subscribe(
      //     data => {
      //       if(data){
      //       this.employeeIdList = data.employeeIds;
      //   if (typeof this.employeeIdList !== 'undefined' && this.employeeIdList.length > 0) {
      //     for(let i=0; i<this.employeeIdList.length; i++){
      //       const qualifier2 = this.employeeIdList[i];
      //       if(qualifier3.trim() === qualifier2.trim()){
      //         this.peopleInfo.empId =  this.employeeIdList[i].toString();
      //         this.employeeId = this.employeeIdList[i].toString();
      //        }
      //       }
      //     }
      //   }
      // });
      //Selection of Branch Id
      //     const qualifier = queryParams.branchcorrect;
      //     this.commonService.getBranchIds().subscribe(
      //       data => {
      //         if(data){
      //           this.branch = data.branches;
      //        if (typeof this.branch !== 'undefined' && this.branch.length > 0) {
      //          for(let i=0; i<this.branch.length; i++){
      //           const qualifier1 = this.branch[i].branchId;
      //             if(qualifier == qualifier1){
      //           this.peopleInfo.branchId =  this.branch[i].branchId;
      //           this.branchname=this.branch[i].branchname;
      //         }
      //       }
      //     }
      //   }
      // });
    }
  }

  // selectBranchId() {

  //   const qualifier = (<HTMLInputElement>document.getElementById('branch')).value;
  //   let branchselected:boolean = false;
  //   console.log(qualifier);
  //   if (typeof this.branch !== 'undefined' && this.branch.length > 0) {
  //   for(let i=0; i<this.branch.length; i++){
  //     const qualifier1 = this.branch[i].branchname;
  //     if(qualifier.trim() === qualifier1.trim()){
  //       this.peopleInfo.branchId =  this.branch[i].branchId;
  //       branchselected = true;
  //     }
  //   }
  //   if(branchselected == false){
  //     this.branchname='';
  //   }
  // }
  // else{
  //   this.branchname='';
  // }
  // }

  // selectEmployeeId() {

  //   const qualifier = (<HTMLInputElement>document.getElementById('employeeIdList')).value;
  //   let employeeSelected:boolean = false;
  //   console.log(qualifier);
  //   if (typeof this.employeeIdList !== 'undefined' && this.employeeIdList.length > 0) {
  //   for(let i=0; i<this.employeeIdList.length; i++){
  //     const qualifier1 = this.employeeIdList[i];
  //     if(qualifier.trim() === qualifier1.trim()){
  //       this.peopleInfo.empId =  this.employeeIdList[i].toString();
  //       employeeSelected = true;
  //     }
  //   }
  //   if(employeeSelected == false){
  //     this.employeeId='';
  //   }
  // }
  // else{
  //   this.employeeId='';
  // }
  // }

  addUser() {
    this.addUserPermission = true;
    this.reFresh();
  }

  addSearch() {
    this.addUserPermission = false;
    this.reFresh();
    this.refreshTable();
  }

  onScroll() {
    this.limitPara = this.limitPara + 7;
    this.getAllPeoples();

  }

  limitpara() {
    this.limitPara = 0;
  }

  @HostListener('scroll', ['$event'])
  scrolled() {
    this.onScroll();
  }

  selectUserFilter() {
    console.log("The select user filter is called");
    const qualifier = (<HTMLInputElement>document.getElementById('useFilters')).value;
    if (qualifier === 'All') {
      this.getPeoples(this.userName);
    } else if (qualifier === 'Only Active') {
      this.getPeople(false, this.userName);
    } else if (qualifier === 'Non Active') {
      this.getPeople(true, this.userName);
    }
  }

  selectTableUserFilter() {

    // let qualifier =  (<HTMLInputElement>document.getElementById('useFilters')).value;
    let qualifier = this.selectedqualifier;

    if (qualifier === 'All') {
      //this.selectedqualifier="All";
      this.getAllPeoples();
    } else if (qualifier === 'Only Active') {
      //this.selectedqualifier="Only Active";
      this.getAllPeoples();
    } else if (qualifier === 'Non Active') {
      //this.selectedqualifier="Non Active";
      this.getAllPeoples();
    }
  }

  save(valid) {
    this.submitted = true;
    this.passwordVerify();
    if (!valid || !this.passwordVerified) {
      return;
    }
    // this.peopleInfo.secondaryRole = 
    this.commonService.save(this.peopleInfo).subscribe(
      data => {
        console.log(data)
        if (data.successMessages != null && data.successMessages.length !== 0) {
          this.alertmessages(data.successMessages[0]['key'], "success");
          this.submitted = false;
          this.hidePassword = false;
          this.hide_email = false;
          this.reFresh();
          this.getAllPeoples();
          //this.getPeoples();
          this.selectUserFilter();
        }
      },
      error => {
        if (error.error.errorMessages != null && error.error.errorMessages.length !== 0) {
          this.alertmessages(error.error.errorMessages[0]['key'], "danger");
        } else {
          this.alertmessages("Not Saved", "danger")
        }
      });

  }
  passwordKeyIn() {
    console.log("Password Key In is Invoked");
    this.showPasswordErr = true;
    this.passwordVerify();
  }
  passwordVerify() {
    this.showPasswordErr = false;
    if (this.peopleInfo.password === this.confirmPassword) {
      this.passwordVerified = true;
    } else {
      this.passwordVerified = false;
    }
  }

  customCheck(obj, type) {
    if (type === 'name' || type === 'role' || type === 'password' || type === 'confirmpassword' || type === 'email') {
      return false;
    }

    return true;

  }
  refreshTable() {
    this.selectedqualifier = 'Only Active';
    this.getAllPeoples();

  }

  reFresh() {
    //this.branchname = '';
    this.peopleInfo = new PeopleInfo();
    this.peopleList = new Array<PeopleInfo>();
    this.confirmPassword = undefined;
    this.hidePassword = false;
    this.hide_email = false;
    this.submitted = false;
    this.previousselectedqualifier = "All";
    this.limitPara = 0;
    this.offsetPara = 7;
    //this.employeeId='';
    this.userName = '';
    //(<HTMLInputElement>document.getElementById('selectedCustomer')).value = '';

  }
  getPeoples(name: any) {
    if (name != null && name.length > 2) {
      this.commonService.getPeoples(name).subscribe(
        data => {
          this.peopleList = data.peopleArray;
          console.log(this.peopleList);

        }
      );
    }
  }

  getPeople(active: any, name: any) {
    if (name != null && name.length > 2) {
      this.commonService.getPeople(active, name).subscribe(
        data => {
          this.peopleList = data.peopleArray;

        }
      );
    }
  }

  //This is for the Table
  getAllPeoples() {

    this.commonService.getAllPeoples(this.limitPara, this.offsetPara, this.selectedqualifier).subscribe(
      data => {
        // START onScrollLoadData
        if (this.limitPara == 0) {
          this.tablepeopleList = data.peopleArray;
        } else if (this.selectedqualifier === this.previousselectedqualifier) {
          this.tablepeopleList = this.tablepeopleList.concat(data.peopleArray);
        } else {
          this.tablepeopleList = data.peopleArray;
        }
        this.previousselectedqualifier = this.selectedqualifier;
        this.scrollLength()
      }
    );
  }
  scrollLength() {
		console.log("dynamic height on scroll ");
		console.log("");
		const regex = /^(\d+(?:\.\d+)?)\s*([a-zA-Z%]+)$/;
		const height = getComputedStyle(document.getElementById("scrolllength")).getPropertyValue("max-height")
		const computedHeight = height.match(regex);
		console.log("computed height", computedHeight,"computed height in no ",Number(computedHeight[1]),"height ",height)
		if (computedHeight && Number(computedHeight[1]) < 450) {
			let value: any = Number(computedHeight[1]);
			console.log("default value = ", value)
			value = value + (2*55);
			value = value + "px";
			console.log("final vl:", value)
			console.log("transition")
			document.getElementById("scrolllength").style.maxHeight = value
		}
		console.log("");
	}

  // //This is for the Table
  // getAllActivePeople(active:any) {
  //   this.commonService.getAllActivePeople(active).subscribe(
  //     data => {
  //       this.tablepeopleList = data.peopleArray;
  //       console.log(this.tablepeopleList);
  //     }
  //   );
  // }

  // getEmployeeIds() {
  //   this.commonService.getEmployeeIds().subscribe(
  //     data => {
  //       if(data){
  //       this.employeeIdList = data.employeeIds;
  //       }
  //     }
  //   );
  // }

  // getBranchIds() {
  //   this.commonService.getBranchIds().subscribe(
  //     data => {
  //       if(data){
  //         this.branch = data.branches;
  //       }
  //     }
  //   );
  // }


  getRoles() {
    this.commonService.getRoles().subscribe(
      data => {
        console.log("Roles fr users role : Webrole ", data);
        this.roles = data.roles;
      }
    );
  }

  // ngAfterViewChecked() {
  //   //    Activate bootstrap-select
  //   if ($(".selectpicker").length != 0) {
  //     $(".selectpicker").selectpicker();
  //   }
  // }

  getUserRole() {
    this.permission = this.sessionService.getRolePermissionList().includes(this.constantService.getRolePermissions()['EMPID']);

  }

  selectPeople() {

    const name = (<HTMLInputElement>document.getElementById('selectedCustomer')).value;

    if (name != null) {
      if (name.length > 2) {
        this.selectUserFilter();
        const nm = name;
        const j: number = this.peopleList.findIndex(c => c.name && c.name.split(' ').join('')
          === nm.split(' ').join(''));

        if (j !== -1) {

          this.peopleInfo.name = this.peopleList[j].name;
          this.peopleInfo.role = this.peopleList[j].role;
          this.peopleInfo.email = this.peopleList[j].email;
          this.peopleInfo.password = this.peopleList[j].password;
          this.confirmPassword = this.peopleList[j].password;
          this.peopleInfo.mobileNumber = this.peopleList[j].mobileNumber;
          this.peopleInfo.discontinue = this.peopleList[j].discontinue;
          this.peopleInfo.empId = this.peopleList[j].empId;
          //this.employeeId = this.peopleList[j].empId;
          this.peopleInfo.branchId = this.peopleList[j].branchId;
          //if (typeof this.branch !== 'undefined' && this.branch.length > 0) {
          //for(let i=0; i<this.branch.length; i++){
          // if(this.branch[i].branchId ===  this.peopleInfo.branchId){
          //   this.branchname = this.branch[i].branchname;
          // }
          //}
          //}
          this.hidePassword = true;
          this.hide_email = true;
        }
      }
    }

  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
      <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }


  selectTablePeople(name: any) {
    if (name != null) {
      this.userName = '';
      const nm = name;
      const j: number = this.tablepeopleList.findIndex(c => c.name && c.name.split(' ').join('')
        === nm.split(' ').join(''));

      if (j !== -1) {

        this.peopleInfo.name = this.tablepeopleList[j].name;
        this.peopleInfo.role = this.tablepeopleList[j].role;
        this.peopleInfo.email = this.tablepeopleList[j].email;
        this.peopleInfo.password = this.tablepeopleList[j].password;
        this.confirmPassword = this.tablepeopleList[j].password;
        this.peopleInfo.mobileNumber = this.tablepeopleList[j].mobileNumber;
        this.peopleInfo.discontinue = this.tablepeopleList[j].discontinue;
        this.peopleInfo.empId = this.tablepeopleList[j].empId;
        //this.employeeId = this.tablepeopleList[j].empId;
        this.peopleInfo.branchId = this.tablepeopleList[j].branchId;
        //if (typeof this.branch !== 'undefined' && this.branch.length > 0) {
        //for(let i=0; i<this.branch.length; i++){
        // if(this.branch[i].branchId ===  this.peopleInfo.branchId){
        //   this.branchname = this.branch[i].branchname;
        // }
        // }
        //}
        this.hidePassword = true;
        this.hide_email = true;
      }
    }

  }

  UserDelete(name: any) {
    this.deleteUserName = name;
    const modal = document.getElementById('showAlert');
    const overlay = document.getElementById('overlay');
    overlay.style.display = "block"
    modal.style.display = 'block';
  }

  closeMRQModal() {
    const modal = document.getElementById('showAlert');
    const overlay = document.getElementById('overlay');
    overlay.style.display = "none"
    modal.style.display = 'none';
  }

  discontinueUser() {
    let name: string = this.deleteUserName;
    if (name != null) {
      this.userName = '';
      const nm = name;
      const j: number = this.tablepeopleList.findIndex(c => c.name && c.name.split(' ').join('')
        === nm.split(' ').join(''));

      if (j !== -1) {

        this.peopleInfo.name = this.tablepeopleList[j].name;
        this.peopleInfo.role = this.tablepeopleList[j].role;
        this.peopleInfo.email = this.tablepeopleList[j].email;
        this.peopleInfo.password = this.tablepeopleList[j].password;
        this.confirmPassword = this.tablepeopleList[j].password;
        this.peopleInfo.mobileNumber = this.tablepeopleList[j].mobileNumber;
        this.peopleInfo.discontinue = true;
        this.peopleInfo.empId = this.tablepeopleList[j].empId;
        //  this.employeeId = this.tablepeopleList[j].empId;
        this.peopleInfo.branchId = this.tablepeopleList[j].branchId;
        //  if (typeof this.branch !== 'undefined' && this.branch.length > 0) {
        //  for(let i=0; i<this.branch.length; i++){
        //  if(this.branch[i].branchId ===  this.peopleInfo.branchId){
        //    this.branchname = this.branch[i].branchname;
        //    }
        //   }
        // }
        this.hidePassword = true;
        this.hide_email = true;
        this.save(true);
      }
    }
  }

  showSecondaryRoles() {
    console.log("Role list show appear : ", this.roles)
  }

  addSecondaryRole(e, obj: any) {
    // console.log("Secondary role : e : obj ", e, obj)

    if (this.secondaryRoleList.find(secondaryRole => secondaryRole.id === obj.id)) {
      // console.log(`${obj.name} already added!`);
      this.secondaryRoleList = this.secondaryRoleList.filter(secondaryRole => secondaryRole.id !== obj.id);
      this._snackBar.open(`Removed ${obj.name}!`, 'Close', {
        duration: 2000,
      });

    } else {
      this.secondaryRoleList.push(obj);
      // console.log(`Selected ${obj.name}`);
      console.log("Check List : ", this.secondaryRoleList);
      this._snackBar.open(`Selected ${obj.name}`, 'Close', {
        duration: 2000,
      });

    }
  }

  clearRole() {
    this.requestDepartmentObj = [];
    let checkallele = document.querySelectorAll('.deCheckall');

    checkallele.forEach(item => {
      (<HTMLInputElement>item).checked = false
    })
    // checkallele
  }

  getRole() {
    console.log(this.model, this.requestDepartmentObj)
  }

}



function ee(arg0: string, ee: any) {
  throw new Error('Function not implemented.');
}

