<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp">

        </div>

        <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-title">Swipe Details</div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form class="form-horizontal form-label-left" novalidate>

                            <div class="x_content row">
                                <div class="col-md-4 col-sm-6 col-xs-6">
                                    <div class="form-group label-floating">
                                        <label class="form-label" for="fromDate"> From Date : </label>
                                        <div>
                                            <input type="date" style="cursor: pointer;" [value]="fromDate"
                                                (change)="changeFromDate($event)" class="form-control fa-hand-pointer"
                                                id="fromDate" />
                                            <span *ngIf="submitted && isEmptyF " class="help-block" style="color: red;">
                                                Please Select From Date</span>
                                            <span *ngIf="submitted && isFromGreater " class="help-block"
                                                style="color: red;">
                                                From Date cannot be greater than To Date</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6 col-xs-6">
                                    <div class="form-group label-floating">
                                        <label class="form-label" style="cursor: pointer;" for="toDate"> To Date :
                                        </label>
                                        <div>
                                            <input type="date" [value]="toDate" (change)="changeToDate($event)"
                                                class="form-control" id="toDate" webkitdirectory />
                                            <span *ngIf="submitted && isEmptyT " class="help-block" style="color: red;">
                                                Please Select To Date</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6 col-xs-6">
                                    <div class="form-group label-floating">
                                        <label class="form-label"> &nbsp; </label>
                                        <div>
                                            <button type="button" class="btn btn-primary wd "
                                                (click)="getEmpAttendanceSummary()" id="navigatetarget">
                                                <i class="fa fa-search" aria-hidden="true"></i>Search</button>
                                            <button class="btn btn-danger wd" type="button"
                                                (click)="onClear()">Clear</button>
                                            <!-- ms-1  ms-4 -->


                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4 col-sm-4 col-xs-6">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Select Member</label>
                                        <div>
                                            <div>
                                                <select class="form-control" type="text" style="width: 100%;"
                                                    (change)="onChange($event)">
                                                    <option [selected]="selectedType === 'select'"
                                                        value="{{uniqueEmpId}}">ME</option>
                                                    <option value="Team_Member" *ngIf="teamMemberList?.length">Team
                                                        Member</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-md-4 col-sm-4 col-xs-6"
                                    *ngIf="selectedType === 'Team_Member' && teamMemberList?.length">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Select Team Member</label>
                                        <div>
                                            <div>
                                                <select class="form-control" type="text" style="width: 100%;"
                                                    (change)="onChange1($event)">
                                                    <option [selected]="selectedType === 'select'"
                                                        value="{{uniqueEmpId}}">ME</option>

                                                    <option value="{{list.uniqueemployeeid}}"
                                                        *ngFor="let list of teamMemberList">{{list.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- </div> -->
                            </div>

                        </form>
                    </div>

                </div>
            </div>
        </div>
        <div class="empty-div-one" style="height: 10px;" id="navigatedest"></div>
        <!-- Attendance Summary -->
    </div>
    <div class="row" *ngIf="showTable">
        <div class="col-xl-12 col-md-12 col-lg-12 mx600px" style="padding: 20px 2.2rem 0 2.2rem">
            <div class="card">
                <div class="card-header  border-0">
                    <h4 class="card-title">Attendance Summary</h4>
                </div>
                <div class="card-body">
                    <div class="table-responsive costumscrolllength" id="scrolllength">
                        <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                            id="emp-attendance">
                            <thead class="tablerow">
                                <tr class="rowcolors stickyatTop">
                                    <th class="border-bottom-0 fixedcolumn">Date</th>
                                    <th class="border-bottom-0">First-In</th>
                                    <th class="border-bottom-0">Last-Out</th>
                                    <th class="border-bottom-0">Total Hours</th>
                                    <th class="border-bottom-0">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let attendance of empAttdSummaryList">
                                    <td class="fixedcolumn backgroundFW">{{attendance.swipetime | date : 'dd-MMM-yyyy'}}
                                    </td>
                                    <td>{{attendance.firstIN | date:'shortTime'}}</td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{attendance.lastOUT == undefined ? "--" :
                                        attendance.lastOUT | date:'shortTime' }}</td>

                                    <!-- <td>{{attendance.totalSwipeHour }}</td> -->

                                    <td>{{ attendance.totalSwipeHourStringFormate ||
                                        attendance.totalSwipeHour || '--'}}
                                    </td>

                                    <!-- <td>
                                        {{ attendance.totalSwipeHourStringFormate ?
                                        (attendance.totalSwipeHourStringFormate + ':00' | date:'HH:mm:ss') :
                                        (attendance.totalSwipeHour | date:'HH:mm:ss') || '--' }}
                                    </td> -->


                                    <td>
                                        <button type="button" class="btn btn-primary plr" data-bs-toggle="modal"
                                            data-bs-target="#additionalModal"
                                            (click)="getswipeinfo(attendance)">Regularize</button>
                                        <button *ngIf="selectedType !== 'select' && teamMemberList?.length"
                                            class="btn btn-success plr" data-bs-toggle="modal"
                                            data-bs-target="#additionalModalSwipe"
                                            (click)="get_Attendence_info(attendance)">
                                            <i class="fa fa-eye" data-bs-toggle="tooltip"
                                                data-original-title="download"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    </div>


    <div class="modal fade" id="additionalModal" tabindex="-1" aria-labelledby="additionalModalLabel" aria-hidden="true"
        style="display: none;">
        <div class="modal-dialog modal-lg modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel" style="font-size: 20px;"> Regularize Attendance on
                        {{currentdate}}
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body" style="padding: 0px;">
                    <div class=" container-fluid">
                        <div class="mobile">
                            <table class="table  table-vcenter text-nowrap table-bordered border-bottom">
                                <thead class="tablerow stickyatTop">
                                    <tr class="rowcolors">
                                        <th class="ps-3">Date - Time </th>
                                        <th>
                                            IN / OUT
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let swipe of regularizedtemp">
                                        <td data-th="DateTime">
                                            <input type="datetime-local" class="form-control" name="swipetime"
                                                id="swipetime" (change)="getswipetime(swipe,$event)">
                                        </td>
                                        <td data-th="IN / OUT">
                                            <select class="form-control" name="swipeinorout" id="swipeinorout"
                                                (change)="getswipetype(swipe,$event)">
                                                <option [value]="null">Select Swipe Type</option>
                                                <option value="1">IN</option>
                                                <option value="2">OUT</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="clearall()">Close</button>
                    <button type="button" class="btn btn-primary" (click)="submitforRegularize()">Submit</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="additionalModalSwipe" tabindex="-1" aria-labelledby="additionalModalLabelSwipe"
        aria-hidden="true" style="display: none;">
        <div class="modal-dialog modal-lg modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel" style="font-size: 20px;">Swipe Info as on
                        {{currentdate}}</h5>
                </div>
                <div class="modal-body" style="padding: 0px;">
                    <div class=" container-fluid">
                        <div class="mobile">
                            <div class="table-responsive"
                                style="min-height: fit-content;max-height: 40vh ; overflow: scroll;width: 100%;">
                                <table class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                    id="emp-attendance">
                                    <thead class="tablerow">
                                        <tr class="rowcolors">
                                            <th class="border-bottom-0">Sl No</th>
                                            <th class="border-bottom-0">Clock IN/OUT</th>
                                            <th class="border-bottom-0">Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let swipe of emp_attendence_list;let i=index">
                                            <td>{{i+1}}</td>
                                            <td>{{ swipe.swipetype === swipeintype ? "IN" :"OUT" }}</td>
                                            <td>{{ swipe.swipetime | date:'shortTime' }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                        aria-label="Close">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>