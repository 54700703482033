import { Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ContractclientService } from '../../services/contractclient.service';
import { ContractclientDocument } from 'src/app/models/ContractClientDocument';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var $: any;

@Component({
  selector: 'app-contract-client-location',
  templateUrl: './contract-client-location.component.html',
  styleUrls: ['./contract-client-location.component.css']
})
export class ContractClientLocationComponent implements OnInit, OnDestroy {
  contractclientlocationlist: ContractclientDocument[] = [];
  contractClientData: ContractclientDocument[];
  contractClientDataForm: ContractclientDocument;
  contractClientLocData: ContractclientDocument;
  deleteCliLocData: ContractclientDocument;
  client_id: ContractclientDocument[];

  clickChangeBussiness: string;
  selectedBusiness: string;

  selectedBusinessTwo: string;
  docid_tobe_deleted: any;
  contractstream$!: Subscription;
  indian_states: any[] = ["Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal"];

  // Validation
  submitted: boolean;
  contactNumberlength: boolean = false;
  contactNumberOnly: boolean = false;
  gstinNumber: boolean = false;
  pinCodelength: boolean = false;
  pinCodeOnly: boolean = false;

  @ViewChild('contractClientLocForm') contractClientLocForm: NgForm;
  constructor(private _snackBar: MatSnackBar, private contractclientService: ContractclientService,
    private render: Renderer2) { }


  ngOnInit(): void {
    this.contractClientDataForm = new ContractclientDocument();
    this.getClientData();

    this.render.listen(document.getElementById('navigateclientLoc'), 'click', () => {
      $('html,body').animate({
        scrollTop: $('#add-update-client-location').offset().top - 90
      },
        'slow');
    })
  }

  getClientData() {
    this.contractstream$ = this.contractclientService.getContractClient().subscribe(
      data => {
        // console.log(data)
        this.contractClientData = data.client;
      },
      error => {
        // console.log(error);
      }
    );
  }

  getclientDetailCall() {

    console.log("clickChangeBussiness = ", this.clickChangeBussiness)
    this.selectedBusiness = this.clickChangeBussiness;
    console.log("selectedBusiness = ", this.selectedBusiness)

    console.log("getclientDetailCall ")
    this.contractClientDataForm.business_name = this.contractClientDataForm.business_name.trim();
    if (this.selectedBusiness === null || this.selectedBusiness === undefined) {
      if (this.contractClientDataForm.business_name === null || this.contractClientDataForm.business_name === undefined || this.contractClientDataForm.business_name?.length === 0) {
        this.selectedBusiness = this.selectedBusinessTwo;
      } else {
        this.selectedBusiness = this.contractClientDataForm.business_name;
      }
    }

    this.client_id = this.contractClientData.filter((dName) => {
      return dName.business_name.toLowerCase() === this.selectedBusiness.toLowerCase();
    });

    this.contractstream$ = this.contractclientService.getContractClientLocation(this.client_id[0].id).subscribe(
      data => {
        // console.log(data)
        this.contractclientlocationlist = data.locationsdeta;
        $('#mainInputConCliLoc').removeClass('disable-div')
        $('#navigateclientLoc').removeClass('disable-div')
      },
      error => {
        // console.log(error);
      }
    );

  }

  getclientDetail(event, c: ContractclientDocument) {

    this.clickChangeBussiness = event;
    // this.selectedBusiness = event;
    console.log("get client detail here : ", this.selectedBusiness)
  }

  saveContractClientData(valid) {
    this.submitted = true;
    this.contactNumberlength = false;
    this.contactNumberOnly = false;
    this.gstinNumber = false;

    if (!valid) {
      // console.log("Not valid")
      return;
    }

    if (!this.checkPhoneNumber()) {
      return;
    }

    if (this.contractClientDataForm.gstin == undefined || this.contractClientDataForm.gstin?.length == 0 || this.contractClientDataForm.gstin == null) {
      this.contractClientDataForm.gstin = undefined;
    }

    if ((this.contractClientDataForm.gstin != undefined) && !(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(this.contractClientDataForm.gstin))) {
      // console.log("Enter proper GSTIN number")
      this.submitted = false;
      this.gstinNumber = true;
    }

    if (!this.checkPinCode()) {
      return;
    }

    if (valid && this.submitted) {

      this.client_id = this.contractClientData.filter((dName) => {
        return dName.business_name.toLowerCase() === this.selectedBusiness.toLowerCase();
      });
      this.contractClientDataForm.location_type = "Sub";
      this.contractClientDataForm.client_id = this.client_id[0].id;
      this.contractstream$ = this.contractclientService.saveContractClientLocation(this.contractClientDataForm).subscribe(
        data => {
          this.getclientDetailCall();
          this.clearTwo()
          this._snackBar.open("Successfully saved!", 'Close', {
            duration: 2000,
          });
        },
        error => {
          // console.log(error);
        }
      );
    } else {
      // console.log("Not valid")
      return;
    }
  }

  updateContractClientData(valid) {
    this.submitted = true;
    this.contactNumberlength = false;
    this.contactNumberOnly = false;
    this.gstinNumber = false;

    if (!valid) {
      // console.log("Not valid")
      return;
    }

    if (!this.checkPhoneNumber()) {
      return;
    }

    if (this.contractClientDataForm.gstin == undefined || this.contractClientDataForm.gstin?.length == 0 || this.contractClientDataForm.gstin == null) {
      this.contractClientDataForm.gstin = undefined;
    }

    if ((this.contractClientDataForm.gstin != undefined) && !(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(this.contractClientDataForm.gstin))) {
      // console.log("Enter proper GSTIN number")
      this.submitted = false;
      this.gstinNumber = true;
    }

    if (!this.checkPinCode()) {
      return;
    }

    if (valid && this.submitted) {
      if (this.selectedBusiness === null || this.selectedBusiness === undefined) {
        this.selectedBusiness = this.contractClientDataForm.business_name;
      }
      this.client_id = this.contractClientData.filter((dName) => {
        return dName.business_name.toLowerCase() === this.selectedBusiness.toLowerCase();
      });
      this.contractClientDataForm.client_id = this.client_id[0].id;
      this.contractstream$ = this.contractclientService.updateContractClientLocation(this.contractClientDataForm).subscribe(
        data => {
          this.getclientDetailCall();
          this.clearTwo();
          this._snackBar.open("Successfully updated!", 'Close', {
            duration: 2000,
          });
        },
        error => {
          // console.log(error);
        }
      );
    } else {
      // console.log("Not valid")
      return;
    }
  }

  contractclientLocEdit(contractClientLoc: ContractclientDocument) {
    $('html,body').animate({
      scrollTop: $('#add-update-client-location').offset().top - 90
    },
      'slow');
    document.getElementById('update-btn').style.display = "inline-block";
    document.getElementById('save-btn').style.display = "none";
    document.getElementById('alter-text').innerText = "Update";
    this.contractClientDataForm = { ...contractClientLoc };
  }

  contractclientLocDeleteDataStore(contractClientLocD: ContractclientDocument) {
    this.deleteCliLocData = null;
    if (contractClientLocD.location_type != "Primary") {
      $('#showAlertWarning').modal('toggle');
      this.deleteCliLocData = contractClientLocD;
    } else {
      console.log("Location type is 'Primary' ")
      $('#showAlertPrimary').modal('toggle');
    }
  }

  contractclientLocDeleteData() {
    this.selectedBusinessTwo = this.deleteCliLocData.business_name;
    this.contractstream$ = this.contractclientService.deleteContractClientLocation(this.deleteCliLocData.id).subscribe(
      data => {
        // console.log(data)
        this.getclientDetailCall();
        this._snackBar.open("Successfully delete!", 'Close', {
          duration: 2000,
        });
      },
      error => {
        // console.log(error);
        if (error.error?.dataAttachedToIt) {
          $('#showAlertDataAttachedToLoc').modal('toggle');
        } else {
          $('#showAlertCantDelete').modal('toggle');
        }
      }
    );
  }

  clear() {
    this.submitted = false;
    this.contactNumberlength = false;
    this.contactNumberOnly = false;
    this.gstinNumber = false;
    this.pinCodeOnly = false;
    this.pinCodelength = false;

    $('#employeename').val('');
    this.contractClientLocForm.reset();
    this.contractclientlocationlist = [];
    $('#mainInputConCliLoc').addClass('disable-div')
    $('#navigateclientLoc').addClass('disable-div')
    if (document.getElementById('save-btn').style.display === "none") {
      document.getElementById('update-btn').style.display = "none";
      document.getElementById('save-btn').style.display = "inline-block";
      document.getElementById('alter-text').innerText = "Add";
    }
  }

  clearTwo() {
    this.submitted = false;
    this.contactNumberlength = false;
    this.contactNumberOnly = false;
    this.gstinNumber = false;
    this.pinCodeOnly = false;
    this.pinCodelength = false;

    this.contractClientLocForm.reset();
    if (document.getElementById('save-btn').style.display === "none") {
      document.getElementById('update-btn').style.display = "none";
      document.getElementById('save-btn').style.display = "inline-block";
      document.getElementById('alter-text').innerText = "Add";
    }
  }

  checkPhoneNumber() {
    this.submitted = true;
    this.contactNumberlength = false;
    this.contactNumberOnly = false;

    if (!(/^[0-9]+$/.test(this.contractClientDataForm.phone))) {
      this.submitted = false;
      this.contactNumberlength = false;
      this.contactNumberOnly = true;
    } else if (this.contractClientDataForm.phone.length != 10) {
      this.submitted = false;
      this.contactNumberOnly = false;
      this.contactNumberlength = true;
    } else {
      return true;
    }
  }

  checkPinCode() {
    this.submitted = true;
    this.pinCodelength = false;
    this.pinCodeOnly = false;

    if (!(/^[0-9]+$/.test(this.contractClientDataForm.pincode))) {
      this.submitted = false;
      this.pinCodelength = false;
      this.pinCodeOnly = true;

    } else if (this.contractClientDataForm.pincode.length != 6) {
      this.submitted = false;
      this.pinCodeOnly = false;
      this.pinCodelength = true;

    } else {
      return true;
    }
  }

  ngOnDestroy(): void {
    this.contractstream$.unsubscribe();
  }

}
