import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringDate24To12'
})
export class StringDate24To12Pipe implements PipeTransform {
  transform(stringList: string): unknown {
    var finalString = null;
    var zeroString = "0";
    if (stringList === null || stringList === undefined) {
      return finalString;
    }

    if(stringList === '00:00:00'){
      return finalString ='ODD';
    }

    var hour = stringList.slice(0, 2);
    var min = stringList.slice(3, 5);


    finalString = hour.concat(":", min);

    return finalString;
  }
}
