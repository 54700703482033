export class ExpenseSubmission {
    id: number;
	client_name: any;
	date_of_expense: Date;
	expense_type_id: number;
	amount: number;
	user_id: any;
	timestamp: any;
	status: any;
	attchment:any;
	fileToUpload: File | null = null;
	filename: any;
	expenseName: any;
	uniqueempid:any;
	 name:any;
	 EMPLOYEEID:any;
	 employeeid:any;
	approvalamount:any;
}