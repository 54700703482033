import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CommonService } from './common.service';

@Injectable({
providedIn: 'root'
})
export class  Approvalworkflowservice {
protected basePath = '/api';
public defaultHeaders: Headers = new Headers();
public url;

constructor(private httpclient: HttpClient, private commonService: CommonService) { }

public getdocs(): Observable<any> {
    this.url = '/v1/documents';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path );
}

public getRoles(): Observable<any> {
  this.url = '/v1/allroles';
  const path = this.basePath + this.url;
  let queryParameters = new HttpParams();
  return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path );
}


public getApprovalWorkflows(docGroup:string): Observable<any> {
  this.url = '/v1/approves';
  const path = this.basePath + this.url;
  let queryParameters = new HttpParams();
  queryParameters = queryParameters.set('docGroup', docGroup);
  return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path );
}

public saveApprovalWorkflow(workFlowInfo: any): Observable<any> {
    this.url = '/v1/approve';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(workFlowInfo, queryParameters, path);
  }

  public updateApprovalWorkflow(workFlowInfo: any): Observable<any> {
    this.url = '/v1/approve';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.putWithHttpInfo(workFlowInfo, queryParameters, path);
  }

  public deleteApprovalWorkflow(number: any): Observable<any> {
    this.url = '/v1/approve';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('Id', number);
    return this.commonService.deleteQueryParamWithHttpInfo(queryParameters, path );
  }

  public viewdocument(filepath:string): Observable<Blob> {
    this.url = '/v1/viewRequesteddocuments';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('filepath', filepath);
    console.log(queryParameters);
    return this.commonService.getByQueryParamWithHttpInfo( queryParameters, path, {responseType: 'arraybuffer'});
    // console.log("Here you can see the rsponse path: " + path)
  }
}