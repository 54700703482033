import { Component, OnInit } from '@angular/core';
import { ApplicationSettingsService } from '../services/application-settings.service';
import { EmployeeMaster } from '../models/employeeMasterts';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { ExpensemanagerService } from '../services/expensemanager.service';
import { ExpenseReport } from '../models/ExpenseReport';
import { EmployeeAttendenceProxyService } from '../services/employee-attendence-proxy.service';

@Component({
  selector: 'app-expense-report',
  templateUrl: './expense-report.component.html',
  styleUrls: ['./expense-report.component.css']
})
export class ExpenseReportComponent implements OnInit {
  fromDate: string;
  toDate: string;
  currentDate: string;
  startDate: boolean;
  endDate: boolean;
  submitted: boolean;
  designation: any[]=[];
  department: any[] = [];
  employeeName: any;
  employeeId: any;
  expenseId:any;
  branchInfo: any[] = [];

  query: any;
 
  keyinempid: any | undefined;
  selectedempid: any;
  expenseReport: ExpenseReport[]=[];
  selecteddesignation: string = "0";
  selecteddepartment: string= "0";
  branch: any = "0";
  employeeMaster: EmployeeMaster[];
  client_id: EmployeeMaster[];


  constructor(private applicationSettingsService: ApplicationSettingsService, private empLeaveService: EmployeeLeaveService,
    private expensemanagerService: ExpensemanagerService, private employeeService: EmployeeAttendenceProxyService) {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    this.currentDate = `${year}-${month}-${day}`;
    this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
  }

  ngOnInit(): void {
   //this.getDesignation();
    this.getAllBranches();
    this.getDepartment();
  }

  checkFromAndToDate() {

    console.log("from date ", "to date ");

    this.startDate = false;
    this.endDate = false;
    this.submitted = true;
    const currentDate = new Date();

    if (this.toDate) {
      if (this.fromDate > this.toDate) {
        this.submitted = false
        this.endDate = true;
      } else {
        this.endDate = false;
        return true;
      }
    }
  }

  getDesignation() {
    this.applicationSettingsService.getDesignationType().subscribe(
      (data) => {
        console.log("Data available :", data);
        this.designation = data.designationDetail;
        console.log("List of all the designation available :", this.designation);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getDepartment() {
    this.applicationSettingsService.getDepartmentType().subscribe(
      (data) => {
        console.log("Data available :", data);
        this.department = data.departmentDetail;
        console.log("List of all the department available :", this.department);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getEmployeeByNameOrId() {
    console.log("query", this.query)
    this.query = this.query.trim();
    console.log("Len ", this.query);
    if (this.query.length <= 2) {
      this.employeeMaster = []
    }

    if (this.query.length >= 3) {
      this.empLeaveService.getEmployeeByNameOrUniqueId(this.query).subscribe(
        data => {
          console.log("Emp Data ", data);
          this.employeeMaster = data.employeeMasterData;
          this.selectedempid = this.employeeMaster[0].uniqueemployeeid;
        },
        error => {
          console.log("Error ", error);

        }
      )
    }
  }

  
  getExpenseReport() {
    console.warn("search");
    let employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
    if (!employeename) {
      employeename = null;
    } else {
      employeename = employeename.trim()
      this.branch = "0";
      this.selecteddepartment = "0";
    }
    var id = null;
    if (employeename) {
      this.client_id = this.employeeMaster.filter((dName) => {
        return dName.name === employeename;
      });

      console.log("data parent: ", this.client_id)
      id = this.client_id[0].uniqueemployeeid;
      this.keyinempid = id;
    } else {
      console.log("Id parent", id)
    }
    this.expensemanagerService.getExpenseReport(this.fromDate, this.toDate, this.branch,
      this.selecteddepartment, id).subscribe(
        (data) => {
          this.expenseReport=data.expenseReportList;
          console.log("Data available :", data);
          console.log(data.expenseReportList);
          console.log(this.expenseReport);
          
        },
        (error) => {
          console.log(error);
        }
      );
  }

  clear() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    this.currentDate = `${year}-${month}-${day}`;
    this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
    this.branch= "0";
    this.employeeName = null;
    this.selecteddepartment = "0";
    this.selecteddesignation = "0";
  }

  getAllBranches() {
    console.log("Called all branches >> >> >>")
    this.employeeService.getAllBranchs().subscribe(
      data => {
        console.log("check this")
        this.branchInfo = data.branches;
      },
      error => {
      }
    );
  }
}
