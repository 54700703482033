<div class="app-content main-content">
  <div class="side-app main-container">
    <div class="alertcomp">
    </div>

    <div class="w-100" style="padding: 0;">
      <div class="card-body row justify-content-between">
        <div class="col-xs-9 col-md-9 col-sm-9 col-8 " style="padding: 0;">
          <div class="my-3">
            <div class="page-leftheader">
              <div class="page-title">Define Documents</div>
            </div>
          </div>
        </div>
        <div class="col-xs-3 col-md-3 col-sm-3 col-4 my-3" style="padding: 0;">
          <button class="btn btn-primary w-100 px-0" (click)="redirectToSearchDocument($event)">
            Search Document
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <form id="fcForm" (ngSubmit)="f.form.valid" enctype="multipart/form-data"
              class="form-horizontal form-label-left" #f="ngForm" novalidate>
              <div class="x_content row p-4">
                <div class="col-md-4 col-sm-6 col-xs-12">
                  <div class="form-group label-floating">
                    <label class="form-label"> Document Group </label>
                    <div>
                      <input type="text" class="form-control  col-md-12 col-xs-12" [(ngModel)]="documentInfo.docGroup "
                        (ngModelChange)="trimtrailing($event , documentInfo.docGroup , 'docGroup' )" name="dGroup"
                        autofocus #dGroup="ngModel" value={{docInfo.docGroup}} placeholder="Enter Document"
                        required="required" autofocus="true" id="documentGrp" />
                      <span *ngIf="submitted && !dGroup.valid " class="help-block" style="color: red;">
                        Please Enter Document Group</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-sm-6 col-xs-12">
                  <div class="form-group label-floating">
                    <label class="form-label"> Document Type </label>
                    <div>
                      <input type="text" class="form-control col-md-12 col-xs-12" [(ngModel)]="documentInfo.docType"
                        (ngModelChange)="trimtrailing($event , documentInfo.docType , 'docType' )" name="dType"
                        autofocus #dType="ngModel" value={{docInfo.docType}} placeholder="Enter Document Type"
                        required="required" autofocus="true" id="documentTyp" />
                      <p *ngIf="submitted && !dType.valid " class="help-block" style="color: red;">
                        Please Enter DocumentType</p>
                      <!-- <p *ngIf="submitted"  class="help-block" style="color: red;">
                            Please Enter Document Type.</p> -->
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-sm-6 col-xs-6">
                  <div class="form-group label-floating">
                    <label class="form-label">Mandatory</label>
                    <div>
                      <select id="mandatory" class="form-control" name="mandatory" placeholder="Please Select"
                        [(ngModel)]="documentInfo.mandatory" #mandatory="ngModel" required>
                        <!---<select class="selectpicker" data-style="btn btn-drpdwn btn-round" name="contactType{{i}}" [(ngModel)]="custcontact.contactType"></select>-->
                        <option [value]="mandatory" *ngFor="let mandatory of mandatoryType" class="selectOption">
                          {{mandatory}}</option>
                      </select>
                      <p *ngIf="submitted && !mandatory.valid " class="help-block" style="color: red;"> Please Select
                        Mandatory </p>
                    </div>

                  </div>
                </div>

                <div class="col-md-4 col-sm-6 col-xs-12">
                  <div class="form-group label-floating">
                    <label class="form-label"> Description</label>
                    <div>
                      <input type="text" class="form-control col-md-12 col-xs-12" [(ngModel)]="documentInfo.description"
                        (ngModelChange)="trimtrailing($event , documentInfo.description , 'description' )"
                        name="dDescription" autofocus #dDescription="ngModel" name="Description"
                        value={{docInfo.description}} placeholder="Description" required="required" autofocus="true"
                        id="docDescription" />
                      <p *ngIf="submitted && !dDescription.valid " class="help-block" style="color: red;">
                        Please Enter Description</p>
                      <!-- <p *ngIf="submitted "  class="help-block" style="color: red;">
                                Please Enter Description.</p> -->
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6 col-xs-6">
                  <div class="form-group label-floating">
                    <label class="form-label"> Validity</label>
                    <div>
                      <select id="validity" class="form-control" name="validity"
                        [(ngModel)]="documentInfo.validityPeriod" #validity="ngModel" required>
                        <!--class="form-control"
                              <option>YES</option>
                              <option> NO </option>
                            -->
                        <option [value]="b.value" *ngFor="let b of validityPeriod">{{b.name}}</option>


                      </select>
                      <p *ngIf="submitted && !validity.valid " class="help-block" style="color: red;"> Please Select
                        Validity </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="actionBar m-0 pe-4 ps-4 pt-0">

                <div class="clearfix"> </div>
                <div>
                  <button class="btn btn-success" type="button" id="save-doc-type-btn" (click)="save(f.form.valid)">Save</button>
                  <button class="btn btn-danger ms-2" type="button" (click)="reFresh()">Clear</button>

                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="card-header  border-0">
            <h4 class="card-title">Employees List</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <div class="col-xs-12 mobile">
                <div *ngIf="documentListX !== null " style="height: 46vh;">
                  <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom" id="hr-table">
                    <thead class="tablerow">
                      <tr class="rowcolors stickyatTop">
                        <th class="border-bottom-0 w-5 fs-7">Doc Group</th>
                        <th class="border-bottom-0 w-5 fs-7">Doc Type</th>
                        <th class="border-bottom-0 fs-7">Mandatory</th>
                        <th class="border-bottom-0 w-10 fs-7">Description</th>
                        <!-- <th class="border-bottom-0 fs-7">Sort Order</th> -->
                        <th class="border-bottom-0 fs-7">Validity Period</th>

                        <th class="border-bottom-0"> </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let j=index; let t of documentListX ">
                        <td data-th="Doc Group" (click)="documentEdit(t.docId,t)">
                          {{t.docGroup}}
                        </td>
                        <td data-th="Doc Type" (click)="documentEdit(t.docId,t)">
                          {{t.docType}}
                        </td>
                        <td data-th="Mandatory" (click)="documentEdit(t.docId,t)">
                          {{t.mandatory}}
                        </td>
                        <td data-th="Description" (click)="documentEdit(t.docId,t)">
                          {{t.description}}
                        </td>
                        <!-- <td data-th="Sort Order">
                                      {{t.sortOrder}}
                                    </td> -->
                        <td data-th="Validity">
                          {{t.validityPeriod}}

                        </td>
                        <td>
                          <a class="btn btn-primary btn-icon btn-sm me-2" (click)="documentEdit(t.docId,t)">
                            <i class="feather feather-edit" data-bs-toggle="tooltip"
                              data-original-title="View/Edit"></i>
                          </a>
                          <a class="btn btn-danger btn-icon btn-sm" (click)="documentDelete(j,t.docId)"
                            data-bs-toggle="tooltip" data-original-title="Delete"><i
                              class="feather feather-trash-2"></i></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>