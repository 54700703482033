<div class="col-lg-12 p-0" *ngIf="teamMemberOnLeave.length">
  <div class="card rounded_corners" id="scroll_container" style="height: fit-content;">

    <div class="position-relative">
      <div class="card-header p-0 px-4 d-flex justify-content-between" style="min-height:60px;max-height: 60px;"
        id="header_sticky">
        <h4 class="card-title">Team Members on Leave this week</h4>

      </div>
    </div>

    <div class="p-0 border-0 position-relative" style="min-height:200px;max-height: 200px;">
      <div class="list-group scroll" style="max-height: 147px;">
        <!-- 145px to 147px -->
        <div class="list-group-item d-flex pt-1 pb-1 border-0" *ngFor="let task of teamMemberOnLeave | slice:0:4">
          <div class="ms-1">
            <p class="m-0 font-weight-bold">{{ task.employeeName }}</p>
            <p class="text-right mb-0">
              <!-- text-right to text-right mb-0 -->
              <!-- Leave on: {{task.leaveDate | date}} -->
               {{task?.leaveDate | date}} | {{task?.leaveType}}
            </p>
          </div>


          <!-- <table class="table  table-vcenter text-nowrap table-bordered border-bottom" id="hr-table">
            <thead class="tablerow">
              <tr class="rowcolors">
                <th class="border-bottom-0 w-5 fs-6">Name</th>
                <th class="border-bottom-0 w-5 fs-6">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let contractclient of teamMemberOnLeave">
                <td data-th="contractclientName">{{contractclient.employeeName}}
                </td>
                <td data-th="contractclientName">{{contractclient.leaveDate | date}}
                </td>
              </tr>
            </tbody>
          </table> -->
        </div>
      </div>
      <div class="card-footer w-100 d-flex justify-content-end pt-3 position-absolute fixed-bottom">
        <a [routerLink]="['/employeeleavestatusreport']" [routerLinkActive]="['highlightActiveLink']"
          [queryParams]="{ leaveStatus:'Approved'}">View All</a>
      </div>
    </div>

  </div>
</div>