<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-titleMod">Form T Report</div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form id="fcForm" class="form-horizontal form-label-left">
                            <div class="x_content row px-4">

                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label"> From Date: </label>
                                        <div>
                                            <input class="form-control" type="date" name="fromdate" id="fromdate"
                                                [(ngModel)]="fromDate">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label"> To Date: </label>
                                        <div>
                                            <input class="form-control" type="date" name="todate" id="todate"
                                                [(ngModel)]="toDate">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <label class="form-label w-100 mb-1">Branch</label>
                                    <div class="form-group m-0">
                                        <div>
                                            <select id="deptInput" class="form-control" name="branch"
                                                placeholder="Select Branch" [(ngModel)]="branch" autocomplete="off"
                                                autofocus="autofocus">

                                                <option value="all" selected="selected">All</option>
                                                <option value={{data.branchId}} *ngFor="let data of branchInfo">
                                                    {{data.branchname}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <form class="form-horizontal" id="fcForm"
                                    (keyup.enter)="limitpara(); searchEmployeeformT()">
                                    <div class="form-group row">
                                        <div class="col-md-12 px-0 ">
                                            <label for="inputName"
                                                class="w-100  fontSize-15 form-label mb-2 p-0 ">Employee Name
                                                / ID</label>
                                            <input type="search" class="form-control" name="Employee Name"
                                                id="employeename" placeholder="Enter Employee Name / ID"
                                                [(ngModel)]="query" autocomplete="off" (input)="getEmployeeByNameOrId()"
                                                list="employeeMasterLucene">
                                            <datalist id="employeeMasterLucene">
                                                <option data-value={{emp.name}}
                                                    *ngFor="let emp of employeeMasterLucene">
                                                    {{emp.employeeid}}
                                                </option>
                                            </datalist>
                                        </div>
                                    </div>
                                    <button type="button" id="navigatetarget"
                                        class="btn btn-primary w-100  w-md-20 mt-3 mt-md-0 p-2 p-md-1"
                                        (click)="limitpara(); searchEmployeeformT(); ">
                                        <i class="fa fa-search" aria-hidden="true"></i> Search
                                    </button>
                                </form>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header  border-0">
                        <h4 class="card-title"></h4>
                        <!-- <button type="button" class="btn btn-primary ms-auto" (click)="formtReportDownload()">
                            <i class="fa fa-download" data-bs-toggle="tooltip" data-original-title="download"></i>
                        </button> -->

                    </div>
                    <div class="card-body">
                        <div class="col-xs-12 mobile view">
                            <div *ngIf="employeeMasterList !== null ">
                                <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                                    [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100"
                                    [scrollWindow]="false" (scrolled)="onScroll()" id="scrolllength">
                                    
                                    <table
                                        class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                        id="hr-table">
                                        <thead class="tablerow">
                                            <tr class=" stickyatTop rowcolors">
                                                <th class="border-bottom-0 w-5 fixedcolumn">S.n.o.</th>
                                                <th class="border-bottom-0 w-5 secondcolumn">Name</th>
                                                <th class="border-bottom-0">Father / Husband Name</th>
                                                <th class="border-bottom-0">M / F</th>
                                                <th class="border-bottom-0">Designation/ Department</th>
                                                <th class="border-bottom-0">Date of Joining</th>
                                                <th class="border-bottom-0">ESI NO</th>
                                                <th class="border-bottom-0">EPF NO</th>
                                                <th class="border-bottom-0">Wages fixed Incuding VDA</th>
                                                <!-- <th class="border-bottom-0" style="text-align: center;">Month
                                            
                                                    <tr class="stickyatTop rowcolors whitebgg"> -->

                                                <th class="padding_top_only text-center border-bottom-0 wC target_row"
                                                    *ngFor="let day of daysArray; index as i">
                                                    {{ day }}

                                                    <!-- <span *ngIf="i === 0" class="move_span">Month</span> -->
                                                </th>

                                                <th class="border-bottom-0">WKD</th>
                                                <th class="border-bottom-0">W/O</th>
                                                <th class="border-bottom-0">LEAVE</th>
                                                <th class="border-bottom-0">ABSENT</th>
                                                <th class="border-bottom-0">Total</th>

                                               


                                                <th class="border-bottom-0">No. of payable Days</th>
                                                <th class="border-bottom-0">Total OT Hrs Worked</th>

                                                <ng-container *ngIf="salaryDetailNameList && salaryDetailNameList.length > 0">
                                                    <!-- Display table headings for salary details -->
                                                    <th *ngFor="let salaryDetail of salaryDetailNameList">{{ salaryDetail.salaryDetailName }}</th>
                                                </ng-container>

                                                 <th class="border-bottom-0">Total</th>

                                              

                                                    <ng-container *ngIf="salaryDetailNameList && salaryDetailNameList.length > 0">
                                                     <th *ngFor="let salaryDetail of salaryDetailNameLists">{{ salaryDetail.salaryDetailName }}</th>    
                                                    </ng-container>
                                                <th class="border-bottom-0">Total</th>
                                                <th class="border-bottom-0">Net Payable</th>
                                                <th class="border-bottom-0">Mode of Pay</th>
                                                <th class="border-bottom-0">Bank A/c. No</th>
                                                <th class="border-bottom-0">Employee Signature</th>







                                            </tr>


                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let employee of employeeArray; let i = index">
                                                <td>{{i+1}}</td>
                                                <td>{{employee.name ? employee.name : '--' }}</td>
                                                <td>{{employee.fathersname? employee.fathersname : '--' }}</td>
                                                <td>{{employee.gender ? employee.gender : '--'}}</td>
                                                <td>{{employee.departmentname ? employee.departmentname : '--'}}</td>
                                                <td>{{employee.joiningDate ? employee.joiningDate : '--'}}</td>
                                                <td>{{employee.esino ? employee.esino : '--'}}</td>
                                                <td>{{employee.pfno ? employee.pfno : '--'}}</td>
                                                <td>--</td>


                                               

                                                <ng-container>
                                                    <td
                                                        *ngFor="let attendance of employee.attendanceList; let j = index">
                                                        <div data-th="Leave Type">
                                                            <span style="font-size: 13px; font-weight: 500;"
                                                                *ngIf="attendance.daysPresent === 0.5">
                                                                0.5
                                                            </span>
                                                            <span style="font-size: 13px; font-weight: 500;"
                                                                *ngIf="attendance.daysPresent === 1">
                                                                1
                                                            </span>
                                                            <span style="font-size: 13px; font-weight: 500; color: red;"
                                                                *ngIf="attendance.oddPunch !== 0">
                                                                ODD
                                                            </span>
                                                            <span style="font-size: 13px; font-weight: 500;"
                                                                *ngIf="attendance.leaveTaken !== 0">
                                                                Approved Leave
                                                            </span>
                                                            <span style="font-size: 13px; font-weight: 500;"
                                                                *ngIf="attendance.daysPresent !== 1 && attendance.weeklyHolidays !== 1 && attendance.annualHoliday !== 1 && attendance.oddPunch === 0 && attendance.afterCurrentDate === 0 && attendance.leaveTaken === 0 && attendance.extraDays===0"
                                                                class="badge btn-danger">
                                                                Absent
                                                            </span>
                                                            <span style="font-size: 13px; font-weight: 500;"
                                                                *ngIf="attendance.weeklyHolidays === 1">
                                                                weekly Holiday
                                                            </span>
                                                            <span style="font-size: 13px; font-weight: 500;"
                                                                *ngIf="attendance.annualHoliday === 1">
                                                                Annual Holiday
                                                            </span>
                                                            <span style="font-size: 13px; font-weight: 500;"
                                                                *ngIf="!(attendance.daysPresent === 0.5 || attendance.daysPresent === 1 || attendance.oddPunch !== 0 || attendance.leaveTaken !== 0 || (attendance.daysPresent !== 1 && attendance.weeklyHolidays !== 1 && attendance.annualHoliday !== 1 && attendance.oddPunch === 0 && attendance.afterCurrentDate === 0 && attendance.leaveTaken === 0 && attendance.extraDays===0) || attendance.weeklyHolidays === 1 || attendance.annualHoliday === 1)">
                                                                --
                                                            </span>
                                                        </div>
                                                    </td>
                                                </ng-container>
                                           
                                                <td>{{employee.daysPresent ? employee.daysPresent : '0'}}</td>
                                                <td>{{employee.weeklyHolidays ? employee.weeklyHolidays : '0'}}</td>
                                                <td>{{employee.leaveTaken ? employee.leaveTaken : '0'}}</td>
                                                <td>{{employee.daysAbsent ? employee.daysAbsent : '0'}}</td>
                                                <td>{{employee.daysInMonth ? employee.daysInMonth : '0'}}</td>
                                                <td>{{employee.payableDays ? employee.payableDays : '0'}}</td>
                                                <td>{{employee.otHours ? employee.otHours : '0'}}</td>
                                               

                                                <ng-container *ngIf="employee.salaryDetailsInfo">
                                                    <td *ngFor="let salaryDetail of salaryDetailNameList">
                                                            <span>{{ getSalaryAmount(employee.uniqueemployeeid, salaryDetail.salaryDetailName) }}</span>
                                                     </td>
                                                     </ng-container>

                                                     <td>{{employee.addSalaryAmount ? employee.addSalaryAmount : '0'}}</td>
                                                    
                                                    <ng-container *ngIf="employee.salaryDetailsInfo">
                                                    <td *ngFor="let salaryDetail of salaryDetailNameLists">
                                                        <span>{{ getSalaryAmount(employee.uniqueemployeeid, salaryDetail.salaryDetailName) }}</span>
                                                     </td>
                                                </ng-container>
                                                
                                                <td>{{employee.deductSalaryAmount ? employee.deductSalaryAmount : '0'}}</td>
                                                <td>{{employee.salaryAmount ? employee.salaryAmount : '0'}}</td>
                                                <td>--</td>
                                                <td>{{employee.bankaccount ? employee.bankaccount : '--' }}</td>
                                                <td>--</td>



                                                

                                               

                                               

                                                <!-- <td>fffff</td> -->
 
                                               
                                                

                                               
            
                                               

                                            </tr>



                                            



                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>