import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { EmployeeLeaveApplied } from '../models/employee-leave-applied.model';
import { EmployeeAttendanceSummary } from '../models/EmployeeAttendanceSummary';
import { EmployeeMaster } from '../models/employeeMasterts';
import { AttendanceService } from '../services/attendance.service';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { StringDate24To12Pipe } from '../pipes/string-date-24-to-12.pipe';
import { EmployeeAttendenceProxyService } from '../services/employee-attendence-proxy.service';

declare var $: any;

@Component({
  selector: 'app-attendance-report',
  templateUrl: './attendance-report.component.html',
  styleUrls: ['./attendance-report.component.css']
})
export class AttendanceReportComponent implements OnInit {

  keyinempid: any | undefined;

  query: any;
  fromDate: string;
  toDate: string;
  currentDate: string;

  reportType: string = "Consolidated Report";
  nowDetailedReport: boolean = false;
  selectedMatrix: boolean = false;
  nowConsolidateReport: boolean = true;
  daysArray: any[] = [];
  dayOfWeekArray: any[] = [];

  activeExitFilters: string = "Active";
  listingFilters: string = "GBB";
  odd_Leave: string = "All";

  shift_setting: string;

  showSearchBar: boolean = true;  // search bar , if true = visible

  leaveTypeMap = new Map();
  employeeMaster: EmployeeMaster[];
  client_id: EmployeeMaster[];

  consolidatedReport: EmployeeMaster[];
  detailedConsolidatedReport: EmployeeMaster[];
  attendanceMatrixReport: EmployeeMaster[] = [];
  tempAttendanceMatrixReport: any;

  detailedReport: EmployeeAttendanceSummary[];
  detailReportInConsolidateReport: EmployeeAttendanceSummary[];

  contractstream$!: Subscription;
  receivedData: any;
  startDate: boolean;
  endDate: boolean;
  submitted: boolean;
  empIdList: any;

  //// remove
  employeeId: any;
  employeeName: any;
  monthName: any;
  branchInfo: any;

  branch: any = "all";
  selectedempid: any;

  limitPara: any;
  offsetPara: any;
  clearChild: number = 0;
  // clearChild: boolean = false;

  constructor(private _snackBar: MatSnackBar, private empLeaveService: EmployeeLeaveService, private attendanceService: AttendanceService, private employeeService: EmployeeAttendenceProxyService) {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    this.currentDate = `${year}-${month}-${day}`;
    this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;

  }

  ngOnInit(): void {

    this.activeExitFilters = "Active";
    this.shift_setting = "Setting";
    this.getAllBranches();
    this.limitPara = 15;
    this.offsetPara = 0;

  }

  getEmployeeByNameOrId() {

    console.log("query", this.query)
    this.query = this.query.trim();

    console.log("Len ", this.query);
    if (this.query.length <= 2) {
      this.employeeMaster = []
    }

    if (this.query.length >= 3) {
      this.empLeaveService.getEmployeeByNameOrUniqueId(this.query).subscribe(
        data => {
          console.log("Emp Data ", data);
          this.employeeMaster = data.employeeMasterData;
          this.selectedempid = this.employeeMaster[0].uniqueemployeeid;
        },
        error => {
          console.log("Error ", error);

        }
      )
    }
  }

  generateDaysArray(): void {
    const startDate = new Date(this.fromDate);
    const endDate = new Date(this.toDate);
    const daysArray: any[] = [];
    const dayOfWeekArray: any[] = []; // Array for day of the week characters

    // Create a copy of the start date to avoid modifying it
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      // Get the day of the month as a string and push it to the array
      const dayOfMonth = currentDate.getDate().toString().padStart(2, '0');
      daysArray.push(dayOfMonth);

      // Get the day of the week as a number (0 = Sunday, 1 = Monday, etc.)
      const dayOfWeek = currentDate.getDay();

      // Convert the day of the week number to its one-character representation
      const dayOfWeekChar = ['S', 'M', 'T', 'W', 'T', 'F', 'S'][dayOfWeek];
      dayOfWeekArray.push(dayOfWeekChar);

      // Increment the current date by one day
      currentDate.setDate(currentDate.getDate() + 1);
    }

    this.daysArray = daysArray;
    this.dayOfWeekArray = dayOfWeekArray; // Store day of the week characters
  }

  search() {
    this.offsetPara = 0;
    this.clearChild = 0; 
    console.log("brfore calling attendnace report function ", this.clearChild);
  }


  childToPar(info: number) {
    this.offsetPara = info;
    console.log("value from child to parent for offset increment :", info);
    console.log("clear child in parent is ", this.clearChild);
    this.clearChild = info;
    this.getAttendenceReport();
  }

  getAttendenceReport() {

    // console.log("clicked search button parent");
    // console.log(" reporttype : ", this.reportType, " fromDate : ", this.fromDate, " toDate : ", this.toDate)
    // console.log(" filter : ", this.odd_Leave, " shift : ", this.shift_setting, "branch : ", this.branch);


    let employeename = (<HTMLInputElement>document.getElementById('employeename')).value;

    if (!employeename) {
      employeename = null;
    } else {
      employeename = employeename.trim()
      this.branch = "all";
    }

    // console.log("Employee parent" + employeename);
    // console.log("Report Type parent", this.reportType)
    var id = null;
    if (employeename) {
      this.client_id = this.employeeMaster.filter((dName) => {
        return dName.name === employeename;
      });

      console.log("data parent: ", this.client_id)
      id = this.client_id[0].uniqueemployeeid;
      this.keyinempid = id;
    } else {
      console.log("Id parent", id)
    }

    // console.log("2 reporttype : ", this.reportType, " fromDate : ", this.fromDate, " toDate : ", this.toDate)
    // console.log("2 filter : ", this.odd_Leave, " shift : ", this.shift_setting, "branch ", this.branch);

    if (this.reportType === "Detailed Report" && !id) {
      this.branch = "all";
      this._snackBar.open("Please select employee!", 'Close', {
        duration: 5000,
      });
      return;
    }

    if (this.reportType === "Attendance Matrix") {
      console.log("clicked getAttendenceMatrix button");
      console.log("11111111111111111111111111111111  : ", this.branch)
      this.getAttendenceMatrix(this.reportType, this.fromDate, this.toDate, this.activeExitFilters, this.listingFilters, this.limitPara, this.offsetPara)
      return;
    }

    console.log("all ( detailreport , consolidate report ) : ")
    console.log("Values to send >>", this.reportType, this.fromDate, this.toDate, 'Curent Date >', this.currentDate, "id : ", id, "shift : ", this.shift_setting, "branch ", this.branch, "keyedinid ", this.keyinempid, "selectedempid", this.selectedempid)
    this.contractstream$ = this.attendanceService.getAttendenceReport(this.reportType, this.fromDate, this.toDate, this.currentDate, id, this.shift_setting, this.branch).subscribe(
      data => {
        console.log("Attedance report parent  : ", data)
        if (this.reportType === "Detailed Report") {
          console.log("selectd is type detailed true parent :", this.reportType)
          this.detailedReport = data.attendanceDetails[0].attendanceList;
          this.detailedConsolidatedReport = data.attendanceDetailsConsolidate;
          console.log("detail report response here : : :  ", this.detailedReport)
          console.log("detailed report + consolidate report : : ", this.detailReportInConsolidateReport)
        } else {
          console.log("selectd is type consolidate true parent :", this.reportType)
          this.consolidatedReport = data.attendanceDetailsConsolidate;
          console.log(" consolidatedReport parent", this.consolidatedReport)
        }
        console.log("grid move up");
        $('html,body').animate({
          scrollTop: $(`#navigatedest`).offset().top - 90
        },
          'slow');
      },
      error => {
        console.log(error);
      }
    );
  }

  getAttendenceMatrix(reportType, fromDate, toDate, activeExitFilters, listingFilters, limitPara, offsetPara) {

    console.log("222222222222222222222222222222  : ", this.branch)
    this.generateDaysArray();
    console.log("clicked getAttendenceMatrix button");
    const monthNames = [
      "January", "February", "March", "April", "May", "June", "July",
      "August", "September", "October", "November", "December"
    ];
    const fromDateParts = this.fromDate.split('-');
    const monthIndex = parseInt(fromDateParts[1]) - 1;
    this.monthName = monthNames[monthIndex];
    var id = null;
    console.log("attendance matrix")
    console.log(" data to pass getAttendenceMatrix ", "RT ", reportType, "FD ", fromDate, "TD ", toDate, "ID ", id, "activefilter", activeExitFilters, "shift", this.shift_setting, "branch ", this.branch);

    this.contractstream$ = this.attendanceService.getAttendenceMatrix(this.reportType, this.fromDate, this.toDate, id, this.activeExitFilters, this.shift_setting, this.branch, listingFilters, limitPara, offsetPara).subscribe(
      data => {
        console.log("Attedance Matrix report parent  : ", data)

        if (this.odd_Leave === "Odd") {
          if (this.offsetPara == 0) {
            const LoopList: any = [];
            this.tempAttendanceMatrixReport = data.attendanceDetails;
            for (let i = 0; i < this.tempAttendanceMatrixReport.length; i++) { // iterate through name / each employee
              // console.log("Loop inside this.tempAttendanceMatrixReport:", i, ": index  :", this.tempAttendanceMatrixReport[i]);
              console.log("Loop inside this.tempAttendanceMatrixReport  : index  :", this.tempAttendanceMatrixReport[i].employeeName);
              console.log("--- --- --- --- --- --- --- ---")

              for (let j = 0; j < this.tempAttendanceMatrixReport[i].attendanceList.length; j++) {  // iterate through attendace list of each emp 
                // console.log("Inside loop this.tempAttendanceMatrixReport[i].attendanceList:", j, ": index  :", this.tempAttendanceMatrixReport[i].attendanceList[j]);
                // console.log("Inside loop this.tempAttendanceMatrixReport[i].attendanceList:  hour : ", this.tempAttendanceMatrixReport[i].attendanceList[j].totalSwipeHour);

                if (this.tempAttendanceMatrixReport[i].attendanceList[j].totalSwipeHour === '00:00:00') { // check condition 
                  console.log("push these into a list '00:00:00'  :", this.tempAttendanceMatrixReport[i].attendanceList[j])
                  // childLoopList.push(this.tempAttendanceMatrixReport[i].attendanceList[j])
                  LoopList.push(this.tempAttendanceMatrixReport[i])
                  console.log(" ")
                  break;
                }
                console.log("-- -- -- -- -- -- -- -- -- -- --")
              }
              console.log("childLoopList -", LoopList)

            }
            this.attendanceMatrixReport = LoopList;
            console.log("attendanceMatrixReport : ", this.attendanceMatrixReport)
          } else {
            const LoopList: any = [];
            this.tempAttendanceMatrixReport = this.tempAttendanceMatrixReport.concat(data.attendanceDetails);
            for (let i = 0; i < this.tempAttendanceMatrixReport.length; i++) { // iterate through name / each employee
              // console.log("Loop inside this.tempAttendanceMatrixReport:", i, ": index  :", this.tempAttendanceMatrixReport[i]);
              console.log("Loop inside this.tempAttendanceMatrixReport  : index  :", this.tempAttendanceMatrixReport[i].employeeName);
              console.log("--- --- --- --- --- --- --- ---")

              for (let j = 0; j < this.tempAttendanceMatrixReport[i].attendanceList.length; j++) {  // iterate through attendace list of each emp 
                // console.log("Inside loop this.tempAttendanceMatrixReport[i].attendanceList:", j, ": index  :", this.tempAttendanceMatrixReport[i].attendanceList[j]);
                // console.log("Inside loop this.tempAttendanceMatrixReport[i].attendanceList:  hour : ", this.tempAttendanceMatrixReport[i].attendanceList[j].totalSwipeHour);

                if (this.tempAttendanceMatrixReport[i].attendanceList[j].totalSwipeHour === '00:00:00') { // check condition 
                  console.log("push these into a list '00:00:00'  :", this.tempAttendanceMatrixReport[i].attendanceList[j])
                  // childLoopList.push(this.tempAttendanceMatrixReport[i].attendanceList[j])
                  LoopList.push(this.tempAttendanceMatrixReport[i])
                  console.log(" ")
                  break;
                }
                console.log("-- -- -- -- -- -- -- -- -- -- --")
              }
              console.log("childLoopList -", LoopList)

            }
            this.attendanceMatrixReport = LoopList;
            console.log("attendanceMatrixReport : ", this.attendanceMatrixReport)
          }

        }
        else if (this.odd_Leave === "Leave") {
          if (this.offsetPara == 0) {
            const LoopList: any = [];
            this.tempAttendanceMatrixReport = data.attendanceDetails;
            for (let i = 0; i < this.tempAttendanceMatrixReport.length; i++) {
              console.log("Loop inside resp  : index  :", this.tempAttendanceMatrixReport[i].employeeName);
              console.log("--- --- --- --- --- --- --- ---")

              for (let j = 0; j < this.tempAttendanceMatrixReport[i].attendanceList.length; j++) {
                console.log(" ")
                console.log("Inside loop resp[i].attendanceList:", j, ": index  :", this.tempAttendanceMatrixReport[i].attendanceList[j]);
                console.log(" ")

                if (this.tempAttendanceMatrixReport[i].attendanceList[j].annualHoliday === 0 && this.tempAttendanceMatrixReport[i].attendanceList[j].weeklyHolidays === 0 &&
                  this.tempAttendanceMatrixReport[i].attendanceList[j].leaveTaken === 0 && this.tempAttendanceMatrixReport[i].attendanceList[j].daysPresent === 0
                  && this.tempAttendanceMatrixReport[i].attendanceList[j].oddPunch === 0 && this.tempAttendanceMatrixReport[i].attendanceList[j].daysAbsent === 1) {

                  console.log("push these into a list '00:00:00'  :", this.tempAttendanceMatrixReport[i].attendanceList[j])
                  LoopList.push(this.tempAttendanceMatrixReport[i])
                  console.log(" ")
                  break;
                }
                console.log("-- -- -- -- -- -- -- -- -- -- --")
              }
              console.log("childLoopList -", LoopList)

            }
            this.attendanceMatrixReport = LoopList;
            console.log("attendanceMatrixReport : ", this.attendanceMatrixReport)
          } else {
            const LoopList: any = [];
            this.tempAttendanceMatrixReport = this.tempAttendanceMatrixReport.concat(data.attendanceDetails);
            for (let i = 0; i < this.tempAttendanceMatrixReport.length; i++) {
              console.log("Loop inside resp  : index  :", this.tempAttendanceMatrixReport[i].employeeName);
              console.log("--- --- --- --- --- --- --- ---")

              for (let j = 0; j < this.tempAttendanceMatrixReport[i].attendanceList.length; j++) {
                console.log(" ")
                console.log("Inside loop resp[i].attendanceList:", j, ": index  :", this.tempAttendanceMatrixReport[i].attendanceList[j]);
                console.log(" ")

                if (this.tempAttendanceMatrixReport[i].attendanceList[j].annualHoliday === 0 && this.tempAttendanceMatrixReport[i].attendanceList[j].weeklyHolidays === 0 &&
                  this.tempAttendanceMatrixReport[i].attendanceList[j].leaveTaken === 0 && this.tempAttendanceMatrixReport[i].attendanceList[j].daysPresent === 0
                  && this.tempAttendanceMatrixReport[i].attendanceList[j].oddPunch === 0 && this.tempAttendanceMatrixReport[i].attendanceList[j].daysAbsent === 1) {

                  console.log("push these into a list '00:00:00'  :", this.tempAttendanceMatrixReport[i].attendanceList[j])
                  LoopList.push(this.tempAttendanceMatrixReport[i])
                  console.log(" ")
                  break;
                }
                console.log("-- -- -- -- -- -- -- -- -- -- --")
              }
              console.log("childLoopList -", LoopList)

            }
            this.attendanceMatrixReport = LoopList;
            console.log("attendanceMatrixReport : ", this.attendanceMatrixReport)
          }
        }
        else {
          if (this.offsetPara == 0) {
            this.attendanceMatrixReport = data.attendanceDetails;
            console.log("attendanceMatrixReport : ", this.attendanceMatrixReport)
          } else {
            this.attendanceMatrixReport = this.attendanceMatrixReport.concat(data.attendanceDetails);
            console.log("attendanceMatrixReport : ", this.attendanceMatrixReport);
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  onAttendanceReportTypeChange(selectedValue: string) {
    this.showSearchBar = true;  // search bar , if true = visible
    this.reportType = selectedValue;
    this.selectedMatrix = false;
    this.nowDetailedReport = false;
    this.nowConsolidateReport = false;

    if (this.reportType === "Attendance Matrix") {
      this.selectedMatrix = true;
      this.showSearchBar = false;  // search bar , if true = visible

      console.log(" / F", this.selectedMatrix)
    } else if (this.reportType === "Detailed Report") {
      this.showSearchBar = true;  // search bar , if true = visible
      this.nowDetailedReport = true;
      this.consolidatedReport = [];
      this.branch = "all";
    } else {
      this.showSearchBar = true;  // search bar , if true = visible
      this.nowConsolidateReport = true;
      this.detailedReport = [];
    }
    console.log("Value", this.reportType)
  }

  checkFromAndToDate() {
    this.startDate = false;
    this.endDate = false;
    this.submitted = true;
    const currentDate = new Date();

    if (this.toDate) {
      if (this.fromDate > this.toDate) {
        this.submitted = false
        this.endDate = true;
      } else {
        this.endDate = false;
        return true;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.contractstream$) {
      this.contractstream$.unsubscribe();
    }
  }

  clear() {

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    this.currentDate = `${year}-${month}-${day}`;
    this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
    this.query = "";
    this.clearOnchange();

  }

  clearOnchange() {
    this.tempAttendanceMatrixReport = [];
    this.attendanceMatrixReport = []; // empty matrix response 
    this.detailedReport = []; // detail report 
    this.detailedConsolidatedReport = []; // detailed consolidate report 
    this.consolidatedReport = []; // consolidate report 
    // this.clearChild = true;
    this.clearChild = 0;
    console.log("clear child after change ", this.clearChild);
  }

  getAllBranches() {
    console.log("Called all branches >> >> >>")
    this.employeeService.getAllBranchs().subscribe(
      data => {
        console.log("check this")
        this.branchInfo = data.branches;
      },
      error => {
      }
    );
  }

  onBranchChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.branch = selectElement.value;
    console.log('Branch changed to: ', this.branch);
    this.clearOnchange();

  }

  onActiveExitFilterChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.activeExitFilters = selectElement.value;
    console.log('Active/Exited filter changed to: ', this.activeExitFilters);
    this.clearOnchange();
  }

  onOddLeaveFilterChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.odd_Leave = selectElement.value;
    console.log('Odd/Leave filter changed to: ', this.odd_Leave);
    this.clearOnchange();
  }

  onListingFilterChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.listingFilters = selectElement.value;
    console.log('Listing filter changed to: ', this.listingFilters);
    this.clearOnchange();
  }

}
