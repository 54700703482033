import { Component, OnInit } from '@angular/core';
import { PeopleInfo } from '../../models/peopleInfo';
import { DocumentInfo } from '../../models/documentInfo';
import { MyDocuments } from '../../models/mydocuents';
import { CommonService } from '../../services/common.service';
import { AlertService } from '../../services';
import { DocumentInfoService } from '../../services/documentinfo.service';


@Component({
  selector: 'app-listmydoc',
  templateUrl: './listmydoc.component.html',
  styleUrls: ['./listmydoc.component.css']
})
export class ListMydocComponent implements OnInit {
  peopleInfo: PeopleInfo;
  documentInfo: DocumentInfo;
  submitted: boolean;

  username: any;
  myDoc: any = {};
  myDocuments: MyDocuments;
  //docGrpArray: docGrp[];
  //docTypArrays: docType[];
  docGrpArray  = [];
  docTypArray = [];
  queryString: any;
  documentList = [];
  documentInfoDetails = [];

  constructor(private commonService: CommonService, private alertService: AlertService, private docmentInfoService: DocumentInfoService) { }
  // private router: Router,private customerService :CustomerService,private alertservice: AlertService ) { }

  ngOnInit() {
    this.username = sessionStorage.getItem('username')
    this.peopleInfo = new PeopleInfo();
    this.myDocuments = new MyDocuments();
    this.peopleInfo.name = this.username;
    this.submitted = false;
    console.log("The User Name is", this.username);


  }


 



  reFresh() {
    this.peopleInfo = new PeopleInfo();

  }
}
