import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AnnualHoliday } from '../models/AnnualHolidayInfo';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  getdesignation(name: string) {
    throw new Error('Method not implemented.');
  }

  public url;
  protected basePath = '/api';
  public defaultHeaders: Headers = new Headers();
  constructor(private httpClient: HttpClient) { }

  private extendObj<T1, T2>(objA: any, objB: any) {
    for (const key in objB) {
      if (objB.hasOwnProperty(key)) {
        objA[key] = objB[key];
      }
    }
    return <T1 & T2>objA;
  }

  public getByQueryParamWithHttpInfo(queryParameters: HttpParams, urlPath: any, extraHttpRequestParams?: any): Observable<any> {
    const path = urlPath;

    let requestOptions: any = {
      params: queryParameters
    };

    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }
    return this.httpClient.get(path, requestOptions);

  }

  public deleteQueryParamWithHttpInfo(queryParameters: HttpParams, urlPath: any, extraHttpRequestParams?: any): Observable<any> {
    const path = urlPath;

    let requestOptions: any = {
      params: queryParameters
    };

    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.httpClient.request("delete", path, requestOptions);

  }

  public postWithHttpInfo(param: any, queryParameters: HttpParams, urlPath: any, extraHttpRequestParams?: any): Observable<any> {

    const path = urlPath;

    // verify required parameter 'param' is not null or undefined
    if (param === null || param === undefined) {
      throw new Error('Required parameter body was null or undefined when calling tevicoPost.');
    }
    let requestOptions: any = {
      params: queryParameters
    };

    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.httpClient.post<any>(path, param, requestOptions);
  }

  public putWithHttpInfo(param: any, queryParameters: HttpParams, urlPath: any, extraHttpRequestParams?: any): Observable<any> {

    const path = urlPath;

    // verify required parameter 'param' is not null or undefined
    if (param === null || param === undefined) {
      throw new Error('Required parameter body was null or undefined when calling tevicoPost.');
    }

    let requestOptions: any = {
      params: queryParameters
    };

    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.httpClient.put<any>(path, param, requestOptions);
  }

  public getStockStorageLevels(product: string, location: any, storeId: any): Observable<any> {
    this.url = '/v1/util/stockStorage';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('product', product);
    queryParameters = queryParameters.set('location', location);
    queryParameters = queryParameters.set('storeId', storeId);
    return this.getByQueryParamWithHttpInfo(queryParameters, path);
  }


  public getStoreStockStorage(product: string, location: any): Observable<any> {
    this.url = '/v1/util/storeList';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('product', product);
    queryParameters = queryParameters.set('location', location);
    return this.getByQueryParamWithHttpInfo(queryParameters, path);
  }


  public getStoreList(location: any): Observable<any> {
    this.url = '/v1/util/store';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('location', location);
    return this.getByQueryParamWithHttpInfo(queryParameters, path);
  }
  public getProducts(type, productType): Observable<any> {
    this.url = '/v1/util/getproducts';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('type', type);
    queryParameters = queryParameters.set('productType', productType);
    return this.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getProductsBySubtype(productType): Observable<any> {
    this.url = '/v1/util/getproducts/subtype';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('productType', productType);
    return this.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public save(peopleInfo: any): Observable<any> {
    this.url = '/v1/createUser';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.postWithHttpInfo(peopleInfo, queryParameters, path);


  }


  public getRoles(): Observable<any> {
    this.url = '/v1/roles';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getPeoples(name: any): Observable<any> {
    this.url = '/v1/allpeoplepattern';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('name', name);
    return this.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getAllPeoples(limitpara: any, offsetpara: any, selectedQualifier: any): Observable<any> {
    this.url = '/v1/allpeoples';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('limitpara', limitpara);
    queryParameters = queryParameters.set('offsetpara', offsetpara);
    queryParameters = queryParameters.set('selectedQualifier', selectedQualifier);
    return this.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getPeople(active: any, name: any): Observable<any> {
    this.url = '/v1/peoples';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('active', active);
    queryParameters = queryParameters.set('name', name);
    return this.getByQueryParamWithHttpInfo(queryParameters, path);
  }


  // public getAllActivePeople(active:any): Observable<any> {
  //   this.url = '/v1/allactivepeoples';
  //   const path = this.basePath + this.url;
  //    let queryParameters = new HttpParams();
  //    queryParameters = queryParameters.set('active', active);
  //    return this.getByQueryParamWithHttpInfo(queryParameters, path);
  // }


  // public getOldPassword(username : any): Observable<any> {
  //   this.url = '/v1/getOldpassword';
  //   const path = this.basePath + this.url;
  //    let queryParameters = new HttpParams();
  //    queryParameters = queryParameters.set('username', username);
  //    return this.getByQueryParamWithHttpInfo(queryParameters, path );
  // }

  public saveNewPassword(peopleInfo: any): Observable<any> {
    this.url = '/v1/saveNewPwd';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.postWithHttpInfo(peopleInfo, queryParameters, path);


  }

  public getEmployeeIds(): Observable<any> {
    this.url = '/v1/employees';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getBranchIds(): Observable<any> {
    this.url = '/v1/branches';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getBranchlist(): Observable<any> {
    this.url = '/v1/branchList';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public saveBranch(branchName: any): Observable<any> {
    this.url = '/v1/saveBranch';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('branchName', branchName);
    return this.postWithHttpInfo(name, queryParameters, path);
  }

  public saveAnnualHoliday(annualName: any): Observable<any> {
    this.url = '/v1/saveAnnualHoliday';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('annualName', annualName);
    return this.postWithHttpInfo(name, queryParameters, path);
  }

  public getAnnualHolidays(): Observable<any> {
    this.url = '/v1/annualholiday';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getjobMaster(): Observable<any> {
    this.url = '/v1/getJobMaster';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getCompOffLeaveApplied(uniqueEmployeeId: number): Observable<any> {
    this.url = '/v1/getCompOffLeaveApplied';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    if (uniqueEmployeeId !== null || uniqueEmployeeId !== undefined) {
      queryParameters = queryParameters.set('uniqueEmployeeId', uniqueEmployeeId);
    }
    return this.getByQueryParamWithHttpInfo(queryParameters, path);
  }


  //addded on 27/9/22---admin reports
  public getemployeehr(): Observable<any> {
    this.url = '/v1/emploersallocatedhr';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.getByQueryParamWithHttpInfo(queryParameters, path);
  }
  //addded on 29/9/22---admin reports
  public getuserdetails(): Observable<any> {
    this.url = '/v1/userdetails';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.getByQueryParamWithHttpInfo(queryParameters, path);
  }
  //addded on 7/10/22---employee doc details
  public getempdocdetails(): Observable<any> {
    this.url = '/v1/empiddet';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getdeparment(name: any): Observable<any> {
    this.url = '/v1/department';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('name', name);
    return this.postWithHttpInfo(name, queryParameters, path);
  }

  public insertJobMaster(joblevel: any, roledescription: any, heirarchy: any): Observable<any> {
    this.url = '/v1/insertJobMaster';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.postWithHttpInfo({ joblevel: joblevel, roledescription: roledescription, hierarchy: heirarchy }, queryParameters, path);
  }

  public deleteJobMaster(id: any): Observable<any> {
    this.url = '/v1/deleteJobMaster';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.postWithHttpInfo({ t_jobId: id }, queryParameters, path);
  }


  public editUpdateJobMaster(joblevel: any, roledescription: any, heirarchy: any, id: number): Observable<any> {
    this.url = '/v1/editUpdateJobMaster';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.postWithHttpInfo({ joblevel: joblevel, roledescription: roledescription, hierarchy: heirarchy, t_jobId: id }, queryParameters, path);
  }


  public getempDesignation(name: any, jobid: string): Observable<any> {
    this.url = '/v1/designation';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('name', name);
    queryParameters = queryParameters.set('joblevel', jobid);

    return this.postWithHttpInfo(name, queryParameters, path);
  }

  public getSearchdeparment(name: any): Observable<any> {
    this.url = '/v1/getdeparment';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('name', name);
    return this.getByQueryParamWithHttpInfo(queryParameters, path);

  }

  public getSearchdesignation(name: any, joblevel: any): Observable<any> {
    this.url = '/v1/getdesignation';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('name', name);
    queryParameters = queryParameters.set('joblevel', joblevel);
    return this.getByQueryParamWithHttpInfo(queryParameters, path);

  }

  public getSearchdeparment1(): Observable<any> {
    this.url = '/v1/getdeparment1';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    //  queryParameters = queryParameters.set('limitpara',limitpara);
    //  queryParameters = queryParameters.set('offset',offset);
    return this.getByQueryParamWithHttpInfo(queryParameters, path);

  }

  public getSearchdesignation1(): Observable<any> {
    this.url = '/v1/getdesignation1';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    //  queryParameters = queryParameters.set(name);
    return this.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getjobLevel(): Observable<any> {
    this.url = '/v1/getjobLevel';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    //  queryParameters = queryParameters.set(name);
    return this.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getCalendarHolidays(): Observable<any> {
    this.url = '/v1/calendar-holiday';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.getByQueryParamWithHttpInfo(queryParameters, path);
  }
}