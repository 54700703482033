import { query } from '@angular/animations';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmployeeAttendence } from '../models/EmployeeAttendenceInfo';
import { TaskDetailsForAttendance } from '../models/TaskDetailForAttendance';
import { CommonService } from './common.service';
import { Employeeswipeintermediateinfo } from '../models/EmployeeswipeIntermediateinfo';

@Injectable({
  providedIn: 'root'
})
export class EmployeeattendenceserviceService {
  protected basePath = '/api';
  public defaultHeaders: Headers = new Headers();
  public url!: string;
  constructor(private httpclient: HttpClient, private commonService: CommonService) { }

  // public saveEmpAttendence(employeeattendenceinfo: EmployeeAttendence): Observable<any> {
  //   this.url = '/v1/employeeattendence';
  //   const path = this.basePath + this.url;
  //   let queryParameters = new HttpParams();
  //   console.log("endpoint value ",employeeattendenceinfo)
  //   return this.commonService.postWithHttpInfo(employeeattendenceinfo, queryParameters, path);
  // }

  public saveEmpAttendence(employeeattendenceinfo: EmployeeAttendence): Observable<any> {
    this.url = '/v1/employeeattendence';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    console.log("endpoint value ", employeeattendenceinfo)
    return this.commonService.postWithHttpInfo(employeeattendenceinfo, queryParameters, path);
  }

  public saveEmpAttendenceCaptureface(employeeattendenceinfo: EmployeeAttendence): Observable<any> {
    this.url = '/v1/employeeattendenceCaptureface';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    console.log("endpoint value ", employeeattendenceinfo)
    return this.commonService.postWithHttpInfo(employeeattendenceinfo, queryParameters, path);
  }


  public getEmpAttendenceSummary(empid: any, fromdate: string, todate: string): Observable<any> {
    this.url = '/v1/emp-attendence-summary';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('uniqueempid', empid)
    queryParameters = queryParameters.set('fromdate', fromdate)
    queryParameters = queryParameters.set('todate', todate)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }


  public getTeamMemberList(empid: string): Observable<any> {
    this.url = '/v1/empTeamMember';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('uniqueempid', empid)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public get_regularized_emp_Summary(uniqueemployeeid: number): Observable<any> {
    this.url = '/v1/attendance-history';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('uniqueEmployeeId', uniqueemployeeid)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  // public getEmployeeByNameOrUniqueId(query: any): Observable<any> {
  //   this.url = '/v1/employeesearch';
  //   const path = this.basePath + this.url;
  //   let queryParameters = new HttpParams();
  //   if (query !== null) {
  //     queryParameters = queryParameters.set('query', query);
  //   }
  //   return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  // }

  public get_regularizedinfo(): Observable<any> {
    this.url = '/v1/empattendence-regularize-list';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  // old 
  // public approve(taskdetail: TaskDetailsForAttendance[]): Observable<any> {
  //   this.url = '/v1/approveAttendanceOfEmployee';
  //   const path = this.basePath + this.url;
  //   let queryParameters = new HttpParams();
  //   return this.commonService.postWithHttpInfo(taskdetail, queryParameters, path)
  // }

  // new
  public approve(taskdetail: Employeeswipeintermediateinfo[], fg: any): Observable<any> {
    console.log("value to approve ", taskdetail, "flag is ", fg);
    this.url = '/v1/approveAttendanceOfEmployee';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('flag', fg)
    return this.commonService.postWithHttpInfo(taskdetail, queryParameters, path)
  }

  public applyforRegularizeForMe(infolist: EmployeeAttendence[]): Observable<any> {
    this.url = '/v1/emp-attendence-regularize';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(infolist, queryParameters, path)
  }


  public applyforRegularizeTeam(infolist: EmployeeAttendence[], type: any): Observable<any> {
    const url = '/v1/emp-attendance-regularize-approve';
    const path = this.basePath + url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('meOrTeam', type)
    console.log("uid or type : ", type)
    return this.commonService.postWithHttpInfo(infolist, queryParameters, path);
  }


  public getemployeebreaksinfo() {
    this.url = '/v1/employee-breaks';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  // date and emp id ...... 
  public get_Attendence_info_based_On_date(empid: string, date: string): Observable<any> {
    this.url = '/v1/current-emp-attendence';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('empid', empid)
    queryParameters = queryParameters.set('date', date)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  // to display server time on UI
  public get_Attendence_info(empid: string): Observable<any> {
    this.url = '/v1/current-emp-attendence-server-time';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('empid', empid)
    // queryParameters = queryParameters.set('date', date)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public get_Attendence_info_based_On_date_Regularize(empid: string, date: string): Observable<any> {
    console.log("in endpoint,  empid : ", empid, " date :", date)
    this.url = '/v1/current-emp-attendence-regularize';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('empid', empid)
    queryParameters = queryParameters.set('date', date)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }
  public validateprofilepic(): Observable<any> {
    console.log("Enetered validateprofilepic method");
    this.url = '/v1/employee-profile-pic';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    console.log("Enetred upload method1");
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

}
