<!-- 
	togglesidebarinmobileview() function  is replaced to notoggle() funtion when we had togglesidebarinmobileview() :

	example : 

	1
		a
		b
	2
		a
		b
	3
		a
		b

	to 

	1
		a
		b
	2
	3
	4

when we click sub element ( a ) , it will hide rest sub element has

	1
	2
	3

to avoid that and to achive 
and even after clicking a or b it must display has

1
	a
	b
2
3

 so i have used notoggle() its an empty / unused function  in future if required  replace  notoggle() to togglesidebarinmobileview()

-->

<!-- new navbar -->
<div class="sticky" *ngIf="isloggedIn == true">
	<aside class="app-sidebar" (mouseover)="mouseOver()">
		<!-- <div class="app-sidebar__logo">
		  
		  <a class="header-brand" >
			<img src="./../../assets/img/paytime_logo-removebg-preview.png" class="header-brand-img " alt="ScalupLogo" style="height: 100%; margin:auto" id="companyname">
			
		</a>
	  </div> -->
		<div class="app-sidebar3 " id="appSidebar3">
			<div class="main-menu">

				<ul class="side-menu marginTop" id="sidemenu" style="cursor: pointer;">

					<!-- dashboard  -->
					<li class="slide" (click)="menuClick($event)" [hasAccess]="{role:getUserRole(),key:'MYDOCUMENTS'}"
						[hasAccess]="{role:getUserRole(),key:'DASHBOARD'}" (click)="togglesidebarinmobileview()">
						<a class="side-menu__item active" id="slid10" data-bs-toggle="slide"
							[routerLink]="['/dashboard']" style="padding: 10px;"
							[routerLinkActive]="['highlightActiveLink']">
							<img src="./../../assets/img/Dashboard.png"
								style="width: 23px; height: 25px; display: block; margin: auto;" alt="">
							<span class="side-menu__label" style="margin-left: 10px;">Dashboard</span>
						</a>
					</li>

					<!-- notie board  -->
					<li class="slide" (click)="menuClick($event)" (click)="togglesidebarinmobileview()"
						[hasAccess]="{role:getUserRole(),key:'NOTICEBOARD'}">
						<a class="side-menu__item " id="slid23" data-bs-toggle="slide" [routerLink]="['/noticeboard']"
							style="padding: 10px;" [routerLinkActive]="['highlightActiveLink']">
							<img src="./../../assets/img/icons8-noticeboard-25.png"
								style="width: 23px; height: 25px; display: block; margin: auto;" alt="">
							<span class="side-menu__label" style="margin-left: 10px;">Notice Board</span>
						</a>

					</li>

					<!-- costumer  -->
					<li class="slide" (click)="menuClick($event)"
						[hasAccess]="{role:getUserRole(),key:'CUSTOMERSEARCH'}">
						<a class="side-menu__item " id="slid12" data-bs-toggle="slide" style="padding: 10px;">
							<img src="./../../assets/img/customers.png"
								style="width: 23px; height: 25px; display: block; margin: auto;" alt="">
							<span class="side-menu__label" style="margin-left: 10px;">Customers</span><i
								class="angle fa fa-angle-right"></i>
						</a>
						<ul class="slide-menu">

							<li [hasAccess]="{role:getUserRole(),key:'CUSTOMER'}" (click)="togglesidebarinmobileview()">
								<a class="slide-item" [routerLink]="['/customers']"
									[routerLinkActive]="['highlightActiveLink']" (click)="togglesidebarinmobileview();$event.stopPropagation()
									">Customer</a>
							</li>
							<li [hasAccess]="{role:getUserRole(),key:'CUSTOMERSEARCH'}" (click)="togglesidebarinmobileview();$event.stopPropagation()
							"><a class="slide-item" [routerLink]="['/customers/customersearch']"
									[routerLinkActive]="['highlightActiveLink']" (click)="togglesidebarinmobileview();$event.stopPropagation()
									">Search
									Customer</a></li>

						</ul>
					</li>

					<!-- employee  -->
					<li class="slide" (click)="menuClick($event)"
						[hasAccess]="{role:getUserRole(),key:'EMPLOYEESECTION'}" data-bs-toggle="tooltip"
						data-bs-placement="left" title="Tooltip on left">
						<a class="side-menu__item " id="slid13" data-bs-toggle="slide" style="padding: 10px;">
							<img src="./../../assets/img/People.png"
								style="width: 23px; height: 25px;  display: block; margin: auto;" alt="">
							<span class="side-menu__label" style="margin-left: 10px;">Employees</span><i
								class="angle fa fa-angle-right"></i>
						</a>
						<ul class="slide-menu">
							<!-- <li ><a class="slide-item" [routerLink]="['/employeemaster']"[routerLinkActive]="['highlightActiveLink']">Employee Master</a></li> -->
							<!-- <li ><a class="slide-item"  [routerLink]="['employeesearch']"[routerLinkActive]="['highlightActiveLink']">Search Employee</a></li> -->
							<li [hasAccess]="{role:getUserRole(),key:'EMPLOYEEMASTER'}"><a class="slide-item"
									[routerLink]="['/employeemaster']" [routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview()"> Employee Master</a>
							</li>
							<li><a class="slide-item" [routerLink]="['employeesearch']"
									[routerLinkActive]="['highlightActiveLink']" (click)="togglesidebarinmobileview();$event.stopPropagation()
								">Search Employee</a>
							</li>
							<li [hasAccess]="{role:getUserRole(),key:'EMPLOYEEMASTER'}"><a class="slide-item"
									[routerLink]="['employeejoiningreport']"
									[routerLinkActive]="['highlightActiveLink']" (click)="togglesidebarinmobileview();$event.stopPropagation()
							">Employee Joining/Exit Report</a>
							</li>

							<li [hasAccess]="{role:getUserRole(),key:'ASSIGNLEAVETYPE'}"><a class="slide-item"
									[routerLink]="['assignLeaveType']" [routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview();$event.stopPropagation()">
									Assign Leave Type
								</a>
							</li>
							<!-- <li [hasAccess]="{role:getUserRole(),key:'EMPLOYEEDOCSEARCH'}"><a class="slide-item" [routerLink]="['employeedocsearch']"
									[routerLinkActive]="['highlightActiveLink']" (click)="notoggle();$event.stopPropagation()
									">Document Submission Report</a>
							</li> -->

						</ul>
					</li>

					<!-- <li class="slide" (click)="menuClick($event)" [hasAccess]="{role:getUserRole(),key:'DOCMASTER'}"> -->
					<!-- <a class="side-menu__item " id="slid14" data-bs-toggle="slide" style="padding: 10px;">
							<img src="./../../assets/img/Documents.png"
								style="width: 23px; height: 25px; display: block; margin: auto;" alt="">
							<span class="side-menu__label" style="margin-left: 10px;">Documents</span><i
								class="angle fa fa-angle-right"></i>
						</a> -->
					<!-- <li ><a class="slide-item" [routerLink]="['/document']" [routerLinkActive]="['highlightActiveLink']">Search Document Type</a></li> -->
					<!-- <li ><a class="slide-item"  [routerLink]="['/document/savedoc']"[routerLinkActive]="['highlightActiveLink']" >Define Document Type</a></li> -->
					<!-- <li [hasAccess]="{role:getUserRole(),key:'APPROVALINBOX'}"><a class="slide-item"
								[routerLink]="['/approvalinbox']" [routerLinkActive]="['highlightActiveLink']"
								(click)="notoggle();$event.stopPropagation()
								">Approval Inbox</a></li> -->
					<!-- <ul class="slide-menu">
							<li><a class="slide-item" [routerLink]="['/bulkuploaddocument']"
									[routerLinkActive]="['highlightActiveLink']" (click)="notoggle();$event.stopPropagation()
									">Bulk Upload Documents </a></li>
							<li [hasAccess]="{role:getUserRole(),key:'APPROVALINBOX'}"><a class="slide-item"
									[routerLink]="['/approvalinbox']" [routerLinkActive]="['highlightActiveLink']"
									(click)="notoggle();$event.stopPropagation()
										">View Documents</a></li>
							<li [hasAccess]="{role:getUserRole(),key:'CLIENTMASTER'}"><a class="slide-item"
									[routerLink]="['/contract-client']" [routerLinkActive]="['highlightActiveLink']"
									(click)="notoggle();$event.stopPropagation()
												">Contract Client</a></li>

						</ul> -->
					<!-- </li> -->

					<!-- contract client  -->
					<li class="slide" (click)="menuClick($event)"
						[hasAccess]="{role:getUserRole(),key:'CONTRACTCLIENT'}">
						<a class="side-menu__item " id="slid25" data-bs-toggle="slide" style="padding: 10px;">
							<img src="./../../assets/img/Contract-Client-icon.png"
								style="width: 23px; height: 25px; display: block; margin: auto;" alt="">
							<span class="side-menu__label" style="margin-left: 10px;">Contract Client</span><i
								class="angle fa fa-angle-right"></i>
						</a>
						<ul class="slide-menu">
							<li [hasAccess]="{role:getUserRole(),key:'CONTRACTCLIENT'}"><a class="slide-item"
									[routerLink]="['/contract-client']" [routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview();$event.stopPropagation()
							">Clients</a></li>
							<li [hasAccess]="{role:getUserRole(),key:'CONTRACTCLIENTLOCATION'}"><a class="slide-item"
									[routerLink]="['/contract-client-location']"
									[routerLinkActive]="['highlightActiveLink']" (click)="togglesidebarinmobileview();$event.stopPropagation()
							">Client Locations</a></li>
							<li [hasAccess]="{role:getUserRole(),key:'CONTRACTCLIENTPROJECT'}"><a class="slide-item"
									[routerLink]="['/contract-client-project']"
									[routerLinkActive]="['highlightActiveLink']" (click)="togglesidebarinmobileview();$event.stopPropagation()
							">Client Projects</a></li>
							<li [hasAccess]="{role:getUserRole(),key:'CONTRACTPROJECTDESEST'}"><a class="slide-item"
									[routerLink]="['/contract-project-des-est']"
									[routerLinkActive]="['highlightActiveLink']" (click)="togglesidebarinmobileview();$event.stopPropagation()
								">Project Budgeting</a></li>
							<li [hasAccess]="{role:getUserRole(),key:'CONTRACTPROJECTDESACT'}"><a class="slide-item"
									[routerLink]="['/contract-project-des-act']"
									[routerLinkActive]="['highlightActiveLink']" (click)="togglesidebarinmobileview();$event.stopPropagation()
									">Project Allocation</a></li>
							<li [hasAccess]="{role:getUserRole(),key:'CONTRACTCLIENTPROJECTFREEZE'}"><a
									class="slide-item" [routerLink]="['/contract-client-project-freeze']"
									[routerLinkActive]="['highlightActiveLink']" (click)="togglesidebarinmobileview();$event.stopPropagation()
									">Freeze Allocation</a></li>
							<li [hasAccess]="{role:getUserRole(),key:'CONTRACTPROJECTALLOCATION'}"><a class="slide-item"
									[routerLink]="['/contract-project-allocation']"
									[routerLinkActive]="['highlightActiveLink']" (click)="togglesidebarinmobileview();$event.stopPropagation()
							">Project Report</a></li>

						</ul>
					</li>

					<!-- [hasAccess]="{role:getUserRole(),key:'EMPLOYEEGROUP'}" -->

					<!-- shift  -->
					<li class="slide" (click)="menuClick($event)" [hasAccess]="{role:getUserRole(),key:'SHIFTMASTER'}">
						<a class="side-menu__item " id="slid24" data-bs-toggle="slide" style="padding: 10px;">
							<img src="./../../assets/img/attendance.png"
								style="width: 23px; height: 25px; display: block; margin: auto;" alt="">
							<span class="side-menu__label" style="margin-left: 10px;">Shift</span><i
								class="angle fa fa-angle-right"></i>
						</a>

						<ul class="slide-menu">
							<li [hasAccess]="{role:getUserRole(),key:'EMPLOYEEGROUP'}"><a class="slide-item"
									[routerLink]="['/employeegroup']" [routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview();$event.stopPropagation()
									">Employee Group Allocation</a></li>
							<li [hasAccess]="{role:getUserRole(),key:'SHIFTALLOCATION'}"><a class="slide-item"
									[routerLink]="['/group-shiftallocation']"
									[routerLinkActive]="['highlightActiveLink']" (click)="togglesidebarinmobileview();$event.stopPropagation()
										">Shift Allocation</a></li>
							<li [hasAccess]="{role:getUserRole(),key:'SHIFTALLOCATED'}"><a class="slide-item"
									[routerLink]="['/empshiftallocated']" [routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview();$event.stopPropagation()
							">Shift Allocated</a></li>
							<!-- <li [hasAccess]="{role:getUserRole(),key:'PAYSLIP'}"><a class="slide-item"  [routerLink]="['/payslip']"[routerLinkActive]="['highlightActiveLink']">Generate Payslip</a></li> -->
							<!-- <li><a class="slide-item" [routerLink]="['/payslip']"
									[routerLinkActive]="['highlightActiveLink']" (click)="notoggle();$event.stopPropagation()
									">My Payslip</a></li> -->

						</ul>
					</li>

					<!-- payroll  -->
					<li class="slide" (click)="menuClick($event)" [hasAccess]="{role:getUserRole(),key:'PAYROLL'}"
						(click)="togglesidebarinmobileview();$event.stopPropagation()
					">
						<a class="side-menu__item " id="slid16" data-bs-toggle="slide" style="padding: 10px;"
							[routerLink]="['/reviewpayslips']" [routerLinkActive]="['highlightActiveLink']">
							<img src="./../../assets/img/payroll.png"
								style="width: 23px; height: 25px; display: block; margin: auto;" alt="">
							<span class="side-menu__label" style="margin-left: 10px;">Payroll</span>
						</a>

					</li>

					<!-- document  -->
					<li class="slide" (click)="menuClick($event)" [hasAccess]="{role:getUserRole(),key:'MYDOCUMENTS'}">
						<a class="side-menu__item " id="slid17" data-bs-toggle="slide" style="padding: 10px;">
							<img src="./../../assets/img/my documents.png"
								style="width: 23px; height: 20px; display: block; margin: auto;" alt="">
							<span class="side-menu__label" style="margin-left: 10px;">Documents </span><i
								class="angle fa fa-angle-right"></i>
						</a>

						<ul class="slide-menu">

							<li><a class="slide-item" [routerLink]="['/document/myDocuments']"
									[routerLinkActive]="['highlightActiveLink']" (click)="togglesidebarinmobileview();$event.stopPropagation()
								">My Document </a></li>
							<li><a class="slide-item" [routerLink]="['/payslip']"
									[routerLinkActive]="['highlightActiveLink']" (click)="togglesidebarinmobileview();$event.stopPropagation()
									">My Payslip</a></li>



							<li [hasAccess]="{role:getUserRole(),key:'DOCMASTER'}"><a class="slide-item"
									[routerLink]="['/bulkuploaddocument']" [routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview();$event.stopPropagation()
												">Bulk Upload Documents </a></li>
							<li [hasAccess]="{role:getUserRole(),key:'DOCMASTER'}"
								[hasAccess]="{role:getUserRole(),key:'APPROVALINBOX'}"><a class="slide-item"
									[routerLink]="['/approvalinbox']" [routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview();$event.stopPropagation()
													">View/Approved Documents</a></li>
							<li [hasAccess]="{role:getUserRole(),key:'EMPLOYEEDOCSEARCH'}"><a class="slide-item"
									[routerLink]="['employeedocsearch']" [routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview();$event.stopPropagation()
														">Document Submission Report</a>
							</li>
							<li [hasAccess]="{role:getUserRole(),key:'DOCMASTER'}"
								[hasAccess]="{role:getUserRole(),key:'CLIENTMASTER'}"><a class="slide-item"
									[routerLink]="['/contract-client']" [routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview();$event.stopPropagation()
																">Contract Client</a></li>


						</ul>
					</li>

					<!-- leave and attendance  -->
					<li class="slide" (click)="menuClick($event)"
						[hasAccess]="{role:getUserRole(),key:'LEAVEANDATTENDANCEMASTER'}">
						<a class="side-menu__item " id="slid18" data-bs-toggle="slide" style="padding: 10px;">
							<img src="./../../assets/img/leave-14 (1).svg"
								style="width: 23px; height: 25px; color: white; display: block; margin: auto;" alt="">
							<span class="side-menu__label" style="margin-left: 10px;">Leave & Attendance </span><i
								class="angle fa fa-angle-right"></i>
						</a>

						<ul class="slide-menu">
							<li [hasAccess]="{role:getUserRole(),key:'APPLYATTENDANCE'}"><a class="slide-item"
									[routerLink]="['employeeattendence']" [routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview();$event.stopPropagation()">Apply Attendance </a>
							</li>

							<li [hasAccess]="{role:getUserRole(),key:'ATTENDANCEREPROXY'}"><a class="slide-item"
									[routerLink]="['employeeattendenceproxy']"
									[routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview();$event.stopPropagation()">Apply Attendance For
									Others</a></li>

							<li [hasAccess]="{role:getUserRole(),key:'ATTENDANCEAPPROVAL'}"><a class="slide-item"
									[routerLink]="['employeeattendenceapproval']"
									[routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview();$event.stopPropagation()">Attendance Approval
								</a>
							<li>

							<li [hasAccess]="{role:getUserRole(),key:'ATTENDANCESUMMARY'}"><a class="slide-item"
									[routerLink]="['employeeattendencesummary']"
									[routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview();$event.stopPropagation()">Swipe Details</a>
							</li>

							<li [hasAccess]="{role:getUserRole(),key:'LEAVEAPPLICATION'}"><a class="slide-item"
									[routerLink]="['/employeeleaveapply']" [routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview();$event.stopPropagation()
										">Apply Leave For Self</a></li>

							<li [hasAccess]="{role:getUserRole(),key:'LEAVEAPPLYONBEHALFOFEMPLOYEE'}"><a
									class="slide-item" [routerLink]="['/employeeleaveproxy']"
									[routerLinkActive]="['highlightActiveLink']" (click)="togglesidebarinmobileview(); $event.stopPropagation()
											">Apply Leave For Others</a></li>

							<li [hasAccess]="{role:getUserRole(),key:'APPROVEAPPLICATION'}"><a class="slide-item"
									[routerLink]="['/employeeleaveapproval']"
									[routerLinkActive]="['highlightActiveLink']" (click)="togglesidebarinmobileview();$event.stopPropagation()
													">Leave Approval</a>
							</li>


							<li [hasAccess]="{role:getUserRole(),key:'APPLYCOMPOFF'}"><a class="slide-item "
									[routerLink]="['/apply-comp-off']" [routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview()">Apply Comp off</a>
							</li>

							<li [hasAccess]="{role:getUserRole(),key:'APPROVECOMPOFF'}"><a class="slide-item "
									[routerLink]="['/approve-comp-off']" [routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview()">Approve Comp off</a>
							</li>

							<li [hasAccess]="{role:getUserRole(),key:'ATTENDANCEREPORT'}"><a class="slide-item"
									[routerLink]="['attendancereport']" [routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview();$event.stopPropagation()">Attendance Report</a>
							</li>

							<li [hasAccess]="{role:getUserRole(),key:'APPROVEAPPLICATION'}"><a class="slide-item"
									[routerLink]="['/employeeleavestatusreport']"
									[routerLinkActive]="['highlightActiveLink']" (click)="togglesidebarinmobileview();$event.stopPropagation()
								">Leave Status Report</a></li>

							<li [hasAccess]="{role:getUserRole(),key:'FORMT'}"><a class="slide-item"
									[routerLink]="['/formtreport']" [routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview();$event.stopPropagation()
								">Form T Report</a></li>


						</ul>
					</li>

					<!-- hr report  -->
					<li class="slide" (click)="menuClick($event)">
						<a class="side-menu__item " data-bs-toggle="slide" id="slid22" style="padding: 10px;"
							[hasAccess]="{role:getUserRole(),key:'REPORTMASTER'}">
							<img src="./../../assets/img/users.png"
								style="width: 23px; height: 25px; display: block; margin: auto;" alt="">

							<span class="side-menu__label" style="margin-left: 10px;">Reports</span> <i
								class="angle fa fa-angle-right"></i>
						</a>
						<ul class="slide-menu">
							<li [hasAccess]="{role:getUserRole(),key:'CUSTOMERSEARCH'}"><a class="slide-item"
									[routerLink]="['/emplhr']" [routerLinkActive]="['highlightActiveLink']" (click)="togglesidebarinmobileview();$event.stopPropagation()
							">Employer HR Details</a></li>
							<li [hasAccess]="{role:getUserRole(),key:'CUSTOMERSEARCH'}"><a class="slide-item"
									[routerLink]="['/userdet']" [routerLinkActive]="['highlightActiveLink']" (click)="togglesidebarinmobileview();$event.stopPropagation()
							">User Details</a></li>

						</ul>
					</li>

					<!-- expense  -->
					<li class="slide" (click)="menuClick($event)"
						[hasAccess]="{role:getUserRole(),key:'EXPENSEMASTER'}">
						<a class="side-menu__item" id="slid30" data-bs-toggle="slide" style="padding: 10px;">
							<img src="./../../assets/img/leave-14 (1).svg"
								style="width: 23px; height: 25px; color: white; display: block; margin: auto;" alt="">
							<span class="side-menu__label" style="margin-left: 10px;">Expenses </span><i
								class="angle fa fa-angle-right"></i>
						</a>
						<ul class="slide-menu">
							<li [hasAccess]="{role:getUserRole(),key:'EXPENSESUBMISSION'}">
								<a class="slide-item" [routerLink]="['/expense-master']"
									[routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview();$event.stopPropagation()">Expense
									Submission</a>
							</li>
							<li [hasAccess]="{role:getUserRole(),key:'EXPENSEAPPROVAL'}">
								<a class="slide-item" [routerLink]="['/expense-approver']"
									[routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview();$event.stopPropagation()">Expense Approval</a>
							</li>
							<li [hasAccess]="{role:getUserRole(),key:'EXPENSETYPE'}">
								<a class="slide-item" [routerLink]="['/expense-type']"
									[routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview();$event.stopPropagation()">Expense Type</a>
							</li>
							<li [hasAccess]="{role:getUserRole(),key:'EXPENSEREPORT'}">
								<a class="slide-item" [routerLink]="['/expense-report']"
									[routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview();$event.stopPropagation()">Expense Report</a>
							</li>

						</ul>
					</li>


					<!-- 
					<li class="slide" (click)="menuClick($event)"
						[hasAccess]="{role:getUserRole(),key:'EXPENSEMASTER'}">
						<a class="side-menu__item " id="slid30" data-bs-toggle="slide" style="padding: 10px;">
							<img src="./../../assets/img/leave-14 (1).svg"
								style="width: 23px; height: 25px; color: white; display: block; margin: auto;" alt="">
							<span class="side-menu__label" style="margin-left: 10px;">Expense Master  </span><i
								class="angle fa fa-angle-right"></i>
						</a>
						<ul class="slide-menu">
							<li [hasAccess]="{role:getUserRole(),key:'EXPENSESUBMISSION'}"><a class="slide-item "
									[routerLink]="['/expense-master']" [routerLinkActive]="['highlightActiveLink']"
									(click)="notoggle()">Expense Submission</a>
							</li>
							<li  [hasAccess]="{role:getUserRole(),key:'EXPENSEAPPROVEAL'}"><a class="slide-item" [routerLink]="['/expenseapprover']"
									[routerLinkActive]="['highlightActiveLink']" (click)="notoggle();$event.stopPropagation()
							">Expense Approver </a>
							</li>
							
						</ul>

					</li> -->

					<!-- <li class="slide" (click)="menuClick($event)" [hasAccess]="{role:getUserRole(),key:'SETUP'}"> // replace master to setup for easy understanging  -->

					<!-- set up  -->
					<li class="slide" (click)="menuClick($event)" [hasAccess]="{role:getUserRole(),key:'MASTER'}">
						<a class="side-menu__item " data-bs-toggle="slide" id="slid21" style="padding: 10px;">
							<img src="./../../assets/img/deparrtment.png"
								style="width: 23px; height: 25px; display: block; margin: auto;" alt="">
							<span class="side-menu__label" style="margin-left: 10px;">Setup</span><i
								class="angle fa fa-angle-right"></i>
						</a>
						<ul class="slide-menu">

							<li [hasAccess]="{role:getUserRole(),key:'LEAVEMASTER'}"><a class="slide-item "
									[routerLink]="['/leave-master']" [routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview()">Leave Master</a>
							</li>

							<li [hasAccess]="{role:getUserRole(),key:'JOBMASTER'}"><a class="slide-item "
									[routerLink]="['/job-master']" [routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview()">Job-Level Master</a>
							</li>

							<li [hasAccess]="{role:getUserRole(),key:'BRANCH'}"><a class="slide-item "
									[routerLink]="['/branchdetails']" [routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview()">Branch</a>
							</li>

							<li [hasAccess]="{role:getUserRole(),key:'BRANCHMASTER'}"><a class="slide-item "
									[routerLink]="['/branchMaster']" [routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview()">Branch Master</a>
							</li>

							<li [hasAccess]="{role:getUserRole(),key:'GROUPMASTER'}"><a class="slide-item "
									[routerLink]="['/groupMaster']" [routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview()">Group Master</a>
							</li>

							<li [hasAccess]="{role:getUserRole(),key:'ANNUALHOLIDAYMASTER'}"><a class="slide-item "
									[routerLink]="['/annual-holidayMaster']"
									[routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview()">Annual Holiday
									Master</a>
							</li>

							<li [hasAccess]="{role:getUserRole(),key:'DEPARTMENT'}"><a class="slide-item "
									[routerLink]="['/departmentetails']" [routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview()">Department</a>
							</li>
							<li [hasAccess]="{role:getUserRole(),key:'DESIGNATION'}"><a class="slide-item "
									[routerLink]="['/designationetails']" [routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview()">Designation</a>
							</li>
							<li [hasAccess]="{role:getUserRole(),key:'DEFINEDOCTYPE'}"><a class="slide-item"
									[routerLink]="['/document/savedoc']" [routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview()">Define Document Type</a>
							</li>

						</ul>
					</li>

					<!-- <li class="slide" (click)="menuClick($event)"
						[hasAccess]="{role:getUserRole(),key:'PRIVACYPOLICY'}">
						<a class="side-menu__item " [routerLink]="['/uploadrulesandpolicy']"
							[routerLinkActive]="['highlightActiveLink']" data-bs-toggle="slide" id="slid26"
							style="padding: 10px;" (click)="notoggle();$event.stopPropagation()
							">
							<img src="./../../assets/img/Policy1.png" alt="" style=" display: block; margin: auto;">
							<span class="side-menu__label" style="margin-left: 10px;">Policy</span>
						</a>
					</li> -->

					<!-- salary master menu  -->
					<li class="slide" (click)="menuClick($event)"
						[hasAccess]="{role:getUserRole(),key:'SALARYHEAD'}">
						<a class="side-menu__item " data-bs-toggle="slide" id="slid26" style="padding: 10px;">
							<img src="./../../assets/img/salary_white.png"
								style="width: 23px; height: 25px; display: block; margin: auto;" alt="">
							<span class="side-menu__label" style="margin-left: 10px;">Salary Master</span><i
								class="angle fa fa-angle-right"></i>
						</a>
						<ul class="slide-menu">
							<li [hasAccess]="{role:getUserRole(),key:'SALARYHEAD'}">
								<a class="slide-item" [routerLink]="['/salary-head-Master']"
									[routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview()">Salary Head Master</a>
							</li>
							<li [hasAccess]="{role:getUserRole(),key:'SALARYDETAIL'}">
								<a class="slide-item" [routerLink]="['/salary-detail-Master']"
									[routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview()">Salary Detail Master
								</a>
							</li>
							<li [hasAccess]="{role:getUserRole(),key:'PAYCALRULE'}">
								<a class="slide-item" [routerLink]="['/pay-calculate-rule']"
									[routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview()">Pay Calculation Rule Master
								</a>
							</li>
						</ul>

					</li>


					<!-- policy  -->
					<li class="slide" (click)="menuClick($event)"
						[hasAccess]="{role:getUserRole(),key:'PRIVACYPOLICY'}">
						<a class="side-menu__item " data-bs-toggle="slide" id="slid27" style="padding: 10px;">
							<img src="./../../assets/img/Policy1.png"
								style="width: 23px; height: 25px; display: block; margin: auto;" alt="">
							<span class="side-menu__label" style="margin-left: 10px;">Policy</span><i
								class="angle fa fa-angle-right"></i>
						</a>
						<ul class="slide-menu">
							<li [hasAccess]="{role:getUserRole(),key:'PRIVACYPOLICY'}">
								<a class="slide-item" [routerLink]="['/uploadrulesandpolicy']"
									[routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview()">Policy Rules</a>
							</li>
							<li [hasAccess]="{role:getUserRole(),key:'POLICYSUBMISSION'}">
								<a class="slide-item" [routerLink]="['/policy-submission']"
									[routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview()">Policy
									Submission</a>
							</li>
							<li [hasAccess]="{role:getUserRole(),key:'POLICYAPPROVAL'}">
								<a class="slide-item" [routerLink]="['/policy-approval']"
									[routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview()">Policy
									Approval</a>
							</li>
						</ul>

					</li>


					<!-- Administration  -->
					<li class="slide" data-bs-toggle="slide" (click)="menuClick($event)">
						<a class="side-menu__item " data-bs-toggle="slide" id="slid20" style="padding: 10px;">
							<img src="./../../assets/img/users.png"
								style="width: 23px; height: 25px; display: block; margin: auto;" alt="">
							<span class="side-menu__label" style="margin-left: 10px;">Administration</span><i
								class="angle fa fa-angle-right"></i>
						</a>
						<ul class="slide-menu">
							<li [hasAccess]="{role:getUserRole(),key:'USER'}"><a class="slide-item"
									[routerLink]="['/people']" [routerLinkActive]="['highlightActiveLink']" (click)="togglesidebarinmobileview();$event.stopPropagation()
									">Users</a>
							</li>
							<li [hasAccess]="{role:getUserRole(),key:'ROLE'}"><a class="slide-item"
									[routerLink]="['/roles/roles']" [routerLinkActive]="['highlightActiveLink']"
									(click)="togglesidebarinmobileview();$event.stopPropagation()
									">Roles</a></li>
							<li [hasAccess]="{role:getUserRole(),key:'SETTING'}"><a class="slide-item"
									[routerLink]="['/setting']" [routerLinkActive]="['highlightActiveLink']" (click)="togglesidebarinmobileview();$event.stopPropagation()
							">Setting</a></li>
							<li [hasAccess]="{role:getUserRole(),key:'CHNGEPASSWORD'}"><a class="slide-item"
									[routerLink]="['/chpwd']" [routerLinkActive]="['highlightActiveLink']" (click)="togglesidebarinmobileview();$event.stopPropagation()
									">Change Password</a>
							</li>
							<li [hasAccess]="{role:getUserRole(),key:'BULKCHPWD'}"><a class="slide-item"
									[routerLink]="['/chpwdlist']" [routerLinkActive]="['highlightActiveLink']" (click)="togglesidebarinmobileview();$event.stopPropagation()
							 ">Reset Employee Password</a>
							</li>
						</ul>
					</li>
				</ul>
			</div>
		</div>
	</aside>
</div>
<!--app-sidebar closed-->
<div class="jumps-prevent" style="padding-top: 85.75px;" *ngIf="isloggedIn == true"></div>