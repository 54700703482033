import { ReturnStatement } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { ContractclientDocument } from '../models/ContractClientDocument';
import { ContractclientService } from '../services/contractclient.service';

declare var $: any;

@Component({
  selector: 'app-contract-project-des-est',
  templateUrl: './contract-project-des-est.component.html',
  styleUrls: ['./contract-project-des-est.component.css']
})
export class ContractProjectDesEstComponent implements OnInit {

  contractClientDesInfo: ContractclientDocument;
  client_id: ContractclientDocument[];
  project_data: ContractclientDocument;
  contractClientProInfoData: ContractclientDocument[] = [];
  contractClientProDesEstDataList: ContractclientDocument[] = [];
  contractClientData: ContractclientDocument[] = [];
  contractClientProDesEstData: ContractclientDocument;
  deleteProEstInfo: ContractclientDocument;
  contractClientProLocList: any[] = [];
  empDesList: any[] = [];
  filterContractClientProLocList: any[] = [];
  cllientBusName: any;
  clientProId: any = undefined;
  contractstream$!: Subscription;

  //Validation

  projectStartDate: any;
  projectEndDate: any;
  projectFreezeDate: string;
  submitted: boolean;
  countNumberOnly: boolean = false;
  billingRateNumberOnly: boolean = false;
  startDate: boolean = false;
  endDate: boolean = false;
  startDateSmall: boolean = false;
  endDateGreat: boolean = false;
  freezeDateCheckStart: boolean = false;
  freezeDateCheckEnd: boolean = false;

  @ViewChild('contractClientDesEstForm') contractClientDesEstForm: NgForm;
  constructor(private contractclientService: ContractclientService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.contractClientDesInfo = new ContractclientDocument();
    this.contractClientProDesEstData = new ContractclientDocument();

    this.getClientData();
    this.getEmpDesData();
  }

  // API calls

  getClientData() {
    this.contractstream$ = this.contractclientService.getContractClient().subscribe(
      data => {
        // console.log(data)
        this.contractClientData = data.client;
        // console.log("List of all contract client in client projects >>>> ", this.contractClientData);
      },
      error => {
        // console.log(error);
      }
    );
  }

  getEmpDesData() {

    this.contractstream$ = this.contractclientService.getDesignationList().subscribe(
      data => {
        // console.log("Employee designation >> >> >>", data)
        this.empDesList = data;
      },
      error => {
        // console.log(error);
      }
    );
  }

  getClientName(v: any) {
    // console.log("Anny >> >> >>", v)
    this.cllientBusName = v;
  }

  getClientProData() {
    this.client_id = [];

    this.client_id = this.contractClientData.filter((dName) => {
      return dName.business_name.toLowerCase() === this.cllientBusName.toLowerCase();
    });

    this.contractstream$ = this.contractclientService.getContractClientProjectInfo(this.client_id[0].id).subscribe(
      data => {
        // console.log("Respons from contract client Project List >>> ", data)
        this.contractClientProInfoData = data.contractprojectdeta;
        this.contractClientProLocList = [];

        if ($("#searchProjectBaseClient").hasClass("d-none") && $("#btnProjectBaseClient").hasClass("d-none")) {
          $('#searchProjectBaseClient').removeClass('d-none')
          $('#btnProjectBaseClient').addClass('d-flex')
          $('#btnProjectBaseClient').removeClass('d-none')
          $('#searchProjectBaseClient').addClass('d-flex')
          $('#serachClient').removeClass('col-md-11')
          $('#serachClient').addClass('col-md-6')
        }
      },
      error => {
        // console.log(error);
      }
    );
  }

  getContractClientProLocSerarch() {
    // console.log("Project Id : Client Project Id", this.contractClientProDesEstData, this.contractClientProDesEstData.id)
    // console.log("Project Id : Client Project Id Two", this.contractClientProInfoData)

    this.project_data = this.contractClientProInfoData.find((dName) => {
      return dName.id == this.contractClientProDesEstData.id;
    });

    // console.log("Something wronf ? :", this.project_data);

    this.getContractClientProLoc(this.project_data)

  }

  getContractClientProLoc(clientProject: any) {
    this.submitted = false;
    this.countNumberOnly = false;
    this.billingRateNumberOnly = false;
    this.startDate = false;
    this.endDate = false;
    this.startDateSmall = false;
    this.endDateGreat = false;
    this.freezeDateCheckStart = false;
    this.freezeDateCheckEnd = false;

    // console.log("Project Loc : ", clientProject)
    this.contractClientProDesEstData.start_date = clientProject.start_date;
    this.projectStartDate = clientProject.start_date;
    this.contractClientProDesEstData.end_date = clientProject.end_date;
    this.projectEndDate = clientProject.end_date;
    this.clientProId = clientProject.id;
    this.projectFreezeDate = clientProject.freeze_date;

    // console.log("freeze from response", clientProject.freeze_date)
    // console.log("Freeze date >>", this.projectFreezeDate);
    this.checkfreezedate();

    // console.log("Project Id : ", this.clientProId)
    this.contractstream$ = this.contractclientService.getContractClientProjectLoc(this.clientProId).subscribe(
      data => {
        // console.log("Respons from contract client Project Locations List >>> ", data)
        // console.log("One step >> >> >>", this.contractClientProLocList)
        this.contractClientProLocList = data.projectlocationdeta;
        // console.log("Srver locations >> ", this.contractClientProLocList);

        this.filterContractClientProLocList = [];

        for (var i = 0; i < this.contractClientProLocList.length; i++) {
          this.filterContractClientProLocList.push(this.contractClientProLocList[i].location_name)
        }
        $('#add-des-est').removeClass('disable-div');
        // console.log("Loc List > > >", this.filterContractClientProLocList)
      },
      error => {
        console.log(error);
      }
    );
    this.getProDesEstData();
  }

  getContractClientProLocView() {
    if ($("#projectDataList").hasClass("d-none") && $("#showProjectDataList").hasClass("fa-th-list")) {
      $('#projectDataList').removeClass('d-none')
      $('#showProjectDataList').removeClass('fa-th-list')
      $('#showProjectDataList').addClass('fa-compress')
    } else {
      $('#projectDataList').addClass('d-none')
      $('#showProjectDataList').addClass('fa-th-list')
      $('#showProjectDataList').removeClass('fa-compress')
    }
  }

  saveContractClientProDesEstData(valid) {
    this.submitted = true;
    this.countNumberOnly = false;
    this.billingRateNumberOnly = false;
    this.startDate = false;
    this.endDate = false;
    this.startDateSmall = false;
    this.endDateGreat = false;
    this.freezeDateCheckStart = false;
    this.freezeDateCheckEnd = false;

    if (!valid) {
      // console.log("Not valid")
      return
    }
    if (!(/^[0-9]+$/.test(this.contractClientProDesEstData.counts)) && !(/^[0-9]+$/.test(this.contractClientProDesEstData.billing_rate))) {
      // console.log("Enter only number")
      this.submitted = false;
      this.billingRateNumberOnly = true;
      this.countNumberOnly = true;
    } else if (!(/^[0-9]+$/.test(this.contractClientProDesEstData.counts)) && (/^[0-9]+$/.test(this.contractClientProDesEstData.billing_rate))) {
      // console.log("Enter only number")
      this.submitted = false;
      this.billingRateNumberOnly = false;
      this.countNumberOnly = true;
    } else if ((/^[0-9]+$/.test(this.contractClientProDesEstData.counts)) && !(/^[0-9]+$/.test(this.contractClientProDesEstData.billing_rate))) {
      // console.log("Enter only number")
      this.submitted = false;
      this.billingRateNumberOnly = true;
      this.countNumberOnly = false;
    }

    if (!this.checkFromAndToDate()) {
      // console.log("Error in date")
      return
    }

    if (valid && this.submitted) {

      // console.log(">> this >> ", this.contractClientProDesEstData)

      if (this.clientProId !== undefined) {
        this.contractClientProDesEstData.project_id = this.clientProId;
        // this.contractClientProDesEstData.designation_id = this.contractClientProDesEstData.designationid;

        // console.log(">> this >> ", this.contractClientProDesEstData)

        this.contractstream$ = this.contractclientService.saveContractClientProDesEstData(this.contractClientProDesEstData).subscribe(
          data => {
            // console.log("Respons from contract client Project Locations List >>> ", data)
            this.getProDesEstData();
            this.clearContractClientProDesEstData()
          },
          error => {
            // console.log(error);
          }
        );

      } else {
        // console.log("Select Projct")
      }
    } else {
      // console.log("Not valid")
      return;
    }
  }

  getProDesEstData() {
    this.contractstream$ = this.contractclientService.getContractClientProDesEstData(this.clientProId).subscribe(
      data => {
        // console.log("Get Project Designation Est >> >> >>", data)
        // this.empDesList = data;
        this.contractClientProDesEstDataList = data.projectdesignest;
      },
      error => {
        // console.log(error);
      }
    );
  }

  updateContractClientProDesEstData(valid) {
    this.submitted = true;
    this.countNumberOnly = false;
    this.billingRateNumberOnly = false;
    this.startDate = false;
    this.endDate = false;
    this.startDateSmall = false;
    this.endDateGreat = false;
    this.freezeDateCheckStart = false;
    this.freezeDateCheckEnd = false;

    if (!valid) {
      // console.log("Not valid")
      return
    }

    if (!(/^[0-9]+$/.test(this.contractClientProDesEstData.counts))) {
      // console.log("Enter only number")
      this.submitted = false;
      this.countNumberOnly = true;
    }

    if (!this.checkFromAndToDate()) {
      // console.log("Error in date")
      return
    }

    if (valid && this.submitted) {

      // console.log(">> this >> ", this.contractClientProDesEstData)

      if (this.clientProId !== undefined) {
        this.contractClientProDesEstData.project_id = this.clientProId;
        // this.contractClientProDesEstData.designation_id = this.contractClientProDesEstData.designationid;

        // console.log(">> this >> ", this.contractClientProDesEstData)

        this.contractstream$ = this.contractclientService.updateContractClientProDesEstData(this.contractClientProDesEstData).subscribe(
          data => {
            // console.log("Respons from contract client Project Locations List >>> ", data)
            this.clearContractClientProDesEstData();
            this.getProDesEstData();
          },
          error => {
            // console.log(error);
          }
        );

      } else {
        // console.log("Select Projct")
      }
    } else {
      // console.log("Not valid")
      return;
    }
  }

  contractClientProDesEstDeleteDataStore(data: any) {
    this.deleteProEstInfo = data;
    $('#showAlertWarning').modal('toggle');
  }

  contractClientProDesEstDeleteData() {

    let projectStartDateUser = new Date(this.deleteProEstInfo.start_date)
    let projectFreezeDateNow = new Date(this.projectFreezeDate)

    if(this.projectFreezeDate){
      if (projectFreezeDateNow.valueOf() >= projectStartDateUser.valueOf()) {
        // console.log("From matrix")
        $('#showAlertCantDeleteFreeze').modal('toggle');
        return;
      }
    }

    // console.log(this.deleteProEstInfo)
    this.contractstream$ = this.contractclientService.deleteContractClientProDesEstData(this.deleteProEstInfo.id, this.deleteProEstInfo.project_location_id, this.deleteProEstInfo.project_id).subscribe(
      data => {
        this.getProDesEstData();
        this._snackBar.open('Successfully delete!', 'Close', {
          duration: 2000,
        });
      },
      error => {
        // console.log("error",error);
        $('#showAlertWarning').modal('toggle');

        if (error.error?.projectfreezed) {
          $('#showAlertCantDeleteFreeze').modal('toggle');
        } else {
          $('#showAlertCantDelete').modal('toggle');
        }
      }
    );

    // this.contractstream$ = this.contractclientService.deleteContractClientProDesEstData(this.deleteProEstInfo.id, this.deleteProEstInfo.project_location_id, this.deleteProEstInfo.project_id).subscribe(
    //   data => {
    //     this.getProDesEstData();
    //     this._snackBar.open('Successfully delete!', 'Close', {
    //       duration: 2000,
    //     });
    //   },
    //   error => {
    //     // console.log("error",error);
    //     $('#showAlertWarning').modal('toggle');

    //     if (error.error?.projectfreezed) {
    //       $('#showAlertCantDeleteFreeze').modal('toggle');
    //     } else {
    //       $('#showAlertCantDelete').modal('toggle');
    //     }
    //   }
    // );
  }

  contractClientProDesEstEdit(data: any) {
    // console.log("Edit data",data)

    let projectStartDateUser = new Date(data.start_date)
    let projectFreezeDateNow = new Date(this.projectFreezeDate)

    if(this.projectFreezeDate){
      if (projectFreezeDateNow.valueOf() >= projectStartDateUser.valueOf()) {
        $('#showAlertCantUpdateFreeze').modal('toggle');
        return;
      }
    }

    this.contractClientProDesEstData = { ...data };

    document.getElementById('update-btn').style.display = "inline-block";
    document.getElementById('save-btn').style.display = "none";
  }

  clearContractClientProDesEstData() {
    this.submitted = false;
    this.countNumberOnly = false;
    this.billingRateNumberOnly = false;
    this.startDate = false;
    this.endDate = false;
    this.startDateSmall = false;
    this.endDateGreat = false;
    this.freezeDateCheckStart = false;
    this.freezeDateCheckEnd = false;
    this.contractClientDesEstForm.reset();
  }

  // Validation

  checkfreezedate() {
    this.freezeDateCheckStart = false;
    this.freezeDateCheckEnd = false;
    // console.log("Reached checkfreezedate() >>")
    // let projectStartDate = new Date(this.projectStartDate)
    // let projectEndDate = new Date(this.projectEndDate)
    // let projectStartDateUser = new Date(this.contractClientDesEstForm.form.get("start_date").value)
    // let projectEndDateUser = new Date(this.contractClientDesEstForm.form.get("end_date").value)
    let projectStartDateUser = new Date(this.contractClientProDesEstData.start_date)
    let projectEndDateUser = new Date(this.contractClientProDesEstData.end_date)
    let projectFreezeDateNow = new Date(this.projectFreezeDate)
    let falseOrTrue = true;

    // console.log("Freeze date", projectFreezeDateNow)
    // console.log("start date", projectStartDateUser)
    // console.log("end date", projectEndDateUser)

    if(this.projectFreezeDate){
      if (projectFreezeDateNow.valueOf() >= projectStartDateUser.valueOf()) {
        // console.log("Reached inside If inside If two two")
        this.submitted = false;
        this.freezeDateCheckStart = true;
        this.freezeDateCheckEnd = false;
        // return false;
        falseOrTrue = false;
      } else if (projectFreezeDateNow.valueOf() >= projectStartDateUser.valueOf()) {
        // console.log("Reached inside If inside If one")
        this.submitted = false;
        this.freezeDateCheckStart = true;
        this.freezeDateCheckEnd = false;
        // return false;
        falseOrTrue = false;
      } 
      
      if(!falseOrTrue){
        // console.log("Reached false")
        return false;
      }else {
        return true;
      }

      // if (projectFreezeDateNow.valueOf >= projectEndDateUser.valueOf) {
      //   console.log("Reached inside If inside If two")
      //   this.submitted = false;
      //   this.freezeDateCheckStart = false;
      //   this.freezeDateCheckEnd = true;
      // }
    }

  }

  checkFromAndToDate() {
    this.startDate = false;
    this.endDate = false;
    this.endDateGreat = false;
    this.startDateSmall = false;
    if (!this.contractClientDesEstForm.form.get("start_date").value) {
      return;
    }
    if (!this.contractClientDesEstForm.form.get("end_date").value) {
      return;
    }

    let projectStartDateUser = new Date(this.contractClientDesEstForm.form.get("start_date").value)
    let projectEndDateUser = new Date(this.contractClientDesEstForm.form.get("end_date").value)

    let projectStartDate = new Date(this.projectStartDate)
    let projectEndDate = new Date(this.projectEndDate)

    if(this.projectFreezeDate){
      if(!this.checkfreezedate()){
        return;
      }
    }

    if (projectEndDateUser.valueOf() > projectEndDate.valueOf()
      && projectStartDateUser.valueOf() >= projectStartDate.valueOf()) {
      this.endDate = false;
      this.submitted = false;
      this.startDateSmall = false;
      this.endDateGreat = true;

    } else if (projectStartDateUser.valueOf() < projectStartDate.valueOf()
      && projectEndDateUser.valueOf() <= projectEndDate.valueOf()) {
      this.endDate = false;
      this.submitted = false;
      this.endDateGreat = false;
      this.startDateSmall = true;

    } else if (projectStartDateUser.valueOf() >= projectEndDateUser.valueOf()) {
      // console.log("It's  not right")
      this.endDate = true;
      this.submitted = false;
      this.startDateSmall = false;
      this.endDateGreat = false;

    } else {
      // console.log("It's okay")
      this.endDate = false;
      this.startDateSmall = false;
      this.endDateGreat = false;

      return true;
    }
  }

  ngOnDestroy(): void {
    this.contractstream$.unsubscribe();
  }
}
