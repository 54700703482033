<!-- page content -->
<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp">
        </div>
        <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-title">Employee Search</div>
            </div>
            <div class="page-rightheader ms-md-auto">
                <div class="align-items-end flex-wrap my-auto right-content breadcrumb-right">
                    <div class="btn-list">
                        <a [routerLink]="['/employeemaster']" class="btn btn-primary me-3">Add New Employee</a>
                        <!-- <button  class="btn btn-light" data-bs-toggle="tooltip" data-bs-placement="top" title="E-mail"> <i class="feather feather-mail"></i> </button>
                <button  class="btn btn-light" data-bs-placement="top" data-bs-toggle="tooltip" title="Contact"> <i class="feather feather-phone-call"></i> </button>
                <button  class="btn btn-primary" data-bs-placement="top" data-bs-toggle="tooltip" title="Info"> <i class="feather feather-info"></i> </button> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <!-- <div class="card-header border-bottom-0">
                <h4 class="card-title">Horizontal Form</h4>
            </div> -->
                    <div class="card-body">
                        <form class="form-horizontal" id="fcForm" (keyup.enter)="limitpara(); searchEmployee()">
                            <div class="form-group row">
                                <div class="col-md-12 px-0 ">
                                    <label for="inputName" class="w-100  fontSize-15 form-label mb-2 p-0 ">Employee Name
                                        / ID</label>
                                    <input type="search" class="form-control" name="Employee Name" id="employeename"
                                        placeholder="Enter Employee Name / ID" [(ngModel)]="query" autocomplete="off"
                                        (input)="getEmployeeByNameOrId()" list="employeeMasterLucene">
                                    <datalist id="employeeMasterLucene">
                                        <option data-value={{emp.name}} *ngFor="let emp of employeeMasterLucene">
                                            {{emp.employeeid}}
                                        </option>
                                    </datalist>
                                </div>
                            </div>
                            <button type="button" id="navigatetarget"
                                class="btn btn-primary w-100  w-md-20 mt-3 mt-md-0 p-2 p-md-1"
                                (click)="limitpara(); searchEmployee(); ">
                                <i class="fa fa-search" aria-hidden="true"></i> Search
                            </button>
                        </form>
                    </div>
                </div>

            </div>
        </div>



        <!-- Row -->
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title "> Employee Details </h4>
                        <button type="button" class="btn btn-primary ms-auto" (click)="employeeMasterCSVDownload()">
                            <i class="fa fa-download" data-bs-toggle="tooltip" data-original-title="download"></i>
                        </button>
                    </div>
                    <!--<div class="card-rightheader ms-md-auto">
                    <div class="align-items-end flex-wrap my-auto right-content breadcrumb-right">
                        <div class="btn-list">
                            <button class="page-rightheader btn btn-primary btn-icon btn-md me-2 " (click)="employeeMasterCSVDownload()" >
                                <i  class="fa fa-download" data-bs-toggle="tooltip" data-original-title="download"></i>
                            </button>
                        </div>
                    </div>
                </div> -->
                    <div class="empty-div-one" style="height: 10px;" id="navigatedest"></div>
                    <div class="card-body">
                        <!-- <div class="table-responsive"> -->
                        <div class="col-xs-12  mobile view">
                            <div *ngIf="employeeMasterList !== null ">
                                <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                                    [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100"
                                    [scrollWindow]="false" (scrolled)="onScroll()" id="scrolllength">
                                    <table
                                        class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                        id="hr-table">
                                        <thead class="tablerow">
                                            <tr class=" stickyatTop rowcolors">
                                                <th class="border-bottom-0 w-5 fs-7 fixedcolumn secondcolumn">Actions
                                                </th>
                                                <!-- <th class="border-bottom-0 w-5 fs-7">Unique Employee Id</th> -->
                                                <th class="border-bottom-0 w-5 fs-7 fixedcolumn">Employee ID</th>
                                                <th class="border-bottom-0 fs-7 fixedcolumn  secondcolumn">Employee Name
                                                </th>
                                                <th class="border-bottom-0 w-10 fs-7">Mobile</th>
                                                <th class="border-bottom-0 w-10 fs-7">Email</th>
                                                <th class="border-bottom-0 w-10 fs-7">Branch Name</th>
                                                <th class="border-bottom-0 fs-7">Gender</th>
                                                <th class="border-bottom-0 fs-7">Blood Group</th>
                                                <th class="border-bottom-0 fs-7">Date of Birth</th>
                                                <th class="border-bottom-0 fs-7">Department</th>
                                                <th class="border-bottom-0 fs-7">Designation</th>
                                                <th class="border-bottom-0 fs-7">Joining Date</th>
                                                <th class="border-bottom-0 fs-7">Leaving Date</th>



                                                <ng-container>
                                                    <th class="border-bottom-0 fs-7"
                                                        [ngStyle]="{'display': showBasedOnPermissionForAddOnComponent ? '' : 'none'}">
                                                        Total Addition Component</th>
                                                    <th class="border-bottom-0 fs-7"
                                                        [ngStyle]="{'display': showBasedOnPermissionForAddOnComponent ? '' : 'none'}">
                                                        Total Deduction Component</th>
                                                </ng-container>



                                                <th *ngFor="let leavelist of LeaveTypeList">
                                                    <div data-th="Leave Type" style="min-width: 6vw;">
                                                        {{ leavelist.info.leaveName}}
                                                    </div>
                                                </th>



                                                <!-- <th class="scrolltheadFixed"> </th> -->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let j=index; let empInfo of employeeMasterList ">

                                                <td class="fixedcolumn  secondcolumn backgroundSW  backgroundFW">
                                                    <a class="btn btn-primary  me-2" style="padding: 4px 8px;"
                                                        (click)="getemployeeinfo(empInfo)"
                                                        [ngStyle]="{'display': accessToEditAndView ? '' : 'none'}">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                            fill="currentColor" class="bi bi-eye-fill"
                                                            viewBox="0 0 16 16">
                                                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                            <path
                                                                d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                                        </svg>
                                                    </a>

                                                    <a class="btn btn-primary btn-icon btn-sm me-2"
                                                        (click)="editempdetails(empInfo)"
                                                        [ngStyle]="{'display': accessToEditAndView ? '' : 'none'}">
                                                        <i class="feather feather-edit" data-bs-toggle="tooltip"
                                                            data-original-title="View/Edit"></i>
                                                    </a>

                                                    <a class="modal-effect btn btn-primary btn-icon btn-sm me-2"
                                                        (click)="viewProfilePic(empInfo)" data-effect="effect-scale"
                                                        data-bs-toggle="modal" href="#modaldemo8">
                                                        <i class="mdi mdi-account-circle" data-bs-toggle="tooltip"
                                                            data-original-title="Profile"></i>
                                                    </a>

                                                    <!--  -->
                                                </td>
                                                <!-- <a class="btn btn-danger btn-icon btn-sm" data-bs-toggle="tooltip" data-original-title="Delete"><i class="feather feather-trash-2"></i></a> -->
                                                <!-- <td data-th="Unique Employee Id">{{empInfo.uniqueemployeeid}}</td> -->
                                                <!-- id  -->
                                                <td class="fixedcolumn backgroundFW" data-th="Employee Id">
                                                    {{empInfo.employeeid}}</td>
                                                <td class="fixedcolumn secondcolumn  backgroundFW backgroundSW"
                                                    data-th="Employee Name"> {{empInfo.name}}
                                                    <!-- <div class="d-flex" > -->
                                                    <!-- {{empInfo.name}} -->
                                                    <!-- <span class="avatar avatar-md brround me-3" style="background-image: url(../assets/images/users/1.jpg)"></span> -->
                                                    <!-- <div class="me-3 mt-0 mt-sm-1 d-block"> -->
                                                    <!-- <h6 class="mb-1 fs-14">{{empInfo.name}}</h6> -->
                                                    <!-- <p class="text-muted mb-0 fs-12">faith@gmail.com</p> -->
                                                    <!-- </div> -->
                                                    <!-- </div> -->
                                                </td>
                                                <td data-th="Mobile">
                                                    {{empInfo.mobile}}</td>
                                                <td data-th="Email">
                                                    {{empInfo.email}}</td>
                                                <td data-th="Branch Name">
                                                    {{empInfo.branchName}}
                                                </td>
                                                <td data-th="Gender">
                                                    {{empInfo.gender}}
                                                </td>
                                                <td data-th="Blood Group">
                                                    {{empInfo.bloodgroup}}
                                                </td>
                                                <td data-th="Date of Birth">
                                                    {{empInfo.dateofbirth | date: 'MM/dd/yyyy'}}
                                                </td>
                                                <td data-th="Department Name">
                                                    {{empInfo.departmentname}}
                                                </td>
                                                <td data-th="Department Name">
                                                    {{empInfo.designationName}}
                                                </td>
                                                <td data-th="Joining Date">
                                                    {{empInfo.joiningdate | date: 'MM/dd/yyyy' }}
                                                </td>
                                                <td data-th="Leaving Date">
                                                    {{empInfo.leavingdate | date: 'MM/dd/yyyy'}}
                                                </td>

                                                <ng-container>
                                                    <td data-th="Total Addition Component"
                                                        [ngStyle]="{'display': showBasedOnPermissionForAddOnComponent ? '' : 'none'}">
                                                        {{empInfo.totalAdditionComponents | currency:"INR"}}
                                                    </td>
                                                    <td data-th="Total Deduction Component"
                                                        [ngStyle]="{'display': showBasedOnPermissionForAddOnComponent ? '' : 'none'}">
                                                        {{empInfo.totalDeductionComponents| currency:"INR"}}
                                                    </td>
                                                </ng-container>

                                                <ng-container
                                                    *ngIf="empInfo.employeeLeaveMasterList && empInfo.employeeLeaveMasterList.length > 0">
                                                    <td *ngFor="let leavelist of empInfo.employeeLeaveMasterList"
                                                        [ngStyle]="{'display': showBasedOnPermissionForLeaveType ? '' : 'none'}">
                                                        <div data-th="Leave Type">
                                                            <span class="light-text ">{{ leavelist.leaveType }} -
                                                            </span>
                                                            <span class="ps-2" *ngIf="leavelist.noOfDays !== -1">
                                                                {{ leavelist.noOfDays }}
                                                            </span>
                                                            <span class="ps-2" *ngIf="leavelist.noOfDays === -1"> Na
                                                            </span>
                                                        </div>
                                                    </td>
                                                </ng-container>
                                                <div
                                                    *ngIf="!empInfo.employeeLeaveMasterList || empInfo.employeeLeaveMasterList.length === 0">
                                                    <td
                                                        [style.display]="showBasedOnPermissionForLeaveType ? 'block' : 'none'">
                                                        -</td>
                                                </div>


                                                <!-- <td><span class="badge badge-success">Active</span></td>
                                                    <td>
                                                        <a class="btn btn-primary btn-icon btn-sm"
                                                            href="hr-empview.html">
                                                            <i class="feather feather-edit" data-bs-toggle="tooltip"
                                                                data-original-title="View/Edit"></i>
                                                        </a>
                                                        <a class="btn btn-danger btn-icon btn-sm"
                                                            data-bs-toggle="tooltip" data-original-title="Delete"><i
                                                                class="feather feather-trash-2"></i></a>
                                                    </td> -->

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal fade" id="modaldemo8" data-bs-backdrop="static">
            <div class="modal-dialog modal-sm modal-dialog-centered text-center" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">{{this.profileName}} Profile Picture</h6><button aria-label="Close"
                            class="btn-close" data-bs-dismiss="modal"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body">
                        <img class="avatar avatar-xxl brround rounded-circle" alt="img"
                            [src]="url || './../../../assets/template_assests/images/users/1.jpg'">

                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-light" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>