import { EmployeeMaster } from "./employeeMasterts";
import { EmployeeSwipeInfo } from "./EmployeeSwipeInfo";

export class EmployeeDaily {
    ID: number;
    filldate: String;
    empObj: any;
    morning: any;
    afternoon: any;
    night:any;
    ottype: string;
    ot: string;
    empEid: number;
    employeename: string;

    //new code
    employeeSwipeInfo: EmployeeSwipeInfo;
    employeeMaster: EmployeeMaster;
 
    
    strSwipeINTime : any; 
    strSwipeOUTTime  : any;
    strSwipeWorkHours  : any;


    uniqueemployeeid : any; 
	name : any; 
	designation : any; 
	branchid : any; 
	departmentid : any; 
	shiftid : any; 
	
	swipetime : any; 
	firstIN : any; 
	lastOUT : any; 
	
	amIN : any; 
	amOUT : any; 
	pmIN : any; 
	pmOUT : any; 
	
	latitude : any; 
	longitude : any; 

	userid : any; 
    enablepenalty:boolean=false;

    penalty:boolean=false;
    remarks:string = "";
    penaltyId:number;
}