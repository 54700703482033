export class employeeDocuments {
   id : number;
   docId : number;
   empId : String;
   branchId : number;
   docGroup : String;
   docType : String;
   updatedBy : String;
   name : String;
   uploadedOn : Date;
   filePath : String;
   overAllStatus : String;
   expiryDate : Date;
   comments : String;
   createdDate : Date;
   updateDate : Date;
}