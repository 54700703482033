// logged-in.guard.ts
import { Injectable, OnInit } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { ConstantService, SessionService } from './services';

@Injectable()
export class LoggedInGuard implements CanActivate, OnInit {
	accessKey: any;

	constructor(private _router: Router,
		private constantService: ConstantService,
		private sessionService: SessionService,
		private activatedroute: ActivatedRoute
	) {
		this.activatedroute.data.subscribe(data => {
			console.log(data, "keys")
			this.accessKey = data && data.accessKey;  // The && is to avoid any data undefined errors.
		})
	}


	emailId: string;
	allowedPermissionList: any[] = [];
	routePermission: any[] = [];


	ngOnInit() {
		this.activatedroute.data.subscribe(data => {
			console.log(data, "keys")
			this.accessKey = data && data.accessKey;  // The && is to avoid any data undefined errors.
		})
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		// var self = this;
		//this.routePermission = this.constantService.getRoleWithRoutePermission();
		this.routePermission = this.constantService.getRolePermissions();
		this.allowedPermissionList = this.sessionService.getRolePermissionList();

		console.log('Active Routes snaps: ', route);
		// return true;
		this.emailId = sessionStorage.getItem('companyId');
		// console.log( "Email Id is ------>" + this.emailId );

		if (!this.emailId) {
			this._router.navigate(['/login']);
			// window.location.href = "/login.html";
			return false;
		} else {
			//console.log(state.url);
			var currentUrl = state.url;
			//currentUrl = currentUrl.split('#')[0];

			var curUrls = route.data['accessKey'];

			console.log('access Key: ', curUrls);

			//for home page
			if (currentUrl.indexOf('employeeswipe') !== -1) {
				return true;
			}

			let hasAccess = this.checkHasAccess(curUrls);

			if (hasAccess) {
				return true;
			} else {
				this._router.navigate(['/login']);
				return false;
			}
		}
	}

	checkHasAccess(curUrls: any) {
		let hasAccess = false;

		// for(let i = 0; i < curUrls.length; i++){
		if (this.allowedPermissionList.includes(curUrls)) {
			hasAccess = true;
		}
		// }

		return hasAccess;
	}
}

