// import { Comment } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { ContractclientDocument } from '../models/ContractClientDocument';
import { Fileextend } from '../models/Fileextend';
// import { Locations } from '../models/locations';
// import { MyDocuments } from '../models/mydocuents';
import { ContractclientService } from '../services/contractclient.service';

import { DocumentInfoService } from '../services/documentinfo.service';

declare var $: any;

export interface existingComment { id: number; comments: string; contract_project_id: number }

@Component({
  selector: 'app-contract-client-project',
  templateUrl: './contract-client-project.component.html',
  styleUrls: ['./contract-client-project.component.css']
})

export class ContractClientProjectComponent implements OnInit {

  projectinfo: ContractclientDocument;
  documents: Array<Fileextend>;
  location: ContractclientDocument[];

  contractClientData: ContractclientDocument[];
  clientid_array: ContractclientDocument[];
  client_id: any;
  clientLocList: ContractclientDocument[];
  selectedBusiness: string;
  selectedBusinessTwo: string;

  contractClientProDataForm: ContractclientDocument;
  contractclientlist = [];

  contractClientLocListData = [];
  contractClientLocList: any[] = [];
  serverContractClientLocList: any[] = [];
  filterContractClientLocList: any[] = [];
  clientLocName: ContractclientDocument;
  cllientBusName: string;

  deleteProjectInfo: ContractclientDocument;
  contractClientProInfoData: any[] = [];

  clientProjectID: any;

  progressInfos: any[] = [];

  files: any[] = [];
  serverFiles: any[] = [];
  // Above
  filesArray: any[] = [];
  // filesArray: any;
  finalFileName: string;

  fileslength: boolean;
  fileToUpload: Fileextend | null = null;
  selectedFiles: FileList;

  loclength: boolean;
  storeDeleteLocName: any;

  existingcomments: existingComment[] = [];
  updateCommentInDoc: boolean = false;
  multipleFilePath = [];
  listmultipleFilePath = [];
  shortLink: string = "";
  loading: boolean = false;

  contractstream$!: Subscription;

  // contractProForm: any;

  primaryOwnerName: any;
  primaryOwnerId: any;
  employeeList: any[] = [];
  ownersList: any[] = [];
  serverOwnersList: any[] = [];

  proOwnerName: ContractclientDocument;

  @ViewChild('contractProForm') contractProForm: NgForm;

  //Validation
  projectFreezeDate: string;
  freezeDateCheckStart: boolean = false;
  freezeDateCheckEnd: boolean = false;

  fileUpdate: boolean = false;
  locUpdate: boolean = false;
  isUpdate: boolean = true;
  isdisable: boolean = true;
  submitted: boolean;

  startDate: boolean = false;
  endDate: boolean = false;


  constructor(private _snackBar: MatSnackBar,
    private docmentInfoService: DocumentInfoService,
    private contractclientService: ContractclientService) { }

  ngOnInit(): void {
    this.contractClientProDataForm = new ContractclientDocument();
    this.projectinfo = new ContractclientDocument();

    this.getClientData();

  }

  // API calls

  getClientData() {


    console.log("Session data : User data ", this.primaryOwnerName)

    this.contractstream$ = this.contractclientService.getContractClient().subscribe(
      data => {
        // console.log(data)
        this.contractClientData = data.client;
        // console.log("List of all contract client in client projects >>>> ", this.contractClientData);
      },
      error => {
        // console.log(error);
      }
    );

    this.contractclientService.getEmployeeList().subscribe(
      data => {
        // console.log(data)
        // console.log("List of all employee >>>> ", data);
        this.employeeList = data.employeeMasterData;

      },
      error => {
        // console.log(error);
      }
    );
  }

  getclientLocDetail(v: any, form: ContractclientDocument) {
    // console.log("Anny >> >> >>", v, form)
    this.cllientBusName = v;
  }

  getData() {
    this.clear()
    // console.log("outfocused")

    this.clientid_array = this.contractClientData.filter((dName) => {
      return dName.business_name.toLowerCase() === this.cllientBusName.toLowerCase();
    });

    this.contractstream$ = this.contractclientService.getContractClientLocation(this.clientid_array[0].id).subscribe(
      data => {
        // console.log("Respons from contract client location >>> ", data)
        this.contractClientLocListData = [];
        this.contractClientLocListData = data.locationsdeta;
        // this.contractclientlocationlist = data.locationsdeta;
        // this.clientid_array = [];
        this.contractClientLocList = [];

        // for (var i = 0; i < this.clientLocList.length; i++) {
        //   this.contractClientLocListData.push(this.clientLocList[i].city)
        // }
        $('#collapseOne').removeClass("disable-div");
        $('#collapseOne').addClass("show")

        // console.log("Location name list >>", this.contractClientLocListData)
      },
      error => {
        console.log(error);
      }
    );

    this.getProjectInfo();

  }

  getProjectInfo() {
    // console.log("Project Info : ")

    this.clientid_array = [];

    this.clientid_array = this.contractClientData.filter((dName) => {
      return dName.business_name.toLowerCase() === this.cllientBusName.toLowerCase();
    });

    this.client_id = this.clientid_array[0].id;
    this.contractstream$ = this.contractclientService.getContractClientProjectInfo(this.client_id).subscribe(
      data => {
        // console.log("Respons from contract client Project List >>> ", data)
        this.contractClientProInfoData = [];
        this.contractClientProInfoData = data.contractprojectdeta;
        // console.log("Project list >>", this.contractClientProInfoData)

      },
      error => {
        // console.log(error);
      }
    );
  }

  // End API calls

  // Project information

  saveProjectInfo(valid) {
    this.submitted = true;

    if (!valid) {
      // console.log("Not valid")
      return;
    }

    if (!this.checkFromAndToDate()) {
      // console.log("Error in date")
      return
    }

    if (valid && this.submitted) {
      if (this.cllientBusName === null || this.cllientBusName === undefined) {
        // console.log("Busines name is empty");
      } else {

        this.clientid_array = this.contractClientData.filter((dName) => {
          return dName.business_name.toLowerCase() === this.cllientBusName.toLowerCase();
        });

        this.projectinfo.business_name = this.clientid_array[0].business_name;
        this.projectinfo.clientid = this.clientid_array[0].id;

        this.contractstream$ = this.contractclientService.saveContractClientProjectInfo(this.projectinfo).subscribe(
          data => {
            // console.log("Response data from save client project save request >>>> ", data)

            this.clientProjectID = undefined;
            this.clientProjectID = data.contractprojectid;

            // console.log("Response : Client Projct ID ", this.clientProjectID);

            this.primaryOwnerName = sessionStorage.getItem('username');
            // console.log("What is this : saveProjectInfo ", this.primaryOwnerName)
            this.primaryOwnerId = sessionStorage.getItem('empId');
            this.ownersList.push({ "name": this.primaryOwnerName, "emp_id": this.primaryOwnerId })

            $('#collapseOne').removeClass("show")
            $('#collapseTwo').addClass("show")
            $('#collapseTwoMain').removeClass("disable-div");
            $('#collapseThreeMain').removeClass("disable-div");
            let typeFunction = "saved";
            this.saveProOwner(typeFunction);
            this.getProjectInfo();

          },
          error => {
            // console.log(error);
          }
        );
      }
    } else {
      // console.log("Not valid")
      return;
    }
  }

  saveProOwner(typeFunction: string) {
    this.ownersList.forEach((item, index) => item["contract_projectID"] = this.clientProjectID);

    // console.log("Value to be uploaded ", this.ownersList);
    this.contractstream$ = this.contractclientService.saveContractClientProOwner(this.ownersList).subscribe(
      data => {
        // console.log("Response data from save client project owner >>>> ", data)
        this._snackBar.open(`Successfully ${typeFunction}!`, 'Close', {
          duration: 2000,
        });

        if (typeFunction === "saved") {
          this.clearProjectInfo();
        } else {
          this.ownersList = []
          this.getProjectOwner(this.clientProjectID);
        }

      },
      error => {
        // console.log(error);
      }
    );
  }

  updateProjectInfo(valid) {
    this.submitted = true;

    if (!valid) {
      // console.log("Not valid")
      return;
    }

    if (!this.checkFromAndToDate()) {
      // console.log("Error in date")
      return
    }

    if (valid && this.submitted) {
      // console.log("Now let's see the", this.projectinfo)

      this.contractstream$ = this.contractclientService.updateContractClientProjectInfo(this.projectinfo).subscribe(
        data => {
          // console.log("Response data from save client project Info request >>>> ", data)
          this.getProjectInfo();
          let typeFunction = "updated";
          this.saveProOwner(typeFunction);
        },
        error => {
          // console.log(error);
        }
      );
    } else {
      // console.log("Not valid")
      return;
    }
  }

  contractclientLocEdit(projectInfo: ContractclientDocument) {
    this.isUpdate = false;
    this.isdisable = false
    this.clientProjectID = projectInfo.id;
    this.projectFreezeDate = projectInfo.freeze_date;

    
    this.projectinfo = { ...projectInfo };
    this.checkfreezedate();
    
    this.getProjectDocs(this.clientProjectID);
    this.getProjectLoc(this.clientProjectID);
    this.getProjectOwner(this.clientProjectID);

    $('#collapseOne').addClass("show");
    $('#collapseTwoMain').removeClass("disable-div");
    $('#collapseThreeMain').removeClass("disable-div");

    document.getElementById('update-btn-1').style.display = "inline-block";
    document.getElementById('save-btn-1').style.display = "none";
    document.getElementById('update-btn-2').style.display = "inline-block";
    document.getElementById('save-btn-2').style.display = "none";
    document.getElementById('update-btn-3').style.display = "inline-block";
    document.getElementById('save-btn-3').style.display = "none";

  }

  contractclientLocDeleteDataStore(projectInfo: ContractclientDocument) {
    this.deleteProjectInfo = projectInfo;
    $('#showAlertWarning').modal('toggle');
  }

  contractclientLocDeleteData() {

    this.contractstream$ = this.contractclientService.deleteContractClientProjectInfo(this.deleteProjectInfo.id).subscribe(
      data => {
        // console.log("Respons from contract client Project Doc Delete >>> ", data)
        this.getProjectInfo()
        this._snackBar.open('Successfully delete!', 'Close', {
          duration: 2000,
        });
      },
      error => {
        // console.log(error);
        if (error.error?.projectfreezed) {
          $('#showAlertCantDeleteFreeze').modal('toggle');
        } else if (error.error?.dataNotFound) {
          $('#showAlertCouldntDelete').modal('toggle');
        } else if (error.error?.dataAttachedToIt) {
          $('#showAlertDataAttachedToPro').modal('toggle');
        } else {
          $('#showAlertCantDelete').modal('toggle');
        }
      }
    );
  }

  clearProjectInfo() {
    this.isdisable = true;
    this.submitted = false;

    this.startDate = false;
    this.endDate = false;

    this.freezeDateCheckStart = false;
    this.freezeDateCheckEnd = false;

    // console.log("Two Two are four", this.freezeDateCheckStart, this.freezeDateCheckEnd)

    this.isUpdate = true;

    this.serverOwnersList = [];
    this.ownersList = [];

    this.files = [];
    this.serverFiles = [];

    this.projectFreezeDate = undefined;

    this.contractClientProDataForm.location_name = ' ';
    this.contractClientLocList = [];
    this.serverContractClientLocList = [];

    document.getElementById('update-btn-1').style.display = "none";
    document.getElementById('save-btn-1').style.display = "inline-block";
    document.getElementById('update-btn-2').style.display = "none";
    document.getElementById('save-btn-2').style.display = "inline-block";
    document.getElementById('update-btn-3').style.display = "none";
    document.getElementById('save-btn-3').style.display = "inline-block";

    // console.log("What is this : clearProjectInfo 1 ", this.primaryOwnerName)
    // console.log("What is this : clearProjectInfo 2", this.projectinfo.name)
    // console.log("What is this : clearProjectInfo 3", this.primaryOwnerName)
    this.contractProForm.reset();

    this.freezeDateCheckStart = false;
    this.freezeDateCheckEnd = false;

    // console.log("Two Two are four", this.freezeDateCheckStart, this.freezeDateCheckEnd)

    // this.primaryOwnerName = sessionStorage.getItem('username');
    this.contractProForm.form.get('name').setValue(this.primaryOwnerName)
    // console.log("What is this : clearProjectInfo 4", this.projectinfo.name)
  }

  collapseItems() {
    // console.log("Remove")
    $('#collapseTwoMain').addClass("disable-div");
    $('#collapseThreeMain').addClass("disable-div");

    if ($('#collapseTwo').hasClass("show")) {
      // console.log("Remove from two")
      $('#collapseTwo').removeClass("show")
    }

    if ($('#collapseThree').hassClass("show")) {
      // console.log("Remove from three")
      $('#collapseThree').removeClass("show")
    }
  }

  addMoreOwner() {
    $('#showAddOwners').modal('toggle');
  }

  getProjectOwner(clientProjectID: number) {
    // console.log("Project Loc : ")

    this.serverOwnersList = [];
    this.ownersList = [];

    this.contractstream$ = this.contractclientService.getContractClientProOwner(clientProjectID).subscribe(
      data => {
        // console.log("Respons from contract client Project owner List >>> ", data)

        this.serverOwnersList = data.contractprojectowner;
        // console.log("Srver locations >> ", this.serverOwnersList);

      },
      error => {
        // console.log(error);
      }
    );
  }

  getProOwnerNames(v: any, form: ContractclientDocument) {
    // console.log(">>", v);
    [this.proOwnerName] = this.employeeList.filter(loc => loc.name === v)

  }

  addProOwnerName() {
    // console.log("Owner name", this.proOwnerName)

    if (this.ownersList.includes(this.proOwnerName)) {
      // console.log("Already exist in list")
      this._snackBar.open("Already added!", 'Close', {
        duration: 2000,
      });

    } else {
      this.proOwnerName.emp_id = this.proOwnerName.uniqueemployeeid;
      this.ownersList.push(this.proOwnerName);
      this.contractClientProDataForm.name = "";
    }
    // console.log("Final location list", this.ownersList);
  }

  deleteOwnerName(v: any) {
    // console.log("Name >>>", v);
    this.ownersList = this.ownersList.filter(function (w) { return w != v });

    // console.log("Final location list after delete", this.ownersList);

    this._snackBar.open("Successfully delete!", 'Close', {
      duration: 2000,
    });
  }

  deleteServerOwnerName(v: any) {
    // console.log("Name >>>", v);

    this.contractstream$ = this.contractclientService.deleteContractClientProOwner(v.id).subscribe(
      data => {
        // console.log("Respons from contract client Project Doc Delete >>> ", data)
        this._snackBar.open("Successfully delete!", 'Close', {
          duration: 2000,
        });
        this.getProjectOwner(this.clientProjectID);
      },
      error => {
        // console.log(error);
      }
    );
  }

  clearOwnerList() {
    this.ownersList = [];
    this._snackBar.open('Successfully cleared', 'Close', {
      duration: 2000,
    });
  }

  // End Project Information

  // Upload multiple files

  getProjectDocs(clientProjectID: number) {
    // console.log("Project Docs : ")

    this.contractclientService.getContractClientProjectDocs(clientProjectID).subscribe(
      data => {
        // console.log("Respons from contract client Project Doc List >>> ", data)
        this.serverFiles = [];
        this.serverFiles = data.projectdoc;
        // console.log("Server files >> ", this.serverFiles);
      },
      error => {
        // console.log(error);
      }
    );
  }

  // resizeImage(file: Fileextend, maxWidth, maxHeight, callback) {

  //   console.log("Reachecd chek point one", file, maxWidth, maxHeight)
  //   const reader = new FileReader();
  //   reader.onload = function (event) {
  //     const img = new Image();
  //     img.onload = function () {
  //       let width = img.width;
  //       let height = img.height;

  //       // Check if the image needs resizing
  //       if (width > maxWidth || height > maxHeight) {
  //         const aspectRatio = width / height;

  //         // Resize the image while maintaining aspect ratio
  //         if (width > maxWidth) {
  //           width = maxWidth;
  //           height = width / aspectRatio;
  //         }

  //         if (height > maxHeight) {
  //           height = maxHeight;
  //           width = height * aspectRatio;
  //         }
  //       }

  //       console.log("Reachecd chek point two", img, width, height)
  //       // Create a canvas element and draw the resized image on it
  //       const canvas = document.createElement('canvas');
  //       const ctx = canvas.getContext('2d');
  //       canvas.width = width;
  //       canvas.height = height;
  //       ctx.drawImage(img, 0, 0, width, height);

  //       // Convert the canvas content to a Blob and create a new File object
  //       canvas.toBlob(function (blob) {
  //         const resizedFile = new File([blob], file.name, { type: file.type });

  //         // Invoke the callback with the resized file
  //         callback(resizedFile);
  //       }, file.type);
  //     };
  //     // img.src = event.target.result.toString();
  //   };
  //   // reader.readAsDataURL(file);
  // }


  resizeImage(image: HTMLImageElement, width: number, height: number) {
    // Create a new canvas with the desired width and height.
    console.log("Image reached", image)
    console.log(typeof image);
    let canvas: HTMLCanvasElement = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    // Create a 2D context for the canvas.
    let ctx: any = canvas.getContext("2d");

    // Draw the image to the canvas.
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

    // Return the resized image.
    return canvas;
  }


  onFileChange(pFileList: Fileextend[]) {
    // console.log("File array is initialy : ", this.filesArray)

    this.filesArray = Object.keys(pFileList).map(key => pFileList[key]);
    // console.log("File array is : ", this.filesArray)
    // console.log("File array elementts are : ", this.filesArray[0])
    // console.log("File array element is : ", this.filesArray[0].File)

    const typeList = ['png', 'jpg', 'jpeg', 'pdf'];
    console.log("Step one check : fileArray", this.filesArray)
    if (typeList.includes(this.filesArray[0].name.split(".").pop())) {

      console.log("Step two check : fileArray", this.filesArray)

      if (this.filesArray[0].name.split(".").pop() === 'pdf' && this.filesArray.length > 0) {
        // if (this.filesArray.length > 0) {
        const file: File = this.filesArray[0];
        const fileSizeInBytes: number = file.size;
        const fileSizeInMB: number = fileSizeInBytes / (1024 * 1024);

        // console.log(`File size: ${fileSizeInMB} MB`);

        if (fileSizeInMB > 10) {
          this._snackBar.open("File size is above 2 MB!", 'Okay', {
            duration: 2000,
          });
          // console.log("File size is above 2 MB")
          return;
        }
      }
      // else { 
      //   // this.resizeImage(this.filesArray[0], 800, 600, function (resizedFile) {
      //   //   // Do something with the resized image file
      //   //   console.log('Resized Image:', resizedFile);
      //   //   console.log('Array befor resize zero : Resized Image:', this.filesArray);
      //   //   this.filesArray = [];
      //   //   console.log('Array after resize zero : Resized Image:', this.filesArray);

      //   //   this.filesArray[0].push(resizedFile);
      //   //   console.log('Array After : Resized Image:', this.filesArray);
      //   // });
      //   // var image = new Image();
      //   // image = this.filesArray[0];
      //   console.log("Now i seond loop : Time for second loop") 
      //   const data = this.resizeImage(this.filesArray[0], 600, 300);

      //   console.log('Array befor resize zero : Resized Image:', this.filesArray);
      //   this.filesArray = [];
      //   console.log('Array after resize zero : Resized Image:', this.filesArray);
      //   // this.filesArray[0].push(this.resizeImage(this.filesArray[0], 800, 600));

      //   console.log('Array befor : Resized Image:', this.filesArray);
      //   console.log('Array befor : Resized Image:', data);
      // }

      for (var i = 0; i < this.filesArray.length; i++) {
        let [fileNameA, fileExt] = this.filesArray[i].name.split('.');
        let fileNameB = fileNameA.split(" ").join("");
        this.finalFileName = fileNameB + '_' + new Date().getSeconds() + '.' + fileExt;
        const myRenamedFile = new File([this.filesArray[i]], this.finalFileName);
        this.files.push(myRenamedFile);
        this.files[i].contract_project_id = this.clientProjectID;
        // console.log("In Loop > > > ", this.files)
      }

      // console.log(this.files)
      // console.log("The Fils Size", this.files.length)
      this.fileslength = true;
      this._snackBar.open("One document selected!", 'Close', {
        duration: 2000,
      });
    } else {
      // console.log("Not in type list")
      this._snackBar.open("Upload only PDF, PNG and JPEG/JPG file!", 'Okay', {
        duration: 2000,
      });
    }
  }

  deleteFile(f) {
    this.files = this.files.filter(function (w) { return w.name != f.name });
    this._snackBar.open("One document removed!", 'Close', {
      duration: 2000,
    });
  }

  deleteServerFile(file) {
    // console.log("Delete file >> ", file, file.id)

    this.contractstream$ = this.contractclientService.deleteContractClientProjectDoc(file.id).subscribe(
      data => {
        // console.log("Respons from contract client Project Doc Delete >>> ", data)
        this._snackBar.open("Successfully delete!", 'Close', {
          duration: 2000,
        });
        this.getProjectDocs(this.clientProjectID);
      },
      error => {
        // console.log(error);
      }
    );
  }

  updateDocs() {
    // console.log("Update is called : One ")
    this.uploadDocs();
  }

  uploadDocs(): void {
    console.log("Update is called : Two ", this.fileUpdate)


    // if (this.files.length == 0) {
    //   this.fileslength = false;
    //   return;

    // } else {
    //   this.fileslength = true;
    // }

    // console.log("The Files Length is ", this.files.length);

    this.multipleFilePath = [];
    // console.log("Final list", this.files)

    if (this.files.length !== 0) {
      for (let i = 0; i < this.files.length; i++) {
        this.upload(i, this.files[i], this.files[i]?.comment === undefined ? null : this.files[i]?.comment);
      }
    } else {
      // console.log("Reached Else part")
      this.contractclientService.saveContractClientProjectDocs(null, null, null, null, this.existingcomments).subscribe({
        next: (event: any) => {
          // console.log("Uploaded the file successfully:");

        },
        error: (err: any) => {

          // console.log("Could not upload the file: ");
        }
      });
    }
  }

  upload(idx: number, file: Fileextend, comments: string): void {
    // this.progressInfos[idx] = { value: 0, fileName: file.name };

    this.contractclientService.saveContractClientProjectDocs(file, this.clientProjectID, this.client_id, comments, this.existingcomments).subscribe({
      next: (event: any) => {
        // console.log("Uploaded the file successfully:", file.name);
        this.files = this.files.filter(function (w) { return w.name != file.name });
        if (this.files.length === 0) {
          if (this.fileUpdate) {
            this._snackBar.open("Successfully updated!", 'Close', {
              duration: 2000,
            });
          } else {
            this._snackBar.open("Successfully uploaded!", 'Close', {
              duration: 2000,
            });
          }
          this.getProjectDocs(this.clientProjectID);
        }
        this.existingcomments = [];
      },
      error: (err: any) => {
        this.progressInfos[idx].value = 0;
        // console.log("Could not upload the file:", file.name);
      }
    });
    // if (idx - 1 === this.files.length) {
    //   console.log("I is equal to length >> >> >> >>")
    // } else {
    //   console.log("I is not equal to length >> >> >> >>")
    // }
  }

  listMultipleFile(multipleDocFiles: any) {
    this.listmultipleFilePath = [];
    if (this.multipleFilePath.length != 0) {
      for (var i = 0; i < this.multipleFilePath.length; i++) {
        this.listmultipleFilePath.push(this.multipleFilePath[i]);
        // console.log("The multipleFile Path is", this.listmultipleFilePath[i]);

      }
    }
  }

  handleFileInput(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileToUpload = file;
    }
  }

  getcomment(e, index: number) {
    this.files[index].comment = e.target.value;
  }

  updateServerFileComment(e: string, f: existingComment) {

    if (e.trim().length !== 0) {
      f.comments = e;
      this.updateCommentInDoc = true;
      if (this.existingcomments.length === 0) {
        this.existingcomments.push(f)
      } else {
        let obj = this.existingcomments.reduce((acc, curr, i) => {
          if (curr.id === f.id) {
            curr.comments = f.comments;
            return curr;
          } else {
            return acc;
          }
        }, null)

        if (!obj) {
          this.existingcomments.push(f)
        }

      }
    }



    // console.log("Commwnt", this.existingcomments)
    // console.log("New comment > ", e)
  }

  clearUploadDocs() {
    // console.log("Update is called : Two ",this.fileUpdate)

    this.files = [];
    this.serverFiles = [];
  }

  // End : Upload multiple files

  // Location data get and manage

  getProjectLoc(clientProjectID: number) {
    // console.log("Project Loc : ")

    this.contractstream$ = this.contractclientService.getContractClientProjectLoc(clientProjectID).subscribe(
      data => {
        // console.log("Respons from contract client Project Locations List >>> ", data)

        this.serverContractClientLocList = data.projectlocationdeta;
        // console.log("Srver locations >> ", this.serverContractClientLocList);

        this.filterContractClientLocList = [];

        for (var i = 0; i < this.serverContractClientLocList.length; i++) {
          this.filterContractClientLocList.push(this.serverContractClientLocList[i].location_name)
        }
      },
      error => {
        // console.log(error);
      }
    );
  }

  getclientLocNames(v: any, form: ContractclientDocument) {
    // console.log(">>>>", v, form);
    [this.clientLocName] = this.contractClientLocListData.filter(loc => loc.location_name === v)

  }

  deleteLocName(v: any, i: any) {
    // console.log("Name >>>", v);
    // console.log("Index >>>", i);

    this.contractClientLocList = this.contractClientLocList.filter(function (w) { return w != v });

    // console.log("Final location list after delete", this.contractClientLocList);

    this._snackBar.open("Successfully delete!", 'Close', {
      duration: 2000,
    });
  }

  StoreDeleteServerLocName(location) {
    this.storeDeleteLocName = [];
    this.storeDeleteLocName = location;
    $('#showAlertWarningDeleteServerLoc').modal('toggle');
    // console.log("Delete file >> ", this.storeDeleteLocName, this.storeDeleteLocName.id, this.clientProjectID)
  }

  deleteServerLocName() {
    // console.log("Delete file >> ", this.storeDeleteLocName, this.storeDeleteLocName.id, this.clientProjectID)

    this.contractstream$ = this.contractclientService.deleteContractClientProjectLoc(this.storeDeleteLocName.id, this.clientProjectID).subscribe(
      data => {
        // console.log("Respons from contract client Project Doc Delete >>> ", data)
        this._snackBar.open("Successfully delete!", 'Close', {
          duration: 2000,
        });
        this.getProjectLoc(this.clientProjectID);
      },
      error => {
        // console.log(error);
        if (error.error?.projectfreezed) {
          $('#showAlertCantDeleteFreeze').modal('toggle');
        } else if (error.error?.dataAttachedToIt) {
          $('#showAlertDataAttachedToLoc').modal('toggle');
        } else {
          this._snackBar.open("couldn't delete!", 'Close', {
            duration: 2000,
          });
        }
      }
    );
  }

  addLocationToPro() {
    // console.log("Add location : Location name is ", this.clientLocName);
    // console.log("Compare List one ", this.contractClientLocList);
    // console.log("Compare List two ", this.serverContractClientLocList);

    if (this.contractClientLocList.includes(this.clientLocName) || this.filterContractClientLocList.includes(this.clientLocName.location_name)) {
      // console.log("Already exist in list", this.clientLocName)
      this._snackBar.open("Already added!", 'Close', {
        duration: 2000,
      });

    } else if (this.contractClientLocListData.includes(this.clientLocName)) {
      this.clientLocName.contract_project_id = this.clientProjectID;
      this.clientLocName.contract_location_id = this.clientLocName.id;
      this.contractClientLocList.push(this.clientLocName);

      this._snackBar.open("One location selected!", 'Close', {
        duration: 2000,
      });

    } else {
      // console.log(this.clientLocName, "Does't exist in location list");

    }
    // console.log("Final location list", this.contractClientLocList);
  }

  updateLoc() {
    this.uploadLoc();
  }

  uploadLoc() {
    if (this.contractClientLocList.length == 0) {
      this.loclength = false;
      return;

    } else {
      this.loclength = true;
    }

    //get image upload file obj;
    // console.log("The location list Length is ", this.contractClientLocList.length);

    // console.log("Final Location list", this.contractClientLocList)

    this.contractclientService.saveContractClientProjectLoc(this.contractClientLocList).subscribe(
      data => {
        // console.log("The Return response is", data);
        if (this.locUpdate) {
          this._snackBar.open("Successfully updated!", 'Close', {
            duration: 2000,
          });
        } else {
          this._snackBar.open("Successfully saved!", 'Close', {
            duration: 2000,
          });
        }
        this.contractClientLocList = [];
        this.getProjectLoc(this.clientProjectID);
      },
      error => {
        // console.log("The Error is ", error);
      });
  }

  clearUploadLoc() {
    this.contractClientProDataForm.location_name = ' ';
    this.contractClientLocList = [];
    this.serverContractClientLocList = [];
  }
  // End : Location data get and manage

  submit() {
    console.log("Submit from c c");
  }

  clear() {
    // console.log("Clear buttons : Clear all fields");
    // let inputs = document.querySelectorAll('input');
    // this.contractProForm.reset();
    // console.log("What is this : clear 1", this.primaryOwnerName)
    this.primaryOwnerName = sessionStorage.getItem('username');
    this.contractProForm.form.get('name').setValue(this.primaryOwnerName)
    // console.log("What is this : clear 2", this.primaryOwnerName)

    this.files = [];
    this.serverFiles = []
    this.serverContractClientLocList = []
    this.contractClientLocList = [];
    this.contractClientLocListData = [];
    this.contractClientProInfoData = [];

    this.isUpdate = true;

    // inputs.forEach(input => input.value = '')
    document.getElementById('update-btn-1').style.display = "none";
    document.getElementById('save-btn-1').style.display = "inline-block";
    document.getElementById('update-btn-2').style.display = "none";
    document.getElementById('save-btn-2').style.display = "inline-block";
    document.getElementById('update-btn-3').style.display = "none";
    document.getElementById('save-btn-3').style.display = "inline-block";
  }

  // Validation

  toggleDisable(obj) {
    if (obj) {
      this.isdisable = false
    } else {
      this.isdisable = true;
    }
  }

  checkFromAndToDate() {
    console.log("Reset reached")
    this.startDate = false;
    this.endDate = false;
    this.freezeDateCheckStart = false;
    this.freezeDateCheckEnd = false;

    if (this.projectFreezeDate) {
      if (!this.checkfreezedate()) {
        return;
      }
    }

    if (this.projectinfo.end_date) {
      if (this.projectinfo.start_date > this.projectinfo.end_date) {
        // console.log("It's  not right")
        this.submitted = false
        this.endDate = true;
      } else {
        // console.log("It's okay")
        this.endDate = false;
        return true;
      }
    }
  }

  checkfreezedate() {
    this.freezeDateCheckStart = false;
    this.freezeDateCheckEnd = false;

    // console.log("Reached checkfreezedate() >>")

    let projectStartDateUser = new Date(this.projectinfo.start_date)
    let projectEndDateUser = new Date(this.projectinfo.end_date)

    let projectFreezeDateNow = new Date(this.projectFreezeDate)

    let falseOrTrue = true;

    // console.log("Freeze date", projectFreezeDateNow)
    // console.log("start date", projectStartDateUser)
    // console.log("end date", projectEndDateUser)

    if (this.projectFreezeDate) {
      if (projectFreezeDateNow.valueOf() >= projectStartDateUser.valueOf()) {
        // console.log("Reached inside If inside If two two", projectStartDateUser)
        this.submitted = false;
        this.freezeDateCheckStart = true;
        // this.freezeDateCheckEnd = false;
        falseOrTrue = false;
      } 
      
      if (projectFreezeDateNow.valueOf() >= projectEndDateUser.valueOf()) {
        // console.log("Reached inside If inside If one", projectEndDateUser)
        this.submitted = false;
        // this.freezeDateCheckStart = false;
        this.freezeDateCheckEnd = true;
        falseOrTrue = false;
      }

      if (!falseOrTrue) {
        // console.log("Reached falseee")
        return false;
      } else {
        return true;
      }

      // if (projectFreezeDateNow.valueOf >= projectEndDateUser.valueOf) {
      //   console.log("Reached inside If inside If two")
      //   this.submitted = false;
      //   this.freezeDateCheckStart = false;
      //   this.freezeDateCheckEnd = true;
      // }
    }

  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 10000);
  }

  ngOnDestroy(): void {
    this.contractstream$.unsubscribe();
  }

}
