import { Component, OnInit, ViewChild } from '@angular/core';
import { EmployeeLeaveInfo } from '../models/employee-leave-info.model';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { EmployeeLeaveApplied } from '../models/employee-leave-applied.model';
import { EmployeeLeaveOverview } from '../models/employee-leave-overview.model';
import { AlertService } from 'src/app/services';
import { ChartComponent } from "ng-apexcharts";

import {
	ApexNonAxisChartSeries,
	ApexResponsive,
	ApexChart,
	ApexFill,
	ApexDataLabels,
	ApexLegend
} from "ng-apexcharts";
import { EmployeeMaster } from '../models/employeeMasterts';

export type ChartOptions = {
	series: ApexNonAxisChartSeries;
	chart: ApexChart;
	responsive: ApexResponsive[];
	labels: any;
	fill: ApexFill;
	legend: ApexLegend;
	dataLabels: ApexDataLabels;
};
declare var $: any;

@Component({
	selector: 'app-employee-leave-proxy',
	templateUrl: './employee-leave-proxy.component.html',
	styleUrls: ['./employee-leave-proxy.component.css']
})
export class EmployeeLeaveProxyComponent implements OnInit {
	@ViewChild("chart") chart: ChartComponent | any;
	public chartOptions!: Partial<ChartOptions> | any;

	employeeMasterLucene: EmployeeMaster[];
	query: any;


	role = sessionStorage.getItem('role');
	//   uniqueEmployeeId = 10001; 
	uniqueEmployeeId = Number(sessionStorage.getItem('empId'));
	// empLeaveInfo = new EmployeeLeaveInfo("2022-06-14","2022-06-17","CL","F");
	empLeaveInfo =  new EmployeeLeaveInfo(-1, -1 , "","","","","","");
	totalLeaveCount: number = 0;
	totalLeaveDates = [];
	leaveTypesList = [
		{ leaveType: "", leaveName: "" }
	]
	leaveDatesList = [
		{ halfFullIndic: "half", leaveDay: "Half Day Leave" },
		{ halfFullIndic: "single", leaveDay: "Single Leave" },
		{ halfFullIndic: "multiple", leaveDay: "Multiple Leaves" }
	]
	leaveTypeMap = new Map();
	employeeLeaveName: string[] = []
	employeeLeaveValue: number[] = []
	employeeLeaveOverview: EmployeeLeaveOverview[] = [new EmployeeLeaveOverview("", -1)]

	employeeLeaveSummary: EmployeeLeaveApplied[] = []
	showDelBtn = false;

	leaveForPastDate: any;
	firstDay: Date;
	configKey: string = 'leaveForPastDate';

	constructor(private empLeaveService: EmployeeLeaveService, private alertService: AlertService) {

	}

	ngOnInit(): void {
		// console.log("-----------------Check NGONINIT OTHERS------------");
		this.getEmployeeLeaveAppliedSummary()

		// leave type loaded for logged user , when we select other we can select their leave type 
		// this.getLeaves();


		// this.setLeaveMap();
		var info = this.empLeaveInfo;
		// var handle = this.handleOnChange.bind(this);
		var calculate = this.calculateLeave.bind(this);

		// Get leave for a past date Limit from Adminconfig
		this.getLeaveForPastDate()

		$('#daterange-categories').on('change', function () {
			document.getElementById('leavetypes')?.setAttribute("disabled", "true")
			let indic = info.halfFullIndic;

			$('.leave-content').hide();

			if (indic === "half" || indic === "single") {

				$('#single').show();
				document.getElementById('singledate')?.removeAttribute("disabled")
			} else if (indic === "multiple") {

				$('#multiple').show();
				document.getElementById('multipledate')?.removeAttribute("disabled")
			}

		});

		$('#singledate').on('apply.daterangepicker', function (ev: any, picker: any) {
			// info.toDate = picker.endDate.format('YYYY-MM-DD');
			// console.log("Single Target ",ev.target.value);
			// console.log("Single Start ",picker.startDate.format('YYYY-MM-DD'));
			// console.log("Single End ",picker.endDate.format('YYYY-MM-DD'));

			info.fromDate = picker.startDate.format('YYYY-MM-DD');
			info.toDate = picker.endDate.format('YYYY-MM-DD');
			console.log("empLeaveInfo  for singledate:", info);
			document.getElementById('leavetypes')?.removeAttribute("disabled")

			// handle()
			calculate()
		});

		$('#multipledate').on('apply.daterangepicker', function (ev: any, picker: any) {
			// console.log("multi target ",ev.target.value);
			// console.log("multi Start ",picker.startDate.format('YYYY-MM-DD'));
			// console.log("multi End ",picker.endDate.format('YYYY-MM-DD'));

			info.fromDate = picker.startDate.format('YYYY-MM-DD');
			info.toDate = picker.endDate.format('YYYY-MM-DD');
			console.log("empLeaveInfo for multipledate:", info);
			document.getElementById('leavetypes')?.removeAttribute("disabled")
			// handle()
			calculate()
		});

		// $('.leave-calendar').pignoseCalendar();

		this.chartOptions = {
			series: this.employeeLeaveValue,
			chart: {
				height: 310,
				type: 'donut',
			},
			dataLabels: {
				enabled: true
			},
			legend: {
				show: false,
			},
			stroke: {
				show: false,
				width: 0
			},
			plotOptions: {
				pie: {
					donut: {
						size: '100%',
						background: 'transparent',
						labels: {
							show: true,
							name: {
								show: true,
								fontSize: '29px',
								color: '#6c6f9a',
								offsetY: -10
							},
							value: {
								show: true,
								fontSize: '26px',
								color: undefined,
								offsetY: 16,
							},
							total: {
								show: true,
								showAlways: false,
								label: 'Total Leaves',
								fontSize: '22px',
								fontWeight: 600,
								color: '#373d3f',
								// formatter: function (w) {
								//   return w.globals.seriesTotals.reduce((a, b) => {
								// 	return a + b
								//   }, 0)
								// }
							}

						}
					}
				}
			},
			responsive: [{
				breakpoint: 480,
				options: {
					legend: {
						show: true,
						position: "bottom"
					}
				}
			}],
			labels: this.employeeLeaveName,
		};
	}
	calculateLeave() {
		this.totalLeaveCount = 0;
		const start = new Date(this.empLeaveInfo.fromDate);
		const end = new Date(this.empLeaveInfo.toDate);
		for (let day = start; day <= end; day.setDate(day.getDate() + 1)) {
			console.log(day);
			++this.totalLeaveCount
		}
	}

	onSubmit() {
		const empname = this.query;
		let empdetailobject = this.employeeMasterLucene.map((emp, i) => {
			if (emp.name.trim() === this.query.trim()) {
				return emp;
			}
		})
		empdetailobject.forEach(e => {
			if (e && e !== undefined) {
				this.uniqueEmployeeId = e.uniqueemployeeid;
			}
		})
		// this.uniqueEmployeeId = empdetailobject[0].uniqueemployeeid  ;


		this.empLeaveInfo.uniqueEmployeeId = this.uniqueEmployeeId;
		let today = new Date();
		this.empLeaveInfo.appliedDate = today.toISOString().substring(0, 10);
		// console.log("Submit Data ",this.empLeaveInfo);
		if (this.empLeaveInfo.halfFullIndic == "") {
			alert("Please Leave Date Type field..")
			return;
		}
		if (this.empLeaveInfo.fromDate == "" || this.empLeaveInfo.toDate == "") {
			alert("Please Select Leave Date")
			return;
		}
		if (this.empLeaveInfo.leaveType == "") {
			alert("Please Select Leave Type")
			return;
		}
		if (this.empLeaveInfo.reason == "") {
			alert("Please Select Reason")
			return;
		}
		this.empLeaveInfo.reason = this.empLeaveInfo.reason.trim();
		this.empLeaveService.saveEmployeeLeave(this.empLeaveInfo).subscribe(
			data => {
				this.getEmployeeLeaveAppliedSummary();
				this.onClear()
				this.alertmessages("Successfully Submitted", "success")
			},
			error => {
				const response = error.error;

				if (response.errorMessages != null && response.errorMessages.length !== 0) {
					let err: string = response.errorMessages[0]['key'];
					if (err.toLocaleLowerCase() === "AlreadyExistsException".toLocaleLowerCase()) {
						this.alertmessages("A leave request has already been made for the selected date.", "danger")
					} else {
						this.alertmessages("Can't Apply for Leave", "danger")
					}
				} else {
					this.alertmessages("Can't Apply for Leave", "danger")
				}
				this.onClear()
			}
		)
	}

	getLeaves(id:any) {
		this.empLeaveService.getAllLeavesById(id).subscribe(
			data => {
				this.leaveTypesList = data.leaveMasters
				this.setLeaveMap();
				console.log("Data Leaves ", this.leaveTypesList);
			},
			error => {
				console.log("Error ", error);
			}
		)
	}

	onChangeDays(day: any) {
		this.empLeaveInfo.halfFullIndic = day
		console.log("Emp Half Full Indic ", this.empLeaveInfo);
		// this.handleOnChange();
	}

	onChangeLeaveTypes(leaveType: any) {
		console.log("Leave Type ", leaveType);
		console.log("Emp Leave Type ", this.empLeaveInfo);
		this.empLeaveInfo.leaveType = leaveType
		// this.handleOnChange();
		document.getElementById('reason')?.removeAttribute("disabled")
	}
	showTable = false
	getEmployeeLeaveAppliedSummary() {
		this.empLeaveService.getEmployeeLeaveAppliedSummary(this.uniqueEmployeeId).subscribe(
			data => {
				this.employeeLeaveSummary = data.employeeLeaveAppliedInfo
				if (this.employeeLeaveSummary.length > 0) {
					this.showTable = true
				}
				console.log("Employee Leave Summary ", this.employeeLeaveSummary);
			},
			error => {
				console.log("Error ", error);
			}
		)
	}

	setLeaveMap() {
		this.leaveTypesList.forEach(type => {
			this.leaveTypeMap.set(type.leaveType, type.leaveName)
		})
		this.getEmployeeLeaveOverview()
	}

	getLeaveName(leaveType: any) {
		return this.leaveTypeMap.get(leaveType)
	}

	onDeleteHandler(empLeaveApplied: any) {
		this.empLeaveService.deleteEmployeeLeaveApplied(empLeaveApplied).subscribe(
			data => {
				this.alertmessages(data.successMessages[0]['key'], "success")
				this.getEmployeeLeaveAppliedSummary();
				console.log("On Delete key ", data.successMessages[0]['key']);
			},
			error => {
				console.log("Error ", error);
				this.alertmessages("Not Deleted", "danger")
			}
		)
	}


	showDeleteBtn(status) {
		return (status !== 'LeaveCancelRejected') || (status !== 'LeaveCancelPending');
	}

	getFirstDayPrevMonth() {
		const date = new Date();
		const prevMonth = date.getMonth() - 1;
		const firstDay = 1;

		return new Date(date.getFullYear(), prevMonth, firstDay);
	}

	onChangeReason(reason: any) {
		console.log("REason ", reason);

		this.empLeaveInfo.reason = reason
		console.log("Emp Leaves ", this.empLeaveInfo);

	}
	// ss
	getEmployeeLeaveOverview() {
		this.empLeaveService.getEmployeeLeaveOverview(this.uniqueEmployeeId).subscribe(
			data => {
				this.employeeLeaveOverview = data.leaveTypeDtos
				this.setEmployeeLeaveOverview(this.employeeLeaveOverview)
				console.log("Emp LEave Overview", this.employeeLeaveOverview);
			},
			error => {
				console.log("Error ", error);
			}
		)
	}

	showChart = false
	setEmployeeLeaveOverview(empLeaveOverview: EmployeeLeaveOverview[]) {
		empLeaveOverview.forEach(view => {
			this.employeeLeaveName.push(this.getLeaveName(view.leaveType))
			this.employeeLeaveValue.push(view.leaveTypeCount);
			this.showChart = true
		})
		console.log("Name ", this.employeeLeaveName);
		console.log("Val ", this.employeeLeaveValue);

	}

	initDatePicker() {
		var info = this.empLeaveInfo;
		// var handle = this.handleOnChange.bind(this);
		var calculate = this.calculateLeave.bind(this);
		$('input[name="singledaterange"]').daterangepicker({
			singleDatePicker: true,
			minDate: new Date(),
			startDate: new Date(),
			endDate: new Date(),
		})
		$('input[name="daterange"]').daterangepicker({
			opens: 'left',
			minDate: new Date(),
			startDate: new Date(),
			endDate: new Date(),
		})


		$('#daterange-categories').on('change', function () {
			document.getElementById('leavetypes')?.setAttribute("disabled", "true")
			let indic = info.halfFullIndic;

			$('.leave-content').hide();

			if (indic === "half" || indic === "single") {
				$('#single').show();
				document.getElementById('singledate')?.removeAttribute("disabled")
			} else if (indic === "multiple") {

				$('#multiple').show();
				document.getElementById('multipledate')?.removeAttribute("disabled")
			}

		});

		$('#singledate').on('apply.daterangepicker', function (ev: any, picker: any) {
			// info.toDate = picker.endDate.format('YYYY-MM-DD');
			// console.log("Single Target ",ev.target.value);
			// console.log("Single Start ",picker.startDate.format('YYYY-MM-DD'));
			// console.log("Single End ",picker.endDate.format('YYYY-MM-DD'));

			info.fromDate = picker.startDate.format('YYYY-MM-DD');
			info.toDate = picker.endDate.format('YYYY-MM-DD');
			console.log("empLeaveInfo  for singledate:", info);
			document.getElementById('leavetypes')?.removeAttribute("disabled")

			// handle()
			calculate()
		});

		$('#multipledate').on('apply.daterangepicker', function (ev: any, picker: any) {
			// console.log("multi target ",ev.target.value);
			// console.log("multi Start ",picker.startDate.format('YYYY-MM-DD'));
			// console.log("multi End ",picker.endDate.format('YYYY-MM-DD'));

			info.fromDate = picker.startDate.format('YYYY-MM-DD');
			info.toDate = picker.endDate.format('YYYY-MM-DD');
			console.log("empLeaveInfo for multipledate:", info);
			document.getElementById('leavetypes')?.removeAttribute("disabled")
			// handle()
			calculate()
		});
	}

	getLeaveForPastDate() {
		this.configKey = 'leaveForPastDate';
		// For now admincofig don't have any perticular value but late wi need to add 3 types;
		// One: Curent month Start | Code: CM01
		// Two: Last Manth start   | Code: LM01
		// Three: Total 30 Days    | Code: TD30
		// Three: Freeze date from contract client feese date  | FD00
		this.empLeaveService.getLeaveForPastDate(this.configKey).subscribe({
			next: (res) => {
				console.log("Min Range", res)
				this.leaveForPastDate = res.adminConfig;

				var date = new Date();

				// From starting of current month.
				if (this.leaveForPastDate === 'CM01') {
					console.log("Inside : CM01")
					this.firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
				}
				// From starting of last month.
				else if (this.leaveForPastDate === 'LM01') {
					console.log("Inside : LM01")
					this.firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
				}
				// 30 days from current day.
				else if (this.leaveForPastDate === 'TD30') {
					console.log("Inside : TD30")
					this.firstDay = new Date(date.setDate(date.getDate() - 30));
				}
				// From Freeze Date.
				// Pending for Now Coz Freeze only for Contract Client Part Later might be there for complete application.
				else if (this.leaveForPastDate === 'FD00') {
					// this.firstDay = 
					console.log("Freeze date is not applicable for application")
				}

				$('input[name="singledaterange"]').daterangepicker({
					singleDatePicker: true,
					// Set min limit for date range picker 
					minDate: this.firstDay,
				})

				$('input[name="daterange"]').daterangepicker({
					opens: 'right',
					// Set min limit for date range picker 
					minDate: this.firstDay,
				})

			}, error: (err) => {
				console.log(err)
			}
		})
	}

	onClear() {
		this.totalLeaveCount = 0;
		this.empLeaveInfo = new EmployeeLeaveInfo(-1, -1 , "","","","","","");
		(<HTMLInputElement>document.getElementById("employeename")).value = "";
		document.getElementById('singledate')?.setAttribute("disabled", "true");
		document.getElementById('multipledate')?.setAttribute("disabled", "true");
		document.getElementById('leavetypes')?.setAttribute("disabled", "true")
		document.getElementById('reason')?.setAttribute("disabled", "true")
		this.query = null;
		this.initDatePicker()
	}

	alertmessages(message: string, sign: string): void {
		let alertDiv = document.createElement('div');
		alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
		<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
		let alertcomp = document.querySelector('.alertcomp');
		alertcomp.append(alertDiv);
		document.body.scrollTop = document.documentElement.scrollTop = 0;

		setTimeout(function () {
			alertcomp.removeChild(alertDiv);
		}, 5000);
	}
	getEmployeeByNameOrId() {
		this.query = this.query.trim();
		console.log("Len ", this.query);
		if (this.query.length <= 2) {
			this.employeeMasterLucene = []
		}
		if (this.query.length >= 3) {

			this.empLeaveService.getEmployeeByNameOrUniqueId(this.query).subscribe(
				data => {
					console.log("Emp Data ", data);
					this.employeeMasterLucene = data.employeeMasterData
					let reducedobj =this.employeeMasterLucene.reduce((prev,curr,i)=>{
						console.log(prev?.name === this.query , prev, curr)
						if(prev?.name===this.query){
							return prev;
						}
					},)
					console.log("check data and pass id ... " , reducedobj.uniqueemployeeid)
					this.getLeaves(reducedobj.uniqueemployeeid);

					// if(this.employeeMaster.length > 0){
					// 	const autoCompleteId = document.getElementById("employeeMaster");
					// 	autoCompleteId.classList.remove("auto-complete-active");
					// }
				},
				error => {
					console.log("Error ", error);

				}
			)
		}
	}
	// isproxy:boolean
	// getisproxy(e){
	// 	let valueforproxy= e.target.value;
	// 	console.log(valueforproxy,"valueforproxy")
	// 	if(valueforproxy === "1"){
	// 		console.log("for employee")
	// 		this.isproxy = true;

	// 	}else if(valueforproxy ==="0"){
	// 		console.log("for self")
	// 		this.isproxy = false;
	// 	}

	// }

}
