import { Component, OnInit } from '@angular/core';
import { SessionService } from '../services';
import { EmployeeattendenceserviceService } from '../services/employeeattendenceservice.service';
import { EmployeeLeaveCompOff } from '../models/employeeLeaveCompOff';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { CommonService } from '../services/common.service';

declare var $: any;

@Component({
  selector: 'app-comp-off',
  templateUrl: './comp-off.component.html',
  styleUrls: ['./comp-off.component.css']
})
export class CompOffComponent implements OnInit {

  uniqueEmpId = Number(sessionStorage.getItem('empId'));
  date: any;
  totalHour: any;
  inTime: any;
  outTime: any;
  reason: any;
  selectedDay: any;
  empLeaveInfo = new EmployeeLeaveCompOff(-1, -1, "", "", "", "", "", "", "", "", "");
  deleteEmployeeLeave: EmployeeLeaveCompOff;
  compOffList: any[] = [];

  constructor(private commonService: CommonService, private empLeaveService: EmployeeLeaveService, private sessionService: SessionService, private empAttendenceService: EmployeeattendenceserviceService) { }

  ngOnInit(): void {
    this.getCompoffLeaveApplied();
  }

  dateFocus() {
    this.checkTotalHourWorked();
  }

  checkTotalHourWorked() {
    console.log(" check total hour worked for date :", this.date);
    this.totalHour = undefined;
    this.inTime = undefined;
    this.outTime = undefined;
    console.log(" brfore ");
    console.log("totalHour :", this.totalHour, " inTime :", this.inTime, " outTime :", this.outTime);

    this.empAttendenceService.getEmpAttendenceSummary(this.uniqueEmpId, this.date, this.date).subscribe(
      data => {
        if (data.attendanceSummary.length !== 0) {
          this.inTime = data.attendanceSummary[0].firstIN;
          this.outTime = data.attendanceSummary[0].lastOUT;
          this.totalHour = data.attendanceSummary[0].totalSwipeHour;
          console.log("after api call  ");
          console.log("totalHour :", this.totalHour, " inTime :", this.inTime, " outTime :", this.outTime);
          this.totalHour = this.totalHour.substring(0, 5);
          this.totalHour = this.totalHour + ' Hr';
          console.log("this total hour slice ed total hour  : ", this.totalHour);
        }
      },
      error => {
        console.log(error);
      }
    )


  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
		<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 5000);
  }

  clear() {
    this.empLeaveInfo = new EmployeeLeaveCompOff(-1, -1, "", "", "", "", "", "", "", "", "");
    this.date = undefined;
    this.reason = undefined;
    this.selectedDay = undefined;
    this.totalHour = undefined;
    this.inTime = undefined;
    this.outTime = undefined;
  }

  validate(): boolean {
    if (this.date != undefined && this.reason != undefined && this.selectedDay != undefined &&
      this.totalHour != undefined && this.selectedDay != "" && this.inTime != undefined && this.outTime != undefined) {
      return true;
    } else if ((this.inTime != undefined && this.outTime == undefined) || (this.inTime == undefined && this.outTime != undefined)) {
      this.alertmessages("Please regularise to apply for comp off", "danger")
      return false;
    } else {
      this.alertmessages(" Can't apply comp off,please check all the required fields.", "danger")
      return false;
    }
  }

  submit() {

    if (this.validate()) {
      console.log("submit ");

      this.empLeaveInfo.uniqueEmployeeId = this.uniqueEmpId;
      this.empLeaveInfo.fromDate = this.date;
      this.empLeaveInfo.toDate = this.date;
      let today = new Date();
      this.empLeaveInfo.appliedDate = today.toISOString().substring(0, 10);
      this.empLeaveInfo.halfFullIndic = this.selectedDay;
      this.empLeaveInfo.reason = this.reason.trim();
      this.empLeaveInfo.totalTime = this.totalHour;
      this.empLeaveInfo.inTime = this.inTime;
      this.empLeaveInfo.outTime = this.outTime;

      console.log(" ");
      console.log("employeeLeaveInfo ", this.empLeaveInfo);

      this.empLeaveService.saveCompOffLeave(this.empLeaveInfo).subscribe(
        data => {

          this.alertmessages("Successfully Submitted", "success")
          this.clear();
          this.getCompoffLeaveApplied();
        },
        error => {
          const response = error.error;
          if (response.errorMessages != null && response.errorMessages.length !== 0) {
            let err: string = response.errorMessages[0]['key'];
            if (err.toLocaleLowerCase() === "AlreadyExistsException".toLocaleLowerCase()) {
              this.alertmessages("A leave request has already been made for the selected date.", "danger")
            }
          }
          this.clear()
        }
      )
    }
  }

  getCompoffLeaveApplied() {
    this.commonService.getCompOffLeaveApplied(this.uniqueEmpId).subscribe(data => {
      console.log(data);
      this.compOffList = data.employeeLeaveAppliedInfo;
    });
  }

  onDeleteHandler(info: any) {
    console.log("delete : ", info);
    this.deleteEmployeeLeave = info;
    $('#showAlertVer').modal('toggle');
  }

  confirmLeaveDelete() {
    if (this.deleteEmployeeLeave.employeeLeaveAppliedId == -1) {
      this.alertmessages("Try again", "danger")
      return;
    }
    this.empLeaveService.deleteEmployeeCompOffApplied(this.deleteEmployeeLeave).subscribe({
      next: (data) => {
        this.alertmessages(data.successMessages[0]['key'], "success")
        this.getCompoffLeaveApplied();
      },
      error: (error) => {
        console.log("Error ", error);
        this.alertmessages("Not Deleted", "danger")
      },
      complete: () => {
        this.closeMRQModal();
      }
    })
  }

  closeMRQModal() {
    $('#showAlertVer').modal('toggle');
  }

}
