import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { employehrInfo } from '../models/employehrInfo';
import { AlertService } from '../services';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-employeehr',
  templateUrl: './employeehr.component.html',
  styleUrls: ['./employeehr.component.css']
})
 

export class EmployeehrComponent implements OnInit {
  // customerroledet:any;

  list:employehrInfo[];
  constructor(private router: Router,private commonservice: CommonService, private alertmessage: AlertService) { }

  ngOnInit(): void {
    this.getemployelist();
  }

   
  getemployelist() {
    
    this.commonservice.getemployeehr().subscribe(
      data=>{
        console.log(data);
       
          this.list= data.customerroledet;
          console.log("list"+this.list);
        // this.customerdetail = data;
      },
      error=>{
        console.log(error);
      }
    );
  }
}
