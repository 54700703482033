import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {
  protected basePath = '/api';
  public defaultHeaders: Headers = new Headers();
  public url;

  constructor(private httpclient: HttpClient, private commonService: CommonService) { }


  public getAttendenceReport(reportType: string, fromDate: string, toDate: string, currentDate: string, id: number, shift_setting: string, branch: number): Observable<any> {
    this.url = '/v1/getAttendanceReport';
    console.log("consolidate report")
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('fromdate', fromDate);
    queryParameters = queryParameters.set('todate', toDate);
    queryParameters = queryParameters.set('reportType', reportType);
    queryParameters = queryParameters.set('currentDate', currentDate);
    queryParameters = queryParameters.set('uempid', id);
    queryParameters = queryParameters.set('shift_setting', shift_setting);
    queryParameters = queryParameters.set('branch', branch);
    // console.log("Let's see the query parameter :",queryParameters)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }



  public getAttendenceMatrix(reportType: string, fromDate: string, toDate: string, id: number, activeExitFilters: string, shift_setting: string, branch: number, listingFilters: string, limitPara: number, offsetPara: number): Observable<any> {
    this.url = '/v1/getAttendanceMatrixReport';
    const path = this.basePath + this.url;
    console.log(shift_setting)
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('fromdate', fromDate);
    queryParameters = queryParameters.set('todate', toDate);
    queryParameters = queryParameters.set('reportType', reportType);
    queryParameters = queryParameters.set('uempid', id);
    queryParameters = queryParameters.set('activeExitFilters', activeExitFilters);
    queryParameters = queryParameters.set('shift_setting', shift_setting);
    queryParameters = queryParameters.set('branch', branch);
    queryParameters = queryParameters.set('listingFilters', listingFilters);
    queryParameters = queryParameters.set('limitPara', limitPara);
    queryParameters = queryParameters.set('offsetPara', offsetPara);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }


  // no where we use this ,dont know why its here , may be for back up .. 
  public getAttendenceReportDetailed(reportType: string, fromDate: string, toDate: string, currentDate: string, id: number, shift_setting: string, branch: number): Observable<any> {
    this.url = '/v1/getAttendanceReport';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('fromdate', fromDate);
    queryParameters = queryParameters.set('todate', toDate);
    queryParameters = queryParameters.set('reportType', reportType);
    queryParameters = queryParameters.set('currentDate', currentDate);
    queryParameters = queryParameters.set('uempid', id);
    queryParameters = queryParameters.set('shift_setting', shift_setting);
    queryParameters = queryParameters.set('branch', branch);
    // console.log("Let's see the query parameter :",queryParameters)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }
}
