import { Component, OnInit } from '@angular/core';
import { Approvalworkflowservice } from 'src/app/services/approvalworkflow.service';
import { AlertService } from 'src/app/services';

import { ActivatedRoute, Router } from '@angular/router';
import { DocumentInfo } from '../models/documentInfo';
import { Roles } from 'src/app/models/Roles';
import { approvalworkflow } from 'src/app/models/approvalworkflow';
import { mandatory } from 'src/app/models/mandatory';

declare var $: any;
@Component({
  selector: 'app-approveworkflow',
  templateUrl: './approveworkflow.component.html',
  styleUrls: ['./approveworkflow.component.css']
})
export class ApproveworkflowComponent implements OnInit {
  submitted: boolean;
  edited: boolean;
  docInfoArray: DocumentInfo[];
  rolesArray: Roles[];
  approvalworkflowInfo: approvalworkflow;
  approvalworkflowInfoArray: approvalworkflow[];
  number: any;

  constructor(private approvalworkflowService: Approvalworkflowservice, private alertservice: AlertService,
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    
    this.docInfoArray = new Array<DocumentInfo>();
    this.approvalworkflowInfo = new approvalworkflow();
    this.getdocs();
    this.getRoles();
    this.getApprovalWorkflows();

    if ($(".selectpicker").length != 0) {
      $(".selectpicker").selectpicker();
    }
    this.approvalworkflowInfo.mandatory = 'Yes';
    (<HTMLInputElement>document.getElementById('cname')).focus();


  }

  customCheck(obj, type)
  {
    if ( type === 'frmapproval' || type === 'frmdocGroup' ||  type === 'frmstage') 
    {
      return false;
    }
   return  true;
  }

  getdocs() {
    this.approvalworkflowService.getdocs().subscribe(
      data => {

        this.docInfoArray = data.masterDocuments;

      },
      error => {

        const response = error.error;
        if (response.errorMessages != null && response.errorMessages.length !== 0) {
          this.alertservice.error(response.errorMessages[0]['key']);
        }
      }
    );
  }

  getRoles() {
    this.approvalworkflowService.getRoles().subscribe(
      data => {

        this.rolesArray = data.rolesArray;

      },
      error => {

        const response = error.error;
        if (response.errorMessages != null && response.errorMessages.length !== 0) {
          this.alertservice.error(response.errorMessages[0]['key']);
        }
      }
    );
  }

  submit(valid: boolean) {
    this.submitted = true;
    if (!valid) {
      return;
    }
    if (this.edited) {
      this.edited = false;

      this.approvalworkflowService.updateApprovalWorkflow(this.approvalworkflowInfo).subscribe(
        data => {

          if (data.successMessages != null && data.successMessages.length !== 0) {
            this.alertservice.success(data.successMessages[0]['key']);
            this.approvalworkflowInfo = new approvalworkflow();
            this.submitted = false;
            this.clear();
          }
        },
        error => {
          const response = error.error;
          if (response.errorMessages != null && response.errorMessages.length !== 0) {
            this.alertservice.error(response.errorMessages[0]['key']);
          }
        });
    }

    else {
      this.approvalworkflowService.saveApprovalWorkflow(this.approvalworkflowInfo).subscribe(
        data => {

          if (data.successMessages != null && data.successMessages.length !== 0) {
            this.alertservice.success(data.successMessages[0]['key']);
            this.approvalworkflowInfo = new approvalworkflow();
            this.submitted = false;
            this.clear();
          }
        },
        error => {
          const response = error.error;
          if (response.errorMessages != null && response.errorMessages.length !== 0) {
            this.alertservice.error(response.errorMessages[0]['key']);
          }
        });
    }
  }

  getApprovalWorkflows() {
    this.edited = false;
    this.approvalworkflowInfo.docGroup=this.approvalworkflowInfo.docGroup.trim();
    this.approvalworkflowService.getApprovalWorkflows(this.approvalworkflowInfo.docGroup).subscribe(
      data => {

        this.approvalworkflowInfoArray = data.approvalWorkFlows;

      },
      error => {

        const response = error.error;
        if (response.errorMessages != null && response.errorMessages.length !== 0) {
          this.alertservice.error(response.errorMessages[0]['key']);
        }
      }
    );
  }

  clear() {
    this.edited = false;
    this.approvalworkflowInfoArray = [];
    (<HTMLInputElement>document.getElementById('stage')).value = '';
    (<HTMLInputElement>document.getElementById('cname')).value = '';
    (<HTMLInputElement>document.getElementById('approverrole')).value = '';
    this.approvalworkflowInfo = new approvalworkflow();
    this.approvalworkflowInfo.mandatory = 'Yes';
    setTimeout(() => {
      $(".selectpicker").selectpicker('refresh');
    }, 300);

  }

  close() {
    const modal = document.getElementById('showAlert');
    modal.style.display = 'none';
  }

  approvalDelete(number: any) {
    this.edited = false;
    this.number = number;
    const modal = document.getElementById('showAlert');
    modal.style.display = 'block';
  }
  confirmapproveDelete() {
    this.close();
    this.approvalworkflowService.deleteApprovalWorkflow(this.number).subscribe(
      data => {
        //this.getApprovalWorkflows();
        this.alertservice.success(data.successMessages[0]['key']);
        this.clear();

      },
      error => {
        const response = error.error;
      }
    );
  }

  approvalEdit(number: any) {
    this.edited = true;
    this.number = number;
    if (this.number != null) {
      const nm = this.number;
      const j: number = this.approvalworkflowInfoArray.findIndex(c => c.stage && c.stage
        === nm);

      if (j !== -1) {
        this.approvalworkflowInfo.id = this.approvalworkflowInfoArray[j].id;
        this.approvalworkflowInfo.roleToApprove = this.approvalworkflowInfoArray[j].roleToApprove;
        this.approvalworkflowInfo.stage = this.number;
        this.approvalworkflowInfo.mandatory = this.approvalworkflowInfoArray[j].mandatory;
        setTimeout(() => {
          $(".selectpicker").selectpicker('refresh');
        }, 300);

      }
    }

  }

}
