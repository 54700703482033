import { ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
//import { NonLoggedinUserComponent} from './nonLoggedInUser/index';
import { CompanySelectionComponent } from "./companyselection/companyselection.component";
import { LoginComponent, SignUpComponent } from "./auth/index";
import { LoggedInGuard } from "./logged-in.guard";
//import { AlertsComponent } from './alerts/alerts.component';
import { PeopleComponent } from "./people/people.component";
import { ChangepwdComponent } from "./changepwd/changepwd.component";
//Added
import { DocumentComponent } from "./document/document.component";
//import { SaveDocComponent } from './savedoc/savedoc.component';
import { SaveDocComponent } from "./document/savedoc/savedoc.component";
import { MyDocComponent } from "./document/myDocuments/mydocuments.component";
import { ApproveworkflowComponent } from "./approveworkflow/approveworkflow.component";
import { ApprovalinboxComponent } from "./approvalinbox/approvalinbox.component";
import { ListMydocComponent } from "./document/listMydocuments/listmydoc.component";
import { ReviewpayslipsComponent } from "./reviewpayslips/reviewpayslips.component";
import { EmployeemasterComponent } from "./employeemaster/employeemaster/employeemaster.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { UploadPolicyComponent } from "./uploadrulesandpolicy/uploadpolicy.component";
import { EmployeesearchComponent } from "./employeesearch/employeesearch.component";
import { EmployeeDocSearchComponent } from "./empdocsearch/employeedocsearch.component";
import { EmployeeLeaveComponent } from "./employee-leave/employee-leave.component";
import { EmployeeLeaveApprovalComponent } from "./employee-leave-approval/employee-leave-approval.component";
import { LeaveStatusReportComponent } from "./leave-status-report/leave-status-report.component";
import { EmployeeLeaveProxyComponent } from "./employee-leave-proxy/employee-leave-proxy.component";
import { EmployeeAttendenceComponent } from "./employee-attendence/employee-attendence.component";
import { BulkUploadDocComponent } from "./bulkuploaddocuments/bulkuploaddocuments.component";
import { PayslipComponent } from "./payslip/payslip.component";
import { EmployeeAttendenceSummaryComponent } from "./employee-attendence-summary/employee-attendence-summary.component";

import { EmployeehrComponent } from "./employeehr/employeehr.component";
import { UserdetailsComponent } from "./userdetails/userdetails.component";

import { AttendanceAprrovalComponent } from "./attendance-aprroval/attendance-aprroval.component";
import { DepartmentComponent } from "./department/department.component";
import { DesignationComponent } from "./designation/designation.component";
import { NoticeBoardComponent } from "./notice-board/notice-board.component";
import { CustCalenderComponent } from "./cust-calender/cust-calender.component";
import { SettingComponent } from "./setting/setting.component";
import { EmployeegroupComponent } from "./employeegroup/employeegroup.component";
import { ShiftallocationComponent } from "./shiftallocation/shiftallocation.component";
import { ShiftallocatedComponent } from "./empshiftallocated/shiftallocated.component";
import { ContractClientComponent } from "./contract-client/contract-client.component";
import { ContractClientLocationComponent } from "./contract-client/contract-client-location/contract-client-location.component";
import { ContractClientProjectComponent } from "./contract-client-project/contract-client-project.component";
import { ContractProjectDesEstComponent } from "./contract-project-des-est/contract-project-des-est.component";
import { ContractProjectDesActComponent } from "./contract-project-des-act/contract-project-des-act.component";
import { ContractClientProjectFreezeComponent } from "./contract-client-project-freeze/contract-client-project-freeze.component";
import { ContractProjectAllocationComponent } from "./contract-project-allocation/contract-project-allocation.component";
import { EmployeelistingComponent } from "./employeelisting/employeelisting.component";
import { AttendanceReportComponent } from "./attendance-report/attendance-report.component"
import { EmployeeAttendenceProxyComponent } from "./employee-attendence-proxy/employee-attendence-proxy.component";
import { BranchComponent } from "./branch/branch.component";

import { LeaveMasterComponent } from "./leave-master/leave-master.component";
import { LeaveAssignComponent } from "./leave-assign/leave-assign.component";
import { BranchMasterComponent } from './branch-master/branch-master.component';
import { ExpenseMasterComponent } from './expense-master/expense-master.component';
import { FaceRecognitionComponent } from "./face-recognition/face-recognition.component";
import { ExpenseApprovalComponent } from "./expense-approval/expense-approval.component";
import { ExpenseTypeComponent } from "./expense-type/expense-type.component";
import { FormTReportComponent } from "./form-t-report/form-t-report.component";
import { PolicySubmissionComponent } from "./policy-submission/policy-submission.component";
import { PolicyApprovalComponent } from "./policy-approval/policy-approval.component";
import { GroupMasterComponent } from "./group-master/group-master.component";
import { AnnualHolidayMasterComponent } from "./annual-holiday-master/annual-holiday-master.component";
import { ExpenseReportComponent } from "./expense-report/expense-report.component";
import { JobmasterComponent } from "./jobmaster/jobmaster.component";
import { EmployeereportComponent } from "./employeereport/employeereport.component";
import { CompOffComponent } from "./apply-comp-off/comp-off.component";
import { ApproveCompOffComponent } from "./approve-comp-off/approve-comp-off.component";
import { SalaryHeadComponent } from "./salary-master/salary-head/salary-head.component";
import { SalaryDetailComponent } from "./salary-master/salary-detail/salary-detail.component";
import { PayCalculateRulesComponent } from "./pay-calculate-rules/pay-calculate-rules.component";


const appRoutes: Routes = [
  //{path: '', component: CompanySelectionComponent},
  { path: "companyselection", component: CompanySelectionComponent },
  { path: "", component: LoginComponent },
  { path: "login", component: LoginComponent },
  { path: "signup", component: SignUpComponent },
  {
    path: "home",
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "scanner",
    loadChildren: () => import("./scanner/scanner.module").then((m) => m.ScannerModule),
  },
  //{ path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule'},
  {
    path: "dashboard", component: DashboardComponent,
    canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.custmgmt.dashboard' }
  },

  // {path: 'alerts', component: AlertsComponent },
  {
    path: "customers",
    loadChildren: () => import("./customers/customers.module").then((m) => m.CustomersModule),
    canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.orgmgmt.customer' }
  },
  {
    path: "customers/customersearch",
    loadChildren: () => import("./customers/customers.module").then((m) => m.CustomersModule), canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.orgmgmt.customersearch' }
  },
  //  {path: 'logjobrequest', loadChildren: './logjobrequest/logjobrequest.module#LogjobrequestModule'},
  //  {path: 'logjobrequest/searchjobrequest', loadChildren: './logjobrequest/logjobrequest.module#LogjobrequestModule'},
  //  {path: 'logjobrequest/searchjobrequesthistory', loadChildren: './logjobrequest/logjobrequest.module#LogjobrequestModule'},
  { path: "people", component: PeopleComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.orgmgmt.user' } },
  {
    path: "roles/roles",
    loadChildren: () => import("./roles/roles.module").then((m) => m.RolesModule),
    canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.orgmgmt.role' }
  },
  { path: 'chpwdlist', component: EmployeelistingComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.emp.bulkchpwd' } },
  { path: "chpwd", component: ChangepwdComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.emp.chngpassword' } },
  { path: "approveworkflow", component: ApproveworkflowComponent, canActivate: [LoggedInGuard], data: { accessKey: '' } },
  { path: "approvalinbox", component: ApprovalinboxComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.docmgmt.approvalInbox' } },




  // { path: 'finishedgoods', loadChildren: './finishedgoods/finishedgoods.module#FinishedgoodsModule', canActivate: [LoggedInGuard]},
  // { path: 'app-vendor-purchase-finder', loadChildren: './purchase-entry/vendor-purchase-finder/vendor-purchase-finder.module#VendorPurchaseFinderModule',},

  //Added
  { path: "document", component: DocumentComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.master.document' } },
  { path: "document/savedoc", component: SaveDocComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.master.document' } },
  { path: "document/myDocuments", component: MyDocComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.docmgmt.mydocuments' } },
  { path: "document/listMydocuments", component: ListMydocComponent, canActivate: [LoggedInGuard], data: { accessKey: '' } },
  { path: "reviewpayslips", component: ReviewpayslipsComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.paymgmt.payroll' } },
  { path: "payslip", component: PayslipComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.docmgmt.mydocuments' } },
  { path: "employeemaster", component: EmployeemasterComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.master.employee' } },
  { path: "uploadrulesandpolicy", component: UploadPolicyComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.police.uploadrulesandpolicy' } },
  { path: "employeesearch", component: EmployeesearchComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.master.employee' } },
  { path: "employeedocsearch", component: EmployeeDocSearchComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.empmgmt.employee' } },
  { path: "employeeleaveapply", component: EmployeeLeaveComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.attend.leave' } },
  { path: "employeeleaveapproval", component: EmployeeLeaveApprovalComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.attend.approve' } },
  { path: "employeeleavestatusreport", component: LeaveStatusReportComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.attend.approve' } },
  { path: "employeeleaveproxy", component: EmployeeLeaveProxyComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.attend.leave.employee' } },
  { path: "employeeattendence", component: EmployeeAttendenceComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.attendance.apply' } },
  { path: "bulkuploaddocument", component: BulkUploadDocComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.docmgmt.docmaster' } },
  { path: "employeeattendencesummary", component: EmployeeAttendenceSummaryComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.attendance.summary' } },
  { path: "attendancereport", component: AttendanceReportComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.attendance.report' } },
  { path: "employeeattendenceproxy", component: EmployeeAttendenceProxyComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.attendance.proxy' } },



  { path: "emplhr", component: EmployeehrComponent, canActivate: [LoggedInGuard], data: { accessKey: '' } },
  { path: "userdet", component: UserdetailsComponent, canActivate: [LoggedInGuard], data: { accessKey: '' } },

  { path: "employeeattendenceapproval", component: AttendanceAprrovalComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.attendance.approval' } },
  { path: "branchdetails", component: BranchComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.master.branch' } },
  { path: "departmentetails", component: DepartmentComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.master.department' } },
  { path: "designationetails", component: DesignationComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.master.designation' } },
  { path: "noticeboard", component: NoticeBoardComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.noticeboard' } },
  // { path: "designationdetails",component: DesignationComponent},
  { path: "employeegroup", component: EmployeegroupComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.empmgmt.employee.group' } },
  { path: "setting", component: SettingComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.admin.setting' } },
  { path: "group-shiftallocation", component: ShiftallocationComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.shiftallocation' } },
  { path: "empshiftallocated", component: ShiftallocatedComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.employee.shiftallocated' } },

  //contract
  { path: 'contract-client', component: ContractClientComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.contractclient' } },
  { path: 'contract-client-location', component: ContractClientLocationComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.contractclientlocation' } },
  { path: 'contract-client-project', component: ContractClientProjectComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.contractclientproject' } },
  { path: 'contract-project-des-est', component: ContractProjectDesEstComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.contractclientprojectdesest' } },
  { path: 'contract-project-des-act', component: ContractProjectDesActComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.contractclientprojectdesact' } },
  { path: 'contract-client-project-freeze', component: ContractClientProjectFreezeComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.contractclientprojectfreeze' } },
  { path: 'contract-project-allocation', component: ContractProjectAllocationComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.contractprojectallocation' } },

  { path: 'leave-master', component: LeaveMasterComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.leavemaster' } },
  { path: 'branchMaster', component: BranchMasterComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.branchmaster' } },
  { path: 'groupMaster', component: GroupMasterComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.groupmaster' } },
  { path: 'expense-master', component: ExpenseMasterComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.expense-submission' } },
  { path: 'annual-holidayMaster', component: AnnualHolidayMasterComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.annual-holidaymaster' } },

  { path: 'assignLeaveType', component: LeaveAssignComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.assignLeaveType' } },
  { path: 'faceRecognition', component: FaceRecognitionComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.face-recognition' } },
  { path: 'expense-approver', component: ExpenseApprovalComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.expense-approval' } },
  { path: 'expense-type', component: ExpenseTypeComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.expense-type' } },
  { path: 'expense-report', component: ExpenseReportComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.expense-report' } },

  { path: "formtreport", component: FormTReportComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.formt.report' } },
  { path: 'policy-submission', component: PolicySubmissionComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.policy-submission' } },
  { path: 'policy-approval', component: PolicyApprovalComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.policy-approval' } },
  { path: 'employeejoiningreport', component: EmployeereportComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.master.employee' } },

  { path: 'job-master', component: JobmasterComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.jobmaster' } },
  { path: 'apply-comp-off', component: CompOffComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.apply.comp.off' } },
  { path: 'approve-comp-off', component: ApproveCompOffComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.approve.comp.off' } },

  { path: 'salary-head-Master', component: SalaryHeadComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.salary.head' } },
  { path: 'salary-detail-Master', component: SalaryDetailComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.salary.detail' } },
  { path: 'pay-calculate-rule', component: PayCalculateRulesComponent, canActivate: [LoggedInGuard], data: { accessKey: 'ng.screen.pay.cal.rule' } },

  // otherwise redirect to home
  { path: "**", redirectTo: "/login" },
];

export const appRoutingProviders: any[] = [];

export const routing: ModuleWithProviders<any> =
  RouterModule.forRoot(appRoutes, {
    scrollPositionRestoration: 'enabled'
  });
