export class EmployeedocInfo{
    createdBy: string ;
createdDate: string
description: string;
docGroup:string; 

docId:number ; 

docType:string ;

mandatory:string ;

updatedBy:string ;

updatedDate:string ;

validityPeriod:string ;

}