import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class EmpsalaryslipService {
  protected basePath = '/api';
  public defaultHeaders: Headers = new Headers();
  public url;
  constructor(private httpclient: HttpClient, private commonService: CommonService) { }

  public generate_salary_slip(empid: any, year: any, month: any): Observable<any> {
    this.url = '/v1/generatesalaryslip';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('employeeid', empid);
    queryParameters = queryParameters.set('year', year);
    queryParameters = queryParameters.set('month', month);
    // queryParameters.set('file', imgUploadFile)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path, { responseType: 'arraybuffer' });
  }

  // get head salary master done 
  public getSalaryDetails(): Observable<any> {
    this.url = '/v1/salaryDetailMaster';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  // get detail salary master done 
  public getSalaryDetailsD(): Observable<any> {
    this.url = '/v1/salaryDetailMasterForDetail';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  // delete head
  public deleteSalaryHead(id: number): Observable<any> {
    console.log("delete id  : ", id);
    this.url = '/v1/deleteSalaryHead';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(id, queryParameters, path);
  }

  // delete detail
  public deleteSalaryDetail(detailid: number, headid: any): Observable<any> {
    console.log("delete id  : ", detailid, headid);
    this.url = '/v1/deleteSalaryDetail';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('detailid', detailid);
    queryParameters = queryParameters.set('headid', headid);
    return this.commonService.postWithHttpInfo(detailid, queryParameters, path);
  }

  // save head
  public saveSalaryHeadMaster(obj: any): Observable<any> {
    console.log("value sent ", obj)
    this.url = '/v1/savingSalaryHead';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(obj, queryParameters, path);
  }

  // save detail 
  public saveSalaryDetailMaster(salaryDetail: any): Observable<any> {
    console.log("value sent ", salaryDetail)
    this.url = '/v1/savingSalaryDetail';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(salaryDetail, queryParameters, path);
  }

  // update head
  public updateSalaryHeadMaster(obj: any): Observable<any> {
    console.log("value sent ", obj)
    this.url = '/v1/updateSalaryHead';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(obj, queryParameters, path);
  }

  // update detail 
  public updateSalaryDetailMaster(obj: any): Observable<any> {
    console.log("value sent ", obj)
    this.url = '/v1/updateSalaryDetail';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(obj, queryParameters, path);
  }

  // get paycalruless 
  public getPayCalRuleDetails(): Observable<any> {
    this.url = '/v1/rulesDetails';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  // delete detail
  public deletePayCalculateRule(ruleid: any): Observable<any> {
    console.log("delete id  : ", ruleid);
    this.url = '/v1/deletePayCalculateRule';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('ruleid', ruleid);
    return this.commonService.postWithHttpInfo(ruleid, queryParameters, path);
  }

  // save pay-cal-rule 
  public savePayCalRuleMaster(paycalruless: any): Observable<any> {
    console.log("value sent ", paycalruless)
    this.url = '/v1/savingPayCalRules';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(paycalruless, queryParameters, path);
  }

  // update pay-cal-rule 
  public updatePayCalRuleMaster(obj: any): Observable<any> {
    console.log("value sent ", obj)
    this.url = '/v1/updatePayCalRuleMaster';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(obj, queryParameters, path);
  }

  // get rule detail                                                                                  -- remove this later
  public getRuleDetails(): Observable<any> {
    this.url = '/v1/getRuleDetails';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  // save rule detail                                                                                 -- remove this later
  public saveRuleDetail(paycalruless: any): Observable<any> {
    console.log("value sent ", paycalruless)
    this.url = '/v1/savingRuleDetail';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(paycalruless, queryParameters, path);
  }

  // delete rule detail                                                                                 -- remove this later 
  public deleteRuleDetail(ruleid: any): Observable<any> {
    console.log("delete id  : ", ruleid);
    this.url = '/v1/deleteRuleDetailByid';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('ruleid', ruleid);
    return this.commonService.postWithHttpInfo(ruleid, queryParameters, path);
  }

  // update rule details                                                                                -- remove this later 
  public updateRuleDetails(obj: any): Observable<any> {
    console.log("value sent ", obj)
    this.url = '/v1/updateRuleDetail';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(obj, queryParameters, path);
  }


}
