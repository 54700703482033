
<div class="app-content main-content">
  <div class="side-app main-container">

  <div class="page-header d-xl-flex d-block">
    
   </div>

<!-- Row -->
<div class="row">
    <div class="col-xl-12 col-md-12 col-lg-12">
        <div class="card">
            <div class="card-header  border-0">
                <h4 class="card-title"> Employer HR Details </h4>
            </div>
            <div class="card-body">
               
                    <div class="col-xs-12 mobile">
                        <div *ngIf="list !== null ">
                        <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                            [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100"
                            [scrollWindow]="false" >
                    <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom" id="hr-table">
                        <thead>
                            <tr>
                                        
                                <th class="border-bottom-0 w-5 fs-6">Name</th>
                                <th class="border-bottom-0 w-5 fs-6">ModuleName</th>
                                <th class="border-bottom-0 w-5 fs-6">Role</th>
                                <th class="border-bottom-0 w-5 fs-6">FromDate</th>
                                <th class="border-bottom-0 w-5 fs-6">ToDate</th>
                                <th class="border-bottom-0 w-5 fs-6">ContactPerSon</th>
                                <th class="border-bottom-0 w-5 fs-6">Email</th>
                                <th class="border-bottom-0 w-5 fs-6">PhoneNumber</th>
                                 
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr  *ngFor="let item of list">

                                        
                                <td data-th>{{item.customerName }}</td>
                                <td data-th>{{item.moduleName}}</td>
                                <td data-th >{{item.role}}</td>
                                <td data-th>{{item.fromDate}}</td>
                                <td data-th>{{item.toDate}}</td>
                                <td data-th>{{item.personname}}</td>
                                <td data-th>{{item.email}}</td>
                                <td data-th>{{item.phonenumber}}</td>
                                                                      
                               
                            </tr>
                        </tbody>
                    </table>
                    </div>
                  </div>  
                </div>
            </div>
        </div>
    </div>
</div>


</div>
</div>