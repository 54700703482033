
export class DocumentInfo{



docGroup: String;

docType: String;

mandatory: String;
description: String;

sortOrder:number;
validityPeriod: String;

docId:number;



}