<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-titleMod">Expense Report</div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form id="fcForm" class="form-horizontal form-label-left">
                            <div class="x_content row px-4">
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Expense From Date: </label>
                                        <div>
                                            <input class="form-control" type="date" name="fromDate" autocomplete="off"
                                                autofocus="true" [disabled]="false" [(ngModel)]="fromDate"
                                                (ngModelChange)="checkFromAndToDate()" required>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Expense To Date: </label>
                                        <div>
                                            <input class="form-control" type="date" name="toDate" autocomplete="off"
                                                autofocus="true" [disabled]="false" [(ngModel)]="toDate"
                                                (ngModelChange)="checkFromAndToDate()" required>
                                            <p *ngIf="endDate" class="help-block " style="color: #ff5757;">
                                                ⓘ End date should be greater than start date
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Select Designation</label>
                                        <div>
                                            <div>
                                                <select id="leaveStatusFilters" class="form-control" id="designation"
                                                    name="designation" [(ngModel)]="selecteddesignation">
                                                    <option value="all" selected="selected">All</option>
                                                    <option *ngFor="let des of designation; let i = index"
                                                        [value]=des.joblevel>{{des.name}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                 <!-- branch 3  -->
                                 <div class="col-md-4 col-sm-4 col-xs-4">
                                 <label class="form-label w-100 mb-1">Branch</label>
                                 <div class="form-group m-0">
                                     <div>
                                         <select id="deptInput" class="form-control" name="branch"
                                             placeholder="Select Branch" [(ngModel)]="branch" autocomplete="off"
                                             autofocus="autofocus">

                                             <option value="0" selected="selected">All</option>
                                             <option *ngFor="let data of branchInfo" [value]=data.branchId>
                                                 {{data.branchname}}
                                             </option>
                                         </select>
                                     </div>
                                 </div>
                             </div>
                                <div class="col-md-12 col-sm-12 col-xs-12 mt-3" style="padding: 0px 0px">
                                    <form class="form-horizontal" id="fcForm">
                                        <div class="form-group row">
                                            <div class="col-md-4 col-sm-4 col-xs-4">
                                                <div class="form-group label-floating">
                                                    <label class="form-label">Select Department</label>
                                                    <div>
                                                        <select id="leaveStatusFilters" class="form-control"
                                                            name="department" id="department"
                                                            [(ngModel)]="selecteddepartment" autocomplete="off"
                                                            autofocus="autofocus">
                                                            <option value="0" selected="selected">All</option>
                                                            <option *ngFor="let dep of department; let i = index"
                                                                [value]=dep.departmentId>{{
                                                                dep.name }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <!--Search employee-->
                                            <div class="col-md-4 col-sm-4 col-xs-4 mb-4">
                                                <label class="form-label w-100 mb-1">Employee Name / ID </label>
                                                <div class="form-group m-0">
                                                    <div>
                                                        <input type="search" class="form-control " name="employeename"
                                                            id="employeename" [(ngModel)]="query" autocomplete="off"
                                                            (input)="getEmployeeByNameOrId()" list="employeeMaster"
                                                            autofocus="autofocus"
                                                            placeholder="Search Employee Name / ID">

                                                        <datalist id="employeeMaster">
                                                            <option data-value={{emp.name}}
                                                                *ngFor="let emp of employeeMaster">
                                                                {{emp.employeeid}}
                                                            </option>
                                                        </datalist>

                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <!-- Search Button -->
                                            <div class="col-md-3 col-sm-3 col-xs-3 mb-4">
                                                <label class="form-label col-md-12 col-sm-4 col-xs-4 mb-1">&nbsp;</label>
                                                <div class="form-group m-0">
                                                    <button type="button" id="navigatetarget"
                                                        class="btn btn-primary w-45" style="margin-right: 1vw"
                                                        (click)="getExpenseReport()">
                                                        Search
                                                    </button>
                                                    <button type="button" class="btn btn-danger w-45" (click)="clear()">
                                                        Clear
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>


        <div class="empty-div-one" style="height: 10px;" id="add-update-client"></div>
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header  border-0">
                        <h4 class="card-title">Expense Summary</h4>
                    </div>
                    <div class="card-body">
                        <div class="col-xs-12 mobile view">
                            <div>
                                <div class="scrollsearchresults" id="scrolllength">
                                    <table
                                        class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                        id="hr-table">
                                        <thead class="tablerow">
                                            <th class="border-bottom-0 fs-7 black-background">Employee ID</th>
                                            <th class="border-bottom-0 fs-7 black-background">Employee Name</th>
                                            <th class="border-bottom-0 fs-7 black-background">Expense ID</th>
                                            <th class="border-bottom-0 w-5 fs-7 black-background">Expense Name</th>
                                            <th class="border-bottom-0 fs-7 black-background">Expense Date</th>
                                            <th class="border-bottom-0 fs-7 black-background">Expense Type</th>
                                            <th class="border-bottom-0 fs-7 black-background">Amount Submitted</th>
                                            <th class="border-bottom-0 fs-7 black-background">Approval Amount</th>
                                            <th class="border-bottom-0 fs-7 black-background">Approver Name</th>
                                            <th class="border-bottom-0 fs-7 black-background">Approval Date</th>
                                        </thead>
                                        <tbody style="max-height: 50vh; overflow: scroll; padding-bottom: 10vh;">
                                            <tr *ngFor="let exp of expenseReport">
                                                <td>{{ exp.employeeid}}</td>
                                                <td>{{ exp.name }}</td>
                                                <td>{{ exp.id}}</td>
                                                <td>{{ exp.clientName }}</td>
                                                <td>{{ exp.dateOfExpense | date: 'MM/dd/yyyy'}}</td>
                                                <td>{{ exp.expenseName }}</td>
                                                <td>
                                                    {{ exp.amount | currency : "INR" : "symbol" : "1.2-2" }}
                                                </td>
                                                <td>
                                                    {{ exp.approvalAmount | currency : "INR" : "symbol" : "1.2-2" }}
                                                </td>
                                                <td>{{ exp.approvedBy }}</td>
                                                <td>{{ exp.timeStamp | date: 'MM/dd/yyyy'}}</td>
                                            </tr>
                                            <br><br><br>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>