import { Component, OnInit } from '@angular/core';
import { AlertService, HelperService, SessionService, ConstantService } from '../services';
import { reviewpayslipService } from 'src/app/services/reviewpayslips.service';
import { reviewPaySlips } from '../models/reviewpayslips';
import { SalaryComponentDetailsInfo } from '../models/salaryComponents';

@Component({
  selector: 'app-reviewpayslips',
  templateUrl: './reviewpayslips.component.html',
  styleUrls: ['./reviewpayslips.component.css']
})
export class ReviewpayslipsComponent implements OnInit {

  fromDate: string;
  toDate : string;
  reviewpayslips : reviewPaySlips[];
  salarycomp : SalaryComponentDetailsInfo[];
  comments : string;
  checkBoxPermission: boolean;

  constructor(private helperService: HelperService,private reviewpayslipservice:reviewpayslipService,private alertservice: AlertService,
    private sessionService: SessionService, private constantService: ConstantService) {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1; 
      this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
      const lastDay = new Date(year, month, 0).getDate();
      this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
     }

  ngOnInit(): void {
    this.toDate = this.helperService.getFinancialDate(new Date());
    this.fromDate = this.getnextMonthDate(new Date());
    this.getUserRole();
    this.comments="";
  }

  getnextMonthDate(dateIn: any) {
		let year = dateIn.getFullYear();
		let mth = dateIn.getMonth();
		let dt = dateIn.getDate();
		let month;
		let date;
		let finalDate;

		dt < 10 ? date = '0' + dt : date = dt;

		mth < 10 ? month = '0' + mth : month = mth;
		finalDate = year + '-' + month + '-' + date;
		return finalDate;
	}


  changetoDate(selectedDate: any){
    this.toDate = selectedDate.target.value;
  }

  changefromDate(selectedDate: any){
    this.fromDate = selectedDate.target.value;
  }

  closeCommetModal() {
    const modal = document.getElementById('Comment');
    const overlay = document.getElementById('overlay');
    overlay.style.display  = "none"
    modal.style.display = 'none';
  }

  openrejectionModal() {
    const modal = document.getElementById('Comment');
    const overlay = document.getElementById('overlay');
    overlay.style.display  = "block"
    modal.style.display = 'block';
  }

  confirmRejection(){
    this.reviewpayslipservice.approveRejectReviewPayslips(this.reviewpayslips,false,this.comments).subscribe(
      data => {
        
        if (data.successMessages != null && data.successMessages.length !== 0)  {
          // this.alertservice.success(data.successMessages[0]['key']);

          let alertDiv = document.createElement('div');         
				alertDiv.innerHTML = ` <div class="alert alert-success mt-4 " role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
				<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>  PaySlips Rejected Successfully.</div>`
				let alertcomp = document.querySelector('.alertcomp');
				alertcomp.append(alertDiv);
				document.body.scrollTop = document.documentElement.scrollTop = 0;
				setTimeout(function(){
					alertcomp.removeChild(alertDiv);
				}, 3000);

          this.closeCommetModal();
          this.clear();
       }
       },
      error => {
        const response = error.error;
       if (response.errorMessages != null && response.errorMessages.length !== 0) {
        //  this.alertservice.error(response.errorMessages[0]['key']);

         let alertDiv = document.createElement('div');         
          alertDiv.innerHTML = ` <div class="alert alert-danger mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
          <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>  Not Rejected.</div>`
          let alertcomp = document.querySelector('.alertcomp');
          alertcomp.append(alertDiv);
          document.body.scrollTop = document.documentElement.scrollTop = 0;
		  setTimeout(function(){
			alertcomp.removeChild(alertDiv);
		}, 3000);

         this.closeCommetModal();
      }
   
    } );
  }

  
  confirmApprove(){
    this.reviewpayslipservice.approveRejectReviewPayslips(this.reviewpayslips,true,this.comments).subscribe(
      data => {
        if (data.successMessages != null && data.successMessages.length !== 0)  {
          // this.alertservice.success(data.successMessages[0]['key']);

          let alertDiv = document.createElement('div');         
				alertDiv.innerHTML = ` <div class="alert alert-success mt-4 " role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
				<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>  PaySlips Approved Successfully.</div>`
				let alertcomp = document.querySelector('.alertcomp');
				alertcomp.append(alertDiv);
				document.body.scrollTop = document.documentElement.scrollTop = 0;
				setTimeout(function(){
					alertcomp.removeChild(alertDiv);
				}, 3000);

          this.clear();
       }
       },
      error => {
        const response = error.error;
       if (response.errorMessages != null && response.errorMessages.length !== 0) {
        //  this.alertservice.error(response.errorMessages[0]['key']);

         let alertDiv = document.createElement('div');         
          alertDiv.innerHTML = ` <div class="alert alert-danger mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
          <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>  Not Approved.</div>`
          let alertcomp = document.querySelector('.alertcomp');
          alertcomp.append(alertDiv);
          document.body.scrollTop = document.documentElement.scrollTop = 0;
		  setTimeout(function(){
			alertcomp.removeChild(alertDiv);
		}, 3000);
      }
   
    } );
  }

  clear(){
    //this.toDate = this.helperService.getFinancialDate(new Date());
    //this.fromDate = this.getnextMonthDate(new Date());
    this.comments="";
    this.reviewpayslips = new Array<reviewPaySlips>();
    this.salarycomp = new Array<SalaryComponentDetailsInfo>();
    this.searchPaySlipHistory();
  }


  getUserRole() {
    this.checkBoxPermission=this.sessionService.getRolePermissionList().includes(this.constantService.getRolePermissions()['PAYSLIPCHECKBOXPERMISSION']);

  }
  

  checkAllSelected(event : any){

    if (typeof this.reviewpayslips !== 'undefined' && this.reviewpayslips.length > 0) {
      for(let i=0; i<this.reviewpayslips.length; i++){
        console.log(event.target.checked);
        if(this.reviewpayslips[i].approved === false){
        this.reviewpayslips[i].ischeckboxselected =  event.target.checked;
        }
      }
      console.log(this.reviewpayslips);
     }
   }

  checkSelected(empInfo:reviewPaySlips,event : any){
   empInfo.ischeckboxselected = event.target.checked;
  }

  searchPaySlipHistory() {
    if ( !(this.fromDate && this.toDate)) {
      const modal = document.getElementById('searchAlert');
      modal.style.display = 'block';
      return;
    }

      this.reviewpayslipservice.getReviewPaySlips(this.fromDate,this.toDate).subscribe(
        data => {
          if (data.successMessages != null && data.successMessages.length !== 0)  {
          this.reviewpayslips = data.reviewPaySlips;
          this.salarycomp = data.salaryComponentDetails;
          console.log(data.reviewPaySlips);
          }
        },
        error => {
          const response = error.error;
         if (response.errorMessages != null && response.errorMessages.length !== 0) {
           this.alertservice.error(response.errorMessages[0]['key']);
        }
     
      } );
    }

}

