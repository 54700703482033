import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { ContractclientDocument } from '../models/ContractClientDocument';
import { ContractclientService } from '../services/contractclient.service';

declare var $: any;

@Component({
  selector: 'app-contract-project-allocation',
  templateUrl: './contract-project-allocation.component.html',
  styleUrls: ['./contract-project-allocation.component.css']
})
export class ContractProjectAllocationComponent implements OnInit {
  contractClientData: ContractclientDocument[];
  contractClientProData: ContractclientDocument[];
  contractClientProAlloData: ContractclientDocument[];
  filterContractClientProAlloData: ContractclientDocument[];
  contractClientProAllocData: ContractclientDocument;
  selectedProject: any;
  unFilteredLocation = [];
  filteredLocation = [];
  client_id: number;
  project_id: number;
  allocationfor: string = 'Actual';
  allocationforChange: string = 'Actual';
  allocationloc: any = 'All';
  projectStartDate: any;
  projectEndDate: any;
  fromDate: string;
  toDate: string;
  contractstream$!: Subscription;

  // Validation
  cliisdisable: boolean = true;
  proisdisable: boolean = true;
  submitted: boolean = false;
  startDate: boolean = false;
  endDate: boolean = false;
  startDateSmall: boolean = false;
  endDateGreat: boolean = false;
  curentDateIsLess: boolean = false;
  Difference_In_Days: any;

  @ViewChild('contractClientReportForm') contractClientReportForm: NgForm;
  constructor(private _snackBar: MatSnackBar,
    private contractclientService: ContractclientService) { }

  ngOnInit(): void {
    this.contractClientProAllocData = new ContractclientDocument;
    this.getClientData()
  }

  getClientData() {
    this.contractstream$ = this.contractclientService.getContractClient().subscribe(
      data => {
        // console.log(data)
        this.contractClientData = data.client;
        // console.log("List of all contract client in client projects >>>> ", this.contractClientData);
      },
      error => {
        // console.log(error);
      }
    );
  }

  getProData(obj) {
    // console.log(obj)

    this.contractstream$ = this.contractclientService.getContractClientProjectInfo(obj).subscribe(
      data => {
        // console.log("Respons from contract client Project List >>> ", data)
        this.contractClientProData = data.contractprojectdeta;
        this.cliisdisable = false;
        $('#allocationProject').removeClass('d-none');
      },
      error => {
        // console.log(error);
      }
    );
  }

  getProInfo(obj) {
    // console.log(obj)
    this.project_id = obj
    this.selectedProject = this.contractClientProData.filter((dName) => {
      return dName.id === obj;
    });
    this.contractClientProAllocData = this.selectedProject[0];

    
    const currentDate = new Date();
    // console.log("Curent Date : ", currentDate.toISOString().split('T')[0])

    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const startOfMonth = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    const endOfMonth = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;

    // const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    // const startOfMonthX = startOfMonth.setHours(0, 0, 0, 0);

    // const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    // const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), lastDay.getDate());
    // const endOfMonthX = endOfMonth.setHours(0, 0, 0, 0);
    console.log("Start of month", startOfMonth);
    console.log("End of month", endOfMonth)

    // Here to

    // if(this.contractClientProAllocData.end_date > startOfMonth){
    //   this.contractClientProAllocData.start_date = startOfMonth;
    // }

    // Here Optional

    this.contractClientProAllocData.start_date = startOfMonth;
    this.contractClientProAllocData.end_date = endOfMonth;
    
    this.projectStartDate = this.contractClientProAllocData.start_date;
    this.projectEndDate = this.contractClientProAllocData.end_date;

    console.log("Now lets see this.something ", this.projectEndDate)
    this.proisdisable = false;
    // this.getAllocationList();

    // console.log("Reacged halfway")
  }

  getAllocationfFor(data) {
    // console.log(data, this.allocationfor)
    this.allocationfor = data;
    // this.getAllocationList();
  }

  getAllocationList() {

    // console.log("Reached")

    let fromDate = new Date(this.projectStartDate);
    let toDate = new Date(this.projectEndDate)

    this.allocationforChange = this.allocationfor;
    if (this.allocationfor === 'Actual') {
      this.contractstream$ = this.contractclientService.getContractProjectAllocationListAct(this.project_id, this.projectStartDate, this.projectEndDate).subscribe(
        data => {
          // console.log("Respons from contract client Project List >>> ", data)
          this.unFilteredLocation = [];
          this.filteredLocation = [];
          $('#projectAlloDataList').removeClass('d-none');

          this.filterContractClientProAlloData = data.projectdesignactual;
          this.contractClientProAlloData = data.projectdesignactual;

          for (var i = 0; i < this.contractClientProAlloData.length; i++) {
            this.unFilteredLocation.push(this.contractClientProAlloData[i])
          }

          // this.unFilteredLocation.forEach((item) => {
          //   if (!this.filteredLocation.includes(item.contract_project_location_ID)) {
          //     this.filteredLocation.push(item);
          //   }
          // })

          this.filteredLocation = this.unFilteredLocation.filter(
            (obj, index) =>
              this.unFilteredLocation.findIndex((item) => item.project_location_id === obj.project_location_id) === index
          );
          this.allocationloc = 'All';
          // console.log(this.unFilteredLocation, this.contractClientProAlloData, this.filteredLocation)
        },
        error => {
          // console.log(error);
        }
      );
    } else {
      this.contractstream$ = this.contractclientService.getContractProjectAllocationListEst(this.project_id, this.projectStartDate, this.projectEndDate).subscribe(
        data => {
          // console.log("Respons from contract client Project List >>> ", data)
          this.unFilteredLocation = [];
          this.filteredLocation = [];
          $('#projectAlloDataList').removeClass('d-none');
          this.filterContractClientProAlloData = data.projectdesignest;
          this.contractClientProAlloData = data.projectdesignest;

          for (var i = 0; i < this.contractClientProAlloData.length; i++) {
            this.unFilteredLocation.push(this.contractClientProAlloData[i])
          }

          this.filteredLocation = this.unFilteredLocation.filter(
            (obj, index) =>
              this.unFilteredLocation.findIndex((item) => item.project_location_id === obj.project_location_id) === index
          );
          this.allocationloc = 'All'
          // console.log(this.unFilteredLocation, this.contractClientProAlloData, this.filteredLocation)
        },
        error => {
          // console.log(error);
        }
      );
    }
  }

  getAllocationfLoc(data) {
    this.allocationloc = data;
    // console.log(this.allocationloc)
    // console.log(this.projectStartDate)
    // console.log(this.projectEndDate)
  }

  applyFilter() {
    this.filterContractClientProAlloData = []

    if (this.allocationloc === 'All' || this.allocationloc === undefined) {
      this.filterContractClientProAlloData = this.contractClientProAlloData;
    } else {
      this.filterContractClientProAlloData = this.contractClientProAlloData.filter((dName) => {
        return dName.project_location_id === parseInt(this.allocationloc);
      });
    }
  }

  applyFilterDate() {
    // this.contractClientProAllocData = this.selectedProject[0];
    this.projectStartDate = this.contractClientProAllocData.start_date;
    this.projectEndDate = this.contractClientProAllocData.end_date;
  }

  dateChageInReport() {
    if (!this.checkFromAndToDate()) {
      // console.log("Error in date")
      return
    }
    // else {
    //   this.getAllocationList()
    // }
  }

  getReportData(valid) {
    console.log("Check if submited is true ", this.submitted)
    // || this.curentDateIsLess
    if (!valid) {
      return
    }
    // console.log("data updated")
    this.getAllocationList()
  }

  clearReportData() {
    console.log("Clear data")
  }
  ProjectReportCSVDownload() {
    // let fromDate = new Date(this.projectStartDate);
    // let toDate = new Date(this.projectEndDate)

    // console.log("Location >> ", this.allocationloc)
    // this.allocationloc = this.contractClientReportForm.form.get("allocationloc").value
    // console.log("Location >> ", this.allocationloc)

    // console.log(`Unique Emp Id: ${this.uniqueEmployeeId}, Year: ${year}, Month: ${month}`)
    this.contractclientService.downloadProjectReportCSVFile(this.project_id, this.allocationfor, this.allocationloc, this.projectStartDate, this.projectEndDate).subscribe(data => {
      // console.log(data)
      var file = new Blob([data], { type: 'text/csv' });
      var fileURL = URL.createObjectURL(file);
      var anchor = document.createElement("a");
      anchor.download = "ProjectReportdetails.csv";
      anchor.href = fileURL;
      anchor.click();
      //window.open(fileURL);
    },
      error => {
        // console.log("Error Occured ", error);
        // console.log("Error Code ",error.status);
      })
  }

  checkFromAndToDate() {
    this.startDate = false;
    this.endDate = false;
    this.endDateGreat = false;
    // this.curentDateIsLess = false;
    this.startDateSmall = false;
    // this.submitted = true;

    if (!this.contractClientProAllocData.start_date) {
      return;
    }
    if (!this.contractClientProAllocData.end_date) {
      return;
    }

    // const currentDate = new Date();
    // if(this.contractClientProAllocData.end_date > currentDate.toISOString().split('T')[0]) {
    //   console.log("Yes boi! it's bigger than this of that")
    //   // this.submitted = false;
    //   this.curentDateIsLess = true;
    //   console.log("Check if submited is true or not", this.submitted)
    // }

    // let projectStartDateUser = new Date(this.contractClientDesActForm.form.get("start_date").value)
    // let projectEndDateUser = new Date(this.contractClientDesActForm.form.get("end_date").value)

    let projectStartDateUser = new Date(this.contractClientProAllocData.start_date)
    let projectEndDateUser = new Date(this.contractClientProAllocData.end_date)

    let projectStartDate = new Date(this.projectStartDate)
    let projectEndDate = new Date(this.projectEndDate)

    if (projectEndDateUser.valueOf() > projectEndDate.valueOf()
      && projectStartDateUser.valueOf() >= projectStartDate.valueOf()) {
      this.endDate = false;
      this.submitted = false;
      this.startDateSmall = false;
      this.endDateGreat = true;

    } else if (projectStartDateUser.valueOf() < projectStartDate.valueOf()
      && projectEndDateUser.valueOf() <= projectEndDate.valueOf()) {
      this.endDate = false;
      this.submitted = false;
      this.endDateGreat = false;
      this.startDateSmall = true;

    } else if (projectStartDateUser.valueOf() >= projectEndDateUser.valueOf()) {
      // console.log("It's  not right")
      this.submitted = false;
      this.startDateSmall = false;
      this.endDateGreat = false;
      this.endDate = true;

    } else {
      console.log("It's okay")
      this.startDate = false;
      this.endDate = false;
      this.startDateSmall = false;
      this.endDateGreat = false;
      // this.submitted = true;
      return true;
    }
  }

  ngOnDestroy(): void {
    this.contractstream$.unsubscribe();
  }
}
