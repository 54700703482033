import { Pipe, PipeTransform } from '@angular/core';
import { ContractclientDocument } from '../models/ContractClientDocument';

@Pipe({
  name: 'employeeSearchAct'
})
export class EmployeeSearchActPipe implements PipeTransform {

  transform(employeelist:ContractclientDocument[], searchvalue:string, serachEmployeeBy: string): ContractclientDocument[] {
    if(!employeelist || !searchvalue){
      console.log('Non search', employeelist);
      return employeelist;
    }

    console.log('search ', employeelist,'term ',searchvalue, 'By',serachEmployeeBy);
    if(serachEmployeeBy=='name'){
      const testLeave = employeelist.filter(task=>task.name.toLowerCase().indexOf(searchvalue.toLowerCase())!=-1)
      console.log("Pipe Leave ",testLeave);
      
      return employeelist.filter(task=>task.name.toLowerCase().indexOf(searchvalue.toLowerCase())!=-1);
    } else {
      const testLeave = employeelist.filter(task=>task.employeeID.toLowerCase().indexOf(searchvalue.toLowerCase())!=-1)
      console.log("Pipe Leave ",testLeave);
      
      return employeelist.filter(task=>task.employeeID.toLowerCase().indexOf(searchvalue.toLowerCase())!=-1);
    }
  }

}
