<!-- page content -->


<div class="page  responsive-log login-bg" style="background-color: rgba(51, 102, 255, 0.3) !important;">
	<div class="page-single">
		<div class="container">
			<div class="row">
				<div class="col mx-auto">
					<div class="row justify-content-center">
						<div class="col-md-8 col-lg-6 col-xl-4 col-xxl-4">
							<div class="card my-5 box_Shadow" >
								<div class=" mx-auto my-3">
									<!-- <img src="../../../assets/img/scale up PNG (2).png" alt="" style="height: 61px; display: block;"> -->
								</div>
								<div class="px-4 pb-1 text-center">
									<p class="text-muted m-0">{{  getcurrentURL() || "Sign In to your account"}}</p>
								</div>
								<form class="card-body pt-3"  name="login"id="demo-form2" data-parsley-validate
								 #f="ngForm"
								(ngSubmit)="login(f.form.valid )">
									<p *ngIf="alertMsg" class="alert alert-danger">{{alertMsg}}</p>
									<div class="form-group" [ngClass]="{ 'has-error': submitted && !email.valid }">
									<label class="form-label" for="unq1">Username</label>
										<div class="input-group mb-4">
											<div class="input-group">
											   <a  class="input-group-text">
														<i class="fe fe-mail" aria-hidden="true"></i>
													</a>
												<input class="form-control" name="email"
												[(ngModel)]="loginData.username" #email="ngModel"
												required="required" placeholder="Username" id="unq1">
												<div *ngIf="submitted && !email.valid" class="help-block" style="width: 100%;">Please
													enter valid username.</div>
												 
												<div *ngIf="isDiscontinue" class="help-block">You are no longer a user.</div>
											</div>
										</div>
									</div>
									<div class="form-group" [ngClass]="{ 'has-error': submitted && !password.valid }">
									<label class="form-label" for="unq2">Password</label>
										<div class="input-group mb-4">
											<div class="input-group" id="Password-toggle" >
												<a  class="input-group-text">
													<i class="fe fe-eye-off displayhidepassword"  (click)="showPassword()"  aria-hidden="true"></i>
												</a>
												<input class="form-control" type="password" placeholder="Password"  name="password" id="unq2"
												[(ngModel)]="loginData.password" #password="ngModel"
												minlength="6" required="required" >
												<div *ngIf="submitted && !password.valid" class="help-block" style="width: 100%;">Password
													is invalid. Minimum length is 6 characters
												</div>
											</div>
										</div>
									</div>
									<!-- <div class="form-group">
										<label class="custom-control custom-checkbox">
											<input type="checkbox" class="custom-control-input" name="example-checkbox1" value="option1">
											<span class="custom-control-label">Remeber me</span>
										</label>
									</div> -->
									<div class="submit">
										<button class="btn btn-primary btn-block"    [disabled]="loading">Login</button>
									</div>
									<div class="text-center mt-3">
										<p class="mb-2"><a  data-bs-toggle="modal" style="cursor: pointer;" data-bs-target="#staticBackdrop" (click)="clearsubalertMsg()">Forgot Password</a></p>
										<!-- <p class="text-dark mb-0">Don't have account?<a class="text-primary ms-1"  href="javascript:void(0);">Register</a></p> -->
									</div>
								</form>
								<!-- <div class="card-body border-top-0 pb-6 pt-2">
									<div class="text-center">
										<span class="avatar brround me-3 bg-primary-transparent text-primary"><i class="ri-facebook-line"></i></span>
										<span class="avatar brround me-3 bg-primary-transparent text-primary"><i class="ri-instagram-line"></i></span>
										<span class="avatar brround me-3 bg-primary-transparent text-primary"><i class="ri-twitter-line"></i></span>
									</div>
								</div> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>



<!-- <div class="full-page login-page" style="height: 657px;">
	<div class="content" role="main" >
		<div class="container">
			<div class="row" > -->
				<!-- <div class="col-md-3"></div> -->
					<!-- <div class="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
		   
					   <div class="card card-login" style="padding: 20px;border: 1px solid #ddd;">	
						   
						   <div class="card-content">
							   <h4 class="card-title text-center"  style="margin-bottom: 50px;">Login</h4>
								<form id="demo-form2" data-parsley-validate
								   class="form-label-left" #f="ngForm"
								   (ngSubmit)="login(f.form.valid )">
		   
								   <p *ngIf="alertMsg" class="alert alert-danger">{{alertMsg}}</p>
							   
								   <div class="form-group label-floating" [ngClass]="{ 'has-error': submitted && !email.valid }">
									   <label class="control-label"
										   for="first-name">Username <span class="required">*</span>
									   </label>
									   <input type="text" class="form-control"
									   name="email"
									   [(ngModel)]="loginData.username" #email="ngModel"
									   required="required" />
										<div *ngIf="submitted && !email.valid" class="help-block">Please
											enter valid username.</div>
										 
										<div *ngIf="isDiscontinue" class="help-block">You are no longer a user.</div>
									  
									   
								   </div>
								   <div class="form-group label-floating" [ngClass]="{ 'has-error': submitted && !password.valid }">
									   <label class="control-label"
										   for="last-name">Password <span class="required">*</span>
									   </label>
									   <input type="password" class="form-control col-md-7 col-xs-12"
											   name="password"
											   [(ngModel)]="loginData.password" #password="ngModel"
											   minlength="6" required="required" />
											   <div *ngIf="submitted && !password.valid" class="help-block">Password
												   is invalid. Minimum length is 6 characters</div>
								   
								   </div>

								   <div class="text-center">
										<button [disabled]="loading" type="submit" class="btn btn-success">Sign In</button>
										<div>
											<a  data-bs-toggle="modal" data-bs-target="#pwdModal" (click)="clearsubalertMsg()">Forgot Password?</a>
										</div>
										<img *ngIf="loading" style="width:15px;height:15px;"
										src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
									</div>
										
									
			   
							   </form> 
		   
							   
						   </div>
					   </div>
				   </div> 
				
			   </div>
		   </div> 
	   </div>

	   <div id="pwdModal" class="modal fade" tabindex="-1" role="dialog" >  
		<div class="modal-dialog"> 
			<div class="modal-content">    
				<div class="modal-header">
					<button type="button" class="close" 
					data-dismiss="modal">
						<span>&times;</span>
						<span class="sr-only">Close</span>
					</button>
					<p *ngIf="subsuccessalertMsg" class="text-center alert alert-success">{{subsuccessalertMsg}}</p> 
					<p *ngIf="suberroralertMsg" class="text-center alert alert-danger">{{suberroralertMsg}}</p> 
					<h3 class="modal-title text-center" id="myModalLabel" style="cursor: pointer;">
						Forgot Password?
					</h3>
				</div>  
				<div class="modal-body">        
					<div class="col-md-12 col-xs-12">               
						<div class="panel panel-default">                   
							<div class="panel-body">                       
								<div class="text-center">                           
									<p>New password will be sent to your registered email.</p>
									<div class="panel-body">
											<div class="form-group">   
												<input class="form-control" id="rusername" placeholder="Enter your Username" autocomplete="off" name="email">
											</div>                                    
											<input class="btn btn-success"  value="Send Password "  type="reset"
												(click)="forgotpassword()"> 
												<label>&nbsp;&nbsp;</label>     
												<button class="btn btn-danger" data-dismiss="modal">Cancel</button>                      
									</div>    
								</div>                   
							</div>           
						</div>    
					</div>  
				</div>  
				<div class="modal-footer"> 
				</div>  
			</div>  
		</div>
	</div>
		
		</div> -->
		


<!-- /page content -->
<!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
	Launch static backdrop modal
  </button> -->
  
  <!-- Modal -->
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
	<div class="modal-dialog">
	  <div class="modal-content">
		<div class="modal-header">
		  <h5 class="modal-title" id="staticBackdropLabel" >Forgot Password</h5>
		  <p *ngIf="subsuccessalertMsg" class="text-center alert alert-success">{{subsuccessalertMsg}}</p> 
			<p *ngIf="suberroralertMsg" class="text-center alert alert-danger">{{suberroralertMsg}}</p> 
			<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
		</div>
		<div class="modal-body">
			<div class="col-md-12 col-xs-12">               
				<div class="panel panel-default">                   
					<div class="panel-body">                       
						<div class="text-center">                           
							<p>New password will be sent to your registered email.</p>
							<div class="panel-body">
									<div class="form-group">   
										<input class="form-control" id="rusername" placeholder="Enter your Username" autocomplete="off" name="email">
									</div>                                    
									<input class="btn btn-success"  value="Send Password "  type="reset"
										(click)="forgotpassword()"> 
										<label>&nbsp;&nbsp;</label>     
										<button class="btn btn-danger" data-bs-dismiss="modal">Cancel</button>                      
							</div>    
						</div>                   
					</div>           
				</div>    
			</div> 
		</div>
		<!-- <div class="modal-footer">
		  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
		  <button type="button" class="btn btn-primary">Understood</button>
		</div> -->
	  </div>
	</div>
  </div>