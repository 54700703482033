import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService, SessionService, ConstantService } from '../services/index';
import { NgForm } from '@angular/forms';
import { CustomersInfo } from 'src/app/models/customersInfo';
import { CustomerService } from 'src/app/services/customer.service';
import { debounceTime } from 'rxjs/operators';

@Component({
	// moduleId: module.id,
	templateUrl: './companyselection.component.html',
	styleUrls: ['./companyselection.component.css'],
	//selector: 'nonloggeduser'
	selector:'companyselection' 
})


export class CompanySelectionComponent implements OnInit {

	//static:  boolean;
	//@ViewChild('companyName', { static: true }) companyName: ElementRef;
	@ViewChild('f',{ static: true }) f: NgForm;
	apiResponse: any;
	isSearching: boolean;
	customerList: CustomersInfo[];

	submitted: boolean;
	loading = false;
	companyName: '';
	//companyName = '';
	//nonLoggedUser = { companyName: ''};
	//companyName = '';
	loader = '';

	alertMsg: string;
	isDiscontinue: boolean;

	reset: boolean;
	rusername: string;
	forgotPassword: boolean;
	suberroralertMsg: string;
	subsuccessalertMsg: string;

	constructor(private service: AppService, private sessionService: SessionService, private customerService: CustomerService,
		private router: Router, private constantService: ConstantService) {
		this.isSearching = false;
		this.apiResponse = [];

		//console.log(this.companyName);
	}


	ngOnInit() {
		console.log("companyName", this.companyName);
		this.customerList = new Array<CustomersInfo>();
		console.log("this.f:",this.f);
		
		this.f.valueChanges.pipe(
			debounceTime(500))
			.subscribe(selectedValue => {

				let strComp = selectedValue.companyName;
				console.log("form value changed....", selectedValue);
				if (strComp) {
					//console.log("form value changed....", selectedValue.companyName.length);

					if (selectedValue.companyName.length > 2) {

						//this.service.unauthorizedUser(selectedValue).subscribe(
						this.customerService.unauthorizedUser(selectedValue).subscribe(
							data => {
								
								console.log("Customers are", data.customersInfo);
								this.customerList = data.customersInfo;
								console.log("Data is", this.customerList.length);
								//this.companyName = '';
							}
						);
					}

				}

			})


		/* fromEvent(this.companyName.nativeElement, 'keyup').pipe(

			map((event: any) => {
			  return event.target.value;
			})
			, filter(res => res.length > 3)
	  
			, debounceTime(1000)
	  
			, distinctUntilChanged()
	  
		  ).subscribe((text: string) => {
	  
			this.isSearching = true;
	  
			this.searchGetCall(text).subscribe((res) => {
			  console.log('res', res);
			  this.isSearching = false;
			  this.apiResponse = res;
			}, (err) => {
			  this.isSearching = false;
			  console.log('error', err);
			});
  
		}); */
	}




	onNavigate(urlToOpen: any) {
		console.log("The URL is" + urlToOpen);
		window.open(urlToOpen, "_blank");
		this.router.navigate(['/login']);
	}


	wantToLogIn(){
		this.router.navigate(['/login']);
	}
}
