import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { EmployeeLeaveApplied } from '../models/employee-leave-applied.model';
import { EmployeeMaster } from '../models/employeeMasterts';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { ActivatedRoute } from '@angular/router';
import { EmployeeMasterservice } from '../services/employeemaster.service';

declare var $: any;

@Component({
	selector: 'app-leave-status-report',
	templateUrl: './leave-status-report.component.html',
	styleUrls: ['./leave-status-report.component.css']
})
export class LeaveStatusReportComponent implements OnInit {
	showTotalLeaveReportSection: boolean = false;
	showTotalLeaveReport = false;
	casualLeave: string = '';
	orderBy: string = 'employeeName';
	query: any="";
	fromDate: string;
	toDate: string;
	leaveStatus: string;
	limitPara: any;
	offsetPara: any;
	title: string;
	leaveTypesList = [
		{ leaveType: "", leaveName: "" }
	]
	leaveTypeMap = new Map();
	employeesLeaveSummary: EmployeeLeaveApplied[];
	employeeMaster: EmployeeMaster[];
	statusValue: string = 'All';
	types: any;
	totalAbsence: number;
	existingquery:string;
	colorCodeMap: Map<string, string> = new Map();
	constructor(private empLeaveService: EmployeeLeaveService, private route: ActivatedRoute, private render: Renderer2,
		private employeeMasterservice: EmployeeMasterservice) {
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = currentDate.getMonth() + 1;
		this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
		const lastDay = new Date(year, month, 0).getDate();
		this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
		this.title = "Leave Summary";
	}

	
	onOrderByChange() {
		this.sortTableData();
	}
	sortTableData() {
		// Assuming employeesLeaveSummary is the array you want to sort

		this.employeesLeaveSummary.sort((a, b) => {
			if (this.orderBy === 'employeeId') {
				return a.employeeId.localeCompare(b.employeeId);
			} else if (this.orderBy === 'employeeName') {
				return a.employeeName.localeCompare(b.employeeName);
			} else if (this.orderBy === 'leaveDate') {
				return new Date(a.leaveDate).getTime() - new Date(b.leaveDate).getTime();
			}
			// Default case if orderBy is not recognized
			return 0;
		});
	}
	leaveTypeCounts: { leaveName: string, count: number }[] = [];
  leaveTypes: Map<string, number> = new Map<string, number>();
  leavesummary(leavetypelist) {
    for (const type of leavetypelist) {
      console.log(leavetypelist);
      if (type.leaveStatus === 'Approved') {
        // Check if the leave type is already in the map
        if (this.leaveTypeMap.has(type.leaveName)) {
          // If the leave type is already in the map, check if it's a half-day leave
          if (type.halfFullIndic === 'H') {
            // If it's a half-day leave, add 0.5 to the existing count
            this.leaveTypeMap.set(type.leaveName, this.leaveTypeMap.get(type.leaveName) + 0.5);
          } else {
            // If it's a full-day leave, add 1 to the existing count
            this.leaveTypeMap.set(type.leaveName, this.leaveTypeMap.get(type.leaveName) + 1);
          }
        } else {
          // If the leave type is not in the map, add it with count 0.5 for half-day and 1 for full-day
          this.leaveTypeMap.set(type.leaveName, type.halfFullIndic === 'H' ? 0.5 : 1);
        }
      }
    }
    console.log(this.leaveTypeMap, "leave summary");
    this.leaveTypeCounts = Array.from(this.leaveTypeMap.entries())
      .map(([leaveName, count]) => ({ leaveName, count }));
    this.totalAbsence = this.leaveTypeCounts.reduce((total, leaveTypeCount) => total + leaveTypeCount.count, 0);
  }
  getLeaveType() {
    this.employeeMasterservice.getLeaveType().subscribe(
      data => {
        this.types = data;
        this.leaveTypeMap.clear();
        for (let type of this.types) {
          this.leaveTypeMap.set(type.leaveName, 0);
          this.colorCodeMap.set(type.leaveName, type.colorcode);
        }
        console.log("List of all the leave type available  :", this.leaveTypeMap)
      }, error => {
        console.log(error);
      }
    );
  }
	ngOnInit(): void {
		this.getLeaveType();
		
		this.employeesLeaveSummary = new Array<EmployeeLeaveApplied>();
		this.getLeaves();
		this.limitPara = 13;

		this.offsetPara = 0;
		this.sortTableData();
		(<HTMLInputElement>document.getElementById('employeename')).focus();
		// this.searchEmployeeLeave()

		this.route.queryParams.subscribe(params => {
			// this.fromDate = params.fromDate;
			// this.toDate = params.toDate;

			this.leaveStatus = params.leaveStatus;
			if (this.leaveStatus && this.leaveStatus.trim().length !== 0) {
				this.title = "Team member on leave"
				this.onLeaveStatusChange(this.leaveStatus)
				this.searchEmployeeLeave()
			}
		});
		console.log("parameter passed from dashboard to leave status");

		this.render.listen(document.getElementById('navigateclient'), 'click', () => {
			$('html,body').animate({
				scrollTop: $(`#add-update-client`).offset().top - 90
			},
				'slow');
		})

	}


	getLeaves() {
		this.empLeaveService.getAllLeaves().subscribe(
			data => {
				this.leaveTypesList = data.leaveMasters
				// this.setLeaveMap();
				console.log("Data Leaves ", this.leaveTypesList);
			},
			error => {
				console.log("Error ", error);
			}
		)
	}

	setLeaveMap() {
		this.leaveTypesList.forEach(type => {
			this.leaveTypeMap.set(type.leaveType, type.leaveName)
		})
	}

	getLeaveName(leaveType: any) {
		return this.leaveTypeMap.get(leaveType)
	}

	searchEmployeeLeave() {
		if(this.query.trim().length===0){
			this.resettypeMap();
		}else if (this.existingquery !== this.query ){
			this.resettypeMap();
		}
		
		console.log("fromDate: ", this.fromDate, "toDate: ", this.toDate, "page limit para :", this.limitPara, "page limit offset :", this.offsetPara);
		console.log(this.statusValue);
		//	this.title="Leave Summary";
		let employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
		if (!employeename) {
			employeename = null;
			//console.log("Employee Name is", employeename )
		} else {
			employeename = employeename.trim()
		}
		console.log("Employee " + employeename);
		this.empLeaveService.getEmployeesLeaveAppliedSummary(employeename, this.fromDate, this.toDate, this.statusValue, this.limitPara, this.offsetPara).subscribe(
			data => {
				// this.employeesLeaveSummary = data.employeeLeaveAppliedInfo
				console.log("Backend Leave Data ", data);
				this.scrollLength()
				// if(this.query.trim().length!==0){
				// 	console.log("called")
				// 	if(!this.existingquery){
				// 		this.leavesummary(data.employeeLeaveAppliedInfo);
					
				// 	}else if (this.existingquery !== employeename ){
				// 		this.leavesummary(data.employeeLeaveAppliedInfo);
				// 	}
				// }
				if (this.offsetPara == 0) {
					if(this.query.trim().length!==0){
						this.resettypeMap();
						this.leavesummary(data.employeeLeaveAppliedInfo);
					}
					this.employeesLeaveSummary = data.employeeLeaveAppliedInfo;


				} else {
					if(this.query.trim().length!==0){
						this.leavesummary(data.employeeLeaveAppliedInfo);
					}
					this.employeesLeaveSummary = this.employeesLeaveSummary.concat(data.employeeLeaveAppliedInfo);
				}

				console.log("Employee Leave Summary ", this.employeesLeaveSummary);
				this.sortTableData();
				this.showTotalLeaveReport = this.query.trim() !== '';
				this.existingquery = employeename;
			},
			error => {
				console.log("Error ", error);
				(<HTMLInputElement>document.getElementById('employeename')).value = "";
			}
		)
	}

	scrollLength() {
		console.log("dynamic height on scroll ");
		console.log("");
		const regex = /^(\d+(?:\.\d+)?)\s*([a-zA-Z%]+)$/;
		const height = getComputedStyle(document.getElementById("scrolllength")).getPropertyValue("max-height")
		const computedHeight = height.match(regex);
		console.log("computed height", computedHeight, "computed height in no ", Number(computedHeight[1]), "height ", height)
		if (computedHeight && Number(computedHeight[1]) < 450) {
			let value: any = Number(computedHeight[1]);
			console.log("default value = ", value)
			value = value + (2 * 55);
			value = value + "px";
			console.log("final vl:", value)
			console.log("transition")
			document.getElementById("scrolllength").style.maxHeight = value
		}
		console.log("");
	}

	onLeaveStatusChange(selectedValue: string) {
		this.statusValue = selectedValue;
		console.log("Value", this.statusValue)
	}


	getEmployeeByNameOrId() {

		this.query = this.query.trim();

		console.log("Len ", this.query);
		if (this.query.length <= 2) {
			this.employeeMaster = []
		}
		if (this.query.length >= 3) {

			this.empLeaveService.getEmployeeByNameOrUniqueId(this.query).subscribe(
				data => {
					console.log("Emp Data ", data);
					this.employeeMaster = data.employeeMasterData

					// if(this.employeeMaster.length > 0){
					// 	const autoCompleteId = document.getElementById("employeeMaster");
					// 	autoCompleteId.classList.remove("auto-complete-active");
					// }
				},
				error => {
					console.log("Error ", error);

				}
			)
		}
	}


	onScroll() {
		this.offsetPara = this.offsetPara + 13;
		console.log("limit = ? ", this.limitPara, "offset = +13 ", this.offsetPara)
		this.searchEmployeeLeave();

	}

	limitpara() {
		this.offsetPara = 0;
		console.log(" on clikc limit para ", this.limitPara)
		console.log("this offset", this.offsetPara)
	}

	@HostListener('scroll', ['$event'])
	scrolled() {

		console.log("Scrolled >>> ")
		this.onScroll();
	}


	 resettypeMap() {
		this.leaveTypeMap.forEach((val,key)=>{
			console.log(val,key);
			this.leaveTypeMap.set(key, 0);
			//val  =0 ;
			// val = 0;
		})
	
	}

}

