<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp">

        </div>
        <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-title">Apply Attendance</div>
            </div>
            <div class="page-rightheader ms-md-auto">
                <div class="align-items-end flex-wrap my-auto right-content breadcrumb-right">
                    <div class="btn-list">
                        <a [routerLink]="['/employeeattendencesummary']" class="btn btn-primary me-3">Swipe details</a>
                        <!-- <button class="btn btn-primary me-3" (click)="EnableLocation1()">Enable Location 1</button> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-4 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">

                        <div class="btn-list text-center ">
                            <div>
                                <img loading="lazy" src="./../../assets/img/clock.JPG" id="clockIN" data-clockin="1"
                                    class=" clock" alt="clockin" style=" width: 180px;">
                                <img loading="lazy" src="./../../assets/img/Clock-move-FINAL.gif" id="clockOut"
                                    data-clockout="2" style=" width: 180px;" class="clock displayNone" alt="clockout">
                            </div>
                            <div class="countdowntimer mt-0">
                                <span id="clocktimer2" class="border-0"></span>
                                <label class="form-label">Current Time</label>
                            </div>
                            <!-- (click)="save_employee_attendences($event,'IN')" -->
                            <!-- <button  class="btn ripple btn-primary "(click)="showtypes_ofbreak()">Clock in</button>
                            <button  class="btn ripple btn-primary" (click)="showtypes_ofbreak()">Clock Out</button> -->
                            <div class="btn-group  mb-2">
                                <button class="btn dropdown bgcolors " (click)="save_employee_attendences($event,'IN')">
                                    Clock in <span class="caret"></span>
                                </button>

                            </div>

                            <div class="btn-group ms-2 mb-2">

                                <button class="btn dropdown bgcolors "
                                    [ngClass]="break.length ===0 ? null : 'dropdown-toggle'"
                                    [attr.data-bs-toggle]="break.length ===0 ? null : 'dropdown'"
                                    (click)="break.length ===0 && save_employee_attendences($event,'OUT') ">
                                    Clock Out <span class="caret" *ngIf="break.length!==0"></span>
                                </button>
                                <ul class="dropdown-menu" role="menu" *ngIf="break.length!==0">
                                    <li class="dropdown-plus-title">
                                        Breaks
                                        <b class="fa fa-angle-up" aria-hidden="true"></b>
                                    </li>
                                    <li style="cursor: pointer;" *ngFor="let item of break">
                                        <a data-breakid="{{item.id}}"
                                            (click)="save_employee_attendences($event,'OUT')">{{item.breaks}}</a>
                                    </li>

                                    <!-- <li class="divider"></li>
                                    <li><a  href="javascript:void(0);">Separated link</a></li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-8 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header  border-0">
                        <h4 class="card-title">Swipe Info as on </h4>
                    </div>
                    <!-- <div class="card-body">
                        <div class="row">
                        </div>
                    </div> -->

                    <div class="card-body">
                        <div class="table-responsive" style="height: 258px;">
                            <table class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                id="emp-attendance">
                                <thead class="tablerow">
                                    <tr class="rowcolors">
                                        <!-- <th class="border-bottom-0">Date</th> -->
                                        <!-- <th class="border-bottom-0">Status</th> -->
                                        <th class="border-bottom-0">Sl No</th>
                                        <th class="border-bottom-0">Clock IN/OUT</th>
                                        <th class="border-bottom-0">Time</th>
                                        <!-- <th class="border-bottom-0">Progress</th> -->
                                        <!-- <th class="border-bottom-0">Hours</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let swipe of emp_attendence_list;let i=index">
                                        <!-- <td>{{swipe}}</td> -->
                                        <!-- <td><span class="badge badge-success">Present</span></td> -->
                                        <td>{{i+1}}</td>
                                        <td>{{ swipe.swipetype === swipeintype ? "IN" :"OUT" }}</td>
                                        <td>{{ swipe.swipetime | date:'shortTime' }}</td>
                                        <!-- <td>
                                            <div class="progress progress-sm d-block">
                                                <div class="progress-bar bg-success w-100"></div>
                                            </div>
                                        </td> -->
                                        <!-- <td>09h :10 mins</td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="emp_attendance_intermediatelist.length !==0">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header  border-0">
                        <h4 class="card-title">Regularized Attendance Summary</h4>
                    </div>
                    <!-- <div class="card-body">
                        <div class="row">
                        </div>
                    </div> -->

                    <div class="card-body">
                        <div class="table-responsive">
                            <div class="costumscrolllength" infiniteScroll [infiniteScrollDistance]="2"
                                [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100" [scrollWindow]="false">
                                <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                    id="emp-attendance">
                                    <thead class="tablerow">
                                        <tr class="stickyatTop rowcolors">
                                            <!-- <th class="border-bottom-0">Date</th> -->
                                            <!-- <th class="border-bottom-0">Status</th> -->
                                            <th class="border-bottom-0">Sl No</th>
                                            <th class="border-bottom-0">Date</th>
                                            <th class="border-bottom-0">Clock IN/OUT</th>
                                            <th class="border-bottom-0">Time</th>
                                            <th class="border-bottom-0">Status</th>

                                            <!-- <th class="border-bottom-0">Progress</th> -->
                                            <!-- <th class="border-bottom-0">Hours</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let emp of emp_attendance_intermediatelist ; let i=index">
                                            <!-- <td>{{swipe}}</td> -->
                                            <!-- <td><span class="badge badge-success">Present</span></td> -->
                                            <td>{{i+1}}</td>
                                            <td>{{emp.stime | date:'MMMM d, y'}}</td>
                                            <td>{{ emp.swipetype === swipeintype ? "IN" :"OUT" }}</td>
                                            <td>{{ emp.swipetime | date:'shortTime' }}</td>
                                            <!-- <td>
                                                <span *ngIf="emp.status === 'PENDING'"
                                                    class="badge badge-warning">{{emp.status}}</span>
                                                <span *ngIf="emp.status === 'APPROVED'"
                                                    class="badge badge-success">{{emp.status}}</span>
                                                <span *ngIf="emp.status === 'REJECTED'"
                                                    class="badge badge-danger">{{emp.status}}</span>
                                            </td> -->
                                            <td>
                                                <span style="font-size: 13px; font-weight: 500;" [ngClass]="
                                                   emp.status === 'Pending' ? 'bg-custom-warning text-custom-dark' :
                                                   emp.status === 'Approved' ? 'bg-custom-green text-custom-dark' :
                                                   emp.status === 'Rejected' ? 'bg-custom-red text-custom-dark' :
                                                   emp.status === 'Partial Approve 1' ? 'bg-custom-orange1 text-custom-dark' :
                                                   emp.status === 'Partial Approve 2' ? 'bg-custom-orange2 text-custom-dark' :
                                                    'bg-custom-warning text-custom-dark'
                                                  " class="badge">
                                                    {{ emp.status }}
                                                </span>
                                            </td>
                                            <!-- <td>
                                            <div class="progress progress-sm d-block">
                                                <div class="progress-bar bg-success w-100"></div>
                                            </div>
                                        </td> -->
                                            <!-- <td>09h :10 mins</td> -->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Select the type of break</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Understood</button>
            </div>
        </div>
    </div>
</div>