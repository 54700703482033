import { Component, HostListener, OnInit } from '@angular/core';
import { EmployeeMasterservice } from '../services/employeemaster.service';

@Component({
  selector: 'app-employeereport',
  templateUrl: './employeereport.component.html',
  styleUrls: ['./employeereport.component.css']
})
export class EmployeereportComponent implements OnInit {
  fromdate:string;
  todate:string;
  limit:number = 10;
  offset:number=0;
  employeemasterinfos: any[]=[];
  reportType:string = "JR";
  constructor(private employeemasterservice:EmployeeMasterservice) { }

  ngOnInit(): void {

  }
  reset(){
    this.offset=0;
  }
  search(){
    
    

    if( !this.fromdate || this.fromdate.length === 0){
      this.alertmessages("From date Mandatory" , "danger")
      return;
    }
    if(!this.todate || this.todate.length === 0  ){
      this.alertmessages("To date Mandatory" , "danger")
      return;
    }

    if(!this.validateaDate(this.fromdate)){
      this.alertmessages("Date a not valid" , "danger")
      return;
    }else if(!this.validateaDate(this.todate)){
      this.alertmessages("Date is not valid" ,"danger")
      return;
    }


    let isValid:boolean = this.checkfromisGreaterThantodate(this.fromdate , this.todate);
    if(isValid){
      this.alertmessages("From date greater than To date" , "danger")
      return;
    }
    // this.getempallocatedshifts(fromdate , todate)
    // call employee joining api
    console.warn(this.fromdate , this.todate);
    if(this.reportType =="JR"){
      this.employeemasterservice.getEmployeeInfoBasedOnJoiningDate(this.fromdate ,this.todate , this.limit , 
        this.offset
      )
      .subscribe(res=>{
        console.warn(res);
        if(this.offset==0){
          this.employeemasterinfos =res.employeeMasterData;
        }else{
         this.employeemasterinfos= this.employeemasterinfos.concat(res.employeeMasterData)
        }
      })
    }else if(this.reportType =="ER"){
      this.employeemasterservice.getEmployeeInfoBasedOnExitDate(this.fromdate ,this.todate , this.limit , 
        this.offset
      )
      .subscribe(res=>{
        console.warn(res);
        if(this.offset==0){
          this.employeemasterinfos =res.employeeMasterData;
        }else{
         this.employeemasterinfos= this.employeemasterinfos.concat(res.employeeMasterData)
        }
      })
    }
    
  }
  checkfromisGreaterThantodate(from , to):boolean{
    let fromdate:Date = new Date(from);
    let todate:Date = new Date(to);

    if(fromdate.valueOf() > todate.valueOf()){
      return true 
    }
    return false;  
  }

  validateaDate(d):boolean{
    let date:Date = new Date(d);
    let lyear = 1900;
    let currentyear = date.getFullYear();
    if(currentyear > lyear){
      return true;
    }
    return false;

  }
  alertmessages(message:string , sign:string):void{
		let alertDiv = document.createElement('div');         
		 alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
		<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
		let alertcomp = document.querySelector('.alertcomp');
		alertcomp.append(alertDiv);
		document.body.scrollTop = document.documentElement.scrollTop = 0;
    
		setTimeout(()=>{
		  alertcomp.removeChild(alertDiv);
		}, 3000);
	}
  onScroll() {
    this.offset = this.offset + 10;
    this.search();
  }

  limitpara() {
    this.limit = 0;
  }

  @HostListener('scroll', ['$event'])
  scrolled() {
    this.onScroll();
    const ele  = document.getElementById('scroll')
    console.log(ele.scrollTop)
  }
  resetRecords(){
    this.employeemasterinfos = [];
  }

  downloadEmployeeReport(){
    if(this.reportType == "JR"){
      this.employeemasterservice.downloadEmployeeJoineeReport(this.fromdate , this.todate).subscribe(bytedata=>{
        var file = new Blob([bytedata], { type: 'application/vnd.ms-excel' });
      var fileURL = URL.createObjectURL(file);
      var anchor = document.createElement("a");
      anchor.download = "EmployeeJoineeReport.xls";
      anchor.href = fileURL;
      anchor.click();
      })
    }else if(this.reportType == "ER"){
      this.employeemasterservice.downloadEmployeeExitReport(this.fromdate , this.todate).subscribe(bytedata=>{
        var file = new Blob([bytedata], { type: 'application/vnd.ms-excel' });
      var fileURL = URL.createObjectURL(file);
      var anchor = document.createElement("a");
      anchor.download = "EmployeeExitReport.xls";
      anchor.href = fileURL;
      anchor.click();
      })
    }
  }
}
