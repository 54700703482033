import { Component, OnInit } from '@angular/core';
import { PolicySubmission } from '../models/PolicySubmission';
import { PolicyManagerServiceService } from '../services/policy-manager-service.service';
import { SessionService } from '../services';

@Component({
  selector: 'app-policy-approval',
  templateUrl: './policy-approval.component.html',
  styleUrls: ['./policy-approval.component.css']
})
export class PolicyApprovalComponent implements OnInit {

  constructor(private policyManagerServiceService: PolicyManagerServiceService, private session: SessionService) { }

  ngOnInit(): void {
    this.getPolicyAppList();
  }

  policySubmission: PolicySubmission = new PolicySubmission();
  id: number;
  fileName: any;
  timestamp: Date;
  amount: number;
  fileType: any;
  userId: any;
  status: any;
  attchment:any;
  fileToUpload: File | null = null;
  filename: any;
  policyList:any[] = [];
  filterTaskList: PolicySubmission[];
  selectedList: any[] = [];
  filteredPolicyList: any[] = [];
  fileSavedPath:any;
  selectAll: boolean = false;
  uniqueempid: string = this.session.getempid()
  approvalDetails:any;

  getPolicyAppList() {
      this.policyManagerServiceService.getPolicyAppList(this.uniqueempid).subscribe(
      (data) => {
        console.log(" Data available : ", data);
        this.policyList = data.policySubmission;
        this.filteredPolicyList = data.policySubmission;
        console.log(" List of all the PolicyList available : ", this.filteredPolicyList);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  approved_details(expid:any) {
    this.policyManagerServiceService.getApprovedDetails(expid).subscribe(
      data => {
        console.log("Approved Details  : ", data)
        this.approvalDetails = data.policyApprovalDetails;
      },
      error => {
        console.log(error);
      }
    );
  }

  viewdocument(path: string,fileName: string) {
    if (path === undefined) {
      path = fileName;
    }
    console.log(" ")
    console.log("view document : ", path)
    this.policyManagerServiceService.viewdocument(path).subscribe(
      (response) => {
        console.log(`Our response : ${response}`)
        if (fileName.includes('.pdf')) {
          var file = new Blob([response], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        } else if (fileName.includes('.jpg') || fileName.includes('.jpeg')) {
          var file = new Blob([response], { type: 'image/jpeg' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        } else if (fileName.toLowerCase().includes('.png')) {
          var file = new Blob([response], { type: 'image/png' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        } 
        else {
          console.log("Other types are not recommended!");
        }
      },
      (error) => { },
    )
  }

  myDocumentDownload(path: any, filename: any) {
    console.log("path ", path);
    this.fileSavedPath = path;
    this.fileName = filename;
    console.log("The File Saved Path is", this.fileSavedPath);
    console.log("The File Saved Path is", this.fileName);
    this.downloadImage();
  }

  downloadImage() {
    console.log("Download Image is called");
    this.policyManagerServiceService.downloadFile(this.fileSavedPath).subscribe(
      (data) => {
        var downloadUrl = URL.createObjectURL(data);
        var link = document.createElement("a");
        link.download = this.fileName;
        link.href = downloadUrl;
        link.click();
        link.remove();
        console.log("The Url is", downloadUrl);
      },
      (error) => {
        console.log("Error occured in download file");
      }
    );
  }

  deletePolicy(id) {
    console.log("delete expense : ", id, " ");

    this.policyManagerServiceService.deletePolicy(id).subscribe(
      (data) => {
        console.log("delete  Expense ", data);
        this.alertmessages("Successfully deleted ", "success");
        this.clear();
        this.getPolicyAppList();
      },
      (error) => {
        this.alertmessages("Expense is in Use ", "danger");
        console.log(error);
      }
    );
    this.clear();
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement("div");
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
  <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`;
    let alertcomp = document.querySelector(".alertcomp");
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

  onFileSelected(event: any) {
    this.fileToUpload = event.target.files[0];
  }

  clear() {
    this.fileName= null;
    this.fileType= null;
  }

  checkallbox(event: any): void {
      // Update the status of all checkboxes based on the Select All checkbox
      this.policyList.forEach((pol) => {
      pol.checked = event.target.checked;
      // Add or remove from selectedList based on checked status
      if (pol.checked) {
        this.addToSelectedList(pol);
      } else {
        this.removeFromSelectedList(pol);
      }
    });
    console.log("Policy list",this.policyList);
    console.log("Policy list",this.selectedList);
  }

  addToSelectedList(pol: any): void {
    // Check if the item is not already in the selectedList before adding
    if (!this.selectedList.includes(pol)) {
      this.selectedList.push(pol);
    }
  }

  removeFromSelectedList(pol: any): void {
    // Remove the item from the selectedList if it exists
    this.selectedList = this.selectedList.filter((item) => item !== pol);
  }

  check_info(pol: any, event: any): void {
    // Update the individual checkbox status
    pol.checked = event.target.checked;
    if (pol.checked) {
      this.addToSelectedList(pol);
    } else {
      this.removeFromSelectedList(pol);
    }
    console.log("Policy list", pol.checked);
    console.log("Policy list", pol);
    console.log("Policy list", this.selectedList);
  }

  approvalhandler() {
    let flag = true
		if (this.selectedList.length === 0) {
			this.alertmessages("Please select item for Approval.", "danger")

			flag = false
			return;
		}

    this.policyManagerServiceService
        .approveList(this.selectedList, this.uniqueempid)
        .subscribe(
          (res) => {
            console.log("filteredExpenseList", this.selectedList);
            this.alertmessages("Successfully Saved ", "success");
            this.selectedList =[];
            this.getPolicyAppList();
          },
          (error) => {
            console.log(error);
            this.alertmessages("Please try again later", "danger");
          }
        );
  }

  rejecthandler() {
    let flag = true
		if (this.selectedList.length === 0) {
			this.alertmessages("Please select item for Approval.", "danger")

			flag = false
			return;
		}
    this.policyManagerServiceService
        .rejectList(this.selectedList)
        .subscribe(
          (res) => {
            console.log("filteredExpenseList", this.selectedList);
            this.alertmessages("Successfully Saved ", "Success");
            this.getPolicyAppList();
            this.selectedList =[];
          },
          (error) => {
            console.log(error);
            this.alertmessages("Please try again later", "danger");
          }
        );
  }


  filterExpenseList(searchText: string) {
    console.log("filter employeename  ")

    if (this.policyList.length != 0) {

      console.log("policyList : ", this.policyList)

      this.filteredPolicyList = this.policyList.filter(pol =>
        pol.userName?.toLowerCase().includes(searchText.toLowerCase())
      );

      console.log("filteredPolicyList : ", this.filteredPolicyList)
      this.filterTaskList = this.filteredPolicyList;
      console.log("filterTaskList : ", this.filterTaskList)

      if (this.filterTaskList.length === 0) {
        this.alertmessages("No Result Found Please Try Again !  ", "danger");
      }

    } else {
      console.log("documentsToBeApproved : ")
      this.filterTaskList = this.filteredPolicyList;

      if (this.filterTaskList.length === 0) {
        this.alertmessages("No Result Found Please Try Again !  ", "danger");
      }
    }
  }
}

