import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TaskDetailsForAttendance } from '../models/TaskDetailForAttendance';

@Pipe({
  name: 'approveAttendancePipe'
})
@Injectable({
  providedIn:'root'
})

export class ApproveAttendancePipePipe implements PipeTransform {

  transform(approveAttendance:TaskDetailsForAttendance[], searchattendance:string): TaskDetailsForAttendance[] {
    if(!approveAttendance || !searchattendance){
      return approveAttendance;
    }    
    return approveAttendance.filter(task=>task.employeeName.toLowerCase().indexOf(searchattendance.toLowerCase())!=-1);
  }

}
