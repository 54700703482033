
<div class="app-content main-content">
    <div class="side-app main-container">
  
    <div class="page-header d-xl-flex d-block">
      
     </div>
  
  <!-- Row -->
  <div class="row">
      <div class="col-xl-12 col-md-12 col-lg-12">
          <div class="card">
              <div class="card-header  border-0">
                  <h4 class="card-title"> User Details </h4>
              </div>
              <div class="card-body">
                 
                      <div class="col-xs-12 mobile">
                          <div  >
                          <div class="scrollsearchresults">
                      <table class="table  table-vcenter text-nowrap table-bordered border-bottom" id="hr-table">
                          <thead>
                              <tr>
                                          
                                  <th class="border-bottom-0 w-5 fs-6">User Name</th>
                                  <th class="border-bottom-0 w-5 fs-6">Role</th>
                                  <th class="border-bottom-0 w-5 fs-6">Company Assigned</th>
                                                              
                                  
                              </tr>
                          </thead>
                          <tbody>
                             
                                <tr *ngFor="let item of list">
                                                                    
                                  <td data-th>{{item.userName}}</td>
                                  <td data-th>{{item.role}}</td>
                                  <td data-th >{{item.companyName}}</td>
                                   
                                </tr>                                           
                                 
                               
                          </tbody>
                      </table>
                      </div>
                    </div>  
                  </div>
              </div>
          </div>
      </div>
  </div>
  
  
  </div>
  </div>