import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../services/common.service';
import { AlertService } from '../services';
import { DocumentInfoService } from '../services/documentinfo.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OrgFilesInfo } from '../models/orgFilesInfo';

@Component({
  selector: 'app-uploadpolicy',
  templateUrl: './uploadpolicy.component.html',
  styleUrls: ['./uploadpolicy.component.css']
})
export class UploadPolicyComponent implements OnInit {
  username: any;
  orgFiles: OrgFilesInfo;
  uploadRulePolicy: any = {};
  fileToUpload: File | null = null;
  submitted: boolean;
  protected basePath = '/api';
  public url;
  showPolicyList: boolean;
  loggedInUserRole: any;
  listOfRuleAndPolicy = [];
  fileSavedPath: any;
  type = null;
  f_Name: any;
  policyId: any;
  index: any;

  constructor(private httpclient: HttpClient, private router: Router, private route: ActivatedRoute, private commonService: CommonService, private alertService: AlertService, private docmentInfoService: DocumentInfoService) { }
  
  ngOnInit() {
    this.orgFiles = new OrgFilesInfo();
    this.submitted = false;
    this.username = sessionStorage.getItem('username');
    this.loggedInUserRole = sessionStorage.getItem('role');
    console.log("The Logged In User Role is", this.loggedInUserRole);
    this.showPolicyList = true;

    this.getTheRulesAndPolicy();
    
  }


  save(valid) {
    this.submitted = true;
    if (!valid) {
      return;
    }

    /*
    this.docmentInfoService.uploadRulesAndPolicy(this.uploadRulePolicy.name, this.fileToUpload).subscribe(
      data => {
        const response = data;
        console.log("The Return response is", data);
        if (data != null && data != 0) {
          //this.alertService.success(data.successMessages[0]['key']);
          this.ngOnInit();
          this.submitted = false;
           this.orgFiles.fileName = "";
          this.uploadRulePolicy.file = "";
        }
      },
      error => {
        const response = error.error;
        console.log("Error is ", error);
        this.submitted = false;
        this.orgFiles.fileName = "";
        this.uploadRulePolicy.file = "";
        if (response.errorMessages != null && response.errorMessages.length !== 0) {
          this.alertService.error(response.errorMessages[0]['key']);
        }else{
          this.alertService.error("The Data is not Saved");
        }
      }

    );
    */

    this.url = '/v1/employer/uploadRulesPolicy';
    const path = this.basePath + this.url;
    const formData: FormData = new FormData();
    //var formData: any = new FormData();
    this.orgFiles.fileName = this.fileToUpload.name;
    const date = new Date(this.fileToUpload.lastModified);
    this.orgFiles.createdDate = date;
    formData.append('rulesAndPolicy', JSON.stringify(this.orgFiles));
    formData.append('file', this.fileToUpload);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    const httpOptions = { headers: headers };
    this.httpclient.post(path, formData, httpOptions).subscribe(
      data => {
        const response = data;
        console.log("The Return response is", data);
        if (data != null && data != 0) {
          //this.alertService.success(data.successMessages[0]['key']);
          // this.alertService.success("The Policy Uploaded Successfully");
          let alertDiv = document.createElement('div');         
				alertDiv.innerHTML = ` <div class="alert alert-success mt-4 " role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
				<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>  The Policy Uploaded Successfully.</div>`
				let alertcomp = document.querySelector('.alertcomp');
				alertcomp.append(alertDiv);
				document.body.scrollTop = document.documentElement.scrollTop = 0;
				setTimeout(function(){
					alertcomp.removeChild(alertDiv);
				}, 3000);
          this.ngOnInit();
          this.submitted = false;
          this.uploadRulePolicy.file = ""
        }
      },
      error => {
        const response = error.error;
        console.log("Error is ", error);
        this.submitted = false;
        this.ngOnInit();
        this.uploadRulePolicy.file = ""
        if (response.errorMessages != null && response.errorMessages.length !== 0) {
          // this.alertService.error(response.errorMessages[0]['key']);
          let alertDiv = document.createElement('div');         
          alertDiv.innerHTML = ` <div class="alert alert-danger mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
          <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${response.errorMessages[0]['key']}</div>`
          let alertcomp = document.querySelector('.alertcomp');
          alertcomp.append(alertDiv);
          document.body.scrollTop = document.documentElement.scrollTop = 0;
		  setTimeout(function(){
			alertcomp.removeChild(alertDiv);
		}, 3000);
        }else{
          // this.alertService.error("The Data is not Saved");
          let alertDiv = document.createElement('div');         
          alertDiv.innerHTML = ` <div class="alert alert-danger mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
          <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>  The Data is not Saved.</div>`
          let alertcomp = document.querySelector('.alertcomp');
          alertcomp.append(alertDiv);
          document.body.scrollTop = document.documentElement.scrollTop = 0;
		  setTimeout(function(){
			alertcomp.removeChild(alertDiv);
		}, 3000);
        }
      }

    );
  

  }

  handleFileInput(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileToUpload = file;
    }
  }


  clearSelection(){
    this.uploadRulePolicy.file = "";
    this.fileToUpload = null;
  }

  reFresh() {
    this.submitted = false;
    this.orgFiles = new OrgFilesInfo();
    this.uploadRulePolicy.file = ""
  }

  getTheRulesAndPolicy() {
    this.docmentInfoService.getRulesAndPolicy().subscribe(
        data => {
            this.listOfRuleAndPolicy = data.orgFileList;
            this.listTheRulesAndPolicy();
        },
        error => {
            console.log("The Error is ", error);
        }
    );

}

listTheRulesAndPolicy() {
  console.log("The Length of ListOfRulesAndPolicy", this.listOfRuleAndPolicy);

  if (this.listOfRuleAndPolicy.length != 0) {
    this.showPolicyList = true;
  } else {
    this.showPolicyList = false;
  }
}


policyDelete(j: any, id: any) {
  this.index = j;
  this.policyId = id;
  const modal = document.getElementById('showAlert');
  modal.style.display = 'block';

}

closeMRQModal() {
  const modal = document.getElementById('showAlert');
  modal.style.display = 'none';
}



confirmPolicyDelete() {
  this.closeMRQModal();
  console.log("Policy Id is -", this.policyId);
  
  this.docmentInfoService.deleteRulesAndPolicy(this.policyId).subscribe(
    data => {
      // this.alertService.success(data.successMessages[0]['key']);
      let alertDiv = document.createElement('div');         
      alertDiv.innerHTML = ` <div class="alert alert-success mt-4 " role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
      <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${data.successMessages[0]['key']}</div>`
      let alertcomp = document.querySelector('.alertcomp');
      alertcomp.append(alertDiv);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      setTimeout(function(){
        alertcomp.removeChild(alertDiv);
      }, 3000);
      //this.listMyDoc();
      this.getTheRulesAndPolicy();
    },
    error => {
      const response = error.error;
      console.log("Error is ", error);

      if (response.errorMessages != null && response.errorMessages.length !== 0) {
        // this.alertService.error(response.errorMessages[0]['key']);
        let alertDiv = document.createElement('div');         
        alertDiv.innerHTML = ` <div class="alert alert-danger mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
        <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${response.errorMessages[0]['key']}</div>`
        let alertcomp = document.querySelector('.alertcomp');
        alertcomp.append(alertDiv);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    setTimeout(function(){
    alertcomp.removeChild(alertDiv);
  }, 3000);
      }
    }
    
  );
}

}
