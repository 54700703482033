import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class AppService {

    public profileUrl = new BehaviorSubject<string | ArrayBuffer>(null);

    setprofile(path: string | ArrayBuffer) {
        console.log(path, "path")
        this.profileUrl.next(path)
    }

    getprofilesub() {
        if (sessionStorage.getItem('photourl')) {
            console.warn(sessionStorage.getItem('photourl'));
            let photo = JSON.parse(sessionStorage.getItem('photourl'));
            this.setprofile(photo);
        }
        return this.profileUrl.asObservable();
    }
    getprofile(): string | ArrayBuffer {
        let url;
        this.profileUrl.asObservable().subscribe(res => {
            url = res;
        });
        return url;
    }


    public url;
    protected basePath = '/api';


    constructor(
        private httpnew: HttpClient, private _router: Router, private httpclient: HttpClient) { }

    private extendObj<T1, T2>(objA: any, objB: any) {
        for (const key in objB) {
            if (objB.hasOwnProperty(key)) {
                objA[key] = objB[key];
            }
        }
        return <T1 & T2>objA;
    }


    public obtainAccessToken(loginData): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.set('username', loginData.username);
        queryParams = queryParams.set('password', loginData.password);

        this.url = '/login';

        return this.getByQueryParamWithHttpInfo(queryParams);
    }

    public getCompanyId() {
        const cId = sessionStorage.getItem('companyId');
        return cId;
    }


    signUp(user: any): Observable<any> {
        this.url = '/v1/signUp';
        let queryParameters = new HttpParams();
        return this.postWithHttpInfo(user, queryParameters);

    }

    logout() {
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('companyId');
        this._router.navigate(['/login']);
    }

    // sujata code starts to get the role of logged in user
    public getRoleOfLoggedUser(username: any): Observable<any> {
        this.url = '/v1/profile';
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('username', username);

        return this.getByQueryParamWithHttpInfo(queryParameters);
    }
    // sujata code ends to get the role of logged in user     


    public getByQueryParamWithHttpInfo(queryParameters: HttpParams, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + this.url;

        let requestOptions: any = {
            params: queryParameters
        };

        if (extraHttpRequestParams) {
            requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
        }

        return this.httpnew.get(path, requestOptions);

    }

    public postWithHttpInfo(param: any, queryParameters: HttpParams, extraHttpRequestParams?: any): Observable<any> {

        const path = this.basePath + this.url;

        // verify required parameter 'param' is not null or undefined
        if (param === null || param === undefined) {
            throw new Error('Required parameter body was null or undefined when calling tevicoPost.');
        }

        let requestOptions: any = {
            params: queryParameters
        };

        if (extraHttpRequestParams) {
            requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
        }

        return this.httpnew.post<any>(path, param, requestOptions);
    }

    public putWithHttpInfo(param: any, queryParameters: HttpParams, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + this.url;


        // verify required parameter 'param' is not null or undefined
        if (param === null || param === undefined) {
            throw new Error('Required parameter body was null or undefined when calling tevicoPost.');
        }

        let requestOptions: any = {
            params: queryParameters
        };

        if (extraHttpRequestParams) {
            requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
        }

        return this.httpnew.put<any>(path, param, requestOptions);
    }

    public putByQueryParam(queryParameters: HttpParams, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + this.url;

        let requestOptions: any = {
            params: queryParameters
        };

        if (extraHttpRequestParams) {
            requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
        }

        return this.httpnew.put<any>(path, {}, requestOptions);
    }

    public deleteWithHttpInfo(queryParameters?: HttpParams, param?: any, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + this.url;

        const requestOptions: any = {
            body: param,
            params: queryParameters
        };

        console.log('Deleting Action with new httpclient module ' + JSON.stringify(requestOptions));
        return this.httpnew.request('delete', path, requestOptions);
    }

    public deleteQueryParamWithHttpInfo(queryParameters: HttpParams, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + this.url;

        const requestOptions: any = {
            params: queryParameters
        };
        console.log('Deleting Action with new httpclient module ' + JSON.stringify(requestOptions));
        return this.httpnew.request('delete', path, requestOptions);
    }

    public emailPassword(rusername: any): Observable<any> {
        this.url = '/v1/forgotPassword';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('rusername', rusername);
        return this.getByQueryParamWithHttpInfo(queryParameters, path);
    }



    public unauthorizedUser(searchCompany: any): Observable<any> {
        this.url = '/v1/customerSearch/unauthorizedUser';
        console.log("The Company Name for Search is", searchCompany);
        const path = this.basePath + this.url;
        //let queryParameters = new HttpParams();
        //queryParameters = queryParameters.set('customerName', searchCompany );

        let queryParams = new HttpParams();
        queryParams = queryParams.set('customerName', searchCompany);
        //let limitPara = 0;
        //queryParameters = queryParameters.set('limitPara', limitPara);
        //queryParameters = queryParameters.set('offsetPara', offsetPara);
        return this.getByQueryParamWithHttpInfo(queryParams, path);


    }

    public viewProfilePic(filePath: string): Observable<any> {
        this.url = '/v1/displayProfilePic';
        const path = this.basePath + this.url;
        console.log("File Path ", filePath);
        const param = new HttpParams().set('filePath', filePath);
        const options = {
            params: param
        };
        return this.httpclient.get(path, { ...options, responseType: 'blob' });
    }
}
