export class Faceregister{
    image1:String;
	image2:String;
	image3:String;
	empid:any;
	 
	date:Date;
	imageName: string;
	imagePath: string;
	
	constructor(imageName: string, imagePath: string) {
		this.imageName = imageName;
		this.imagePath = imagePath;
	  }
}