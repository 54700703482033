import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
    selector: 'formly-wrapper-panel',
    template: `
    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
    <div class="panel panel-default">
        <div class="panel-heading" role="tab" id="headingOne">
            <h3 class="panel-title">
                <a role="button" data-toggle="collapse" data-parent="#accordion" href="#{{'collapseOne'+ to.id}}" aria-expanded="true" aria-controls="collapseOne">
                <i class="zmdi zmdi-triangle-down"></i>
                {{ to.label }}
                </a>
            </h3>
        </div>
        <div id="{{'collapseOne'+ to.id}}" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
        <div class="card-body">
            <ng-container #fieldComponent></ng-container>
        </div>
    </div>
</div>
       `,
})
export class PanelWrapperComponent extends FieldWrapper {
}

