import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TaskDetailsForLeave } from '../models/task-details-for-leave.model';

@Pipe({
  name: 'approveLeave'
})
@Injectable({
  providedIn:'root'
})
export class ApproveLeavePipe implements PipeTransform {

  transform(approvalLeave:TaskDetailsForLeave[], searchleave:string): TaskDetailsForLeave[] {
    if(!approvalLeave || !searchleave){
      // console.log('Non search', approvalLeave);
      return approvalLeave;
    }

    // console.log('search ', approvalLeave,'term ',searchleave);
    const testLeave = approvalLeave.filter(task=>task.employeeName.toLowerCase().indexOf(searchleave.toLowerCase())!=-1)
    // console.log("Pipe Leave ",testLeave);
    
    return approvalLeave.filter(task=>task.employeeName.toLowerCase().indexOf(searchleave.toLowerCase())!=-1);
  }

}
