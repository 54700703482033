<!-- <div class="card"> -->
    <!-- <div class="card-header border-0"> -->
                <!-- <h3 class="card-title">Employees On Leave</h3> -->
                <!-- <div class="card-options ">
                    <a href="javascript:void(0);" class="btn btn-outline-light me-3">View All</a>
                    <div class="dropdown"> <a href="javascript:void(0);" class="btn btn-outline-light dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"> Date <i class="feather feather-chevron-down"></i> </a>
                        <ul class="dropdown-menu dropdown-menu-end" role="menu">
                            <li><a href="javascript:void(0);">Monthly</a></li>
                            <li><a href="javascript:void(0);">Yearly</a></li>
                            <li><a href="javascript:void(0);">Weekly</a></li>
                        </ul>
                    </div>
                </div> -->
    <!-- </div> -->
    <div class="table-responsive attendance_table mt-2" style="height: 300px;">
                <table class="table mb-0 text-nowrap">
                    <thead class="theadsticky tablerow" >
                        <tr class="rowcolors">
                            <th class="text-center">S.No</th>
                            <th class="text-start">Employee Id</th>
                            <th class="text-start">Employee</th>
                            <th class="text-center">Status</th>
                            <!-- <th class="text-center">CheckIn</th>
                            <th class="text-center">CheckOut</th>
                            <th class="text-center">Actions</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border-bottom" *ngFor="let empAbsent of empabsencelist;let i=index">
                            <td class="text-center"><span class="avatar avatar-sm brround">{{i+1}}</span></td>
                            <td class="font-weight-semibold fs-14">{{empAbsent.employeeid}}</td>
                            <td class="font-weight-semibold fs-14">{{empAbsent.name}}</td>
                            <td class="text-center"><span class="badge bg-success-transparent" style="padding: 7px;">Approved</span></td>
                            <!-- <td class="text-center">09:30 Am</td>
                            <td class="text-center">06:30 Pm</td>
                            <td class="text-center">
                                <a href="javascript:void(0);" class="action-btns" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Contact" aria-label="Contact"><i class="feather feather-phone-call text-primary"></i></a>
                                <a href="javascript:void(0);" class="action-btns" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Chat" aria-label="Chat"><i class="feather-message-circle  text-success"></i></a>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
    </div>
<!-- </div> -->
