import { Component, OnInit } from '@angular/core';
import { branches } from '../models/branches';
import { AlertService } from '../services';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.css']
})
export class BranchComponent implements OnInit {

  name: string;
  list: branches[];

  constructor(private commonservice: CommonService, private alertservice: AlertService) { }

  ngOnInit(): void {
  }

  selectBranch(e: any) {
    //console.log("NAme ",e);
    //console.log("NAme ",e.target.value);
    this.name = e.target.value;
  }

  savBranch() {

    console.log(this.name)

    if (!this.name) {
      this.alertmessages("Please enter Branch Name", "danger");
      return;
    }
    this.commonservice.saveBranch(this.name.trim()).subscribe(
      data => {
        this.list = data.branches;

        console.log("save" + data);
        // if (this.employe) {
        //   this.alertmessages("Successfully Updated", "success");
        //   return;
        // }

      },
      error => {
        const response = error.error;
        if (response.errorMessages != null && response.errorMessages.length !== 0) {
          this.alertservice.error(response.errorMessages[0]['key']);
        }
      });
  }
  searchBranch() {

    // if(!this.name){
    //   this.alertmessages("Please enter name" , "danger");
    //   return;
    // }

    // if (!this.name) {
      this.commonservice.getBranchlist().subscribe(
        data => {
          // if (this.offset === 0) {
          this.list = data.branches;

          console.log(this.list)
          // }else{
          //   this.list=this.list.concat(data);
          // }

        },
        error => {
          const response = error.error;
          if (response.errorMessages != null && response.errorMessages.length !== 0) {
            this.alertservice.error(response.errorMessages[0]['key']);
          }
        });
    // }


    // else {
    //   this.commonservice.getSearchdeparment(this.name).subscribe(
    //     data => {
    //       this.list = data;

    //       console.log("search" + this.list);


    //     },
    //     error => {
    //       const response = error.error;
    //       if (response.errorMessages != null && response.errorMessages.length !== 0) {
    //         this.alertservice.error(response.errorMessages[0]['key']);
    //       }
    //     });
    // }

  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    // (<HTMLButtonElement>document.getElementById('btndisabled')).disabled = true;
    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/departmentetails']);
      });


    }, 3000);
  }
}
