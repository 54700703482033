<!-- page content -->
<div class="app-content main-content">
  <div class="side-app main-container">
    <!-- <div class="form-group" >
      <div class="clearfix"></div>

      
  </div>  -->

    <div class="alertcomp">

    </div>

    <div class="page-header d-xl-flex d-block">
      <div class="page-leftheader">
        <div class="page-title">Users</div>
      </div>
    </div>


    <div class="row">
      <div class="col-md-12 col-xs-12">
        <div class="card">
          <div class="card-content">
            <form id="fcForm" name="registerForm" (ngSubmit)="f.form.valid" #f="ngForm" autocomplete="off" novalidate>
              <section class="p-3">
                <!--  
                            
  <div>
      <h3 style="margin-bottom: 5px;padding-left: 10px;">Users</h3>
      <div class="clearfix"></div>
</div> -->
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-6" *ngIf="!addUserPermission">
                    <div class="form-group label-floating">
                      <label class="form-label"> Filter </label>
                      <div>
                        <select id="useFilters" style="height: 38px;" class="selectpicker col-md-12"
                          data-style="btn btn-drpdwn btn-round" [(ngModel)]="selectedqualifier"
                          (change)="reFresh(); selectTableUserFilter()" name="dateFilter">
                          <option>All</option>
                          <option selected>Only Active</option>
                          <option>Non Active </option>

                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-6 col-xs-12" *ngIf="!addUserPermission">
                    <div class="form-group label-floating">
                      <label class="form-label"> Search Username </label>
                      <div>
                        <input type="text" class="form-control col-md-12 col-xs-12" name="cname" [(ngModel)]="userName"
                          #userme="ngModel" autofocus name="UserSearchAutoOff" autocomplete="off" list="customernm"
                          onfocus="this.value=''" onchange="this.blur();" autofocus id="selectedCustomer"
                          (ngModelChange)="selectPeople($event)" placeholder="Search Username" />
                        <datalist id="customernm">
                          <option *ngFor="let p of peopleList; let i=index">{{p.name}}</option>
                        </datalist>
                      </div>
                    </div>
                  </div>

                  <div class="clearfix" *ngIf="!addUserPermission"></div>

                  <div class="clearfix" *ngIf="!addUserPermission"></div>

                  <div class="col-md-6 col-sm-6 col-xs-12">
                    <div class="form-group label-floating">
                      <label class="form-label"> Username </label>
                      <div>

                        <input type="text" class="form-control col-md-12 col-xs-12"
                          pattern="^(?=.{5,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$"
                          [(ngModel)]="peopleInfo.name" autocomplete="off" minlength="2" name="cname"
                          name="AutoOffUserName" #cname="ngModel" placeholder=" Username" required="required"
                          autofocus="true" id="username" [disabled]="hidePassword" />
                        <span *ngIf="submitted && !cname.valid && !hidePassword" class="help-block" style="color: red;">
                          User Name is Mandatory and is Invalid</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-6 col-xs-12">
                    <div class="form-group label-floating">
                      <label class="form-label"> Select Role </label>
                      <div>
                        <input type="text" class="form-control col-md-12 col-xs-12" [(ngModel)]="peopleInfo.role"
                          name="roleAutoOff" #role="ngModel" id="customerinput" autocomplete="off" required="required"
                          list="customer" />
                        <datalist id="customer">
                          <option *ngFor="let s of roles; let i=index">{{s.name}}</option>
                        </datalist>
                        <p *ngIf="submitted    && !role.valid && !customCheck(null,'role')" class="help-block"
                          style="color: red;">
                          Role is Mandatory</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-6 col-xs-12">
                    <div class="form-group  label-floating">
                      <label class="form-label">Email</label>
                      <div>
                        <input type="search" name="emailAutoEverOFF" email #email="ngModel"
                          class="form-control col-md-12 col-xs-12"
                          pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$" [(ngModel)]="peopleInfo.email"
                          autocomplete="off" id="email" required="required" placeholder="Email"
                          [disabled]="hide_email" />
                        <span *ngIf="submitted  && email.errors?.required " class="help-block" style="color: red;">
                          Email is Mandatory </span>
                        <span *ngIf="submitted  && email.errors?.pattern" class="help-block" style="color: red;">
                          Email is Invalid </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-6 col-xs-12" *ngIf="!hidePassword">
                    <div class="clearfix"></div>
                    <div class="form-group  label-floating">
                      <label class="form-label">Password</label>
                      <div>
                        <input type="password" class="form-control col-md-12 col-xs-12" name="password"
                          #password="ngModel" [(ngModel)]="peopleInfo.password" id="pwd" required="required"
                          placeholder="Password"
                          pattern="^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{7,19}$" />
                        <p *ngIf="submitted    && !password.valid" class="help-block" style="color: red;">
                          Password should be atleast 8 characters long
                          and should contain one number,one character and one special
                          character
                        </p>
                        <div class="clearfix"></div>
                        <div *ngIf="!submitted &&  !password.valid">
                          <div message="pattern">
                            Password should be atleast 8 characters long
                            and should contain one number,one character and one special
                            character
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="col-md-6 col-sm-6 col-xs-12" *ngIf="!hidePassword">
                    <div class="form-group label-floating">
                      <label class="form-label">Confirm Password</label>
                      <div>
                        <input class="form-control col-md-12 col-xs-12" [(ngModel)]="confirmPassword"
                          (input)="passwordKeyIn()" name="cpassword" #cpassword="ngModel" placeholder="Confirm Password"
                          id="pwd1" type="password" required="required"
                          pattern="^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{7,19}$" />
                        <p *ngIf="submitted && !cpassword.valid" class="help-block" style="color: red;">
                          Password should be atleast 8 characters long
                          and should contain one number,one character and one special
                          character
                        </p>
                        <div class="clearfix"></div>
                        <p *ngIf="submitted && cpassword.valid &&  !passwordVerified && !showPasswordErr"
                          class="help-block" style="color: red;">
                          Passwords didn't match. Try again.</p>

                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-6 col-xs-12">
                    <div class="form-group label-floating">
                      <label class="form-label">Telephone</label>
                      <div>
                        <input class="form-control col-md-12 col-xs-12" autocomplete="off" name="pAutoOffever"
                          [(ngModel)]="peopleInfo.mobileNumber" #cphone="ngModel" minlength="10" placeholder="Telephone"
                          onKeyDown="if(this.value.length>9 && event.keyCode!=8  && event.keyCode!=9) return false"
                          type="text" required="required" />
                        <span *ngIf="submitted  && cphone.errors?.required" class="help-block" style="color: red;">
                          Phone Number is Mandatory </span>
                        <p *ngIf="submitted && !cphone.valid && !cphone.errors?.required" class="help-block"
                          style="color: red;">
                          Invalid Phone Number</p>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="clearfix" *ngIf="(submitted && addUserPermission && permission)"><p style="color: white;">scaleupglobe</p></div> -->

                  <!-- <div class="col-md-6 col-sm-6 col-xs-12" *ngIf="permission">
    <div class="form-group label-floating">
        <label class="form-label"> Select Employee ID</label>
        <div>
            <input type="text" class="form-control col-md-12 col-xs-12" 
            name="employeeId"  autocomplete="off" [(ngModel)]="employeeId" #empId="ngModel" (change) ="selectEmployeeId()" placeholder="Employee ID"
            list="employeeIds"  required="required"  id ="employeeIdList" />
             //[(ngModel)]="peopleInfo.empId" #empId="ngModel" 
                <datalist id="employeeIds">
                  <option *ngFor="let e of employeeIdList;" required>{{e}}</option>
                    </datalist>
                    <span *ngIf="submitted  && empId.errors?.required" class="help-block" style="color: red;">
                      Please select the Employee Id</span>
        </div>
      </div>
  </div> -->

                  <!-- <div class="col-md-6 col-sm-6 col-xs-12" *ngIf="permission">
    <div class="form-group label-floating">
        <label class="form-label">Select Branch Name</label>
        <div>
            <input type="text" class="form-control col-md-12 col-xs-12" [(ngModel)]="branchname" (change) ="selectBranchId()"
            name="branches" autocomplete="off" placeholder="Branch Name"
            list="branches"  required="required"  id ="branch" #branchId="ngModel"/>
                <datalist id="branches" >
                  <option  *ngFor="let e of branch;" required>{{e.branchname}}</option>
                    </datalist>
                    <span *ngIf="submitted  && branchId.errors?.required " class="help-block" style="color: red;">
                      Please select the Branch Name</span>
        </div>
      </div>
   </div> -->

                  <div class="col-md-6 col-sm-6 col-xs-12" *ngIf="hidePassword">
                    <div class="form-group label-floating">
                      <label class="form-label">Deactivate</label>
                      <!-- <div> -->
                      <input class="checkmark" name="discontinue" [(ngModel)]="peopleInfo.discontinue"
                        type="checkbox" />
                      <!-- </div> -->
                    </div>
                  </div>

                </div>

                <!-- 
<input  class="form-control col-md-7 col-xs-12" 
name="phoneAutoOFFF"  [(ngModel)]="customerInfo.phonenumber"  minlength="10"
placeholder="Phone Number" #cphone="ngModel" onKeyDown="if(this.value.length>9 && event.keyCode!=8  && event.keyCode!=9) return false"
type="search" 
/>
<p *ngIf="submitted && !cphone.valid " class="help-block" style="color: red;">
Invalid Phone Number</p> -->

                <!-- <div class="col-md-4 col-sm-6 col-xs-12" *ngIf="hidePassword">
    <div class="form-group label-floating">
      <label class="form-label">Deactivate</label>
      
                <input  class="checkmark" 
                name="discontinue"  [(ngModel)]="peopleInfo.discontinue" 
              type="checkbox" 
              /> -->

                <!-- </div> -->

                <!-- </div>
  </div> -->

                <!-- 
<div class="col-md-4 col-sm-6 col-xs-12">
  <label class="container"> 
    <input  type="checkbox"  name ="defaultunits" [(ngModel)]="enableDefaultUnits" data-md-icheck (change)="defaultUnit($event)"/>
    <label>Deactivate user</label>
    </label>
  </div> -->
                <div class="clearfix"></div>

              </section>

              <div class="actionBar">

                <!-- <button class="btn btn-primary" type="button"
      (click)="saveCustomer()">Save</button>
      <button 
      class="btn btn-danger-medium"
       type="button" 
      (click)="clear()">Clear</button> -->

                <!-- <button class="btn btn-primary" type="button"
      (click)="searchCustomer()">Search</button> -->

                <div class="pro-fab">
                  <button type="button" class="btn btn-info " (click)="addUser()" *ngIf="!addUserPermission">
                    <i class="fa fa-plus-square-o" aria-hidden="true"> </i>Add
                  </button>
                  <button type="button" class="btn btn-info ms-2" (click)="addSearch()" *ngIf="addUserPermission">
                    <i class="fa fa-search" aria-hidden="true"> </i>Search
                  </button>
                  <button class="btn btn-success ms-2" type="button" (click)="save(f.form.valid)">Save</button>
                  <button class="btn btn-danger ms-2" type="button" (click)="reFresh()">Clear</button>
                  <!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                    data-bs-target="#secondaryRoleModal" (click)="showSecondaryRoles()"> Secondary
                    Role</button> -->
                </div>
              </div>


            </form>

          </div>
        </div>
      </div>
    </div>


    <div class="col-xl-12 col-md-12 col-lg-12 p-0" *ngIf="tablepeopleList !== null && !addUserPermission">
      <div class="card">
        <!-- <div class="card-header  border-0">
          <h4 class="card-title">Document List</h4>
      </div> -->
        <div class="card-body ">
          <form id="custrList" name="custrList" type="multipart/form-data">
            <div class="col-xs-12 mobile">

              <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100" [scrollWindow]="false"
                (scrolled)="onScroll()" id="scrolllength">
                <table class="table  table-vcenter text-nowrap table-bordered border-bottom" id="hr-table">
                  <thead class="tablerow">
                    <tr class="rowcolors stickyatTop">
                      <th class="border-bottom-0 w-5 fs-6">UserName</th>
                      <th class="border-bottom-0 fs-6" style="width: 66px;"></th>
                      <th class="border-bottom-0 fs-6"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let j=index; let people of tablepeopleList ">
                      <td data-th="UserName" (click)="onNavigate(customInfo.domain)" style="cursor: pointer;">
                        {{people.name}}
                      </td>
                      <td>
                        <button type="button" rel="tooltip" class="btn btn-primary btn-simple" data-method="edit"
                          title="Edit" (click)="selectTablePeople(people.name)">
                          <!-- <span class="docs-tooltip" data-toggle="tooltip"> -->
                          <i class="fa fa-edit"></i>
                          <!-- </span> -->
                        </button>
                      </td>
                      <td>
                        <button type="button" rel="tooltip" class="btn btn-danger btn-simple" data-method="delete"
                          title="Delete" (click)="UserDelete(people.name)">
                          <!-- <span class="docs-tooltip" data-toggle="tooltip"> -->
                          <i class="fa fa-trash"></i>
                          <!-- </span> -->
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>

          </form>
        </div>
      </div>
    </div>

    <!-- 
<div class="col-md-12 col-xs-12" *ngIf="tablepeopleList !== null && !addUserPermission">
  <div class="card">
      <div class="card-content">
              <div class="Clearfix"></div>
              <form id="custrList" name="custrList" type="multipart/form-data">

              <div class="col-xs-12 mobile">
                  <div>
                      <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                          [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100" [scrollWindow]="false"
                          (scrolled)="onScroll()">
                          <table class="table table-hover cf">
                              <thead >
                                  <tr>
                                      <th class="scrolltheadFixed">UserName</th>
                                      <th class="scrolltheadFixed"></th>
                                  </tr>
                              </thead>
                              <tbody >
                                  <tr *ngFor="let j=index; let people of tablepeopleList ">
                                      <td data-th="UserName" (click)="onNavigate(customInfo.domain)" style="cursor: pointer;">
                                          {{people.name}}
                                      </td>
                                      <td>
                                          <button type="button" rel="tooltip" class="btn btn-success btn-simple" data-method="edit" title="Edit"
                                              (click)="selectTablePeople(people.name)">
                                              <span class="docs-tooltip" data-toggle="tooltip">
                                                  <span class="fa fa-edit"></span>
                                              </span>
                                          </button>
                                      </td>
                                       <td>
                                          <button type="button" rel="tooltip" class="btn btn-danger btn-simple" data-method="delete"
                                              title="Delete" (click)="UserDelete(people.name)">
                                              <span class="docs-tooltip" data-toggle="tooltip">
                                                  <span class="fa fa-trash"></span>
                                              </span>
                                          </button>
                                      </td> 
                                  </tr>
                              </tbody>
                          </table>
                      </div>

                  </div>
              </div>
              </form>
          </div>
      </div>
        </div> s -->
  </div>

</div>

<div id="showAlert" class="modal" style="top: 40px;">
  <div class="modal-dialog">
    <div class="modal-content animate">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="closeMRQModal()">×</button>
        <h4 class="modal-title">Warning</h4>
      </div>

      <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
        <!-- auto scroll -->
        <span><b>User will be deactivated permanently. Are you sure you want to continue?</b></span>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-danger medium" (click)="discontinueUser(); closeMRQModal()">OK</button>
        <button type="button" class="btn btn-primary" (click)="closeMRQModal()">Cancel</button>

      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="secondaryRoleModal" tabindex="-1" aria-labelledby="secondaryModalLabel" aria-hidden="true"
  style="display: none;">
  <div class="modal-dialog  modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="secondaryModalLabel" style="font-size: 20px;">Select Secondary
          Role</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <div class=" container-fluid">
          <!-- {{secondaryDepartmentObj | json }} -->
          <!-- <div>Salary Detail Additional Component</div> -->
          <!-- <h5 class="card-title mt-3 mb-3 "> Additional Details </h5> -->
          <ul class="list-group">
            <li class="list-group-item " style="cursor: pointer;" *ngFor="let sec of roles">
              <input type="checkbox" (change)="addSecondaryRole($event , sec);$event.stopPropagation()"
                class="deCheckall" id="{{sec.name}}">
              <label for="{{sec.name}}" style="margin-left: 10px;">{{sec.name}}</label>
            </li>

          </ul>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="clearRole()">Close</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="getRole()">Ok</button>
      </div>
    </div>
  </div>
</div>