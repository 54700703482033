<!-- page content -->

<div class="app-content main-content">
  <div class="side-app main-container">
    <div class="alertcomp">

    </div>
    <!-- <div class="form-group">
     <div class="clearfix"></div>  
  </div> -->

    <div class="page-header d-xl-flex d-block">
      <div class="page-leftheader">
        <div class="page-title">Change Password - {{username}}</div>
      </div>
    </div>


    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-content">
            <form id="fcForm" (ngSubmit)="f.form.valid" #f="ngForm" novalidate>
              <section class="p-3">
                <div class="row">
                  <div class="col-md-4 col-sm-6 col-xs-12">
                    <div class="form-group label-floating">
                      <label class="form-label"> Old Password </label>
                      <div>
                        <input type="password" class="form-control col-md-12 col-xs-12"
                          [(ngModel)]="peopleInfo.oldpassword" placeholder="Enter Old Password" name="cname" autofocus
                          #cname="ngModel" name="Product" required="required" autofocus="true" id="customerName" />
                        <p *ngIf="submitted && !cname.valid " class="help-block" style="color: red;">
                          Old Password is Mandatory</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 col-sm-6 col-xs-12">
                    <div class="form-group label-floating">
                      <label class="form-label">New Password</label>
                      <div>
                        <input class="form-control col-md-12 col-xs-12" type="password" name="password"
                          #password="ngModel" placeholder="Enter New Password" [(ngModel)]="peopleInfo.password"
                          minlength="6" required="required"
                          pattern="^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{7,19}$" />

                        <p *ngIf="submitted  && !password.valid" class="help-block" style="color: red;">
                          Password should be atleast 8 characters long
                          and should contain one number,one character and one special
                          character</p>
                        <div class="clearfix"></div>
                        <div *ngIf="!submitted &&  !password.valid">
                          <div message="pattern">
                            New Password should be atleast 8 characters long
                            and should contain one number,one character and one special
                            character
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 col-sm-6 col-xs-12">
                    <div class="form-group label-floating">
                      <label class="form-label">Confirm New Password</label>
                      <div>
                        <input class="form-control col-md-12 col-xs-12" [(ngModel)]="confirmPassword"
                          (input)="passwordKeyIn()" name="cpassword" #cpassword="ngModel" type="password"
                          required="required" placeholder="Confirm New Password" />
                        <p *ngIf="submitted  && password.valid  && !cpassword.valid" class="help-block"
                          style="color: red;">
                          Confirm New password</p>
                        <p *ngIf="submitted && cpassword.valid &&  !passwordVerified && !showPasswordErr"
                          class="help-block" style="color: red;">
                          Password didn't match. Try again.</p>

                      </div>
                    </div>
                  </div>
                </div>

                <div class="clearfix"></div>
              </section>
              <div class="clearfix"> </div>
              <!-- <div class="actionBar"> -->

              <div class="clearfix"></div>
              <div class="pro-fab">
                <button class="btn btn-success" type="button" (click)="save(f.form.valid)">Save</button>
                <button class="btn btn-danger ms-2" type="button" (click)="reFresh()">Clear</button>

              </div>
              <!-- </div> -->

            </form>


          </div>
        </div>
      </div>
    </div>
  </div>
</div>