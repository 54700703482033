export class EmployeeLeaveCompOff {
  constructor(
    public uniqueEmployeeId: number,
    public employeeLeaveAppliedId:number,
    public fromDate: string,
    public toDate: string,
    public appliedDate: string,
    public leaveType: string,
    public halfFullIndic: string,
    public reason: string,
    public inTime:string,
    public outTime:string,
    public totalTime:string,
  ) { }
}
