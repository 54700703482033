<div class="app-content main-content">
    <div class="side-app main-container">

        <!-- <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-title">Notice Board</div>
            </div>
            <div class="page-rightheader ms-md-auto">
                <div class="d-flex align-items-end flex-wrap my-auto end-content breadcrumb-end">
                    <div class="btn-list">
                        <a href="javascript:void(0);" class="btn btn-primary me-3" data-bs-toggle="modal"
                            data-bs-target="#addnoticemodals">Add New Notice</a>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="w-100" style="padding: 0;">

            <div class="card-body row justify-content-between">
                <div class="col-xs-9 col-md-9 col-sm-9 col-8 " style="padding: 0;">

                    <div class="my-3">

                        <div class="page-leftheader">
                            <div class="page-title">Notice Board</div>
                        </div>

                    </div>
                </div>

                <div class="col-xs-3 col-md-3 col-sm-3 col-4 my-3" style="padding: 0;">
                    <button class="btn btn-primary w-100 px-0" (click)="addNewData()" data-bs-target="#addnoticemodals" data-bs-toggle="modal">
                        Add New Notice
                    </button>
                </div>


            </div>

        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header  border-0">
                        <h4 class="card-title">Notice Summary</h4>
                    </div>
                    <div class="card-body ">
                        <div class="scrollsearchresults" id="scroll" infiniteScroll [infiniteScrollDistance]="2"
                            [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100" [scrollWindow]="false"
                            (scrolled)="onScroll()" id="scrolllength">
                            <div class="table-responsive">
                                <table class="table table-hover  table-vcenter text-nowrap table-bordered border-bottom"
                                    id="hr-notice">
                                    <thead class="tablerow">
                                        <tr class=" stickyatTop rowcolors">
                                            <th class="border-bottom-0 w-5" scope="col">No</th>
                                            <th class="border-bottom-0" scope="col">Title</th>
                                            <th class="border-bottom-0" scope="col">Description</th>
                                            <th class="border-bottom-0" scope="col">From date</th>
                                            <th class="border-bottom-0" scope="col">To date</th>
                                            <!-- <th class="border-bottom-0">To</th> -->
                                            <th class="border-bottom-0" scope="col">Created On</th>
                                            <th class="border-bottom-0" scope="col">Action</th>

                                            <!-- <th class="border-bottom-0">Status</th> -->
                                            <!-- <th class="border-bottom-0">Action</th> -->
                                        </tr>
                                    </thead>    
                                    <tbody>
                                        <tr *ngFor="let notice of renderNoticeList;let i=index">
                                            <td data-label="Sl No." [scope]="i!==0 ? '': 'row'">{{i+1}}</td>
                                            <td class="text-wrap" style="min-width:10vw;max-width:14vw;"  data-label="Title">{{notice.title}}</td>
                                            <td class="text-wrap" style="min-width:10vw;max-width: 20vw;" data-label="Description">{{ notice.description }}</td>
                                            <td data-label="From date">{{notice.fromdate | date:'MM-dd-yyyy HH:mm:ss'}}
                                            </td>
                                            <td data-label="To date">{{notice.todate | date:'MM-dd-yyyy HH:mm:ss'}}</td>
                                            <td data-label="Created On">{{notice.createdtime | date:'MM-dd-yyyy'}}</td>
                                            <td>
                                                <button class="btn btn-primary btn-icon btn-sm me-2"
                                                    (click)="noticeboardEdit(notice)" data-method="edit"
                                                    title="Edit">
                                                    <i class="fa fa-edit" data-bs-toggle="tooltip"
                                                        data-original-title="edit"></i>
                                                </button>
                                                <!-- <button class="btn btn-primary btn-icon btn-sm me-2 " data-bs-target="#addnoticemodals" data-bs-toggle="modal">
                                                    <i class="ion-wand" data-bs-toggle="tooltip"
                                                        data-original-title="edit"></i>
                                                </button> -->
                                                <button class="btn btn-danger btn-icon btn-sm me-2"
                                                    (click)="noticeboardDelete(notice.id)" data-method="delete"
                                                    title="Delete" data-bs-target="#showAlertWarning">
                                                    <i class="fa fa-trash" data-bs-toggle="tooltip"
                                                        data-original-title="edit"></i>
                                                </button>
                                            </td>
                                            <!-- <td><span class="badge badge-success">Active</span></td> -->
                                            <!-- <td>
                                            <div class="d-flex">
                                            <a  href="javascript:void(0);" class="action-btns1" data-bs-toggle="modal" data-bs-target="#editnoticemodal"><i class="feather feather-eye primary text-primary"  data-bs-toggle="tooltip" data-bs-placement="top" title="Edit/View"></i></a>
                                            <a  href="javascript:void(0);" class="action-btns1" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"><i class="feather feather-trash-2 text-danger"></i></a>
                                            </div>
                                            </td> -->
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>


</div>



<div class="modal fade" id="addnoticemodals">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Add New Notice</h5>
                <button class="btn-close" (click)="closemodal($event)" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="form-horizontal" (ngSubmit)="form.form.valid && saveNoticeInfo(form.form.valid)"
                    #form="ngForm">
                    <div class="alertcomp"></div>
                    <div class="form-group">
                        <label class="form-label">Title</label>
                        <input class="form-control" placeholder="Enter Title" id="title" name="title"
                            [(ngModel)]="savenoticeinfo.title" #title="ngModel" required>
                        <p *ngIf="submitted && !title.valid" class="help-block " style="color: #ff5757;">
                            ⓘ Title is required
                        </p>
                    </div>

                    <div class="form-group ">
                        <label class="form-label" for="dsasf32">From Date:</label>
                        <input type="datetime-local" class=" form-control" id="fromdate" name="fromdate"
                            [(ngModel)]="savenoticeinfo.fromdate" #fromdate="ngModel" required
                            (ngModelChange)="from_date_change_listner($event , fromdate)">
                        <p *ngIf="submitted && !fromdate.valid" class="help-block " style="color: #ff5757;">
                            ⓘ From Date is required
                        </p>
                        <!-- <p class="help-block" style="color: tomato;">ⓘ From date is not Valid</p> -->
                    </div>

                    <div class="form-group ">
                        <label class="form-label" for="dsasf13">To Date:</label>
                        <input type="datetime-local" class=" form-control" id="todate" name="todate"
                            [(ngModel)]="savenoticeinfo.todate" #todate="ngModel" required
                            (ngModelChange)="to_date_change_listner($event)">
                        <p *ngIf="submitted && !todate.valid" class="help-block " style="color: #ff5757;">
                            ⓘ To Date is required
                        </p>
                    </div>
                    <div class="form-group">
                        <label class="form-label" for="description">Description:</label>
                        <textarea name="text" class="form-control" id="description" name="description"
                            [(ngModel)]="savenoticeinfo.description" #description="ngModel" required></textarea>
                        <p *ngIf="submitted && !description.valid" class="help-block " style="color: #ff5757;">
                            ⓘ Description is required
                        </p>
                    </div>
                </form>
                <!-- <div class="form-group">
                    <label class="form-label">Attachment:</label>
                    <div class="form-group">
                    <label for="form-label" class="form-label"></label>
                        <input class="form-control" type="file">
                    </div>
                </div> -->

            </div>
            <div class="modal-footer">
                <button class="btn btn-success" *ngIf="!isEditing" (click)="saveNoticeInfo(form.form.valid)">Save</button>
                <button class="btn btn-success" *ngIf="isEditing" (click)="updateNoticeInfo(form.form.valid)">Update</button>
                <button class="btn btn-secondary" (click)="closemodal($event)">Close</button>
              </div>
              
        </div>
    </div>
</div>




<!-- change to update save  -->

<!-- <div class="modal fade" id="editnoticemodals">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Edit Notice</h5>
                <button class="btn-close" (click)="closemodale($event)" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="form-horizontal" (ngSubmit)="form.form.valid && updateNoticeInfo(form.form.valid)"
                    #form="ngForm">
                    <div class="alertcomp"></div>
                    <div class="form-group">
                        <label class="form-label">Title</label>
                        <input class="form-control" placeholder="Enter Title" id="title" name="title"
                            [(ngModel)]="updatenoticeinfo.title" #title="ngModel" required>
                        <p *ngIf="submitted && !title.valid" class="help-block " style="color: #ff5757;">
                            ⓘ Title is required
                        </p>
                    </div>

                    <div class="form-group ">
                        <label class="form-label" for="dsasf32">From Date:</label>
                        <input type="datetime-local" class=" form-control" id="fromdate" name="fromdate"
                            [(ngModel)]="updatenoticeinfo.fromdate" #fromdate="ngModel" required
                            (ngModelChange)="from_date_change_listner($event , fromdate)">
                        <p *ngIf="submitted && !fromdate.valid" class="help-block " style="color: #ff5757;">
                            ⓘ From Date is required
                        </p>
                    </div>

                    <div class="form-group ">
                        <label class="form-label" for="dsasf13">To Date:</label>
                        <input type="datetime-local" class=" form-control" id="todate" name="todate"
                            [(ngModel)]="updatenoticeinfo.todate" #todate="ngModel" required
                            (ngModelChange)="to_date_change_listner($event)">
                        <p *ngIf="submitted && !todate.valid" class="help-block " style="color: #ff5757;">
                            ⓘ To Date is required
                        </p>
                    </div>
                    <div class="form-group">
                        <label class="form-label" for="description">Description:</label>
                        <textarea name="text" class="form-control" id="description" name="description"
                            [(ngModel)]="updatenoticeinfo.description" #description="ngModel" required></textarea>
                        <p *ngIf="submitted && !description.valid" class="help-block " style="color: #ff5757;">
                            ⓘ Description is required
                        </p>
                    </div>
                </form>

            </div>
            <div class="modal-footer">
                <button class="btn btn-outline-primary" (click)="closemodale($event)">Close</button>
                <button class="btn btn-success" (click)="updateNoticeInfo(form.form.valid )">Update</button>
             </div>
        </div>
    </div>
</div> -->