import { Component,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Event, NavigationEnd } from '@angular/router';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})


export class AppComponent {
    
    title = "app";
   
    constructor(private route: Router) {    }
    


    ngOnInit() {

        this.route.events.subscribe(
                (page_link: Event) => {
                    if (page_link instanceof NavigationEnd ) {
                        var page = page_link.url;
                        this.redirectPage(page);
                    }
                }
            );
    }
    
    
    
    redirectPage(currentPage) {
        
        const pages = ['/', ''];
        
        for (const page in pages) {

            if (currentPage == pages[page]) {
                 this.route.navigateByUrl('/login');
                 //this.route.navigateByUrl('/companyselection');
            }
            
        }
    }
	
}
