import { Component, OnInit } from '@angular/core';
import { PeopleInfo } from '../models/peopleInfo';
import { CommonService } from '../services/common.service';
import { AlertService } from '../services';
import { Router} from '@angular/router'

@Component({
  selector: 'app-changepwd',
  templateUrl: './changepwd.component.html',
  styleUrls: ['./changepwd.component.css']
})
export class ChangepwdComponent implements OnInit {
  peopleInfo: PeopleInfo;
  submitted: boolean;
  confirmPassword: string;
  passwordVerified: boolean;
  showPasswordErr: boolean;
  username: any;
  showOldPasswordErr: boolean;
  oldpasswordVerified: boolean;
  oldPassword: string;

  constructor(private commonService: CommonService , private alertService: AlertService, private router: Router) { }

  ngOnInit() {
    this.username = sessionStorage.getItem('username')
    this.peopleInfo = new PeopleInfo();   
    this.peopleInfo.name= this.username; 
    this.submitted = false;
    (<HTMLInputElement>document.getElementById('customerName')).focus();
   // this.getOldPassword();
  }
  // getOldPassword()
  // {    
  //   this.commonService.getOldPassword(this.username).subscribe(
  //         data => {
  //           this.oldPassword = data.successMessages[0]['key'];
  //           console.log("old pwd "+this.oldPassword);
  //         });
  // }
  passwordKeyIn() {
    this.showPasswordErr = true;
    }
  save(valid) {
    this.submitted = true;
    this.passwordVerify();
    //this.oldPasswordVerify();
    //if (!valid || !this.passwordVerified || !this.oldpasswordVerified) {
    if (!valid || !this.passwordVerified) {
     return;
    }
    console.log(this.peopleInfo);
        this.commonService.saveNewPassword(this.peopleInfo).subscribe(
        data => {
            console.log(data.successMessages[0]['key']);
            // this.alertService.success(data.successMessages[0]['key']);
            let alertDiv = document.createElement('div');         
					alertDiv.innerHTML = ` <div class="alert alert-success mt-4 " role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
					<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>  Successfully updated password.</div>`
					let alertcomp = document.querySelector('.alertcomp');
					alertcomp.append(alertDiv);
					document.body.scrollTop = document.documentElement.scrollTop = 0;
					setTimeout(function(){
						alertcomp.removeChild(alertDiv);
					}, 3000);
            this.submitted = false;
            this.reFresh();
            this.logout();
        },
        error => {
          const response = error.error;
          // this.alertService.error(response.errorMessages[0]['key']);
          let alertDiv = document.createElement('div');         
			  alertDiv.innerHTML = ` <div class="alert alert-danger mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
			  <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>  Password Not Updated.</div>`
			  let alertcomp = document.querySelector('.alertcomp');
			  alertcomp.append(alertDiv);
			  document.body.scrollTop = document.documentElement.scrollTop = 0;
			  setTimeout(function(){
				alertcomp.removeChild(alertDiv);
			}, 3000);
      
        });

  }

   logout(){
    sessionStorage.clear();
    // this.toggleSideMenu();
     
    this.router.navigate( ['/login'] );

   }

   toggleSideMenu() {
    var element1 = document.getElementById("sideNavBar");
    var element2 = document.getElementById("overlayer");
      element1.style.width = "0";
      element2.style.display = "none"
}

  passwordVerify() {
    this.showPasswordErr = false;
    if (this.peopleInfo.password  === this.confirmPassword) {
              this.passwordVerified = true;
    } else {
      this.passwordVerified = false;
    }
    }

    // oldPasswordVerify() {
    //   this.showOldPasswordErr = false;
      
    //   if (this.peopleInfo.name  === this.oldPassword) {
    //             this.oldpasswordVerified = true;
    //   } else {
    //     this.oldpasswordVerified = false;
    //   }
    //   }

    customCheck(obj, type) {
      if ( type === 'name' || type  === 'role' || type === 'password' || type === 'confirmpassword') {
        return false;
            }  
     return  true;  
     }

     reFresh() {
      this.peopleInfo = new  PeopleInfo();
      this.confirmPassword = undefined;      
    }
}
