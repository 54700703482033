import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DesignationInfo } from '../models/designationInfo';
import { JoblevelInfo } from '../models/joblevel';
import { AlertService } from '../services';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-designation',
  templateUrl: './designation.component.html',
  styleUrls: ['./designation.component.css']
})
export class DesignationComponent implements OnInit {
  roledescription: string;
  name: string;
  designationid: number;
  payscaleid: number;
  employe: DesignationInfo[];
  list: DesignationInfo[];
  joblevel: JoblevelInfo[];

  constructor(private commonservice: CommonService, private router: Router, private alertservice: AlertService) { }

  ngOnInit(): void {
    this.getjoblevel();
    (<HTMLInputElement>document.getElementById('textType')).focus()

  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/temp', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));

  }

  reFresh() {

    this.redirectTo('/designationetails');

  }

  selectDesignation(e) {
    this.name = e.target.value;

  }
  selectedjobid: string;
  saveDesignation() {
    if (!this.name) {
      this.alertmessages("Please enter Designation Name", "danger");
      return;
    }

    if (!this.roledescription) {
      this.alertmessages("Please enter Joblevel", "danger");
      return;
    }

    this.commonservice.getempDesignation(this.name.trim(), this.roledescription).subscribe(
      data => {
        this.employe = data.savedesignation;

        console.log("save" + this.employe);
        if (this.employe) {
          this.alertmessages("Successfully Saved", "success");
          return;
        }

      },
      error => {
        const response = error.error;
        if (response.errorMessages != null && response.errorMessages.length !== 0) {
          this.alertservice.error(response.errorMessages[0]['key']);
        }
      });

  }
  onScroll() {

  }

  searchDesignation() {

    if (!this.name) {
      // this.alertservice. error("Please enter name");
      // return;
      this.commonservice.getSearchdesignation1().subscribe(
        data => {
          this.list = data;

          // console.log("search"+this.list);
          // if(!this.employe){
          //   this.alertmessages("Enteries not found" , "danger");
          //   return;
          // }
        },

        error => {
          const response = error.error;
          if (response.errorMessages != null && response.errorMessages.length !== 0) {
            this.alertservice.error(response.errorMessages[0]['key']);
          }
        });
    }

    else {
      if (!this.roledescription) {
        this.alertmessages("Please select Job Level", "danger");
        return;
      }
      this.commonservice.getSearchdesignation(this.name, this.roledescription).subscribe(
        data => {
          this.list = data;

          console.log("search" + this.list);

        },
        error => {
          const response = error.error;
          if (response.errorMessages != null && response.errorMessages.length !== 0) {
            this.alertservice.error(response.errorMessages[0]['key']);
          }

        });
    }


  }


  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
          <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    //  (<HTMLButtonElement>document.getElementById('btndisabled')).disabled = true;
    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/designationetails']);
      });


    }, 3000);
  }





  getjoblevel() {

    this.commonservice.getjobLevel().subscribe(
      data => {
        this.joblevel = data;
        console.log(this.joblevel)

      },

      error => {
        const response = error.error;
        if (response.errorMessages != null && response.errorMessages.length !== 0) {
          this.alertservice.error(response.errorMessages[0]['key']);
        }
      });
  }

  selectedJobllevel(i, e) {
    // this.getjoblevel();
    this.joblevel[i].joblevel = e.target.value;
  }
  setjobid(e) {
    console.log(e)
    console.log(e.target.value)
    this.roledescription = e.target.value;

  }
}




