export class PolicySubmission {
id: number;
fileName: any;
timestamp: Date;
amount: number;
fileType: any;
userId: any;
status: any;
attchment:any;
fileToUpload: File | null = null;
filename: any;
uniqueempid:any;
}