<div class="container-fluid" role="main">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-content">
          <form id="fcForm" name="registerForm" (ngSubmit)="f.form.valid" #f="ngForm" novalidate>

            <section>

              <div>
                <h3 style="margin-bottom: 30px;padding-left: 10px;">Approval Workflow</h3>
              </div>


              <div class="col-md-4 col-sm-6 col-xs-12">
                <div class="form-group label-floating">
                  <label class="control-label">DocGroup </label>
                  <div>
                    <input type="text"  class="form-control col-md-7 col-xs-12" name="cname" id="cname"  autocomplete="off"
                      list="docGroup"  autofocus autofocus="true" #cname="ngModel" [(ngModel)]="approvalworkflowInfo.docGroup" 
                      (change)="getApprovalWorkflows()" required ="required" />
                    <datalist id="docGroup">
                      <option *ngFor="let d of docInfoArray;">{{d.docGroup}}</option>
                    </datalist>

                    <p *ngIf="submitted && cname.errors?.required" class="help-block"
                    style="color: red;">
                    DocGroup is Mandatory</p> 
                  </div>
                </div>
              </div>

              <div class="clearfix"></div>

              <div class="col-md-4 col-sm-6 col-xs-12">
                <div class="form-group label-floating">
                  <label class="control-label">Stage</label>
                  <div>
                    <input type="text" class="form-control col-md-7 col-xs-12" name="stage" id="stage" autocomplete="off"
                     maxlength="4" [(ngModel)]="approvalworkflowInfo.stage" #stage="ngModel" required ="required"/>

                     <p *ngIf="submitted  && stage.errors?.required" class="help-block"
                    style="color: red;">
                    Stage is Mandatory</p> 

                  </div>
                </div>
              </div>

              <div class="col-md-4 col-sm-6 col-xs-12">
                <div class="form-group label-floating">
                  <label class="control-label"> Approvers Role </label>
                  <div>
                    <input type="text" class="form-control col-md-7 col-xs-12" 
                      autocomplete="off" name="approverrole" autofocus required="required" autofocus="true"
                      id="approverrole" #approverrole="ngModel" list="rolesApprove" [(ngModel)]="approvalworkflowInfo.roleToApprove"/>
                      <datalist id="rolesApprove">
                        <option *ngFor="let p of rolesArray;">{{p.name}}</option>
                      </datalist>

                    <p *ngIf="submitted && !approverrole.valid && !customCheck(null,'frmapproval')" class="help-block"
                      style="color: red;">
                      Approvers Role is Mandatory</p> 
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-4 col-xs-6">
                <div class="form-group label-floating">
                  <label class="control-label"> Filter </label>
                  <div>
                    <select class="selectpicker"  data-style="btn btn-drpdwn btn-round" id="mandatoryFilter" #mandatory="ngModel"
                      name="mandatoryFilter" [(ngModel)]="approvalworkflowInfo.mandatory" [attr.value]="approvalworkflowInfo.mandatory">
                      
                       <!-- <option *ngFor="let opt of options ; let i=index;" [value]="opt.id">{{opt.name}}</option>  -->
                      <option>Yes</option>
                      <option>No</option>
                      <option>No, if Next Approved</option>
                      <option>Information Only</option>   

                    </select>
                  </div>
                </div>
              </div>
              <div class="clearfix"></div>
            </section>
            <div class="clearfix"> </div>
          </form>
        </div>
      </div>
    </div>

    <div class="col-md-12 " >
      <div class="card">
        <div class="card-content">
          <section>
            <table class="table table-hover cf ">
              <thead>
                <tr>
                  <th class="scrolltheadFixed">Stage</th>
                  <th class="scrolltheadFixed">Approver</th>
                  <th class="scrolltheadFixed">Mandatory</th>
                  <th class="scrolltheadFixed"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let approval of approvalworkflowInfoArray">
                  <td>{{approval.stage}}</td>
                  <td>{{approval.roleToApprove}}</td>
                  <td>{{approval.mandatory}}</td>
                  <td>
                    <button type="button" rel="tooltip" class="btn btn-success btn-simple" data-method="edit"
                      title="Edit" (click)="approvalEdit(approval.stage)">
                      <span class="docs-tooltip" data-toggle="tooltip">
                        <span class="fa fa-edit"></span>
                      </span>
                    </button>
                    <button type="button" rel="tooltip" class="btn btn-danger btn-simple" data-method="delete"
                      title="Delete" (click)="approvalDelete(approval.stage)">
                      <span class="docs-tooltip" data-toggle="tooltip">
                        <span class="fa fa-trash"></span>
                      </span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>

        <div id="showAlert" class="modal" style="top: 40px;">
          <div class="modal-dialog">
            <div class="modal-content animate">
              <div class="modal-header">
                <button type="button" class="close" (click)="close()">×</button>
                <h4 class="modal-title">Warning</h4>
              </div>

              <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
                <span><b> DocGroup Stage will be deleted permanently . Are you sure you want to continue?</b></span>
              </div>

              <div class="modal-footer">
                <button type="button" class="btn btn-danger medium" (click)="confirmapproveDelete()">OK</button>
                <button type="button" class="btn btn-primary" (click)="close()">Cancel</button>

              </div>
            </div>
          </div>
        </div>

        <div class="actionBar">

          <div class="clearfix"> </div>
          <div class="pro-fab">
            <button class="btn btn-success" type="button" (click)="submit(f.form.valid)">Save</button>
            <button class="btn btn-danger" type="button" (click)="clear()">Clear</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>