import { Injectable } from "@angular/core";
import { CommonService } from "./common.service";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { configSetting } from "../models/configSetting";
import { LeaveMaster } from "../models/leaveMaster";
import { mandatory } from "../models/mandatory";
import { Configkey } from "../models/Configkey";
import { AdminConfig } from "../models/AdminConfig";
import { ExpenseSubmission } from "../models/ExpenseSubmission";
import { PolicySubmission } from "../models/PolicySubmission";

@Injectable({
  providedIn: 'root'
})
export class PolicyManagerServiceService {
  constructor(private httpclient: HttpClient, private commonService: CommonService) { }

  protected basePath = '/api';
  public defaultHeaders: Headers = new Headers();
  public url;

  public savePolicySubmission(file: File, policySubmission: PolicySubmission): Observable<any> {
    console.log("value sent ExpenseSubmission", policySubmission);
    console.log("value sent file", file);
    this.url = '/v1/addPolicySubmission';
    const path = this.basePath + this.url;
    const formData: FormData = new FormData();
    
    formData.append('file', file);
  
    // Convert the JSON data to a Blob and append it to the FormData
    const jsonBlob = new Blob([JSON.stringify(policySubmission)], { type: 'application/json' });
    formData.append('policySubmission', jsonBlob, 'policySubmission.json');
  
    // Use HttpHeaders correctly
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
  
    // Use set instead of append for HttpHeaders
    const httpOptions = { headers: headers };
  
    // Use proper type for the return value (Observable<HttpResponse<any>>)
    return this.httpclient.post<any>(path, formData, httpOptions);
  }

  public getPolicySubList(user_id: string): Observable<any> {
    this.url = '/v1/getPolicySubmissionDetails';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('userid', user_id)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public viewdocument(filepath:string): Observable<any> {
    this.url = '/v1/viewRequesteddocuments';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('filepath', filepath);
    console.log(queryParameters);
    return this.commonService.getByQueryParamWithHttpInfo( queryParameters, path, {responseType: 'arraybuffer'});
     console.log("Here you can see the rsponse path: " + path)
  }

  public downloadFile(filePath: string): Observable<any> {
    this.url = '/v1/downloadPolicySubmssion';
    const path = this.basePath + this.url;
    console.log("File Path is", filePath);
    const param = new HttpParams().set('filePath', filePath);
    const options = {
        params: param
    };
    return this.httpclient.get(path, { ...options, responseType: 'blob' });
  }

  public deletePolicy(id: any): Observable<any> {
    console.log("delete id  : ", id);
    this.url = '/v1/deletePolicySubmssion';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('id', id);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getPolicyAppList(uid: any): Observable<any> {
    this.url = '/v1/policyApprovalList';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('uniqueEmpid', uid)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public approveList(expenseApproval: any,uid: any): Observable<any> {
    console.log("value sent ", expenseApproval);
    this.url = '/v1/savePolicyApprovalList';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('uniqueEmpid', uid);
    return this.commonService.postWithHttpInfo(expenseApproval, queryParameters, path);
  }

  public rejectList(expenseApproval: any): Observable<any> {
    console.log("value sent ", expenseApproval);
    this.url = '/v1/policyRejectList';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(expenseApproval, queryParameters, path);
  }

  public getApprovedDetails(expid:any): Observable<any> {
    this.url = '/v1/policyApprovalDetails';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('expid', expid);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }
}
