import { Component, Input, OnInit } from '@angular/core';
import { TaskDetailsForLeave } from 'src/app/models/task-details-for-leave.model';
import { ApproveLeavePipe } from 'src/app/pipes/approve-leave.pipe';
import { DashboardService } from 'src/app/services/dashboard.service';


@Component({
  selector: 'app-show-all-leave-for-approval',
  templateUrl: './show-all-leave-for-approval.component.html',
  styleUrls: ['./show-all-leave-for-approval.component.css']
})
export class ShowAllLeaveForApprovalComponent implements OnInit {
  // showLeaveForApproval: TaskDetailsForLeave[] = []
  // showLeaveForApprovalListLength: number;
  // showLeaves: number = 30;

  @Input() showLeaveForApproval: TaskDetailsForLeave[] = [];

  constructor(private dashboardService: DashboardService, private approve: ApproveLeavePipe) { }

  ngOnInit() {
    // this.getLeaveForApproval()
  }


  navigateLeaveApproval() {
    localStorage.setItem('navigateDashboard', 'true')
  }

  // getLeaveForApproval() {
  //   console.log("Clicked");
  //   this.dashboardService.getLeaveForApproval().subscribe(
  //     data => {
  //       // console.log("Leave For Approval ",data);
  //       this.showLeaveForApproval = data.leavesToBeApproved
  //       this.showLeaveForApprovalListLength = this.showLeaveForApproval.length;

  //     },
  //     error => {
  //       console.error("Error while getting Leave For Approval ", error);
  //     }
  //   )
  // }
}
