export class dailyTasksInfo {
    taskId :number;
    userId : number;
    description : string;
    creationDate : any;
    dueDate : any;
    priority :string;
    reference : any;
    status:any;

}