import { Component, OnInit, HostListener, Renderer2 } from '@angular/core';
import { CustomersInfo } from 'src/app/models/customersInfo';
import { CustomerService } from 'src/app/services/customer.service';
import { Approvalinboxservice } from 'src/app/services/approvalinbox.service';
import { AlertService, ConstantService, SessionService } from 'src/app/services';
import { Router } from '@angular/router';
import { taskInfo } from '../models/taskInfo';
import { employeeDocuments } from '../models/employeeDocuments';
import { DocumentInfoService } from '../services/documentinfo.service';

// Test bellow if works well and good or else try somthing new
import { DomSanitizer } from '@angular/platform-browser';
import { Approvalworkflowservice } from '../services/approvalworkflow.service';
import { ChartType } from 'ng-apexcharts';

declare var $: any;

@Component({
  selector: 'app-approvalinbox',
  templateUrl: './approvalinbox.component.html',
  styleUrls: ['./approvalinbox.component.css']
})
export class ApprovalinboxComponent implements OnInit {

  taskList: taskInfo[];
  _filterDep: String;
  _filterStatus: String;
  filterTaskList: taskInfo[];
  filterTaskListTwo: taskInfo[];
  unFilteredDepartmentNames = []
  filteredDepartmentNames = []
  unFilteredStatus = []
  filteredStatus = []

  get filterDep() {
    return this._filterDep;
  }

  set filterDep(value: String) {
    this._filterDep = value;
    this.filterTaskList = this.filterDepartment(value);
  }

  get filterStatus() {
    return this._filterStatus;
  }

  set filterStatus(value: String) {
    this._filterStatus = value;
    this.filterTaskList = this.filterStatusName(value);
  }

  documentsToBeApproved: any[];
  filteredEmployees: any[] = [];

  filterListFromDeptStat: any[] = [];

  limitPara: any;
  offsetPara: any;

  accept: Boolean;
  taskId: String;
  employeeDocs: employeeDocuments;
  departmentname: String;
  filePath: any;
  f_Name: any;
  rejected: boolean;
  fileSavedPath: any;
  type: any;
  depListArray: String[];

  selectedFileBLOB: any;

  constructor(private render: Renderer2, private router: Router, private customerService: CustomerService, private alertservice: AlertService, private constantService: ConstantService, private sessionService: SessionService, private approvalinboxservice: Approvalinboxservice, private docmentInfoService: DocumentInfoService, private sanitizer: DomSanitizer, private approvalworflow: Approvalworkflowservice) { }

  ngOnInit() {
    this.taskList = new Array<taskInfo>();
    this.employeeDocs = new employeeDocuments();
    this.getCustomer();
    // this.getAllData();
    this.rejected = false;
    this.filterTaskList = this.taskList;

    this.limitPara = 0;
    this.offsetPara = 10;
  }

  getCustomer() {
    this.approvalinboxservice.getTasks().subscribe(
      data => {
        console.log("List Of all submitted data: >>>>", data)
        this.taskList = data.documentsToBeApproved;
        this.documentsToBeApproved = data.documentsToBeApproved;

        for (var i = 0; i < this.taskList.length; i++) {
          this.unFilteredDepartmentNames.push(this.taskList[i].departmentname)
          this.unFilteredStatus.push(this.taskList[i].documents.overAllStatus)
        }

        this.unFilteredDepartmentNames.forEach((item) => {
          if (!this.filteredDepartmentNames.includes(item)) {
            this.filteredDepartmentNames.push(item);
          }
        })
        this.unFilteredStatus.forEach((item) => {
          if (!this.filteredStatus.includes(item)) {
            this.filteredStatus.push(item);
          }
        })

        this.filterTaskList = this.filterDepartment("all");
        // this.filterStatusName("all");
      },
      error => {
        console.log(error);
      });
  }

  // filterEmployees(searchText: string) {
  //   this.filteredEmployees = this.documentsToBeApproved.filter(employee =>
  //     employee.employeeName.toLowerCase().includes(searchText.toLowerCase())
  //   );
  //   console.log(this.filteredEmployees)
  // }

  filterEmployees(searchText: string) {
    console.log("filter employeename  ")

    if (this.filterListFromDeptStat.length != 0) {

      console.log("filterListFromDeptStat : ", this.filterListFromDeptStat)

      this.filteredEmployees = this.filterListFromDeptStat.filter(employee =>
        employee.employeeName.toLowerCase().includes(searchText.toLowerCase())
      );

      // Update the filterTaskList with the filtered results
      this.filterTaskList = this.filteredEmployees;

      if (this.filterTaskList.length === 0) {
        this.alertmessages("No Result Found Please Try Again !  ", "danger");
      }

    } else {

      console.log("documentsToBeApproved : ", this.documentsToBeApproved)

      this.filteredEmployees = this.documentsToBeApproved.filter(employee =>
        employee.employeeName.toLowerCase().includes(searchText.toLowerCase())
      );

      // Update the filterTaskList with the filtered results
      this.filterTaskList = this.filteredEmployees;

      if (this.filterTaskList.length === 0) {
        this.alertmessages("No Result Found Please Try Again !  ", "danger");
      }
    }

  }

  // getAllData() {
  //   console.log("Hello from getAllData()")
  //   // this.approvalinboxservice.getAllDocData().subscribe(
  //   //   data => {
  //   //     console.log("List Of all approved & rejected data: >>>>", data)
  //   //   }
  //   // )
  // }

  // Addeed on 16/2/2023 - Lezer

  // filterDepartment(filterValue: String) {

  //   console.log("Filter Departmnr : ", this.filterDep, "Filter Status : ", this.filterStatus)

  //   if (this.filterTaskList.length === 0 || this.filterDep === 'all' || this.filterDep === undefined ) {

  //     return this.taskList;
  //   } else {

  //     return this.taskList.filter((dName) => {
  //       return dName.departmentname.toLowerCase() === this.filterDep.toLowerCase();
  //     })
  //   }

  // }

  filterDepartment(filterVOne: String) {

    console.log("filter department  ")
    let filteredlist = [];
    if (this.filterDep === 'all' || this.filterDep === undefined) {
      filteredlist = this.taskList;
    } else {
      filteredlist = this.taskList.filter((dName) => {
        return dName.departmentname.toLowerCase() === this.filterDep.toLowerCase();
      });
    }
    if (this.filterStatus !== undefined) {
      if (this.filterStatus !== "all") {
        filteredlist = filteredlist.filter(status => {
          // console.log(status, this.filterDep, this.filterStatus)
          return status.documents.overAllStatus.toLowerCase() === this.filterStatus.toLowerCase();
        })
      }
    }
    console.log("department filteredlist :", filteredlist)
    this.filterListFromDeptStat = filteredlist;
    console.log("save : ", this.filterListFromDeptStat)

    return filteredlist;

  }

  filterStatusName(filterVTwo: String) {
    console.log("filter status  ")
    let filteredlist = []
    if (this.filterStatus === 'all' || this.filterStatus === undefined) {
      filteredlist = this.taskList;
    } else {
      filteredlist = this.taskList.filter((dName) => {
        return dName.documents.overAllStatus.toLowerCase() === this.filterStatus.toLowerCase();
      });
    }
    if (this.filterDep !== undefined) {
      if (this.filterDep !== "all") {
        filteredlist = filteredlist.filter(dName => {
          // console.log(dName, this.filterDep, this.filterStatus)
          return dName.departmentname.toLowerCase() === this.filterDep.toLowerCase();
        })
      }
    }

    console.log("status filteredlist :", filteredlist)
    this.filterListFromDeptStat = filteredlist;
    console.log("save : ", this.filterListFromDeptStat)

    return filteredlist;
  }

  onScroll() {
    console.log("scrolled!!");
    this.limitPara = this.limitPara + 7;
    console.log(this.limitPara)
    this.render.listen(document.getElementById('navigatetarget'), 'scrolled', () => {
      $('html,body').animate({
        scrollTop: $(`#navigatedest`).offset().top - 90
      },
        'slow');
    })
  }

  approveDocument() {
    // if (this.employeeDocs.comments === null || this.employeeDocs.comments === undefined || this.employeeDocs.comments?.trim()?.length === 0) {
    //   return;
    // }
    this.approvalinboxservice.approveDocument(this.employeeDocs, this.taskId, this.accept).subscribe(
      data => {
        if (data) {
          // console.log(data)
          // console.log("Approved data ------ ", data)

          this.alertmessages(data.successMessages[0]['key'], "success");
          this.getCustomer()
        }
        // if (data.successMessages != null && data.successMessages.length !== 0) {
        //   this.alertservice.success(data.successMessages[0]['key']);
        // }
      },
      error => {
        const response = error.error;
        this.alertmessages("Error Occured Please Try Again !  ", "danger");
        // if (response.errorMessages != null && response.errorMessages.length !== 0) {
        //   this.alertservice.error(response.errorMessages[0]['key']);
        // }
      });
    this.closeCommetModal();
    this.closeApproveModal();
    this.clear();

  }

  clear() {
    this.taskList = new Array<taskInfo>();
    this.employeeDocs = new employeeDocuments();
    this.getCustomer();
    this.rejected = false;
  }

  closeMRQModal() {
    const modal = document.getElementById('showAlert');
    modal.style.display = 'none';
    const overlay = document.getElementById('overlay')
    overlay.style.display = 'none'
  }

  closeCommetModal() {
    const modal = document.getElementById('Comment');
    modal.style.display = 'none';
    const overlay = document.getElementById('overlay')
    overlay.style.display = 'none'
  }

  closeOnlyCommetModal() {
    const modal = document.getElementById('OnlyComment');
    modal.style.display = 'none';
    const overlay = document.getElementById('overlay')
    overlay.style.display = 'none'
  }

  closeApproveModal() {
    const modal = document.getElementById('Approve');
    modal.style.display = 'none';
    const overlay = document.getElementById('overlay')
    overlay.style.display = 'none'
  }

  closeViewModal() {
    $('#imgDownload').remove();
    var modal = document.getElementById('viewUploadFile');
    modal.style.display = "none";
    const overlay = document.getElementById('overlay')
    overlay.style.display = 'none'
  }

  confirmOnlycomment() {
    const modal = document.getElementById('OnlyComment');
    modal.style.display = 'block';
    const overlay = document.getElementById('overlay')
    overlay.style.display = 'block'

  }

  confirmrejection(employeeDocs: employeeDocuments, id: String, accept: Boolean) {
    this.employeeDocs = employeeDocs;
    this.taskId = id;
    this.accept = accept;
    const modal = document.getElementById('Comment');
    modal.style.display = 'block';
    const overlay = document.getElementById('overlay')
    overlay.style.display = 'block'
    this.rejected = true;

  }

  downloadImage(filePath: any) {
    console.log(filePath)
    this.approvalinboxservice.downloadFile(filePath).subscribe(
      data => {
        var downloadUrl = URL.createObjectURL(data);
        console.log(downloadUrl)
        console.log()
        var link = document.createElement("a");
        link.download = this.f_Name;
        link.href = downloadUrl;

        var modalBody = document.getElementById('fileViewer');

        let elem = document.createElement('img');
        elem.setAttribute("id", "imgDownload");
        elem.setAttribute("src", downloadUrl);
        elem.setAttribute("width", "100%");
        elem.setAttribute("height", "375");

        modalBody.appendChild(elem);

        setTimeout(() => {
          var modal = document.getElementById('viewUploadFile');
          modal.style.display = "block";
          const overlay = document.getElementById('overlay')
          overlay.style.display = 'block'
        }, 100);

      },
      error => {
        console.log('Error occured in download file');
      }
    );
  }

  confirmapprove(employeeDocs: employeeDocuments, id: String, accept: Boolean) {
    this.employeeDocs = employeeDocs;
    this.taskId = id;
    this.accept = accept;
    const modal = document.getElementById('Approve');
    modal.style.display = 'block';
    const overlay = document.getElementById('overlay')
    overlay.style.display = 'block'
  }

  myDocumentDownload(filName: any, path: any) {
    console.log(this.filePath, filName, path)
    this.fileSavedPath = path;
    console.log("The File Saved Path is", this.fileSavedPath);
    //this.fileSavedPath = '../../../assets/uploads/';
    this.type = filName.split('.').pop();
    this.f_Name = filName;

    //this.downLoadFile(this.fileSavedPath, this.type, this.f_Name);
    this.download();

  }

  download() {
    console.log("Download Image is called");
    this.docmentInfoService.downloadFile(this.fileSavedPath).subscribe(
      data => {

        var downloadUrl = URL.createObjectURL(data);
        var link = document.createElement("a");
        link.download = this.f_Name;
        link.href = downloadUrl;

        let blob = new Blob([data], { type: this.type });
        let url = window.URL.createObjectURL(blob);

        this.selectedFileBLOB = this.sanitizer.bypassSecurityTrustUrl(url);

        // reader.readAsDataURL(data);
        // var fileURL = URL.createObjectURL(file);
        console.log(this.selectedFileBLOB);

        // window.open(this.f_Name);

        link.click();
        link.remove();
        console.log("The Url is", downloadUrl);

      },
      error => {
        console.log('Error occured in download file');
      }
    );
  }

  // Just to keep track and do exp

  // myDocumentDownload(filName: any, path: any) {
  //   console.log(this.filePath, filName, path)
  //   this.fileSavedPath = path;
  //   console.log("The File Saved Path is", this.fileSavedPath);
  //   //this.fileSavedPath = '../../../assets/uploads/';
  //   this.type = filName.split('.').pop();
  //   this.f_Name = filName;

  //   //this.downLoadFile(this.fileSavedPath, this.type, this.f_Name);
  //   this.download();
  // }

  // Just to keep track and do exp

  // download() {
  //   console.log("Download Image is called");
  //   this.docmentInfoService.downloadFile(this.fileSavedPath).subscribe(
  //     data => {
  //       var downloadUrl = URL.createObjectURL(data);
  //       var link = document.createElement("a");
  //       link.download = this.f_Name;
  //       link.href = downloadUrl;
  //       link.click();
  //       link.remove();
  //       console.log("The Url is", downloadUrl);

  //     },
  //     error => {
  //       console.log('Error occured in download file');
  //     }
  //   );
  // }

  viewdocument(filName: string, path: string) {
    if (path === undefined) {
      path = filName;
    }
    console.log(" ")
    console.log("view document : ", path)
    this.approvalworflow.viewdocument(path).subscribe(
      (response) => {
        console.log(`Our response : ${response}`)
        if (filName.includes('.pdf')) {
          var file = new Blob([response], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        } else if (filName.includes('.jpg') || filName.includes('.jpeg')) {
          var file = new Blob([response], { type: 'image/jpeg' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        } else if (filName.includes('.png')) {
          var file = new Blob([response], { type: 'image/png' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        } else {
          console.log("Other types are not recommended!");
        }
        // console.log(`Here you can see the rsponse oneeeee:${response}`)
        // var file = new Blob([response], {type: 'application/pdf'});
        // var file = new Blob([response], {type: 'image/jpeg'});
        // var fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
      },
      (error) => { },
    )

  }

  filterDepandStatus(event) {
    console.log(event)


    // if(this.filterDep)
    // if(this.filterStatus)

  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
  <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

}
