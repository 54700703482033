export class MyDocuments {
    docGroup: String;

    docType: String;

    name: String;

    empId: String;

    docId: number;

    path: String

    uploadedOn: Date

    expiryDate: Date;

    status: String;

    comments: String;

   
}