import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeDocService {
  protected basePath = '/api';
  public defaultHeaders: Headers = new Headers();
  public url;

  constructor(private common : CommonService , private httpclient:HttpClient) { }

  public deleteVerifiedDoc(id:number){
    this.url = '/v1/employee-verified-doc';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set("id",id);
    return this.httpclient.delete(path , { params : queryParameters })
  }

}
