<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>
        <div class="card-body px-0">
            <div class="page-leftheader my-3">
                <div class="page-title">Apply Comp Off</div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body row">
                        <div class="col-md-3 col-sm-3 col-xs-3">
                            <div class="form-group label-floating col-xl-12 col-md-12 col-lg-12">
                                <label class="form-label">Select day worked<span style="color: rgb(248, 111, 111);">
                                        &nbsp;*</span></label>
                                <div class="input-group">
                                    <input required type="date" id="singledate" name="singledaterange"
                                        class="form-control" placeholder="select dates" [(ngModel)]="date"
                                        (change)="dateFocus()" />

                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-3">
                            <div class="form-group label-floating col-xl-12 col-md-12 col-lg-12">
                                <label class="form-label">In Time</label>
                                <div>{{inTime == undefined ? '--' : inTime | date:'shortTime' }}</div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-3">
                            <div class="form-group label-floating col-xl-12 col-md-12 col-lg-12">
                                <label class="form-label">Out Time</label>
                                <div>{{outTime == undefined ? '--' : outTime | date:'shortTime' }}</div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-3">
                            <div class="form-group label-floating col-xl-12 col-md-12 col-lg-12">
                                <label class="form-label">Total Hours Worked</label>
                                <div>{{totalHour == undefined ? '--' : totalHour }}</div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-4">
                            <div class="form-group label-floating  col-xl-12 col-md-12 col-lg-12">
                                <label class="form-label">Reason<span style="color: rgb(248, 111, 111);">
                                        &nbsp;*</span></label>
                                <textarea required id="reason" [(ngModel)]="reason" class="form-control" rows="1"
                                    placeholder="Please enter reason for work."></textarea>
                            </div>
                        </div>
                        <div class="col-md-8 col-sm-8 col-xs-8">
                            <div class="form-group label-floating col-xl-6 col-md-6 col-lg-6">
                                <label class="form-label">Apply for<span style="color: rgb(248, 111, 111);">
                                        &nbsp;*</span></label>
                                <select required [(ngModel)]="selectedDay" name="leavetypes"
                                    class="form-control custom-select select2" id="leavetypes">
                                    <option value="">Select</option>
                                    <option value="H">Half Day</option>
                                    <option value="F">Full Day</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="d-flex">
                            <div class="ms-auto">
                                <button [disabled] class="btn btn-success" type="submit"
                                    (click)="submit()">Submit</button>
                                <button type="button" class="btn btn-danger ms-2" (click)="clear()">Clear</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-body">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="col-md-12 col-sm-12 col-xs-12">
                                <div class="card-header border-bottom-0">
                                    <h3 class="card-title">Comp Off History</h3>
                                </div>
                                <div class="card-body card1S p-0">
                                    <div class="table-responsive">
                                        <table
                                            class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                            id="hr-table">
                                            <thead class="tablerow">
                                                <tr class="rowcolors stickyatTop">
                                                    <th class="border-bottom-0 w-5 fs-6 ">Worked On
                                                    </th>
                                                    <th class="border-bottom-0 w-5 fs-6 ">Day
                                                    </th>
                                                    <th class="border-bottom-0 w-5 fs-6 ">Reason</th>
                                                    <th class="border-bottom-0 w-5 fs-6 ">Applied Date
                                                    </th>
                                                    <th class="border-bottom-0 w-5 fs-6 ">Status</th>
                                                    <th class="border-bottom-0 w-5 fs-6 ">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody style="max-height: 50vh; overflow: scroll;">
                                                <tr *ngFor="let cm of compOffList; let i = index">
                                                    <!-- <td>{{cm.employeeId}}</td>
                                                    <td>{{cm.employeeName}}</td> -->
                                                    <td>{{cm.leaveDate | date }}</td>
                                                    <td>{{cm.halfFullIndic === "F" ? "Full Day" : "Half Day" }}</td>
                                                    <td>{{cm.reason}}</td>
                                                    <td>{{cm.appliedDate | date }}</td>
                                                    <td>
                                                        <span style="font-size: 13px;font-weight: 500;"
                                                            [ngClass]="cm.leaveStatus === 'Approved' ? 'bg-custom-green text-custom-dark' : cm.leaveStatus === 'Partial Approve 1' ? 'bg-custom-orange1 text-custom-dark' : cm.leaveStatus === 'Partial Approve 2' ? 'bg-custom-orange2 text-custom-dark' :  cm.leaveStatus === 'Rejected' ? 'bg-custom-red text-custom-dark' : cm.leaveStatus === 'Comp Off Cancel Rejected' ? 'badge-danger':cm.leaveStatus === 'Comp Off Cancel Pending' ? 'badge-warning text-custom-dark' : 'bg-custom-warning text-custom-dark'"
                                                            class="badge">{{cm.leaveStatus}}</span>
                                                    </td>
                                                    <td class="text-start d-flex">
                                                        <button *ngIf="cm.leaveStatus !== 'Comp Off Cancel Rejected'"
                                                            type="button" (click)="onDeleteHandler(cm)"
                                                            class="btn btn-danger btn-icon btn-sm"
                                                            data-bs-toggle="tooltip" data-bs-placement="top"
                                                            title="Delete">
                                                            <i class="feather feather-trash-2 "></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div id="showAlertVer" class="modal" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content animate">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">×</button>
                <h4 class="modal-title">Warning</h4>
            </div>

            <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
                <!-- auto scroll -->
                <span><b> Leave entry will be deleted permanently . Are you sure you want to continue ?</b></span>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-danger medium" (click)="confirmLeaveDelete()">OK</button>
                <button type="button" class="btn btn-primary" (click)="closeMRQModal()">Cancel</button>

            </div>

        </div>
    </div>
</div>