import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { EmployeeAttendanceSummary } from 'src/app/models/EmployeeAttendanceSummary';
import { EmployeeMaster } from 'src/app/models/employeeMasterts';
import { AttendanceService } from 'src/app/services/attendance.service';
import { EmployeeLeaveService } from 'src/app/services/employee-leave.service';
import { DocumentInfoService } from 'src/app/services/documentinfo.service';
declare var $: any;

@Component({
  selector: 'app-consolidated-report',
  templateUrl: './consolidated-report.component.html',
  styleUrls: ['./consolidated-report.component.css']
})
export class ConsolidatedReportComponent implements OnInit {
  query: any;
  currentDate: string;
  // uempid: number=2;
  // fromdate: string;
  // todate: string;
  reportType: string = "Consolidated Report";
  nowDetailedReport: boolean = false;
  leaveTypeMap = new Map();
  employeeMaster: EmployeeMaster[];
  client_id: EmployeeMaster[];
  uempid: string = "";
  consolidatedReport1: EmployeeMaster[];
  detailedConsolidatedReport: EmployeeMaster[];

  detailedReport: EmployeeAttendanceSummary[];
  detailReportInConsolidateReport: EmployeeAttendanceSummary[];

  contractstream$!: Subscription;
  receivedData: any;
  startDate: boolean;
  endDate: boolean;
  submitted: boolean;
  popname: any;
  selectedRemarks:any[];
  constructor(private _snackBar: MatSnackBar, private empLeaveService: EmployeeLeaveService, private attendanceService: AttendanceService, private documentInfoService: DocumentInfoService) {

  }


  // const currentDate = new Date();
  //   this.currentDate = this.fromDate;

  @Input() fromDate: string;
  @Input() toDate: string;
  @Input() shift_setting: string;
  @Input() consolidatedReport: EmployeeMaster[] = [];
  @Input() branch: any;
  @Input() selectedempid: any;
  @Input() keyinempid: any;



  ngOnInit(): void {
  }


  consolidate_to_deatiled(id, name, i , remarks?:any) {
    this.popname = name;
    console.log(remarks)
    this.selectedRemarks = remarks;
    this.currentDate = this.fromDate;
    console.log(" selected  id   : ", id, " index : ", i);
    this.reportType = "Detailed Report";
    console.log("Report Type", this.reportType)


    console.log("passing value  from date 2 ", this.fromDate, "  todate ", this.toDate, " currentdate ", this.currentDate)
    console.log(" report typee ", this.reportType, " id ", id, " shift : ")
    this.contractstream$ = this.attendanceService.getAttendenceReport(this.reportType, this.fromDate, this.toDate, this.currentDate, id, this.shift_setting, this.branch).subscribe(
      data => {
        console.log("Attedance report parent  : ", data)
        if (this.reportType === "Detailed Report") {
          console.log("selectd is type detailed true child :", this.reportType)
          this.detailedReport = data.attendanceDetails[0].attendanceList;
          this.detailedConsolidatedReport = data.attendanceDetailsConsolidate;
          console.log("check this : ", this.detailedReport, " ", this.detailedConsolidatedReport)
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  attendanceReportCSVDownload(reporttype: string) {
    // console.log(`Unique Emp Id: ${this.uniqueEmployeeId}, Year: ${year}, Month: ${month}`)
    console.log("consolidate report value from  parent ")
    console.log("1: ", this.fromDate, this.toDate, this.shift_setting, this.branch, this.selectedempid ? this.selectedempid : sessionStorage.getItem('id'))

    console.log("2: ", this.fromDate, this.toDate, this.shift_setting, this.branch, this.keyinempid)
    const listingFilters="GBB";
    this.documentInfoService.downloadAttendanceReportCSVFile(reporttype, this.keyinempid ? this.keyinempid : null, this.fromDate, this.toDate, this.currentDate, null, this.branch ,listingFilters).subscribe(data => {
      // console.log(data)
      this.keyinempid = null;
      var file = new Blob([data], { type: 'application/vnd.ms-excel' });
      var fileURL = URL.createObjectURL(file);
      var anchor = document.createElement("a");
      anchor.download = "ConsolidatedAttendanceReport.xls";
      anchor.href = fileURL;
      anchor.click();
      //window.open(fileURL);
    },
      error => {
        console.log("Error Occured ", error);
        // console.log("Error Code ",error.status);
      })
  }

}
