import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';
@Injectable({
  providedIn: 'root'
})
export class NoticeboardService {
  protected basePath = '/api';
	public defaultHeaders: Headers = new Headers();
	public url;

  constructor(private constantservice:CommonService, private httpclient: HttpClient,) { }

  public saveNoticeInfo(info:any):Observable<any>{
    this.url = '/v1/notice';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    console.log("Update data", info)
    return this.constantservice.postWithHttpInfo(info , queryParameters , path);
  }

  public updateNoticeInfo(info:any):Observable<any>{
    this.url = '/v1/noticeupdate';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    console.log("Update data", info)
    return this.constantservice.postWithHttpInfo(info , queryParameters , path);
  }

 

  public getnoticeinfolist(limit:number , offset:number):Observable<any>{
    this.url = '/v1/noticeall';
		const path = this.basePath + this.url;
		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('limit', limit.toString());
        queryParameters = queryParameters.set('offset', offset.toString());
		return this.constantservice.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getnoticeinfolist_based_on_currendate(datetime:string):Observable<any>{
    this.url = '/v1/noticeinfo-based-on-date';
		const path = this.basePath + this.url;
		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('currentdatetime', datetime);
		return this.constantservice.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public noticeDelete(noticeId: number): Observable<any> {
    this.url = '/v1/noticeboardDelete';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('id', noticeId)

    console.log("We are in service : Delete", noticeId)
    return this.httpclient.delete(path, {
      params: queryParameters
    })
  }

  public notifiacationTable(limitPara : any, offsetPara : any):Observable<any>{
    this.url = '/v1/notification';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    console.log("notification endpoint called")
    queryParameters = queryParameters.set('limitPara',limitPara);
    queryParameters = queryParameters.set('offsetPara',offsetPara);
    return this.constantservice.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  // readNotification
  public readNotification(info:any):Observable<any>{
    this.url = '/v1/readnotification';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    console.log("read notification of : ", info)
    // queryParameters = queryParameters.set('readnotifiaction', offset.toString());
    return this.constantservice.postWithHttpInfo(info , queryParameters , path);
  }
}
