import { error } from '@angular/compiler/src/util';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ruleDetailCredit, RuleDetails } from 'src/app/models/RuleDetails';
import { salaryHeadMaster } from 'src/app/models/SalaryMaster';
import { UserDetails } from 'src/app/models/UserDetails';
import { EmpsalaryslipService } from 'src/app/services/empsalaryslip.service';

@Component({
  selector: 'app-pay-calculate-rules',
  templateUrl: './pay-calculate-rules.component.html',
  styleUrls: ['./pay-calculate-rules.component.css']
})
export class PayCalculateRulesComponent implements OnInit {
  emp: any;

  constructor(private empService: EmpsalaryslipService) { }

  addOrUpdate: boolean = true;
  add_view_toggle: string = 'viewPayCalculateRule'; // viewPayCalculateRule addPayCalculateRule
  salaryMaster: any[] = [];
  salaryMasterWithrules = new RuleDetails();
  insertPayCalRules;
  detailMasterSection: any;
  detailMasterCompSection: any;

  // rules 
  ruleDetailMaster = new RuleDetails();
  insertEmployeeRuleDetail;
  insertEmployeerRuleDetail;
  insertAdministrativeCharge;
  employeeRuleDetail: any[] = [];
  employerRuleDetail: any[] = [];
  administrativeCharge: any[] = [];

  @ViewChild('employeeContributionForm') employeeContributionForm: NgForm;
  @ViewChild('employeerContributionForm') employeerContributionForm: NgForm;
  @ViewChild('administrativeChargeForm') administrativeChargeForm: NgForm;
  @ViewChild('payCalcualteForm') payCalcualteForm: NgForm;


  ngOnInit(): void {
    this.insertPayCalRules = new RuleDetails(); // create instace of class 
    this.getPayCal_HeadDetail();

    // this.payCalcualteForm.form.reset();
    this.insertEmployeeRuleDetail = new ruleDetailCredit();
    this.insertEmployeerRuleDetail = new ruleDetailCredit();
    this.insertAdministrativeCharge = new ruleDetailCredit();
  }

  getPayCal_HeadDetail() {
    this.getSalaryMaster();
    this.getPayCalRule();
  }

  getPayCalRule() {
    this.empService.getPayCalRuleDetails().subscribe((data) => {
      console.log(data);
      this.salaryMasterWithrules = data.rulesDetail;  // edit and delete here 
      this.ruleDetailMaster = data.rulesDetail;

      console.log("salarymaster with rules :", this.salaryMasterWithrules);
      console.log("ruledetalsMaster :", this.ruleDetailMaster);

    }, (error) => {
      console.log(error);
    });
  }

  getSalaryMaster() {
    this.empService.getSalaryDetails().subscribe((data) => {
      console.log(data);
      this.salaryMaster = data.salaryHeadMaster;
      this.payCalcualteForm.form.reset();
    }, (error) => {
      console.log(error);
    });
  }

  addPayCalculateRule() {
    this.add_view_toggle = 'addPayCalculateRule';
  }

  preventNegative(event: KeyboardEvent) {
    // Allow backspace, delete, tab, left/right arrows, home/end keys
    if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'Tab' ||
      event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'Home' || event.key === 'End') {
      return;
    }

    // Allow only numbers and decimal point
    if (!/[0-9.]/.test(event.key)) {
      event.preventDefault();
    }
  }

  updatePayCalculateRule() {
    console.log("update pay calculate rule ");
    console.log(this.insertPayCalRules);

    if (this.insertPayCalRules.ruleName == undefined || this.insertPayCalRules.ruleName.trim().length == 0) {
      this.alertmessages("Rule Name is Required", "danger");
      return;
    }

    if (this.insertPayCalRules.salaryHeadId == undefined || this.insertPayCalRules.salaryHeadId.trim().length == 0) {
      this.alertmessages("Salary Head Name is Required", "danger");
      return;
    }

    if (this.insertPayCalRules.salaryDetailId == undefined || this.insertPayCalRules.salaryDetailId.trim().length == 0) {
      this.alertmessages("Salary Detail Name is Required", "danger");
      return;
    }

    if (this.insertPayCalRules.percentTotalIndic == undefined || this.insertPayCalRules.percentTotalIndic.trim().length == 0) {
      this.alertmessages("Percent / Total Indic is Required", "danger");
      return;
    }
    if (this.insertPayCalRules.grossSelectIndic == undefined || this.insertPayCalRules.grossSelectIndic.trim().length == 0) {
      this.alertmessages("Gross Select Indic is Required", "danger");
      return;
    }

    // rules 
    console.log("Update  clicked");
    const combinedList = [...this.employeeRuleDetail, ...this.employerRuleDetail, ...this.administrativeCharge];
    console.log(combinedList);

    this.insertPayCalRules.rulesDetail = [];
    this.insertPayCalRules.ruleDetails = combinedList;

    console.log("final object to sent for update is ", this.insertPayCalRules);


    // update pay cal comp  
    this.empService.updatePayCalRuleMaster(this.insertPayCalRules).subscribe(
      (data) => {
        console.log(data);
        this.getPayCal_HeadDetail();
        this.viewPayCalculateRule();
        this.alertmessages("Successfully Updated Pay Calculate Rule", "success");
      }, (error) => {
        console.log(error)
        this.alertmessages("Please Try Again , Rule is in Use", "danger");
      });


    // this is from rule 
    // updateRuleDetails
    // this.empService.updateRuleDetails(combinedList).subscribe((data) => {
    //   console.log(data);
    //   this.alertmessages("Successfully saved rule details ", "success");
    //   this.viewPayCalculateRule();
    //   // this.getRuleDetail();
    // }, (error) => {
    //   console.log(error);
    //   this.alertmessages("Please Try Again", "danger");
    // });

  }

  viewPayCalculateRule() {
    this.add_view_toggle = 'viewPayCalculateRule';
    this.addOrUpdate = true;
    this.clear();
  }

  clear() {
    this.detailMasterSection = [];
    this.insertPayCalRules.salaryHeadId = '';
    this.insertPayCalRules.salaryHeadName = '';
    this.insertPayCalRules.salaryDetailId = '';
    this.insertPayCalRules.salaryDetailName = '';
    this.insertPayCalRules.ruleId = '';
    this.insertPayCalRules.ruleName = '';
    this.insertPayCalRules.percentTotalIndic = '';
    this.insertPayCalRules.grossSelectIndic = '';
    this.insertPayCalRules.otAmountIncluded = '';
    this.insertPayCalRules.bonusAmount = '';
    this.insertPayCalRules.userId = '';
    this.insertPayCalRules.daArears = false;
    this.insertPayCalRules.ruleComp = [];

    // rules 
    this.employeeRuleDetail = [];
    this.employerRuleDetail = [];
    this.administrativeCharge = [];
    this.employeeContributionForm.form.reset();
    this.employeerContributionForm.form.reset();
    this.administrativeChargeForm.form.reset();
    this.payCalcualteForm.form.reset();
  }

  submit() {
    console.log(" save ", this.insertPayCalRules);

    if (this.insertPayCalRules.ruleName == undefined || this.insertPayCalRules.ruleName.trim().length == 0) {
      this.alertmessages("Rule Name is Required", "danger");
      return;
    }

    if (this.insertPayCalRules.salaryHeadId == undefined || this.insertPayCalRules.salaryHeadId.trim().length == 0) {
      this.alertmessages("Salary Head Name is Required", "danger");
      return;
    }

    if (this.insertPayCalRules.salaryDetailId == undefined || this.insertPayCalRules.salaryDetailId.trim().length == 0) {
      this.alertmessages("Salary Detail Name is Required", "danger");
      return;
    }

    if (this.insertPayCalRules.percentTotalIndic == undefined || this.insertPayCalRules.percentTotalIndic.trim().length == 0) {
      this.alertmessages("Percent / Total Indic is Required", "danger");
      return;
    }
    if (this.insertPayCalRules.grossSelectIndic == undefined || this.insertPayCalRules.grossSelectIndic.trim().length == 0) {
      this.alertmessages("Gross Select Indic is Required", "danger");
      return;
    }

    const combinedList = [...this.employeeRuleDetail, ...this.employerRuleDetail, ...this.administrativeCharge];
    console.log('combined list ', combinedList);

    this.insertPayCalRules.ruleDetails = combinedList;

    console.log("insertPayCalRules ", this.insertPayCalRules);

    this.empService.savePayCalRuleMaster(this.insertPayCalRules).subscribe(
      (data) => {
        console.log(data);
        this.getPayCal_HeadDetail();
        this.viewPayCalculateRule();
        this.alertmessages("Successfully saved Pay Calculated Rules", "success");
      }, (error) => {
        console.log(error)
        this.alertmessages("Please Try Again", "danger");
      });


    // rules 

    // this.empService.saveRuleDetail(combinedList).subscribe((data) => {
    //   console.log(data);
    //   this.alertmessages("Successfully saved rule Details ", "success");
    //   // this.getRuleDetail();
    // }, (error) => {
    //   console.log(error);
    //   this.alertmessages("Please Try Again", "danger");
    // });

  }

  editPayCalculateRule(info: any) {
    console.log("edit pcr");

    this.addOrUpdate = false;
    this.add_view_toggle = 'addPayCalculateRule';

    this.insertPayCalRules.salaryHeadId = info.salaryHeadId;
    this.insertPayCalRules.salaryHeadName = info.salaryHeadName;
    this.insertPayCalRules.salaryDetailId = info.salaryDetailId;
    this.insertPayCalRules.salaryDetailName = info.salaryDetailName;
    this.insertPayCalRules.ruleId = info.ruleId;
    this.insertPayCalRules.ruleName = info.ruleName;
    this.insertPayCalRules.percentTotalIndic = info.percentTotalIndic;
    this.insertPayCalRules.grossSelectIndic = info.grossSelectIndic;
    this.insertPayCalRules.otAmountIncluded = info.otAmountIncluded;
    this.insertPayCalRules.bonusAmount = info.bonusAmount;
    this.insertPayCalRules.userId = info.userId;
    this.insertPayCalRules.daArears = info.daArears;
    this.insertPayCalRules.ruleComp = info.ruleComp;
    this.insertPayCalRules.ruleDetails = info.ruleDetails;

    this.editRuleDetail(info); // for rules to sort based on indicator 

    console.log('value set has ', this.insertPayCalRules);
    this.headChange(this.insertPayCalRules.salaryHeadId);
    this.compChange(this.insertPayCalRules.salaryHeadId);


  }

  deletePayCalculateRule(info: any) {
    console.log("delete function ", info);

    // delete pay cal rule  + rules 
    this.empService.deletePayCalculateRule(info).subscribe((data) => {
      this.alertmessages("Successfully deleted ", "success");
      this.getPayCalRule();
    }, (error) => {
      this.alertmessages("Rule Detail is in use", "danger");
    })

  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

  headChange(info: any) {
    console.log("head change ");
    this.detailMasterSection = this.salaryMaster.find(head => head.salaryHeadId == info).salaryDetailMaster;
    console.log(this.detailMasterSection);
  }

  compChange(info: any) {
    console.log("component change ");
    this.detailMasterCompSection = this.salaryMaster.find(head => head.salaryHeadId == info).salaryDetailMaster;
    console.log(this.detailMasterCompSection);
  }

  addRulesComp(headid, detailid) {

    let headName = this.salaryMaster.find(ele => ele.salaryHeadId == headid).salaryHeadName;
    let detailName;

    if (this.detailMasterCompSection.length > 1) {
      detailName = this.detailMasterCompSection.find(ele => ele.salaryDetailId == detailid)?.salaryDetailName;
    } else {
      detailName = this.detailMasterCompSection[0].salaryDetailName;
    }
    if (detailid == undefined || detailid == '') {
      detailid = this.detailMasterCompSection[0].salaryDetailId;
    }

    const obj = {
      selSalCompId: '',
      ruleId: '',
      selSalaryHeadId: headid,
      selSalaryHeadName: headName,
      selSalaryDetailId: detailid,
      selSalaryDetailName: detailName,
      userId: 'Add',
    }

    if (obj.selSalaryHeadId != '' && obj.selSalaryDetailId != '' && obj.selSalaryDetailName != undefined && obj.selSalaryHeadName != undefined) {
      this.insertPayCalRules.ruleComp.push(obj);
      this.insertPayCalRules.ruleComp.selSalaryHeadId = '';
      this.insertPayCalRules.ruleComp.selSalaryHeadName = '';
      this.insertPayCalRules.ruleComp.selSalaryDetailId = '';
      this.insertPayCalRules.ruleComp.selSalaryDetailName = '';
      this.detailMasterCompSection = [];
      console.log("after push ", this.insertPayCalRules.ruleComp);
    }

  }

  deleteRulesComp(index) {
    console.log(" slice obj from ruleComp ", index);
    if (index !== null && index !== undefined) {

      // this.insertPayCalRules.ruleComp.splice(index, 1);

      console.log("this indez ", this.insertPayCalRules.ruleComp[index]);
      this.insertPayCalRules.ruleComp[index].userId = 'Del';
      console.log("List existing ", this.insertPayCalRules.ruleComp);

    } else {
      console.error("Select Object to Delete!");
    }
  }

  onChangeOff(info: any) {
    console.log("change in fixed component ", this.insertPayCalRules.grossSelectIndic);

    if (this.insertPayCalRules.grossSelectIndic !== 'S') {
      const element = document.getElementById('addAdditionalComp');
      this.insertPayCalRules.ruleComp = [];
      console.log("add disable ");
      element.classList.add('disable');
    } else {
      const element = document.getElementById('addAdditionalComp');
      console.log("remove disable ");
      element.classList.remove('disable');
    }
  }

  // rules 
  // getRuleDetail() {
  //   this.empService.getRuleDetails().subscribe((data) => {
  //     this.ruleDetailMaster = data.rulesDetail;
  //     console.log("rule detail master : ", this.ruleDetailMaster);
  //   }, (error) => {
  //     console.log(error);
  //   });
  // }

  clearEmployeeRule() {

    this.insertEmployeeRuleDetail.ruleDetailId = '';
    this.insertEmployeeRuleDetail.ruleId = '';
    this.insertEmployeeRuleDetail.salaryLowerLimit = '';
    this.insertEmployeeRuleDetail.salaryUpperLimit = '';
    this.insertEmployeeRuleDetail.percentAmount = '';
    this.insertEmployeeRuleDetail.calcLowerLimit = '';
    this.insertEmployeeRuleDetail.calcUpperLimit = '';
    this.insertEmployeeRuleDetail.indicator = '';
    this.insertEmployeeRuleDetail.userId = '';
  }

  clearEmployerRule() {
    this.insertEmployeerRuleDetail.ruleDetailId = '';
    this.insertEmployeerRuleDetail.ruleId = '';
    this.insertEmployeerRuleDetail.salaryLowerLimit = '';
    this.insertEmployeerRuleDetail.salaryUpperLimit = '';
    this.insertEmployeerRuleDetail.percentAmount = '';
    this.insertEmployeerRuleDetail.calcLowerLimit = '';
    this.insertEmployeerRuleDetail.calcUpperLimit = '';
    this.insertEmployeerRuleDetail.indicator = '';
    this.insertEmployeerRuleDetail.userId = '';
  }

  clearAdministrativeRule() {
    this.insertAdministrativeCharge.ruleDetailId = '';
    this.insertAdministrativeCharge.ruleId = '';
    this.insertAdministrativeCharge.salaryLowerLimit = '';
    this.insertAdministrativeCharge.salaryUpperLimit = '';
    this.insertAdministrativeCharge.percentAmount = '';
    this.insertAdministrativeCharge.calcLowerLimit = '';
    this.insertAdministrativeCharge.calcUpperLimit = '';
    this.insertAdministrativeCharge.indicator = '';
    this.insertAdministrativeCharge.userId = '';
  }

  editRuleDetail(info) {
    console.log("edit rd", info);
    this.addOrUpdate = false;
    this.add_view_toggle = 'addPayCalculateRule';

    console.log("ruleDetails", info.ruleDetails);

    for (let i = 0; i < info.ruleDetails.length; i++) {
      const detail = info.ruleDetails[i];
      if (detail.indicator === 'A') {
        this.administrativeCharge.push(detail);
      } else if (detail.indicator === 'F') {
        this.employerRuleDetail.push(detail);
      } else if (detail.indicator === 'E') {
        this.employeeRuleDetail.push(detail);
      }
    }
  }

  // deleteRuleDetail(info) {
  //   console.log("delete ", info.ruleId);
  //   this.empService.deleteRuleDetail(info.ruleId).subscribe((data) => {
  //     console.log(data);
  //     this.alertmessages("Successfully deleted", "success");
  //   }, (error) => {
  //     console.log(error);
  //     this.alertmessages("Please Try Again", "danger");
  //   });
  // }

  addEmployeeRule() {
    console.log("add employee comp ");

    const obj1 = {
      ruleDetailId: '',
      salaryLowerLimit: this.insertEmployeeRuleDetail.salaryLowerLimit === null ? 0 : this.insertEmployeeRuleDetail.salaryLowerLimit,
      salaryUpperLimit: this.insertEmployeeRuleDetail.salaryUpperLimit === null ? 0 : this.insertEmployeeRuleDetail.salaryUpperLimit,
      percentAmount: this.insertEmployeeRuleDetail.percentAmount === null ? 0 : this.insertEmployeeRuleDetail.percentAmount,
      calcLowerLimit: this.insertEmployeeRuleDetail.calcLowerLimit === null ? 0 : this.insertEmployeeRuleDetail.calcLowerLimit,
      calcUpperLimit: this.insertEmployeeRuleDetail.calcUpperLimit === null ? 0 : this.insertEmployeeRuleDetail.calcUpperLimit,
      indicator: 'E',
      userId: 'Add',
    }

    const valuesToCheck = [
      obj1.salaryLowerLimit,
      obj1.salaryUpperLimit,
      obj1.percentAmount,
      obj1.calcLowerLimit,
      obj1.calcUpperLimit,
    ];

    for (const value of valuesToCheck) {
      if (isNaN(value) || value < 0) {
        this.alertmessages("Please enter valid amount", "danger");
        this.employeeContributionForm.form.reset();
        return;
      }
    }

    if (!obj1.salaryLowerLimit && !obj1.salaryUpperLimit &&
      !obj1.percentAmount && !obj1.calcLowerLimit && !obj1.calcUpperLimit) {
      this.alertmessages("Please enter amount for Employee Contribution", "danger");
      this.employeeContributionForm.form.reset();
      return;
    }

    this.employeeRuleDetail.push({ ...obj1 });
    this.clearEmployeeRule();
    console.log("addEmployeeRule ", this.employeeRuleDetail);
    this.employeeContributionForm.form.reset();

  }

  addEmployerRule() {
    console.log("add employer comp ");

    const obj2 = {
      ruleDetailId: '',
      salaryLowerLimit: this.insertEmployeerRuleDetail.salaryLowerLimit === null ? 0 : this.insertEmployeerRuleDetail.salaryLowerLimit,
      salaryUpperLimit: this.insertEmployeerRuleDetail.salaryUpperLimit === null ? 0 : this.insertEmployeerRuleDetail.salaryUpperLimit,
      percentAmount: this.insertEmployeerRuleDetail.percentAmount === null ? 0 : this.insertEmployeerRuleDetail.percentAmount,
      calcLowerLimit: this.insertEmployeerRuleDetail.calcLowerLimit === null ? 0 : this.insertEmployeerRuleDetail.calcLowerLimit,
      calcUpperLimit: this.insertEmployeerRuleDetail.calcUpperLimit === null ? 0 : this.insertEmployeerRuleDetail.calcUpperLimit,
      indicator: 'F',
      userId: 'Add',
    }

    const valuesToCheck = [
      obj2.salaryLowerLimit,
      obj2.salaryUpperLimit,
      obj2.percentAmount,
      obj2.calcLowerLimit,
      obj2.calcUpperLimit,
    ];

    for (const value of valuesToCheck) {
      if (isNaN(value) || value < 0) {
        this.alertmessages("Please enter valid amount", "danger");
        this.employeeContributionForm.form.reset();
        return;
      }
    }

    if (!obj2.salaryLowerLimit && !obj2.salaryUpperLimit &&
      !obj2.percentAmount && !obj2.calcLowerLimit && !obj2.calcUpperLimit) {
      this.alertmessages("Please enter amount for Employeer Contribution", "danger");
      this.employeerContributionForm.form.reset();
      return;
    }

    this.employerRuleDetail.push({ ...obj2 });
    this.clearEmployerRule();
    console.log("addEmployerRule ", this.employerRuleDetail);
    this.employeerContributionForm.form.reset();

  }

  addAdministrativeRule() {
    console.log("add administritive Charge ");

    const obj3 = {
      ruleDetailId: '',
      salaryLowerLimit: this.insertAdministrativeCharge.salaryLowerLimit === null ? 0 : this.insertAdministrativeCharge.salaryLowerLimit,
      salaryUpperLimit: this.insertAdministrativeCharge.salaryUpperLimit === null ? 0 : this.insertAdministrativeCharge.salaryUpperLimit,
      percentAmount: this.insertAdministrativeCharge.percentAmount === null ? 0 : this.insertAdministrativeCharge.percentAmount,
      calcLowerLimit: this.insertAdministrativeCharge.calcLowerLimit === null ? 0 : this.insertAdministrativeCharge.calcLowerLimit,
      calcUpperLimit: this.insertAdministrativeCharge.calcUpperLimit === null ? 0 : this.insertAdministrativeCharge.calcUpperLimit,
      indicator: 'A',
      userId: 'Add',
    }

    const valuesToCheck = [
      obj3.salaryLowerLimit,
      obj3.salaryUpperLimit,
      obj3.percentAmount,
      obj3.calcLowerLimit,
      obj3.calcUpperLimit,
    ];

    for (const value of valuesToCheck) {
      if (isNaN(value) || value < 0) {
        this.alertmessages("Please enter valid amount", "danger");
        this.employeeContributionForm.form.reset();
        return;
      }
    }

    if (!obj3.salaryLowerLimit && !obj3.salaryUpperLimit &&
      !obj3.percentAmount && !obj3.calcLowerLimit && !obj3.calcUpperLimit) {
      this.alertmessages("Please enter amount for Administrative Charge", "danger");
      this.administrativeChargeForm.form.reset();
      return;
    }

    this.administrativeCharge.push({ ...obj3 });
    this.clearAdministrativeRule();
    console.log("administrativeCharge ", this.administrativeCharge);
    this.administrativeChargeForm.form.reset();

  }

  deleteEmployeeRule(index) {

    console.log(" slice obj from deleteEmployeeRule ", index);
    if (index !== null && index !== undefined) {

      console.log("this indez ", this.employeeRuleDetail[index]);
      this.employeeRuleDetail[index].userId = 'Del';
      console.log("List existing ", this.employeeRuleDetail);

    } else {
      console.error("Select Object to Delete!");
    }
  }

  deleteEmployerRule(index) {
    console.log(" slice obj from deleteEmployerRule ", index);
    if (index !== null && index !== undefined) {

      console.log("this indez ", this.employerRuleDetail[index]);
      this.employerRuleDetail[index].userId = 'Del';
      console.log("List existing ", this.employerRuleDetail);

    } else {
      console.error("Select Object to Delete!");
    }
  }

  deleteAdministrativeRule(index) {
    console.log(" slice obj from deleteAdministrativeRule ", index);
    if (index !== null && index !== undefined) {

      console.log("this indez ", this.administrativeCharge[index]);
      this.administrativeCharge[index].userId = 'Del';
      console.log("List existing ", this.administrativeCharge);

    } else {
      console.error("Select Object to Delete!");
    }
  }

}
