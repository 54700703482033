import {Injectable} from '@angular/core';

@Injectable()
export class SessionService {


    /*to get auth token*/
    getAuthToken() {
        const authToken = sessionStorage.getItem('authToken');
        return authToken;
    }

    /* to set auth token in Session storage*/
    setAuthToken(authToken: string) {
        if (authToken) {
            sessionStorage.setItem('authToken', authToken);
        }
    }
    
    /* to get logged user's role */
	getLoggedUserRole(){
		return sessionStorage.getItem("role");	
	}

    getRolePermissionList(){
        if(sessionStorage.getItem("permission")) {
            return JSON.parse(sessionStorage.getItem("permission"));

        }
		return [];	
	}

    getLoggedUserId(){
		return sessionStorage.getItem("id");	
	}
    getloggedusername(){
        return sessionStorage.getItem("username");
    }

    getempid(){
        return sessionStorage.getItem("empId");
    }
}
