import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ContractclientService } from '../services/contractclient.service';
import { ContractclientDocument } from 'src/app/models/ContractClientDocument';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscriber, Subscription } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-contract-client',
  templateUrl: './contract-client.component.html',
  styleUrls: ['./contract-client.component.css']
})

export class ContractClientComponent implements OnInit {

  contractClientData: ContractclientDocument;
  contractClientDataMaster: ContractclientDocument;
  deleteClientId: number;
  searchBusinessName: string;
  displayContractClientData: ContractclientDocument[] = [];
  scrollPoint: number;
  contractclientlist = [];
  clientData: ContractclientDocument[];
  proOwnerName: ContractclientDocument;
  contractstream$!: Subscription;
  indian_states: any[] = ["Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal"];
  billing_types: any[];

  // Validation
  submitted: boolean;
  contactNumberlength: boolean = false;
  contactNumberOnly: boolean = false;
  gstinNumber: boolean = false;
  pinCodelength: boolean = false;
  pinCodeOnly: boolean = false;
  @ViewChild('contractClientForm') contractClientForm: NgForm;
  constructor(private _snackBar: MatSnackBar, private contractclientService: ContractclientService,
    private render: Renderer2) { }

  ngOnInit() {

    this.contractClientData = new ContractclientDocument();
    this.contractClientDataMaster = new ContractclientDocument();

    this.getContractClientData();
    this.getContractClientBillingTypes();

    this.render.listen(document.getElementById('navigateclient'), 'click', () => {
      $('html,body').animate({
        scrollTop: $(`#add-update-client`).offset().top - 90
      },
        'slow');
    })
  }

  getContractClientData() {
    // console.log(Session["empId"].ToString());
    // var mySessionVar="<%= Session["empId"] %>";

    this.contractstream$ = this.contractclientService.getContractClient().subscribe(
      data => {
        console.log(data)
        this.displayContractClientData = data.client;
        this.scrollLength()
        // console.log("List of all contract client >>>> ", this.displayContractClientData);
      },
      error => {
        // console.log(error);
      }
    );
  }

  // scrollLength() {
  //   console.log("dynamic height on scroll ");
  //   console.log("");
  //   const regex = /^(\d+(?:\.\d+)?)\s*([a-zA-Z%]+)$/;
  //   let height = getComputedStyle(document.getElementById("scrolllength")).getPropertyValue("max-height")
  //   console.log("reg exp : ", regex, "height ", height)
  //   const computedHeight = height.match(regex);
  //   console.log( "computedheight : ", computedHeight)
  //   if (computedHeight && Number(computedHeight[1]) < 450) {
  //     let value: any = Number(computedHeight[1]);
  //     value = value + (2 * 19.2);
  //     value = value + "px";
  //     console.log("final vl:", value)

  //     document.getElementById("scrolllength").style.maxHeight = value
  //   }
  //   console.log("");
  // }

  scrollLength() {
    console.log("dynamic height on scroll ");
    console.log("");
    const regex = /^(\d+(?:\.\d+)?)\s*([a-zA-Z%]+)$/;
    const height = getComputedStyle(document.getElementById("scrolllength")).getPropertyValue("max-height")
    const computedHeight = height.match(regex);
    console.log("computed height", computedHeight, "computed height in no ", Number(computedHeight[1]), "height ", height)
    if (computedHeight && Number(computedHeight[1]) < 450) {
      let value: any = Number(computedHeight[1]);
      console.log("default value = ", value)
      value = value + (2 * 55);
      value = value + "px";
      console.log("final vl:", value)
      console.log("transition")
      document.getElementById("scrolllength").style.maxHeight = value
    }
    console.log("");
  }


  getContractClientBillingTypes() {
    this.contractstream$ = this.contractclientService.getContractClientBillingTypes().subscribe(
      data => {
        console.log("Type list : ", data)
        this.billing_types = data.contractClientBillingType;
      },
      error => {
        // console.log(error);
      }
    );
  }

  getDataFromSearch() {
    console.log("Search value : ", this.searchBusinessName)
    if (this.searchBusinessName == undefined || this.searchBusinessName?.length == 0 || this.searchBusinessName == null) {
      this.searchBusinessName = undefined;
      this._snackBar.open("Enter client name", 'Okay', {
        duration: 2000,
      });
    } else {
      this.clientData = [];
      this.clientData = this.displayContractClientData.filter((dName) => {
        return dName.business_name.toLowerCase() === this.searchBusinessName.toLowerCase();
      });

      if (this.clientData[0] != null) {
        document.getElementById('update-btn').style.display = "inline-block";
        document.getElementById('save-btn').style.display = "none";
        document.getElementById('alter-text').innerText = "Update";
        this.contractClientData = { ...this.clientData[0] };
        $('html,body').animate({
          scrollTop: $('#add-update-client').offset().top - 90
        },
          'slow');
        console.log(this.contractClientData, this.clientData[0]);
      } else {
        this._snackBar.open("Couldn't find client", 'Okay', {
          duration: 2000,
        });
      }
    }
    this.contractClientData.business_name = this.contractClientData.business_name.trim()
    console.log("Client data : ", this.contractClientData.business_name)
  }

  saveContractClientData(valid) {
    this.submitted = true;
    this.contactNumberlength = false;
    this.contactNumberOnly = false;
    this.gstinNumber = false;

    // if(this.contractClientData.gstin == undefined){
    //   this.contractClientData.gstin = null;
    // }

    console.log("GST in num : ", this.gstinNumber);

    if (!valid) {
      // console.log("Not valid")
      return;
    }

    if (!this.checkPhoneNumber()) {
      return;
    }

    if (this.contractClientData.gstin == undefined || this.contractClientData.gstin?.length == 0 || this.contractClientData.gstin == null) {
      this.contractClientData.gstin = undefined;
    }

    if ((this.contractClientData.gstin != undefined) && !(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(this.contractClientData.gstin))) {
      // console.log("Enter proper GSTIN number")
      this.submitted = false;
      this.gstinNumber = true;
    }

    if (!this.checkPinCode()) {
      return;
    }

    if (valid && this.submitted) {
      this.contractClientDataMaster = this.contractClientData;
      // console.log("Now let's see save data : ", this.contractClientDataMaster)
      this.contractstream$ = this.contractclientService.saveContractClient(this.contractClientDataMaster).subscribe(
        data => {
          // console.log("Response data from save client request >>>> ", data)
          this.contractClientDataMaster.client_id = data.clientid;
          this.contractClientDataMaster.location_type = "Primary";
          this.contractstream$ = this.contractclientService.saveContractClientLocation(this.contractClientDataMaster).subscribe(
            data => {
              // console.log("Response data from save client location request >>>> ", data)
              this._snackBar.open("Successfully saved", 'Close', {
                duration: 2000,
              });
              this.getContractClientData();
              this.clear();
            },
            error => {
              // console.log(error);
            }
          );
        },
        error => {
          // console.log(error);
        }
      );

    } else {
      // console.log("Not valid")
      return;
    }
  }

  updateContractClientData(valid) {
    this.submitted = true;
    this.contactNumberlength = false;
    this.contactNumberOnly = false;
    this.gstinNumber = false;

    if (!valid) {
      // console.log("Not valid")
      return;
    }

    if (!this.checkPhoneNumber()) {
      return;
    }

    if (this.contractClientData.gstin == undefined || this.contractClientData.gstin?.length == 0 || this.contractClientData.gstin == null) {
      this.contractClientData.gstin = undefined;
    }

    if ((this.contractClientData.gstin != undefined) && !(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(this.contractClientData.gstin))) {
      // console.log("Enter proper GSTIN number")
      this.submitted = false;
      this.gstinNumber = true;
    }

    if (!this.checkPinCode()) {
      return;
    }

    if (valid && this.submitted) {
      // console.log("Update from : ");
      this.contractClientDataMaster = this.contractClientData;
      // console.log("Now let's see update data : ", this.contractClientDataMaster)
      this.contractstream$ = this.contractclientService.updateContractClient(this.contractClientDataMaster).subscribe(
        data => {
          // console.log("Response data from update request >>>> ", data)
          this.contractClientDataMaster.client_id = this.contractClientData.id;
          this.contractClientDataMaster.id = this.contractClientData.clientlocid;
          // this.contractClientDataMaster.location_name = "Hehehehehehe"
          this.contractstream$ = this.contractclientService.updateContractClientLocation(this.contractClientDataMaster).subscribe(
            data => {
              // console.log("Response data from update two request >>>> ", data)
              this._snackBar.open("Successfully updated!", 'Close', {
                duration: 2000,
              });
              this.getContractClientData();
              this.clear();
            },
            error => {
              // console.log(error);
            }
          );
        },
        error => {
          // console.log(error);
        }
      );
    } else {
      // console.log("Not valid")
      return;
    }
  }

  deleteContractClientDataStore(clientid: number) {
    this.deleteClientId = clientid;
    $('#showAlertWarning').modal('toggle');
  }

  deleteContractClientData() {
    // console.log("Now let's see delete data : ", this.deleteClientId)
    this.contractstream$ = this.contractclientService.deleteContractClient(this.deleteClientId).subscribe(
      data => {
        // console.log("Response data from delete request >>>> ", data)
        // $('#showAlertVer').modal('toggle');
        this._snackBar.open("Successfully delete!", 'Close', {
          duration: 2000,
        });
        this.getContractClientData();
        this.clear();
      },
      error => {
        // console.log(error);
        if (error.error?.dataNotFound) {
          $('#showAlertCouldntDelete').modal('toggle');
        } else if (error.error?.dataAttachedToIt) {
          $('#showAlertDataAttachedToClient').modal('toggle');
        } else {
          $('#showAlertCantDelete').modal('toggle');
        }
      }
    );
  }

  contractClientEdit(contractClient: ContractclientDocument) {
    $('html,body').animate({
      scrollTop: $('#add-update-client').offset().top - 90
    },
      'slow');
  
    // console.log("Edit contract client data : ", contractClient)
    document.getElementById('update-btn').style.display = "inline-block";
    document.getElementById('save-btn').style.display = "none";
    document.getElementById('alter-text').innerText = "Update";
    // let inputs = document.querySelectorAll('input');
    // inputs.forEach(input => input.value = '')
    this.contractClientData = { ...contractClient };
  }

  clear() {
    this.submitted = false;
    this.contactNumberlength = false;
    this.contactNumberOnly = false;
    this.gstinNumber = false;
    this.pinCodeOnly = false;
    this.pinCodelength = false;

    // console.log("Clear : Clear all ");
    this.contractClientForm.reset();
    if (document.getElementById('save-btn').style.display === "none") {
      document.getElementById('update-btn').style.display = "none";
      document.getElementById('save-btn').style.display = "inline-block";
      document.getElementById('alter-text').innerText = "Add";
    }
  }

  checkPhoneNumber() {
    this.submitted = true;
    this.contactNumberlength = false;
    this.contactNumberOnly = false;

    if (!(/^[0-9]+$/.test(this.contractClientData.phone))) {
      // console.log("Enter only number")
      this.submitted = false;
      this.contactNumberlength = false;
      this.contactNumberOnly = true;
    } else if (this.contractClientData.phone.length != 10) {
      // console.log("Enter 10 digit number")
      this.submitted = false;
      this.contactNumberOnly = false;
      this.contactNumberlength = true;
    } else {
      return true;
    }
  }

  checkPinCode() {
    // console.log("We are inside");
    // console.log("Pin code pinCodelength : two", this.pinCodelength);
    // console.log("Pincode only numver: two ", this.pinCodeOnly);

    this.submitted = true;
    this.pinCodelength = false;
    this.pinCodeOnly = false;

    // console.log("Pin code pinCodelength : Three", this.pinCodelength);
    // console.log("Pincode only numver: Three ", this.pinCodeOnly);

    if (!(/^[0-9]+$/.test(this.contractClientData.pincode))) {
      // console.log("Enter only number")
      this.submitted = false;
      this.pinCodelength = false;
      this.pinCodeOnly = true;

      // console.log("We are inside loop one");
      // console.log("Pin code pinCodelength  >>", this.pinCodelength);
      // console.log("Pincode only numver >> ", this.pinCodeOnly);

    } else if (this.contractClientData.pincode.length != 6) {
      // console.log("Enter 6 digit number")
      this.submitted = false;
      this.pinCodeOnly = false;
      this.pinCodelength = true;

      // console.log("We are inside loop two");
      // console.log("Pin code pinCodelength  >>", this.pinCodelength);
      // console.log("Pincode only numver >> ", this.pinCodeOnly);
    } else {

      // console.log("We are inside exit point");
      // console.log("Pin code pinCodelength  >>", this.pinCodelength);
      // console.log("Pincode only numver >> ", this.pinCodeOnly);
      return true;
    }
  }

  ngOnDestroy(): void {
    this.contractstream$.unsubscribe();
  }
}