import { Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-tabs',
  template: `
  <mat-tab-group>
  <mat-tab *ngFor="let tab of field.fieldGroup; let i = index; let last = last;"
    [label]="tab.templateOptions.label" style="background-color: white !important; padding:10px !important"
    >
    <formly-field [field]="tab" ></formly-field>
  
  </mat-tab>
  </mat-tab-group>
`,
})
export class FormlyFieldTabs extends FieldType {
  isValid(field: FormlyFieldConfig) {
    if (field.key) {
      return field.formControl.valid;
    }

    return field.fieldGroup
      ? field.fieldGroup.every((f) => this.isValid(f))
      : true;
  }
}
