import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { AnnualHoliday } from "src/app/models/AnnualHolidayInfo";

@Component({
  selector: "calender",
  templateUrl: "./calender.component.html",
  styleUrls:['./calender.component.css']
})
export class CalenderComponent implements OnInit,  OnChanges  {
 
  today: any;
  currentMonth: any;
  currentYear: any;
  createYear: any;
  monthAndYear: any;
  months: any;
  days: any;
  @Input() annualhoildaylist:AnnualHoliday[] = [];
  public value: Date = new Date();
  ngOnInit() {
    this.today = new Date();
    this.currentMonth = this.today.getMonth();
    this.currentYear = this.today.getFullYear();

    this.createYear = this.generate_year_range(1970, 2050);
    this.createCalender();
  }
   monthDefault = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  generate_year_range(start, end) {
    var years = "";
    for (var year = start; year <= end; year++) {
      years += "<option value='" + year + "'>" + year + "</option>";
    }
    return years;
  }

  createCalender() {

    var calendar = document.getElementById("calendar");
    var lang = calendar.getAttribute("data-lang");

    var monthDefault = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    var dayDefault = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    this.months = monthDefault;
    this.days = dayDefault;

    // create a table
    var $dataHead = "<tr>";
    for (let dhead in this.days) {
      $dataHead +=
        "<th data-days='" +
        this.days[dhead] +
        "'>" +
        this.days[dhead] +
        "</th>";
    }
    $dataHead += "</tr>";

    document.getElementById("thead-month").innerHTML = $dataHead;
    this.monthAndYear = document.getElementById("monthAndYear");
    this.showCalendar(this.currentMonth, this.currentYear , monthDefault);
  }

  showCalendar(month, year , monthDefault) {
    var firstDay = new Date(year, month).getDay();
    var tbl = document.getElementById("calendar-body");
    tbl.innerHTML = "";

    this.monthAndYear.innerHTML = this.months[month] + " " + year;

    // creating all cells
    var date = 1;
    for (var i = 0; i < 6; i++) {
      var row = document.createElement("tr");

      var cell, cellText;
      for (var j = 0; j < 7; j++) {
        if (i === 0 && j < firstDay) {
          cell = document.createElement("td");
          cellText = document.createTextNode("");
          cell.appendChild(cellText);
          row.appendChild(cell);
        } else if (date > this.daysInMonth(month, year)) {
          break;
        } else {
          cell= document.createElement("td");
          cell.setAttribute("data-date", date);
          cell.setAttribute("data-month", month + 1);
          cell.setAttribute("data-year", year);
          cell.setAttribute("data-month_name", this.months[month]);
          cell.setAttribute("id" , `${date}-${month}-${year}`)
          cell.className = "date-picker";
          cell.innerHTML = "<span>" + date + "</span>";

          if (
            date === this.today.getDate() &&
            year === this.today.getFullYear() &&
            month === this.today.getMonth()
          ) {
            cell.className = "date-picker selected";
          }
          row.appendChild(cell);
          date++;
        }
      }

      tbl.appendChild(row);
    }



    this.displayAnnualHoliday(monthDefault)

  }

  displayAnnualHoliday(monthDefault){
    if(this.annualhoildaylist !==undefined && this.annualhoildaylist.length !==0){
      this.annualhoildaylist.forEach(holiday=>{
       let date= new Date(holiday.date).getDate() ;
       let month = new Date(holiday.date).getMonth();
       let year = new Date(holiday.date).getFullYear();

      //  console.log(date , month , year )

      //  let element = document.querySelector(`td[data-date=${date}][data-month=${month}][data-year=${year}]`)
      let annualele =  document.getElementById(`${date}-${month}-${year}`)
      
      // console.log(annualele)
        if(annualele){
          annualele.style.backgroundColor = "#255bfb"
          annualele.style.color = "white"
          // annualele.setAttribute('title', holiday.reason)
          annualele.setAttribute('class', 'annualholiday')
          annualele.setAttribute('data-title' , holiday.reason)
          
        }
      })
    }
  }

  daysInMonth(iMonth, iYear) {
    return 32 - new Date(iYear, iMonth, 32).getDate();
  }

  next() {
    this.currentYear =
    this.currentMonth === 11 ? this.currentYear + 1 : this.currentYear;
    this.currentMonth = (this.currentMonth + 1) % 12;
    this.showCalendar(this.currentMonth, this.currentYear , this.monthDefault);
  }

  previous() {
    this.currentYear =
    this.currentMonth === 0 ? this.currentYear - 1 : this.currentYear;
    this.currentMonth = this.currentMonth === 0 ? 11 : this.currentMonth - 1;
    this.showCalendar(this.currentMonth, this.currentYear , this.monthDefault);
  }
  ngOnChanges(changes: SimpleChanges): void {
   console.log(changes.annualhoildaylist.currentValue)
   this.annualhoildaylist = changes.annualhoildaylist.currentValue
   this.displayAnnualHoliday(this.monthDefault)
  }
}
