export class EmployeeAttendence{
     swipeinfoid:number;
     uniqueemployeeid:number;
     swipetime:Date;
     swipetype:number;
     shifted:boolean;
     processed:boolean;
     manual:boolean;
     userid:string;
     timestamp:Date;
     empid:string;
     createdDate:string;
     updatedBy:string;
     stime:string;
     breakid:number;
     latitude:number;
     longitude:number;
}