import { Component, Input, OnInit } from '@angular/core';
import { AnnualHoliday } from 'src/app/models/AnnualHolidayInfo';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'annualholiday',
  templateUrl: './annualholiday.component.html',
  styleUrls: ['./annualholiday.component.css']
})
export class AnnualholidayComponent implements OnInit {
 @Input() annualhoildaylist:AnnualHoliday[] = [];
  constructor() { }

  ngOnInit(): void {
   
  }
  
}
