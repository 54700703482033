import { Component, Input, OnInit } from '@angular/core';
import { EmployeeAbsence } from 'src/app/models/EmployeeAbsenceinfo';
import { SessionService } from 'src/app/services';
import { EmployeeLeaveService } from 'src/app/services/employee-leave.service';

@Component({
  selector: 'employeeonleave',
  templateUrl: './employeeonleave.component.html',
  styleUrls: ['./employeeonleave.component.css']
})
export class EmployeeonleaveComponent implements OnInit {

  @Input() empabsencelist:EmployeeAbsence[];

  constructor(private empleaveService:EmployeeLeaveService , private sessionservice:SessionService) { }
  ngOnInit(): void {
    
  }

}
