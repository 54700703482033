import {Component, Input,Output, EventEmitter, ElementRef, OnInit, Inject ,HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


import { AppService } from '../../services/index';

@Component({
    templateUrl: './signup.component.html',
    selector:'signup'
})

export class SignUpComponent implements OnInit {

    submitted: boolean;
    loading = false;
    
    // user : User;
    
    
    loader : string = '';

    constructor(private service: AppService, private router:Router) { }

    ngOnInit(){
        
        // this.user = new User();
        // this.user.company = new Company();

    }
    
     
    
    
    // signUp( valid: boolean ) {
    //     this.submitted = true;

    //     if ( !valid ) {
    //         return;
    //     }

    //     this.loading = true;
        
    //     this.service.signUp( this.user ).subscribe(
    //             data => {         
                    
    //                 this.submitted = false;
                    
    //                 setTimeout(()=>{ this.loading = false; this.router.navigate( ['/login'] ); 
    //                                   }, 2000); 
    //             },
    //             error => {
    //                 let err = error.error; 
    //                 console.log(err.error);

    //                 this.submitted = false;
    //                 this.loading = false;
    //             });
       
    // }
    
    
    cancel() {
       this.router.navigate( ['/login'] );
    }

}
