<div class="app-content main-content">
    <div class="side-app main-container">

        <div class="alertcomp"></div>

        <div class="page-leftheader" style="margin-bottom: 0.75rem">
            <div class="page-title">Project report</div>
        </div>

        <div class="col-xl-12 col-md-12 col-lg-12" style="padding: 0; margin-bottom: 30px;">
            <div class="card">
                <div class="card-body">
                    <form (ngSubmit)="contractClientReportForm.form.valid" name="addContractClientReportForm"
                        #contractClientReportForm="ngForm" novalidate>
                        <div class="card-body row">
                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pe-4 ps-4">
                                <div class="form-group label-floating">
                                    <label class="form-label" id="formLable">
                                        Client
                                    </label>
                                    <div>
                                        <select class="form-control" data-style="btn btn-drpdwn btn-round" id="dGroup"
                                            name="business_name" #business_name="ngModel" [(ngModel)]="client_id"
                                            (ngModelChange)="getProData($event)" required>

                                            <option [ngValue]="undefined" [disabled]="true" [hidden]="true">
                                                Select Client</option>
                                            <option [ngValue]="data.id" *ngFor="let data of contractClientData">
                                                {{data.business_name}}
                                            </option>
                                        </select>

                                        <p *ngIf="submitted && !business_name.valid" class="help-block "
                                            style="color: #ff5757;">
                                            ⓘ Client name is required
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pe-4 ps-4">
                                <div class="form-group label-floating">
                                    <label class="form-label" id="formLable">
                                        Project
                                    </label>
                                    <div>
                                        <select class="form-control" data-style="btn btn-drpdwn btn-round" id="dGroup"
                                            name="projectname" #projectname="ngModel" [(ngModel)]="project_id"
                                            (ngModelChange)="getProInfo($event)" required [disabled]="cliisdisable">

                                            <option [ngValue]="undefined" [disabled]="true" [hidden]="true">
                                                Select Client</option>
                                            <option [ngValue]="data.id" *ngFor="let data of contractClientProData">
                                                {{data.projectname}}
                                            </option>

                                        </select>

                                        <p *ngIf="submitted && !projectname.valid" class="help-block "
                                            style="color: #ff5757;">
                                            ⓘ Project name is required
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pe-4 ps-4">
                                <div class="form-group label-floating">
                                    <label class="form-label">Location</label>
                                    <div>
                                        <select class="form-control" data-style="btn btn-drpdwn btn-round" id="dGroup"
                                            name="allocationLoc" #allocationLoc="ngModel" [(ngModel)]="allocationloc"
                                            (ngModelChange)="getAllocationfLoc($event); applyFilter()" required
                                            [disabled]="proisdisable">

                                            <option value="All">All</option>
                                            <option [value]="data.project_location_id"
                                                *ngFor="let data of filteredLocation">
                                                {{data.location_name}}
                                            </option>

                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pe-4 ps-4 ">
                                <div class="form-group label-floating">
                                    <label class="form-label">Report</label>
                                    <div>
                                        <select class="form-control" data-style="btn btn-drpdwn btn-round" id="dGroup"
                                            name="allocationFor" #allocationFor="ngModel" [(ngModel)]="allocationfor"
                                            (ngModelChange)="getAllocationfFor($event); applyFilter()" required
                                            [disabled]="proisdisable">

                                            <option [ngValue]="undefined" [disabled]="true" [hidden]="true">
                                                Select Allocation</option>
                                            <option value="Actual">Actual</option>
                                            <option value="Budgeting">Budgeting</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 pe-4 ps-4">
                                <div class="form-group label-floating">
                                    <label class="form-label">Report From</label>
                                    <div>
                                        <input type="date" class="form-control col-md-12 col-xs-12" autocomplete="off"
                                            placeholder="Enter Start Date" autofocus="true" name="start_date"
                                            #start_date="ngModel" [(ngModel)]="contractClientProAllocData.start_date"
                                            (ngModelChange)="applyFilterDate(); checkFromAndToDate(); dateChageInReport()"
                                            required [disabled]="proisdisable" />

                                        <p *ngIf="submitted && !start_date.valid" class="help-block "
                                            style="color: #ff5757;">
                                            ⓘ Start date is required
                                        </p>

                                        <p *ngIf="startDate" class="help-block " style="color: #ff5757;">
                                            ⓘ Start should be less than end date
                                        </p>

                                        <p *ngIf="startDateSmall" class="help-block " style="color: #ff5757;">
                                            ⓘ Start date should be with in project start date {{projectStartDate}}
                                        </p>


                                    </div>
                                </div>
                            </div>

                            <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 pe-4 ps-4">
                                <div class="form-group label-floating">
                                    <label class="form-label">Report To</label>
                                    <div>
                                        <input type="date" class="form-control col-md-12 col-xs-12" autocomplete="off"
                                            placeholder="Enter End Date" autofocus="true" name="end_date"
                                            #end_date="ngModel" [(ngModel)]="contractClientProAllocData.end_date"
                                            (ngModelChange)="applyFilterDate(); checkFromAndToDate(); dateChageInReport()"
                                            required [disabled]="proisdisable" />

                                        <p *ngIf="submitted && !end_date.valid" class="help-block "
                                            style="color: #ff5757;">
                                            ⓘ End date is required
                                        </p>

                                        <p *ngIf="endDate" class="help-block " style="color: #ff5757;">
                                            ⓘ End date should be greater than start date
                                        </p>

                                        <p *ngIf="endDateGreat" class="help-block " style="color: #ff5757;">
                                            ⓘ End date should be with in project end date {{projectEndDate}}
                                        </p>

                                        <p *ngIf="curentDateIsLess" class="help-block " style="color: #ff5757;">
                                            ⓘ End date should not be greater than current date
                                        </p>

                                    </div>
                                </div>
                            </div>

                            <div class="actionBar pe-4 ps-4">
                                <div class="clearfix"> </div>
                                <div>
                                    <button class="btn btn-primary" type="button" style="display: inline-block"
                                        (click)="getReportData(contractClientReportForm.form.valid)"><i
                                            class="fa fa-search p-0" aria-hidden="true"></i> Search</button>
                                    <!-- <button class="btn btn-danger ms-2" type="button" (click)="clearReportData()">Clear</button> -->
                                </div>
                            </div>

                        </div>
                    </form>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>

        <div class="row d-none" id="projectAlloDataList">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header border-0">
                        <h4 class="card-title">{{allocationforChange}} report</h4>

                        <button type="button" class="btn btn-primary ms-auto" (click)="ProjectReportCSVDownload()">
                            <i class="fa fa-download" data-bs-toggle="tooltip" data-original-title="download"></i>
                        </button>
                    </div>
                    <div class="card-body pt-0">
                        <div class="col-xs-12 mobile">
                            <div class="scrollsearchresults">
                                <table class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                    id="hr-table">
                                    <thead class="tablerow">
                                        <tr class="rowcolors">

                                            <th class="border-bottom-0 w-5 fs-6">location</th>
                                            <th class="border-bottom-0 w-5 fs-6">Designation</th>

                                            <th class="border-bottom-0 w-5 fs-6">Start Date</th>
                                            <th class="border-bottom-0 w-5 fs-6">End Date</th>

                                            <th class="border-bottom-0 w-5 fs-6"
                                                *ngIf="allocationforChange === 'Actual'">
                                                Name</th>

                                            <th class="border-bottom-0 w-5 fs-6"
                                                *ngIf="allocationforChange === 'Actual'">
                                                ID</th>

                                            <th class="border-bottom-0 w-5 fs-6"
                                                *ngIf="allocationforChange === 'Actual'">
                                                Total Days</th>

                                            <th class="border-bottom-0 w-5 fs-6"
                                                *ngIf="allocationforChange === 'Actual'">
                                                Present</th>

                                            <th class="border-bottom-0 w-5 fs-6"
                                                *ngIf="allocationforChange === 'Actual'">
                                                Absent</th>

                                            <th class="border-bottom-0 w-5 fs-6"
                                                *ngIf="allocationforChange === 'Actual'">
                                                Odd Punch</th>

                                            <th class="border-bottom-0 w-5 fs-6"
                                                *ngIf="allocationforChange === 'Actual'">
                                                Leave/Holiday</th>

                                            <th class="border-bottom-0 w-5 fs-6"
                                                *ngIf="!(allocationforChange === 'Actual')">
                                                Count</th>

                                            <th class="border-bottom-0 w-5 fs-6"
                                                *ngIf="allocationforChange === 'Actual'">
                                                Expense</th>

                                            <th class="border-bottom-0 w-5 fs-6"
                                                *ngIf="!(allocationforChange === 'Actual')">
                                                Duration</th>

                                            <th class="border-bottom-0 w-5 fs-6"
                                                *ngIf="!(allocationforChange === 'Actual')">
                                                Billing Rate</th>

                                            <th class="border-bottom-0 w-5 fs-6"
                                                *ngIf="!(allocationforChange === 'Actual')">
                                                UOM</th>

                                            <th class="border-bottom-0 w-5 fs-6"
                                                *ngIf="!(allocationforChange === 'Actual')">
                                                Amount</th>
                                            <th class="border-bottom-0 w-5 fs-6"
                                                *ngIf="allocationforChange === 'Actual'">
                                                Company Revenue</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let contractclient of filterContractClientProAlloData">

                                            <!-- General -->
                                            <td data-th="contractclientName">{{contractclient.location_name}}
                                            </td>
                                            <!-- General -->
                                            <td data-th="contractclientName">{{contractclient.designation_name}}
                                            </td>
                                            <!-- General -->
                                            <td data-th="contractclientName">{{contractclient.start_date |
                                                date:'MM/dd/yyyy'}}
                                            </td>
                                            <!-- General -->
                                            <td data-th="contractclientName">{{contractclient.end_date |
                                                date:'MM/dd/yyyy'}}
                                            </td>
                                            <!-- Actual -->
                                            <td data-th="contractclientName" *ngIf="allocationforChange === 'Actual'">
                                                {{contractclient.name}}
                                            </td>
                                            <!-- Actual -->
                                            <td data-th="contractclientName" *ngIf="allocationforChange === 'Actual'">
                                                {{contractclient.employeeID}}
                                            </td>
                                            <!-- Actual -->
                                            <td data-th="contractclientName" *ngIf="allocationforChange === 'Actual'">
                                                {{contractclient.totalDays}}
                                            </td>
                                            <!-- Actual -->
                                            <td data-th="contractclientName" *ngIf="allocationforChange === 'Actual'">
                                                {{contractclient.netAttendence}} Days
                                            </td>
                                            <!-- Actual -->
                                            <td data-th="contractclientName" *ngIf="allocationforChange === 'Actual'">
                                                {{contractclient.absent}} Days
                                            </td>
                                            <!-- Actual -->
                                            <td data-th="contractclientName" *ngIf="allocationforChange === 'Actual'">
                                                {{contractclient.oddPunch}} Days
                                            </td>
                                            <!-- Actual -->
                                            <td data-th="contractclientName" *ngIf="allocationforChange === 'Actual'">
                                                {{contractclient.totalLeaveAndHolidays}} Days
                                            </td>
                                            <!-- Budgeting -->
                                            <td data-th="contractclientName"
                                                *ngIf="!(allocationforChange === 'Actual')">
                                                {{contractclient.counts}}
                                            </td>
                                            <td data-th="contractclientName" *ngIf="allocationforChange === 'Actual'">
                                                {{contractclient.expenss}}
                                            </td>
                                            <!-- Budgeting -->
                                            <td data-th="contractclientName"
                                                *ngIf="!(allocationforChange === 'Actual')">
                                                {{contractclient.attendence}} Days
                                            </td>
                                            <!-- Budgeting -->
                                            <td data-th="contractclientName"
                                                *ngIf="!(allocationforChange === 'Actual')">
                                                {{contractclient.billing_rate}}
                                            </td>
                                            <!-- Budgeting -->
                                            <td data-th="contractclientName"
                                                *ngIf="!(allocationforChange === 'Actual')">
                                                {{contractclient.uom }}
                                            </td>
                                            <!-- Budgeting -->
                                            <td data-th="contractclientName"
                                                *ngIf="!(allocationforChange === 'Actual')">
                                                {{contractclient.amount | allocationAmountPipe}}
                                            </td>
                                            <!-- Actual -->
                                            <td data-th="contractclientName" *ngIf="allocationforChange === 'Actual'">
                                                {{contractclient.companyRevenue}}
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>