import { Component, OnInit, HostListener } from '@angular/core';
import { PeopleInfo } from '../models/peopleInfo';
import { CommonService } from '../services/common.service';
import { AlertService } from '../services';
import { DocumentInfoService } from '../services/documentinfo.service';
import { DocumentInfo } from '../models/documentInfo';
import { Router } from '@angular/router';



@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent implements OnInit {
  documentInfo: DocumentInfo;
  peopleInfo: PeopleInfo;
  submitted: boolean;

  username: any;
  //documentInfo: any = {};
  //mandatoryType: any[] = [{ name: 'Yes', value: 'Yes' }, { name: 'No', value: 'No' }];
  documentDetails = [];
  documentList = [];
  documentInfoDetails = [];
  docInfoDetails = [];
  queryString: any;
  //docInfo = [];

  //currentItem = 'Television';


  // datas must load when when we scroll down
  limitPara: any;
  offsetPara: any;
  docId: any;
  index: any;

  constructor(private router: Router, private commonService: CommonService, private alertService: AlertService, private docmentInfoService: DocumentInfoService) { }
  // private router: Router,private customerService :CustomerService,private alertservice: AlertService ) { }

  ngOnInit() {
    this.username = sessionStorage.getItem('username')
    this.peopleInfo = new PeopleInfo();
    this.documentInfo = new DocumentInfo();
    this.peopleInfo.name = this.username;
    this.submitted = false;
    this.listDocument();

  }
  ngDoCheck(){
    //  (<HTMLInputElement>document.getElementById('customer1')).focus()
  }

  save(valid) {
    this.submitted = true;
    if (!valid) {
      return;
    }
    console.log("Mandatory is", this.documentInfo.mandatory);
    //console.log(this.peopleInfo);


    this.docmentInfoService.saveDocument(this.documentInfo).subscribe(
      data => {
        if (data.successMessages != null && data.successMessages.length !== 0) {
          console.log(this.documentInfo + "is document is Saved");
          this.submitted = false;
          this.alertService.success(data.successMessages[0]['key']);

        }
      },
      error => {
        const response = error.error;
        console.log("Error is ", error);
        //   // console.error(error);
        //   // if (response.errorMessages != null && response.errorMessages.length !== 0) {
        //   // this.alertservice.error(response.errorMessages[0]['key']);
        //   // }
      }
    );


  }

  selectMandatory(itemSelectionType) {
    var selectionType = null;
    selectionType = itemSelectionType;

  }

  listDocument() {

    console.log("Listing all Documents");

    const docTypename = (<HTMLInputElement>document.getElementById('customer1')).value;
    console.log("customer" + docTypename);

    this.docmentInfoService.getDocument(docTypename).subscribe(
      data => {
        //this.documentDetails = data.documentInfo;
        this.documentInfoDetails = data.masterDocuments;
        //console.log("Data is", data.masterDocuments);
        this.listAllDocumentDetails(this.documentInfoDetails);
      },
      error => {
        console.log("The Error is ", error);
      });

  }

  listAllDocumentDetails(documentInfoDetails: any) {
    this.documentList = [];
    if (this.documentInfoDetails.length != 0) {
      // var i = 0;i<this.documentInfoDetails.length; i++)
      for (var i = 0; i < this.documentInfoDetails.length; i++) {
        this.documentList.push(this.documentInfoDetails[i]);
        /*
        if (i == 0) {
          console.log("The First Element is", this.documentInfoDetails[i]);
        }
        */

      }
    }



  }

  openPage() {

    this.router.navigate(['/document/savedoc']);
  }

  //Added

  documentEdit(id: any, object) {
    this.docInfoDetails = object;
    //console.log(" Object Is", this.docInfoDetails);
    this.router.navigate(['/document/savedoc'], { queryParams: { docId: id } });

    //console.log("The query Parameter", { queryParams: { docId: id } });
  }
  documentDelete(j: any, id: any) {
    this.index = j;
    this.docId = id;
    console.log("The Id is", this.docId, id);
    const modal = document.getElementById('showAlert');
    const overlay = document.getElementById('overlay')
    overlay.style.display = 'block'
    modal.style.display = 'block';

  }
  closeMRQModal() {
    const modal = document.getElementById('showAlert');
    const overlay = document.getElementById('overlay')
    overlay.style.display = 'none'
    modal.style.display = 'none';
  }
  confirmDocDelete() {
    this.closeMRQModal();

    this.docmentInfoService.deleteDocument(this.docId).subscribe(
      data => {
        this.alertService.success(data.successMessages[0]['key']);
        this.alertmessages("Successfully Deleted" , "success");
        //this.customerList = this.customerList.splice(this.index,1);
        this.listDocument();
      },
      error => {
        const response = error.error;
        console.log("Error is ", error);
        if (response.errorMessages != null && response.errorMessages.length !== 0) {
          this.alertmessages(response.errorMessages[0]['key'] , "danger");
          // this.alertService.error(response.errorMessages[0]['key']);
        }
        //console.log(response.errorMessages[0]['key']);
      }

    );

  }

  onScroll() {
    this.limitPara = this.limitPara + 10;
    //this.searchCustomers1();

  }
  @HostListener('scroll', ['$event'])
  scrolled() {
    this.onScroll();
  }

  reFresh() {
    this.peopleInfo = new PeopleInfo();

  }
  alertmessages(message:string , sign:string):void{
    let alertDiv = document.createElement('div');         
     alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function(){
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }
}
