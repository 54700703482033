import { NgModule , ModuleWithProviders} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule }    from '@angular/forms';
import { HttpModule , Http} from '@angular/http';
import { RouterModule } from '@angular/router';
import { HasAccessDirective } from './hasAccess.directive';
import {NavbarComponent, SidebarComponent, AlertComponent,PeopleFilter} from './index';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  imports: [
            FormsModule,
            HttpModule,
            CommonModule,
            RouterModule,
            InfiniteScrollModule
          ],
  declarations: [
           NavbarComponent,
           SidebarComponent,
           AlertComponent,
           PeopleFilter,
           HasAccessDirective
  ],
  exports: [
            NavbarComponent,
            SidebarComponent,
            AlertComponent,
            PeopleFilter,
            HasAccessDirective
            ]
})

export class CommonComponentsModule {
  static forChild(): ModuleWithProviders<any> {
    return {
      ngModule: CommonComponentsModule
    }
  }
}
