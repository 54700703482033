<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>

        <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-title">Shift Allocation</div>
            </div>
        </div> 
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
              <div class="card">
                <div class="card-body">
                
                  <form id="fcForm" class="form-horizontal form-label-left" #f="ngForm" (ngSubmit)="f.form.valid" >
      
                    <div class="x_content row px-4">
                        <div class="col-md-6 col-sm-6 col-xs-6 " >
                          <div class="form-group label-floating">
                            <label class="form-label"> Group </label>
                            <div>
                              <select class="form-control" data-style="btn btn-drpdwn btn-round" id="dType" name="group" [(ngModel)]="groupid" >
                                <!-- [(ngModel)]="myDocuments.docType" #docType="ngModel" (change)="docTypeChange()" required -->
                                <!-- <option [ngValue]="null">
                                          Choose Months Commitment...
                                        </option> -->
                                <option [ngValue]="0"  disabled>Select Group</option>
                                <option [value]="g.id" *ngFor="let g of group">{{g.name}}</option>
                              </select>
                              <!-- <span *ngIf="submitted && !docType.valid " class="help-block" style="color: red;">
                                        Please Select Document Type</span> -->
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 col-sm-6 col-xs-6 " >
                          <div class="form-group label-floating ">
                            <label class="form-label"> Shifts </label>
                            <div>
      
                              <select class="form-control " style="appearance: auto !important;"
                                data-style="btn btn-drpdwn btn-round" id="dGroup" name="shift"
                               [(ngModel)]="shift"
                                >
                                <!-- [(ngModel)]="myDocuments.docGroup" #docGroup="ngModel" (change)="docGroupChange()" required -->
                                <option [ngValue]="0" disabled>Select Shifts</option>
                                <option  [value]="s.shiftid" *ngFor="let s of shifts" >{{s.shiftname}}</option>
                                
  
                              </select>
                              <!-- <span *ngIf="submitted && !docGroup.valid " class="help-block" style="color: red;">
                                          Please Select Document Group</span> -->
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 col-sm-6 col-xs-6 " >
                            <div class="form-group label-floating ">
                              <label class="form-label"> From Date: </label>
                              <div>
                                <input class="form-control" type="date" name="fromdate" id="fromdate"   [(ngModel)]="fromdate">
                                <!-- <select class="form-control " style="appearance: auto !important;"
                                  data-style="btn btn-drpdwn btn-round" id="dGroup" name="shift"
                                 [(ngModel)]="shift"
                                  > -->
                                  <!-- [(ngModel)]="myDocuments.docGroup" #docGroup="ngModel" (change)="docGroupChange()" required -->
                                  <!-- <option [value]="null" selected>Select Shifts</option>
                                  <option  >shift1</option>
                                  <option  >shift3</option> -->
    
                                <!-- </select> -->
                                <!-- <span *ngIf="submitted && !docGroup.valid " class="help-block" style="color: red;">
                                            Please Select Document Group</span> -->
                              </div>
                            </div>
                          </div>

                          <div class="col-md-6 col-sm-6 col-xs-6 " >
                            <div class="form-group label-floating ">
                              <label class="form-label"> To Date: </label>
                              <div>
                                <input class="form-control" type="date" name="todate" id="todate"   [(ngModel)]="todate">
                              </div>
                            </div>
                          </div>
                      </div>
      
      
      
                    <div class="actionBar">
                      <div class="clearfix"> </div>
                      <div class="pro-fab">
                        <button class="btn btn-success" type="button" [disabled]="isupdate"  id="btnsave" (click)="showSavemodal()">Save</button>
                        <!-- <button class="btn btn-warning ms-2" type="button" id="btnsave"  (click)="update(f.form.value)">Update</button> -->
                        <button class="btn btn-primary ms-2" type="button" id="btnsave" (click)="clear()">Clear</button>
                      </div>
                    </div>
      
                  </form>
                </div>
      
              </div>
            </div>
          </div>

          <div class="row" *ngIf="allocatedShits.length !==0">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header  border-0 justify-content-between flex-wrap  flex-sm-nowrap">
                        <h4 class="card-title w-100"   >Allocated Shifts</h4>
                        <select class="form-control  fw-semibold mt-2 mt-sm-0" style="appearance: auto !important; font-weight: 500;" (change)="searchby($event)">
                          <option value="null" class="fw-semibold">Select Records</option>
                          <option value="Employee" class="fw-semibold" >Based on Employee</option>
                          <option value="date" selected class="fw-semibold">Based on Dates</option>

                        </select>
                    </div>
                    <div class="card-body" *ngIf="defaultsearchby === 'date'">
                        <div class="col-xs-12 mobile">
                            <div class="scrollbar">
                            <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom" id="hr-table">
                                <thead class="tablerow">
                                    <tr class="stickyatTop  rowcolors" >
                                        <th class="border-bottom-0 w-5 fs-7">Sl No.</th>
                                        <th class="border-bottom-0 w-30 fs-7">Group Name</th>
                                        <th class="border-bottom-0 w-20 fs-7">Shift Name</th>
                                        <th class="border-bottom-0 w-20 fs-7">From Date</th>
                                        <th class="border-bottom-0 w-20 fs-7">To Date</th>
                                        <!-- <th class="border-bottom-0 fs-7">edit</th> -->
                                       
                                        <!-- <th class=""> </th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let shift of allocatedShits;let i=index" >
                                      <td data-th="Sl No." >{{i+1}}</td>
                                        <td data-th="Group" >{{shift.groupname}}</td>
                                        <td data-th="Shift" >{{shift.shiftname}}</td>
                                        <td data-th="From Date" >{{shift.fromdate | date:'MM-dd-yyyy'}}</td>
                                        <td data-th="To Date" >{{shift.todate | date:'MM-dd-yyyy'}}</td>
                                        <!-- <td style="width: 16.67%;">
                                            <button class="btn btn-primary btn-icon btn-sm me-2" (click)="editAllocatedShifts(shift)"  >
                                              <i  class="fa fa-edit" data-bs-toggle="tooltip" data-original-title="edit"></i>
                                            </button>
                                        </td> -->
        
                                        
                                        <!-- <td><span class="badge badge-success">Active</span></td> -->
                                        <!-- <td>
                                            <a class="btn btn-primary btn-icon btn-sm"  href="hr-empview.html">
                                                <i class="feather feather-edit" data-bs-toggle="tooltip" data-original-title="View/Edit"></i>
                                            </a>
                                            <a class="btn btn-danger btn-icon btn-sm" data-bs-toggle="tooltip" data-original-title="Delete"><i class="feather feather-trash-2"></i></a>
                                        </td> -->
                                        <!-- <td>
                                            <a class="btn btn-primary btn-icon btn-sm me-2"  >
                                                <i class="feather feather-edit" data-bs-toggle="tooltip" data-original-title="View/Edit"></i>
                                            </a>
                                            <a class="btn btn-danger btn-icon btn-sm" data-bs-toggle="tooltip" data-original-title="Delete"><i class="feather feather-trash-2"></i></a>
                                        </td> -->
                                    </tr>
                                </tbody>
                            </table>
                           
                          </div>  
                        </div>
                    </div>
                    <div class="card-body" *ngIf="defaultsearchby === 'Employee'">
                      <div class="col-xs-12 mobile">
                          <div class="scrollbar">
                          <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom" id="hr-table">
                              <thead class="tablerow"> 
                                  <tr class="stickyatTop rowcolors">
                                      <th class="border-bottom-0 w-5 fs-7">Sl No.</th>
                                      <th class="border-bottom-0 fs-7">Employee Name</th>
                                      <th class="border-bottom-0 fs-7">Group Name</th>
                                      <th class="border-bottom-0 w-10 fs-7">Shift Name</th>
                                      <th class="border-bottom-0 w-10 fs-7">From Date</th>
                                      <th class="border-bottom-0 w-10 fs-7">To Date</th>
                                      <!-- <th class="border-bottom-0 fs-7">edit</th> -->
                                     
                                      <!-- <th class=""> </th> -->
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr *ngFor="let shift of allocatedShits;let i=index" >
                                    <td data-th="Sl No.">{{i+1}}</td>
                                    <td data-th="Employee Name">{{shift.empname |  titlecase}}</td>
                                      <td data-th="Group" >{{shift.groupname}}</td>
                                      <td data-th="Shift" >{{shift.shiftname}}</td>
                                      <td data-th="From Date" >{{shift.fromdate | date:'dd-MM-yyyy'}}</td>
                                      <td data-th="To Date" >{{shift.todate | date:'dd-MM-yyyy'}}</td>
                                      <!-- <td style="width: 16.67%;">
                                          <button class="btn btn-primary btn-icon btn-sm me-2" (click)="editAllocatedShifts(shift)"  >
                                            <i  class="fa fa-edit" data-bs-toggle="tooltip" data-original-title="edit"></i>
                                          </button>
                                      </td> -->
      
                                      
                                      <!-- <td><span class="badge badge-success">Active</span></td> -->
                                      <!-- <td>
                                          <a class="btn btn-primary btn-icon btn-sm"  href="hr-empview.html">
                                              <i class="feather feather-edit" data-bs-toggle="tooltip" data-original-title="View/Edit"></i>
                                          </a>
                                          <a class="btn btn-danger btn-icon btn-sm" data-bs-toggle="tooltip" data-original-title="Delete"><i class="feather feather-trash-2"></i></a>
                                      </td> -->
                                      <!-- <td>
                                          <a class="btn btn-primary btn-icon btn-sm me-2"  >
                                              <i class="feather feather-edit" data-bs-toggle="tooltip" data-original-title="View/Edit"></i>
                                          </a>
                                          <a class="btn btn-danger btn-icon btn-sm" data-bs-toggle="tooltip" data-original-title="Delete"><i class="feather feather-trash-2"></i></a>
                                      </td> -->
                                  </tr>
                              </tbody>
                          </table>
                         
                        </div>  
                      </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <div id="shiftModal" class="modal" style="top: 40px;">
      <div class="modal-dialog">
        <div class="modal-content animate">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"  >×</button>
            <h4 class="modal-title">Warning</h4>
          </div>
    
          <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
            <!-- auto scroll -->
            <span><b> Are you sure you want to Save ?</b></span>
          </div>
    
          <div class="modal-footer">
            <button type="button" class="btn btn-danger medium" (click)="confirmsave(f.form.value)">OK</button>
            <button type="button" class="btn btn-primary" (click)="closeMRQModal()">Cancel</button>
    
          </div>
    
        </div>
      </div>
    </div>
</div>
