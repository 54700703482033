<!-- <div class="card daily-tasks">
    <div class="card-content">
        <div class="position-relative">
            <h4 class="card-title mb-3">Policy Document</h4>
            <table class="table">
                <thead>
                    <tr>
                        <th>Document</th>
                        <th>Uploaded Date </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let j=index; let t of listOfRuleAndPolicy ">
                        <td>{{t.fileType}}
                        </td>
                        <td>{{t.uploadedOn | date: 'dd/MM/yyyy'}}
                        </td>
                        <td>
                            <table>
                                <tr>
                                    <td>
                                        <button class="btn btn-success btn-simple" data-method="download"
                                            title="download" (click)="downloadRulesAndPolicy(t.fileName, t.filePath)">
                                            <span class="fa fa-download" title="download"></span>
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div> -->

<div class="card rounded_corners "   id="scroll_container" style="height: 60vh !important;" >
    <div class="card-header pb-4" id="header_sticky">
      <h4 class="card-title">Policy Document</h4>
    </div>
    <div class="pt-2 border-0 scroll" style="height:100%;">
      <div class="list-group">
        <div class="list-group-item d-flex pt-1 pb-1 border-0" id="download_pdf"*ngFor=" let t of listOfRuleAndPolicy; let j=index" >
          <div class="me-3 me-xs-0" id="center_img" >
            <img src="./../../../assets/img/pdflogo.svg" alt="pdf_logo" id="logo_size-60">
            <!-- <div class="calendar-icon icons">
              <div class="date_time bg-pink-transparent"> <span class="date">18</span> <span class="month">FEB</span> </div>
            </div> -->
          </div>
          <div class="ms-1 " id="width_100" >
              <div class="width_112">
                <div class=" fs-14 ml-5">{{t.fileType}}</div> 
                <small class="text-muted">{{t.uploadedOn | date: 'dd/MM/yyyy'}}</small>
              </div>
          </div>
          <div class=" ms-auto">
            <button class="btn btn-primary btn-simple" data-method="download"
            title="download"(click)="downloadRulesAndPolicy(t.fileName, t.filePath)" >
            <span class="fa fa-download" title="download"></span>
            </button>
          </div>
        </div>   
      </div>
    </div>
  </div>