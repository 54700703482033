
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { EmployeeLeaveApplied } from '../models/employee-leave-applied.model';
import { EmployeeMaster } from '../models/employeeMasterts';

import { EmployeeMasterservice } from '../services/employeemaster.service';

import { ActivatedRoute } from '@angular/router';
import { FormsModule } from '@angular/forms';


@Component({
	selector: 'app-employeelisting',
	templateUrl: './employeelisting.component.html',
	styleUrls: ['./employeelisting.component.css']
})
export class EmployeelistingComponent implements OnInit {

	resetPasswordBulk: EmployeelistingComponent[] = []

	empResetList: EmployeelistingComponent[] = []
	searchlist: any;
	reset: boolean;
	rusername: string;
	forgotPassword: boolean;
	suberroralertMsg: string;
	subsuccessalertMsg: string;



	constructor(private route: ActivatedRoute, private employeeMasterservice: EmployeeMasterservice) { }

	ngOnInit(): void {
		this.employeeListChpwd()
	}


	employeeListChpwd() {
		this.employeeMasterservice.resetPasswordBulk().subscribe(
			(response) => {
				console.log(response);
				this.resetPasswordBulk = response.employeeList4ResetPWD;

			},
			(error) => {
				console.log(error);
			}
		);

	}


	checkAllSelected(e: any) {
		const checkboxes = document.querySelectorAll('input[type="checkbox"]');
		if (e.target.checked) {
			for (let i = 0; i < checkboxes.length; i++) {
				const checkbox = checkboxes[i] as HTMLInputElement;
				checkbox.checked = true;
				const employeeList = this.resetPasswordBulk[i];
				this.empResetList.push(employeeList);
			}
			console.log("selected all item: ", this.empResetList)

		} else {
			for (let i = 0; i < checkboxes.length; i++) {
				const checkbox = checkboxes[i] as HTMLInputElement;
				checkbox.checked = false;
			}
			this.empResetList = [];
			console.log(" unseleted all item: ", this.empResetList)

		}
	}



	checkSelected(employeeList: any, e: any) {
		if (e.target.checked) {
			this.empResetList.push(employeeList);
		} else {
			const index = this.empResetList.indexOf(employeeList);
			if (index > -1) {
				this.empResetList.splice(index, 1);
			}
		}

	}

	ResetHandler() {
		if (this.empResetList.length === 0) {
			this.alertmessages("Please select item for Reset", "danger");
		} else if (this.empResetList.length > 0) {
			this.alertmessages("Login credentials sent to selected items", "success");
			console.log("seleceted item (have item): ", this.empResetList)
			this.employeeMasterservice.ResetPasswordList(this.empResetList).subscribe(
				data => {
					console.log("Response", data);
					this.employeeListChpwd()
				},
				error => {
					console.log(error);
				}
			);
		}
	}




	alertmessages(message: string, sign: string): void {
		let alertDiv = document.createElement('div');
		alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
		<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
		let alertcomp = document.querySelector('.alertcomp');
		alertcomp.append(alertDiv);
		document.body.scrollTop = document.documentElement.scrollTop = 0;

		setTimeout(function () {
			alertcomp.removeChild(alertDiv);
		}, 4000);
	}
}
