import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { EmployeeMasterservice } from '../services/employeemaster.service';
import { SessionService } from '../services';

@Component({
  selector: 'app-leave-assign',
  templateUrl: './leave-assign.component.html',
  styleUrls: ['./leave-assign.component.css']
})
export class LeaveAssignComponent implements OnInit {

  LeaveTypeList: any[];
  EmployeeListByGroup: any[];
  shiftList: any[];
  groupByType: any;
  empSelectedList: LeaveAssignComponent[] = []
  empSelectedListBulk: LeaveAssignComponent[] = []
  LeaveTypeListValue: [{ leaveType: any }, { noOfDays: number }];

  uid: string = this.session.getempid()

  constructor(private employeeMasterservice: EmployeeMasterservice, private employeeMasterService: EmployeeMasterservice, private session: SessionService) {
    this.groupByType = 'All';
  }

  ngOnInit(): void {
    this.getShiftName();
    this.getLeaveType();
    this.getIndividualLeaveType(this.uid);
  }

  getLeaveType() {
    // existing api dont modify ... 
    this.employeeMasterservice.getLeaveType().subscribe(
      data => {
        this.LeaveTypeList = data;
        console.log("List of all the leave type available  :", this.LeaveTypeList)
      }, error => {
        console.log(error);
      }
    );
  }

  getShiftName() {
    this.employeeMasterservice.getShiftName().subscribe(
      data => {
        this.shiftList = data.shiftMasterdetail;
        console.log("shift type ", this.shiftList);
      },
      error => {
        console.log("error", error)
      }
    );
  }

  onClickSearch() {
    // clearr chea=ckbox when ever u click serach , so it will not save old data 
    this.empSelectedList = []
    this.getEmployeeListByGroup();
  }

  checkBoxClear() {
    console.log("clear () ")
    const checkboxes = document.querySelectorAll('.checkboxclass');
    for (let i = 0; i < checkboxes.length; i++) {
      const checkbox = checkboxes[i] as HTMLInputElement;
      checkbox.checked = false;
    }
    this.empSelectedList = [];
    this.empSelectedListBulk = [];
    console.log("clear ", this.empSelectedList, " ", this.empSelectedListBulk)

    const checkboxMain = document.querySelector('.checkboxMain') as HTMLInputElement;
    if (checkboxMain) {
      checkboxMain.checked = false;
    }
  }

  checkAllSelected(e: any) {
    this.empSelectedList = []
    this.empSelectedListBulk = []
    const checkboxes = document.querySelectorAll('.checkboxclass');
    if (e.target.checked) {
      for (let i = 0; i < checkboxes.length; i++) {
        const checkbox = checkboxes[i] as HTMLInputElement;
        checkbox.checked = true;
        const employeeList = this.EmployeeListByGroup[i];
        this.empSelectedListBulk.push(employeeList);
      }
      console.log("select bulk  ", this.empSelectedList, " ", this.empSelectedListBulk)
    } else {
      for (let i = 0; i < checkboxes.length; i++) {
        const checkbox = checkboxes[i] as HTMLInputElement;
        checkbox.checked = false;
      }
      this.empSelectedList = [];
      this.empSelectedListBulk = [];
      console.log("unselect all in bulk ", this.empSelectedList, " ", this.empSelectedListBulk)

    }
  }

  checkSelected(index: any, e: any, EmployeeListByGroup: any) {
    if (e.target.checked) {
      this.empSelectedList.push(EmployeeListByGroup[index])
      this.empSelectedListBulk = this.empSelectedList;
      console.log("select ind ", this.empSelectedList, " ", this.empSelectedListBulk)
      console.log("list ", this.empSelectedList)
      console.log("bulk ", this.empSelectedListBulk)
    } else {
      console.log("here")
      const unit = this.empSelectedList?.indexOf(EmployeeListByGroup[index]);
      if (unit > -1) {
        this.empSelectedList.splice(unit, 1);
      }
      this.empSelectedListBulk = this.empSelectedList;
      console.log("unselect indiv ", this.empSelectedList, " ", this.empSelectedListBulk)
    }
  }

  getEmployeeListByGroup() {
    this.employeeMasterservice.getLeaveAssignForEmployeeList(this.groupByType).subscribe(
      data => {
        this.EmployeeListByGroup = data;
        console.log("List of employee based on shift  :", this.EmployeeListByGroup)
      }, error => {
        console.log(error);
      }
    );
  }

  changeGroupByType(event) {
    console.log("change in group by type", event)
    this.EmployeeListByGroup = [];
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
		<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 4000);
  }

  // using this just get leave type and noof days of logged user , and those number are update to all the emp list 
  getIndividualLeaveType(uid) {
    this.employeeMasterService.getEmployeeLeaveType(uid).
      subscribe({
        next: (data) => {

          data.forEach(item => {
            item.noOfDays = 0;
          });

          this.LeaveTypeListValue = data;
          console.log("this.LeaveTypeListValue  value  = ", this.LeaveTypeListValue)

        },
        error: (e) => {
          console.log(e)
        },
      }
      )
  }

  onInputChange(updatedValue: any, index: number) {
    const enteredValue = updatedValue.target.value;
    this.LeaveTypeListValue[index]["noOfDays"] = enteredValue;
  }

  update_handler() {
    if (this.empSelectedListBulk.length === 0) {
      this.alertmessages("Please Select Employee", "danger");
    } else if (this.empSelectedListBulk.length > 0) {
      console.log("update handler -- ")
      console.log();

      this.employeeMasterService.update_insert_LeaveMasterBulk(this.LeaveTypeListValue, this.empSelectedListBulk).subscribe(
        data => {
          console.log("data ", data)
          this.alertmessages("LeaveType and No Of Days Assigned ", "success");
          this.checkBoxClear();
        },
        error => {
          console.log("error", error)
        }
      );

    }
  }

}
