<div class="app-content main-content">
  <div class="side-app main-container">

    <div class="alertcomp">

    </div>

    <div class="page-header d-block">
      <div class="page-leftheader" style="margin-bottom: 0.75rem">
        <div class="page-title">View Document</div>
      </div>

      <div class="row">
        <div class="col-xl-12 col-md-12 col-lg-12">
          <div class="card">
            <div class="card-body row">

              <form class="col-md-3 col-sm-6 col-xs-6">

                <div class="form-group label-floating">
                  <label class="form-label"> Select Department </label>
                  <div>
                    <select class="form-control" data-style="btn btn-drpdwn btn-round" id="dGroup" name="dGroup"
                      [(ngModel)]="filterDep">

                      <option value="all" selected="selected">All</option>
                      <option value={{data}} *ngFor="let data of filteredDepartmentNames">{{data}}</option>

                    </select>
                  </div>
                </div>
              </form>

              <form class="col-md-3 col-sm-6 col-xs-6">
                <div class="form-group label-floating">
                  <label class="form-label">Select Status</label>
                  <div>
                    <select class="form-control" data-style="btn btn-drpdwn btn-round" id="dGroup" name="dGroup"
                      ngModel="value" [(ngModel)]="filterStatus">

                      <option value="all" selected="selected">All</option>
                      <option value={{data}} *ngFor="let data of filteredStatus">{{data}}</option>

                    </select>
                  </div>
                </div>
              </form>

              <form class="col-md-3 col-sm-6 col-xs-6">

                <div class="form-group label-floating">
                  <label class="form-label">Search Name</label>
                  <div>
                    <input type="text" class="form-control col-md-12 col-xs-12" placeholder="Search"
                      (input)="filterEmployees($event.target.value)" />
                  </div>
                </div>

              </form>

            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-xs-12 mobile">
        <div class="card">
          <div class="card-body">
            <div class="Clearfix"></div>

            <div class="empty-div-one" style="height: 10px;" id="navigatedest"></div>
            <div class="col-xs-12 mobile">
              <div *ngIf="filterTaskList !== null ">
                <div class="scrollsearchresults">
                  <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom" id="hr-table">
                    <thead class="tablerow">
                      <tr class="rowcolors stickyatTop">
                        <th class="border-bottom-0 w-5 fs-7">Employee Name</th>
                        <th class="border-bottom-0 w-5 fs-7">Department</th>
                        <th class="border-bottom-0 w-5 fs-7">Document Type</th>
                        <th class="border-bottom-0 w-5 fs-7">Status</th>
                        <th class="border-bottom-0 w-5 fs-7">Action</th>
                      </tr>
                    </thead>
                    <tbody infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="3000"
                      [scrollWindow]="false" (scrolled)="onScroll()" id="navigatetarget">
                      <tr *ngFor="let j=index; let taskInfo of filterTaskList">
                        <td data-th="Employee Name">
                          {{taskInfo.employeeName}}
                        </td>

                        <td data-th="Employee Name">
                          {{taskInfo.departmentname}}
                        </td>

                        <td data-th="Document Type">
                          {{taskInfo.documents.docType}}
                        </td>

                        <td data-th="Document Type">
                          {{taskInfo.documents.overAllStatus}}
                        </td>

                        <td data-th="Action">
                          <button class="btn btn-primary btn-icon btn-sm me-2"
                            (click)="myDocumentDownload(taskInfo.documents.name, taskInfo.documents.filePath)"
                            style="width: 32px;">
                            <i class="fa fa-download" data-bs-toggle="tooltip" data-original-title="download"></i>
                          </button>

                          <button class="btn btn-primary btn-icon btn-sm me-2"
                            (click)="viewdocument(taskInfo.documents.name, taskInfo.documents.filePath)"
                            style="width: 32px;">
                            <i class="fa fa-eye" data-bs-toggle="tooltip" data-original-title="download"></i>
                          </button>

                          <button *ngIf="taskInfo.documents.overAllStatus === 'Submitted'"
                            class="btn btn-light btn-icon btn-sm me-2" type="button" rel="tooltip" title="Comment"
                            (click)="confirmOnlycomment()" style="width: 32px;">
                            <i class="zmdi zmdi-comments fa-2x" data-toggle="tooltip"></i>
                          </button>


                          <button *ngIf="taskInfo.documents.overAllStatus === 'Submitted'"
                            class="btn btn-success btn-icon btn-sm me-2" type="button" rel="tooltip"
                            data-method="Approve" style="width: 32px;" title="Approve"
                            (click)="confirmapprove(taskInfo.documents,taskInfo.taskId,true)">
                            <i class="zmdi zmdi-check-square fa-2x" data-toggle="tooltip"></i>
                          </button>

                          <button *ngIf="taskInfo.documents.overAllStatus=== 'Submitted'"
                            class="btn btn-danger btn-icon btn-sm me-2" type="button" rel="tooltip" data-method="Reject"
                            style="width: 32px;" title="Reject"
                            (click)="confirmrejection(taskInfo.documents,taskInfo.taskId,false)">
                            <i class="zmdi zmdi-close fa-2x" data-toggle="tooltip" style="width: 12px;"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="Comment" class="modal" style="top: 40px;">
      <div class="modal-dialog">
        <div class="modal-content animate">

          <div class="modal-header">
            <button type="button" class="btn-close" (click)="closeCommetModal()">×</button>
            <h4 class="modal-title"><b>Reject</b></h4>
          </div>

          <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
            <span>Comments for Rejection</span>
          </div>

          <div class="modal-footer form-group ">
            <span *ngIf="employeeDocs.comments === undefined || employeeDocs.comments?.length===0"
              style="color: red;">Comments are mandatory</span>
            <textarea class="form-control" #comments="ngModel" [(ngModel)]="employeeDocs.comments" rows="5"
              autofocus="true" required="required" autocomplete="off"></textarea>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-danger medium" (click)="approveDocument()">Reject</button>
            <button type="button" class="btn btn-primary" (click)="closeCommetModal()">Cancel</button>
          </div>

        </div>
      </div>
    </div>


    <div id="OnlyComment" class="modal" style="top: 40px;">
      <div class="modal-dialog">
        <div class="modal-content animate">

          <div class="modal-header">
            <button type="button" class="btn-close" (click)="closeOnlyCommetModal()">×</button>
            <h4 class="modal-title"><b>Comments</b></h4>
          </div>

          <div class="modal-body">
            <textarea class="form-control" [(ngModel)]="employeeDocs.comments" #comments="ngModel" rows="5"></textarea>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="closeOnlyCommetModal()">Ok</button>
          </div>
        </div>
      </div>
    </div>

    <div id="Approve" class="modal" style="top: 40px;">
      <div class="modal-dialog">
        <div class="modal-content animate">

          <div class="modal-header">
            <button type="button" class="btn-close" (click)="closeApproveModal()">×</button>
            <h4 class="modal-title"><b>Approve</b></h4>
          </div>

          <!-- <div class="modal-body">
            <span>Document will be Approved. Are you sure you want to continue ?</span>
          </div> -->
          <!-- <div *ngIf="employeeDocs.comments === undefined || employeeDocs.comments?.length===0" style="color: red;">
            Comments are mandatory</div> -->

          <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
            <span>Document will be Approved. Are you sure you want to continue ?</span>
            <div style="color: red;">
              Comments is Optional</div>
            <textarea class="form-control" [(ngModel)]="employeeDocs.comments" #comments="ngModel" rows="5"></textarea>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="approveDocument()">Approve</button>
            <button type="button" class="btn btn-danger medium" (click)="closeApproveModal()">Cancel</button>

          </div>
        </div>
      </div>
    </div>

    <div id="viewUploadFile" class="modal" style="top: 40px;">
      <div class="modal-dialog">
        <div class="modal-content animate">
          <div class="modal-header">
            <button type="button" class="btn-close" (click)="closeViewModal()">×</button>
            <h4 class="modal-title">Image Uploaded</h4>
          </div>

          <div id="fileViewer" class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
            <!-- auto scroll -->
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="closeViewModal()">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>